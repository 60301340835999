import cx from 'classnames'
import * as React from 'react'

import Icon from '../icon'
import type { Props as IconProps } from '../icon/icon'
import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import VisuallyHidden from '../visually-hidden'
import styles from './button.module.scss'
import { LoadingSpinner } from '..'

type ButtonPropsWithoutChildren = Omit<
  React.ComponentPropsWithoutRef<'button'>,
  'children'
>
type ButtonPropKeys = keyof ButtonPropsWithoutChildren

export type Props = {
  children?: React.ReactNode | string
  theme?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'invisible'
    | 'destructive'
    | 'dark'
  loading?: boolean
  disabled?: boolean
  loneIcon?: boolean
  fullWidth?: boolean
  onClick?: (arg0: React.MouseEvent<HTMLButtonElement>) => unknown
  icon?: IconProps['icon']
  iconColor?: IconProps['color']
  iconSize?: IconProps['size']
  className?: string
  href?: string
  selected?: boolean
  iconOnlyOnMobile?: boolean
  size?: 'small' | 'regular'
} & Partial<
  Omit<React.ComponentPropsWithoutRef<'a'>, 'children' | ButtonPropKeys>
> &
  Partial<ButtonPropsWithoutChildren> &
  SpacingProps

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, Props>(
  (props, ref) => {
    const {
      children,
      theme = 'primary',
      disabled = false,
      loading = false,
      icon,
      iconSize = props.size === 'small' ? 16 : 20,
      iconOnlyOnMobile,
      loneIcon,
      onClick,
      fullWidth,
      className,
      selected,
      iconColor,
      size = 'regular',
      ...otherProps
    } = props

    const classNames = cx(
      [styles.button],
      styles[`theme-${theme}`],
      styles[`size-${size}`],
      {
        [styles['lone-icon']]: loneIcon,
        [styles['icon-only-on-mobile']]: iconOnlyOnMobile,
        [styles['full-width']]: fullWidth,
        [styles['button-loading']]: loading,
        [styles['selected']]: selected,
      },

      className,
    )

    const is = props.href !== undefined && props.href !== '' ? 'a' : 'button'
    const hasDarkLoadingSpinner = theme === 'primary' || theme === 'destructive'
    return (
      <Spacer
        {...otherProps}
        is={is}
        ref={
          is === 'a'
            ? (ref as React.RefObject<HTMLAnchorElement>)
            : (ref as React.RefObject<HTMLButtonElement>)
        }
        disabled={disabled || loading}
        onClick={onClick}
        className={classNames}
      >
        {loading && (
          <>
            <LoadingSpinner
              size={24}
              className={styles.spinner}
              theme={hasDarkLoadingSpinner ? 'light' : 'dark'}
              color={hasDarkLoadingSpinner ? 'white' : 'sky'}
            />

            <VisuallyHidden is="span">Loading</VisuallyHidden>
          </>
        )}

        {icon !== undefined && (
          <Icon
            size={iconSize}
            color={iconColor}
            icon={icon}
            className={styles.icon}
            fill={theme === 'tertiary' ? 'grey' : 'white'}
          />
        )}

        <span className={cx(styles.content, loading && styles.hideContent)}>
          {children}
        </span>
      </Spacer>
    )
  },
)

export default Button
