import { ACTION_EVENT_TYPE_FRIENDLY_NAMES } from './constants'
import { ActionAnalyticsAttributes } from './types'
import type { ActionType, CustomAnalyticsParams } from './types'

export function stripDecorationFromScreenEventName(
  screenEventName: string,
  webAppName: string,
): string {
  const regex = new RegExp('^(screen.' + webAppName + '.)')
  return screenEventName.replace(regex, '')
}

export function convertStringToValue(str: string): string | number | boolean {
  if (isNaN(Number(str)) === false) {
    return +str
  }
  if (str === 'false' || str === 'true') {
    return str === 'true'
  }
  return str
}

export function getActionTypeFromEvent(
  event: React.MouseEvent<EventTarget>,
): ActionType {
  // We use the `detail` property on an event to determine whether it's come from a click or not.
  // It counts the number of clicks on the target element the event is coming from. So if it is
  // zero then it's come from a key press.
  // Sadly there's not a nicer way to do this :'(
  if (event.detail > 0) {
    return ACTION_EVENT_TYPE_FRIENDLY_NAMES.mousedown
  }
  return ACTION_EVENT_TYPE_FRIENDLY_NAMES.keydown
}

// This function turns an object of action analytics data into attributes for a React component. For example:
//
// <Button {...buildActionAnalyticsAttributes("hello")}>
//   Hello
// </Button>
export function buildActionAnalyticsAttributes(
  name: string,
  parameters: CustomAnalyticsParams = {},
): {
  [key in ActionAnalyticsAttributes]?: string
} {
  const parametersArray = Object.keys(parameters).map((parameterKey) => {
    const valueAsString =
      parameters[parameterKey] == null ? '' : parameters[parameterKey]
    return `${parameterKey.toString()}=${valueAsString}`
  })
  const actionTrackingParameters =
    parametersArray.length > 0 ? parametersArray.join('&') : ''
  return {
    [ActionAnalyticsAttributes.ACTION_TRACKING_NAME]: name,
    [ActionAnalyticsAttributes.ACTION_TRACKING_PARAMETERS]:
      actionTrackingParameters,
  }
}

export function keyEventIsTrackable(
  event: KeyboardEvent,
  element: HTMLElement,
): boolean {
  if (
    element.nodeName === 'BUTTON' &&
    event.key !== ' ' &&
    event.key !== 'Spacebar' &&
    event.key !== 'Enter'
  ) {
    return false
  }
  if (element.nodeName !== 'BUTTON' && event.key !== 'Enter') {
    return false
  }
  return true
}

export function browserIsCompatible(): boolean {
  // Check whether the storage APIs we use are available. This is commonly disabled in
  // a webview browser. Or by folks who have manually disabled it in their browsers.
  try {
    const storageTestKey = 'monzo_web_test'
    localStorage.setItem(storageTestKey, '')
    localStorage.removeItem(storageTestKey)

    sessionStorage.setItem(storageTestKey, '')
    sessionStorage.removeItem(storageTestKey)
  } catch (error) {
    return false
  }

  return true
}

export function isBlank(
  str?: string | null | undefined,
): str is null | undefined | '' {
  return str == null || str === ''
}
