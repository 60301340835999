import * as MoneyLib from '@mondough/money'

import apiFetch from './apiFetch'
import { DEFAULT_CURRENCY_CODE } from './constants'
import { captureSharedTabsException } from './errorHandling'
import { Tab, getTabParticipant } from './tab'

export const currencySymbol = MoneyLib.getSymbolFromCode(DEFAULT_CURRENCY_CODE)

export interface HistoricRateSingleProp {
  type: string
  provider: string
  provider_label: string
  first_available?: string
  latest_available?: string
  interest_rate_aer?: string
  interest_rate_gross?: string
  fixed_term_length?: string
  conditions?: string
}
type SettlementBreakdownResponse = {
  breakdown: {
    amount: string
    title: string
    user_id: string
  }[]
  can_send_reminder: boolean
  cta_markdown: string
  total: string
}

export type BreakdownItem = {
  amount: string
  title: string
  userId: string
}

export const convertToMoney = ({
  value,
  minorUnits = true,
}: {
  value: number | string
  minorUnits?: boolean
}): number => {
  const parsedValue = value.toString().includes(DEFAULT_CURRENCY_CODE)
    ? value.toString()
    : `${DEFAULT_CURRENCY_CODE} ${value}`
  const amount = MoneyLib.parse(parsedValue).minorUnitsInteger
  return minorUnits ? amount : amount / 100
}

export const roundToTwoDecimalPlaces = <
  As extends 'string' | 'number' = 'string',
>(
  value: number | string,
  as: As = 'string' as As,
) => {
  const roundedValue = (
    Math.round((Number(value) + Number.EPSILON) * 100) / 100
  ).toFixed(2)
  return (
    as === 'string' ? roundedValue : Number(roundedValue)
  ) as As extends 'string' ? string : number
}

export const markAsPaid = async ({
  payeeId,
  tab,
  amount,
}: {
  payeeId: string
  tab: Tab
  amount: number
}) => {
  try {
    await apiFetch(`tabs/${tab.id}/mark-as-paid`, {
      method: 'PUT',
      body: JSON.stringify({
        idempotency_key: crypto.randomUUID(),
        items: [
          {
            participant_id: getTabParticipant({ userId: payeeId, tab })?.id,
            amount: `${DEFAULT_CURRENCY_CODE} ${roundToTwoDecimalPlaces(
              amount,
              'string',
            )}`,
          },
        ],
      }),
    })
    return {
      success: true,
    }
  } catch (e) {
    const error = e as Error
    captureSharedTabsException(error, {
      tab_id: tab.id,
    })
    throw new Error('Error marking as paid', error)
  }
}

export const truncateToTwoDecimalsStr = (numStr: string): string => {
  if (numStr === '') {
    return ''
  }
  const index = numStr.indexOf('.')
  if (index !== -1 && numStr.length > index + 3) {
    return numStr.substring(0, index + 3) // Plus 3: 1 for the dot and 2 for the two decimal places
  }
  return Number(numStr).toFixed(2)
}

// Normalise the value to fit a max and min interval
export const normaliseValueToInterval = ({
  value = 0,
  min,
  max,
}: {
  value: number | string
  min: number
  max: number
}) => {
  if (typeof value === 'string') {
    value = Number(value)
  }
  return Math.min(Math.max(value, min), max)
}

export const getSettlementBreakdown = async (
  tabId: string,
): Promise<BreakdownItem[]> => {
  try {
    const { breakdown } = await apiFetch<SettlementBreakdownResponse>(
      `tabs/${tabId}/settlement-breakdown`,
      {
        method: 'GET',
      },
    )
    return breakdown.map((item) => ({
      amount: item.amount,
      title: item.title,
      userId: item.user_id,
    }))
  } catch (e) {
    const error = e as Error
    captureSharedTabsException(e, {
      tab_id: tabId,
    })
    throw new Error('Error requesting settlement breakdown', error)
  }
}

export const fetchMaxInterestRate = async (): Promise<string | null> => {
  try {
    const response = await apiFetch<{
      historic_rates: HistoricRateSingleProp[]
    }>('pots/savings/issues/historic-rates', {
      method: 'GET',
      authorizationType: 'client',
    })
    return (
      response?.historic_rates
        ?.filter(
          (issue: HistoricRateSingleProp) =>
            issue.type === 'fixed_frontbook' &&
            issue.fixed_term_length === '6 month',
        )
        .sort(
          (a, b) => Number(b.interest_rate_aer) - Number(a.interest_rate_aer),
        )[0]?.interest_rate_aer || null
    )
  } catch (e) {
    captureSharedTabsException(e as Error)
    return null
  }
}
