import cookies from 'nookies'

import {
  destroyClient as destroyAnalyticsClient,
  getClient as getAnalyticsClient,
} from '@mondough/analytics-v2'

import { setupAnalytics } from './analytics'
import {
  COOKIES_ACCEPTED,
  COOKIES_EXPIRY_DATE_IN_DAYS,
  COOKIE_BANNER_DISMISSED,
} from './constants'

// We set the expiry date to 30 days in the future.
const getExpiryDate = () => {
  const date = new Date()
  const expiryDaysInMs = COOKIES_EXPIRY_DATE_IN_DAYS * 24 * 60 * 60 * 1000
  date.setTime(date.getTime() + expiryDaysInMs)
  return date
}

export const setCookieConsent = async (value: string) => {
  if (value === 'false') {
    cookies.destroy({}, COOKIES_ACCEPTED)
    destroyAnalyticsClient()
  } else {
    if (getAnalyticsClient() == null) {
      await setupAnalytics()
    }
    cookies.set({}, COOKIES_ACCEPTED, value, {
      path: '/',
      expires: getExpiryDate(),
    })

    const analyticsClient = getAnalyticsClient()
    if (!analyticsClient) return
    analyticsClient.trackAction({
      name: 'cookie-preferences-updated',
      type: 'click',
      customParameters: {
        cookiesAccepted: true,
      },
    })
  }
}

export const setCookieBannerDismissed = () => {
  cookies.set({}, COOKIE_BANNER_DISMISSED, 'true', {
    path: '/',
    expires: getExpiryDate(),
  })
}

export const getCookie = (name: string): string | null | undefined => {
  const results = cookies.get({})
  return results[name]
}

export function getCookieConsent(): boolean {
  return getCookie(COOKIES_ACCEPTED) === 'true'
}
