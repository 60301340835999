import React from 'react'

import {
  buildActionAnalyticsAttributes,
  getClient,
} from '@mondough/analytics-v2'
import { Button, Checkbox, Text, Title } from '@mondough/monzo-ui'

import BottomSheet from '../../BottomSheet/BottomSheet'
import styles from './NotificationConsentBanner.module.scss'
import { NotificationConsentBannerProps } from './NotificationConsentBanner.types'

const NotificationConsentBanner: React.FC<NotificationConsentBannerProps> = ({
  showBanner,
  onButtonClick,
  error,
}: NotificationConsentBannerProps) => {
  const [checked, setChecked] = React.useState(true)

  if (!showBanner) return null

  return (
    <BottomSheet
      contentClassName={styles.content}
      overlayZIndex={102}
      containerZIndex={103}
    >
      <div className={styles.text}>
        <Title is="h2">Your email is used for tab notifications only</Title>
        <Text color="secondary">
          We won't market to you or share your email with anyone.
        </Text>
      </div>

      <Button
        theme="primary"
        fullWidth
        onClick={() => onButtonClick(checked)}
        className={styles.button}
        {...buildActionAnalyticsAttributes('confirm', {
          checked,
        })}
      >
        Got it
      </Button>
      <Checkbox
        checked={checked}
        onChange={() => {
          setChecked((value) => !value)
          const analyticsClient = getClient()
          if (!analyticsClient) return
          analyticsClient.trackAction({
            name: 'checkbox',
            type: 'click',
          })
        }}
        label="If you don't want to receive tab notifications, uncheck the box."
      />
      {error && (
        <Text size="small" color="destructive">
          Something went wrong. Please try again.
        </Text>
      )}
    </BottomSheet>
  )
}

export default NotificationConsentBanner
