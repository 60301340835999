import * as React from 'react'
export default function SvgChevronDoubleLeft(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path
        d="M 3.787 19.294 C 3.252 19.848 3.517 20.773 4.264 20.959 C 4.599 21.042 4.953 20.948 5.201 20.708 L 13.201 12.708 C 13.591 12.318 13.591 11.684 13.201 11.294 L 5.201 3.294 C 4.647 2.759 3.722 3.024 3.536 3.771 C 3.453 4.106 3.547 4.46 3.787 4.708 L 11.08 12.001 L 3.787 19.294 Z"
        fill="currentColor"
        fillRule="evenodd"
        transform="matrix(-1, 0, 0, -1, 16.998903, 24.001007)"
      />

      <path
        d="M 10.789 19.28 C 10.254 19.834 10.519 20.759 11.266 20.945 C 11.601 21.028 11.955 20.934 12.203 20.694 L 20.203 12.694 C 20.593 12.304 20.593 11.67 20.203 11.28 L 12.203 3.28 C 11.649 2.745 10.724 3.01 10.538 3.757 C 10.455 4.092 10.549 4.446 10.789 4.694 L 18.082 11.987 L 10.789 19.28 Z"
        fillRule="evenodd"
        fill="currentColor"
        transform="matrix(-1, 0, 0, -1, 31.002905, 23.973007)"
      />
    </svg>
  )
}
