import * as React from 'react'
export default function SvgWorkflowsShapes(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5873 6.88885H9.18254C9.71164 6.88885 10.1085 6.75393 10.373 6.48409C10.6376 6.21424 10.7698 5.80683 10.7698 5.26185V1.61895C10.7698 1.07397 10.6376 0.669199 10.373 0.404645C10.1085 0.1348 9.71164 -0.00012207 9.18254 -0.00012207H1.5873C1.0582 -0.00012207 0.661376 0.1348 0.396825 0.404645C0.132275 0.669199 0 1.07397 0 1.61895V5.26185C0 5.80683 0.132275 6.21424 0.396825 6.48409C0.661376 6.75393 1.0582 6.88885 1.5873 6.88885ZM3.50794 14.4128H10.1508C10.6852 14.4128 11.0847 14.2778 11.3492 14.008C11.6138 13.7434 11.746 13.3387 11.746 12.7937V10.1667C11.746 9.6164 11.6138 9.20899 11.3492 8.94444C11.0847 8.67459 10.6852 8.53967 10.1508 8.53967H3.50794C2.97884 8.53967 2.57937 8.67459 2.30952 8.94444C2.04497 9.20899 1.9127 9.6164 1.9127 10.1667V12.7937C1.9127 13.3387 2.04497 13.7434 2.30952 14.008C2.57937 14.2778 2.97884 14.4128 3.50794 14.4128ZM14.8968 13.3731H20.4048C20.9339 13.3731 21.3307 13.2381 21.5952 12.9683C21.8651 12.6985 22 12.291 22 11.7461V2.75388C22 2.2089 21.8651 1.80149 21.5952 1.53164C21.3307 1.2618 20.9339 1.12688 20.4048 1.12688H14.8968C14.3624 1.12688 13.963 1.2618 13.6984 1.53164C13.4339 1.80149 13.3016 2.2089 13.3016 2.75388V11.7461C13.3016 12.291 13.4339 12.6985 13.6984 12.9683C13.963 13.2381 14.3624 13.3731 14.8968 13.3731Z"
        fill="currentColor"
      />
    </svg>
  )
}
