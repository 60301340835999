import cx from 'classnames'
import Head from 'next/head'
import React from 'react'

import { SkipLink } from '@mondough/monzo-ui'

import { CookieBanner } from '../'
import styles from './PageWrapper.module.scss'

type Props = {
  title?: string
  className?: string
  children: React.ReactNode
}

export default function PageWrapper({
  children,
  className,
  title = 'Monzo | Shared Tabs',
}: Props) {
  return (
    <>
      <SkipLink target="main" />
      <CookieBanner />
      <div className={cx(styles.wrapper, className)}>
        <Head>
          <title>{title}</title>
        </Head>
        {children}
      </div>
    </>
  )
}
