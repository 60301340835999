/**
 * @file lib.money
 * @module @mondough/money
 *
 * The purpose of this library is to ensure correct handling of money values.
 * It allows you to create instances of the Money.Value class below with an
 * amount and associated currency. Amounts may be larger or smaller than a
 * standard JavaScript number value can hold, and we don't want to be subject
 * to floating-point rounding error, so we use the decimal.js library to hold
 * the value. The class wraps operations provided by decimal.js in order to
 * check that the currency of the operands is the same.
 *
 * A central principle is that, from the input, through any calculation, to any
 * display of the result of an operation, the value should never be converted
 * to a JavaScript number. To that end, please consider `minorUnitsInteger` an
 * unsafe operation. In the future it may be renamed to something more scary.
 *
 * The "serialised format" talked about in this file isn't formally specified
 * anywhere as far as I know, but it is intended to be compatible with the
 * format understood by
 * https://godoc.tools.prod.prod-ffs.io/pkg/github.com/monzo/wearedev/libraries/decimal/#ParseMoney
 * and
 * https://godoc.tools.prod.prod-ffs.io/pkg/github.com/monzo/wearedev/libraries/decimal/#Money.String
 *
 * The Value class is exported, but please use the helper functions
 * `fromMinorUnits`, `fromMajorUnits`, `parse`, or `maybeParse` at the bottom
 * of the file to create values. They are more explicit about the nature of the
 * arguments, and mean that you don't need the `new` keyword.
 */

import { Decimal as DecimalUnsafe } from 'decimal.js'

import rawCurrencies from './currencies.json'
import {
  CurrencyMismatchError,
  CurrencyNotFoundError,
  IntegerOverflowError,
  IntegerUnderflowError,
  MoneyParseError,
} from './errors'

export {
  CurrencyMismatchError,
  CurrencyNotFoundError,
  IntegerOverflowError,
  IntegerUnderflowError,
  MoneyParseError,
}

export type Currency = Readonly<{
  code: string
  numericCode: string | null | undefined
  name: string
  symbol: string
  thousandsSeparator: string
  decimalSeparator: string
  symbolOnLeft: boolean
  spaceBetweenAmountAndSymbol: boolean
  decimalDigits: number | null
}>

type FormatOptions = Readonly<{
  separateThousands?: boolean
  showNegative?: boolean
  showPositive?: boolean
  // use the currency's decimal and thousand separators
  useCurrencySeparators?: boolean
  // Don't display the trailing ".00" when it's zero
  hideFractionalWhenZero?: boolean
}>

type DecimalOptions = Readonly<{
  rounding?:
    | typeof DecimalUnsafe.ROUND_DOWN
    | typeof DecimalUnsafe.ROUND_UP
    | typeof DecimalUnsafe.ROUND_HALF_UP
    | typeof DecimalUnsafe.ROUND_HALF_EVEN
}>

type AcceptableDecimal = DecimalUnsafe.Value

// Typescript can infer types for the JSON, but we want to use it as a Record
const currencies: Record<string, Currency> = rawCurrencies

export function getCurrencies(): Record<string, Currency> {
  return currencies
}

function getCurrency(c: string | Currency): Currency {
  if (typeof c !== 'object') return getCurrencyByCode(c)
  else return c
}

function getCurrencyByCode(code: string): Currency {
  const currency = currencies[code]
  if (currency == null) throw new CurrencyNotFoundError(code)
  return currency
}

export function getCurrencyByNumericCode(code: string): Currency {
  const currenciesArray = Object.values(currencies)

  const currency = currenciesArray.find((ccy) => ccy.numericCode === code)

  if (currency == null) throw new CurrencyNotFoundError(code)
  return currency
}

/**
 * Instances of this class are single, immutable values of one currency. The
 * constructor isn't private but please use `fromMajorUnits` or
 * `fromMinorUnits` at the module level below instead because then you don't
 * have to contend with the `new` keyword.
 *
 * @param minorUnits value in "minor units"
 * @param currency string of currency code, or currency object
 */

export class Value {
  readonly #Decimal: typeof DecimalUnsafe
  readonly #minorUnits: DecimalUnsafe
  readonly #currency: Currency
  readonly #decimalOptions?: DecimalOptions | null | undefined

  constructor(
    minorUnits: AcceptableDecimal,
    currency: string | Currency,
    decimalOptions?: DecimalOptions | null | undefined,
  ) {
    this.#Decimal = DecimalUnsafe.clone({
      rounding: decimalOptions?.rounding ?? DecimalUnsafe.ROUND_DOWN,
    })

    this.#decimalOptions = decimalOptions
    this.#minorUnits = new this.#Decimal(minorUnits)
    this.#currency = getCurrency(currency)
  }

  // We're going to use computed properties against the wishes of the ESLint
  // config (that we wrote) because we're being careful here that these
  // methods don't have side-effects.
  /* eslint-disable no-restricted-syntax */

  /**
   * An object describing the currency associated with this value.
   */
  get currency(): Currency {
    return this.#currency
  }

  /**
   * The ISO 4217 3-letter currency code.
   */
  get currencyCode(): string {
    return this.#currency.code
  }

  /**
   * A Decimal object giving the value of this object, in major units. For
   * example, if this value was created with `fromMajorUnits('GBP', '2.23')`,
   * the Decimal object would be `Decmial('2.23')`.
   */
  get majorUnitsDecimal(): DecimalUnsafe {
    return this.#minorUnits.dividedBy(10 ** (this.#currency.decimalDigits ?? 0))
  }

  /**
   * A Decimal object giving the value of this object in minor units. For
   * example, if this value was created with `fromMajorUnits('GBP', '2.23')`,
   * the Decimal object would be `Decimal('223')`.
   */
  get minorUnitsDecimal(): DecimalUnsafe {
    return new this.#Decimal(this.#minorUnits)
  }

  /**
   * A native JavaScript number giving the value of this object in minor units.
   * *Unsafe* — the range of a Decimal object is practically unlimited,
   * whereas the range of a JS number is very much not. This will crash if
   * the JS number would over/underflow. Only use if there is absolutely no
   * other option.
   */
  get minorUnitsInteger(): number {
    const n = this.#minorUnits.toDecimalPlaces(0)
    checkDecimalToNumberLimits(n)
    return n.toNumber()
  }

  /* eslint-enable */

  /**
   * Return a new instance with a different value, but the same currency.
   * @param amount New amount to use.
   */
  withMinorUnits(amount: AcceptableDecimal): Value {
    return new Value(amount, this.#currency, this.#decimalOptions)
  }

  /**
   * Return a representation in the canonical string serialisation. This is
   * intended mainly for machine consumption, but is readable by humans too.
   * It is aliased as `toString`.
   */
  toJSON(): string {
    return `${this.#currency.code} ${this.majorUnitsDecimal.toFixed(4)}`
  }

  /**
   * Alias of `toJSON`.
   */
  toString(): string {
    return this.toJSON()
  }

  /**
   * Format this value as a nice human-readable string, using the right
   * currency symbol, negative sign, and so on. For example, a value created
   * with `fromMinorUnits('GBP', 223)` would return `£2.23`.
   * @param opts See `FormatOptions`
   */
  format(opts?: FormatOptions): string {
    const { showPositive = false, showNegative = true } =
      opts == null ? {} : opts
    const amountSpace = this.#currency.spaceBetweenAmountAndSymbol ? ' ' : ''
    const positive = showPositive && this.#minorUnits.isPositive() ? '+' : null
    const negative = showNegative && this.#minorUnits.isNegative() ? '-' : null
    const sign = positive || negative || ''
    const amount = this.formatAmount({ ...opts, showNegative: false }) // we'll handle the negative symbol here
    if (this.#currency.symbolOnLeft)
      return `${sign}${this.#currency.symbol}${amountSpace}${amount}`
    else return `${sign}${amount}${amountSpace}${this.#currency.symbol}`
  }

  /**
   * Format this value as a nice human-readable string, but just the number.
   * For example, a value created with `fromMinorUnits('GBP', 223) would
   * return `2.23`.
   * @param opts See `FormatOptions`
   */
  formatAmount(opts?: FormatOptions): string {
    const {
      separateThousands = true,
      showNegative = true,
      useCurrencySeparators = false,
      hideFractionalWhenZero = false,
    } = opts == null ? {} : opts

    const dp = this.#currency.decimalDigits

    const { decimalSeparator = '.', thousandsSeparator = ',' } =
      useCurrencySeparators ? this.#currency : {}
    const noSep = this.majorUnitsDecimal.toFixed(dp ?? 0)
    if (separateThousands) {
      const [whole, fractional] = noSep.split('.')
      // Force 'en' locale, otherwise there's a danger we'd get `.`
      // thousands separators when we don't want them.
      // We replace the thousands separator manually instead
      const unformattedAbsStr = Math.abs(
        Number.parseInt(whole ?? '', 10),
      ).toLocaleString('en')

      const hideFractional =
        hideFractionalWhenZero && fractional?.match(/^0+$/) != null
      const fractionalStr =
        fractional == null || hideFractional
          ? ''
          : `${decimalSeparator}${fractional}`

      const absStr =
        (thousandsSeparator === ','
          ? unformattedAbsStr
          : unformattedAbsStr.replace(/,/gi, thousandsSeparator)) +
        fractionalStr

      return this.#minorUnits.isNegative() && showNegative
        ? `-${absStr}`
        : absStr
    } else {
      return showNegative ? noSep : noSep.replace(/^-/, '')
    }
  }

  /**
   * Add two values. If another Money.Value is passed, it must be of the same
   * currency or an error will be thrown. A new instance is returned; neither
   * operand is modified.
   * @param x right-hand side
   */
  plus(x: Value | AcceptableDecimal): Value {
    return this.withMinorUnits(this.#minorUnits.plus(this._normaliseOperand(x)))
  }

  /**
   * Subtract the passed value from this one. If another Money.Value is passed,
   * it must be of the same currency or an error will be thrown. A new instance
   * is returned; neither operand is modified.
   * @param x right-hand side
   */
  minus(x: Value | AcceptableDecimal): Value {
    return this.withMinorUnits(
      this.#minorUnits.minus(this._normaliseOperand(x)),
    )
  }

  /**
   * Multiply a value by a given number.
   * @param x multiplier
   */
  times(x: number): Value {
    return this.withMinorUnits(this.#minorUnits.times(x))
  }

  /**
   * Rounds the value using the rounding options passed in in DecimalOptions
   */
  round(): Value {
    return this.withMinorUnits(this.#minorUnits.round())
  }

  /**
   * Apply the absolute function to this value and return the result in a new
   * instance.
   */
  absoluteValue(): Value {
    return this.withMinorUnits(this.#minorUnits.absoluteValue())
  }

  /**
   * Negate this value and return the result in a new instance.
   */
  negated(): Value {
    return this.withMinorUnits(this.#minorUnits.negated())
  }

  /**
   * Return a boolean indicating if this value is greater than the passed
   * value or not. If another Money.Value is passed, it must be of the same
   * currency or an error will be thrown.
   */
  greaterThan(x: Value | AcceptableDecimal): boolean {
    return this.#minorUnits.greaterThan(this._normaliseOperand(x))
  }

  /**
   * Return a boolean indicating if this value is less than the passed
   * value or not. If another Money.Value is passed, it must be of the same
   * currency or an error will be thrown.
   */
  lessThan(x: Value | AcceptableDecimal): boolean {
    return this.#minorUnits.lessThan(this._normaliseOperand(x))
  }

  /**
   * Return a boolean indicating if this value is greater than or equal to the
   * passed value or not. If another Money.Value is passed, it must be of the
   * same currency or an error will be thrown.
   */
  greaterThanOrEqualTo(x: Value | AcceptableDecimal): boolean {
    return this.#minorUnits.greaterThanOrEqualTo(this._normaliseOperand(x))
  }

  /**
   * Return a boolean indicating if this value is less than or equal to the
   * passed value or not. If another Money.Value is passed, it must be of the
   * same currency or an error will be thrown.
   */
  lessThanOrEqualTo(x: Value | AcceptableDecimal): boolean {
    return this.#minorUnits.lessThanOrEqualTo(this._normaliseOperand(x))
  }

  /**
   * Return a boolean indicating if this value is equal in magnitude to the
   * passed value. If another Money.Value is passed, it must be of the same
   * currency or an error will be thrown.
   */
  equalTo(x: Value | AcceptableDecimal): boolean {
    return this.#minorUnits.equals(this._normaliseOperand(x))
  }

  isNegative(): boolean {
    return this.#minorUnits.isNegative()
  }

  isPositive(): boolean {
    return this.#minorUnits.isPositive()
  }

  /**
   * Return a new value that is constrained to the range indicated by the lower-
   * and upper-bound arguments. If Money.Value objects are passed, they must be
   * of the same currency or an error will be thrown.
   * @param lower lower-bound
   * @param upper upper-bound
   */
  clamp(
    lower: Value | AcceptableDecimal,
    upper: Value | AcceptableDecimal,
  ): Value {
    const lowerVal = this._normaliseOperand(lower)
    if (this.#minorUnits.lessThan(lowerVal))
      return this.withMinorUnits(lowerVal)
    const upperVal = this._normaliseOperand(upper)
    if (this.#minorUnits.greaterThan(upperVal))
      return this.withMinorUnits(upperVal)
    return this
  }

  _normaliseOperand(x: Value | AcceptableDecimal): DecimalUnsafe {
    if (x instanceof Value) {
      if (x.#currency !== this.#currency) {
        throw new CurrencyMismatchError(this.currencyCode, x.currencyCode)
      } else {
        return x.#minorUnits
      }
    } else if (
      typeof x === 'number' ||
      typeof x === 'string' ||
      x instanceof DecimalUnsafe
    ) {
      return new this.#Decimal(x)
    } else {
      throw new Error('_normaliseOperand passed a weird Value')
    }
  }
}

function checkDecimalToNumberLimits(d: DecimalUnsafe): void {
  if (d.greaterThan(Number.MAX_SAFE_INTEGER))
    throw new IntegerOverflowError(d.toString())
  if (d.lessThan(Number.MIN_SAFE_INTEGER))
    throw new IntegerUnderflowError(d.toString())
}

/**
 * Create an instance of Money.Value given a value in "major" units (pounds,
 * dollars, etc.)
 * @param currency Currency code or object for this value.
 * @param amount Amount for this value. Note that the type signature
 * intentionally doesn't allow `number` here, to avoid floating-point rounding
 * danger.
 */
export const fromMajorUnits = (
  currency: Currency | string,
  amount: string | DecimalUnsafe,
  decimalOptions?: DecimalOptions,
): Value => {
  const Decimal = getDecimalInstance(decimalOptions)
  const currencyObj = getCurrency(currency)
  const minorUnits = new Decimal(amount).times(
    10 ** (currencyObj.decimalDigits ?? 0),
  )
  return new Value(minorUnits, currencyObj, decimalOptions)
}

/* Converts '3,500.00' or '3.500,00' (depending on currency) to a Money value */
export function newFromStringWithThousandsSeparator(
  currencyCode: string,
  amount: string,
): Value {
  const currency = getCurrencyByCode(currencyCode)
  const { thousandsSeparator, decimalSeparator } = currency
  // remove the thousands separator to get the full major units
  // escape it, as if it's a decimal point the regex will interpret it as matching any character
  const noThousands = amount.replace(
    new RegExp(`\\${thousandsSeparator}`, 'gi'),
    '',
  )

  // replace the decimal separator with a decimal point if it's not one already, otherwise the decimal lib won't be able to parse it.
  const majorUnits =
    decimalSeparator === '.'
      ? noThousands
      : noThousands.replace(new RegExp(decimalSeparator), '.')
  return fromMajorUnits(currencyCode, majorUnits)
}

function newFromParseResults(
  amount: string,
  currencyCode: string,
  decimalOptions?: DecimalOptions,
): Value {
  return fromMajorUnits(currencyCode.toUpperCase(), amount, decimalOptions)
}

/**
 * Parse a string in the serialised format and return a Money.Value object.
 * Throws an error if the parsing fails.
 * @param s String to be parsed.
 */
export function parse(s: string, decimalOptions?: DecimalOptions): Value {
  const match =
    /^\s*(([A-Za-z]{3})\s*(-?\d+(?:\.\d+)?)|(-?\d+(?:\.\d+)?)\s*([A-Za-z]{3}))\s*$/.exec(
      s,
    )

  if (match == null) {
    throw new MoneyParseError(`Unable to parse "${s}" as Money`)
  }
  const [
    // eslint-disable-next-line no-unused-vars
    _all,
    // eslint-disable-next-line no-unused-vars
    _allWithoutWhitespace,
    currencyCodeBefore,
    amountCurrencyBefore,
    amountCurrencyAfter,
    currencyCodeAfter,
  ] = match
  if (
    currencyCodeBefore == null &&
    amountCurrencyAfter != null &&
    currencyCodeAfter != null
  ) {
    return newFromParseResults(
      amountCurrencyAfter,
      currencyCodeAfter,
      decimalOptions,
    )
  } else if (amountCurrencyBefore != null && currencyCodeBefore != null) {
    return newFromParseResults(
      amountCurrencyBefore,
      currencyCodeBefore,
      decimalOptions,
    )
  } else {
    throw new MoneyParseError(`Unable to parse "${s}" as Money`)
  }
}

/**
 * Parse a string in the serialised format. Return `null` if the parsing fails,
 * and the Money.Value object otherwise.
 * @param s String to be parsed.
 */
export function maybeParse(s: string): Value | null | undefined {
  try {
    return parse(s)
  } catch (err) {
    return null
  }
}

export function getSymbolFromCode(code: string): string {
  const currency = getCurrencyByCode(code)
  return currency.symbol
}

/**
 * Create a new instance of Decimal with any, if provided, configuration
 * @param options Config to pass down to the underlying Decimal instance
 */
function getDecimalInstance(options?: DecimalOptions) {
  return DecimalUnsafe.clone({
    rounding: options?.rounding ?? DecimalUnsafe.ROUND_DOWN,
  })
}

/**
 * Create an instance of Money.Value given a value in "minor" units (pence,
 * cents, etc.).
 * @param currency Currency code or object for this value.
 * @param amount Amount for this value. A JS number is allowed here, because
 * we are only dealing in integers.
 */
export const fromMinorUnits = (
  currency: Currency | string,
  amount: AcceptableDecimal,
  decimalOptions?: DecimalOptions,
): Value => new Value(amount, currency, decimalOptions)

export type MoneyValue = Value
