import * as React from 'react'
export default function SvgCopy(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 20 23">
      <path
        d="M14.5.735h-12c-1.1 0-2 .9-2 2v14h2v-14h12v-2zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-11v-14h11v14z"
        fill="currentColor"
      />

      <path
        d="M15.5 1.735a1 1 0 11-2 0 1 1 0 012 0zM2.5 16.735a1 1 0 11-2 0 1 1 0 012 0z"
        fill="currentColor"
      />
    </svg>
  )
}
