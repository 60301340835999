import * as React from 'react'
export default function SvgSwap(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 22 20">
      <path
        d="M4.8 9.3a1 1 0 011.4 1.4l-2.79 2.8H12a1 1 0 011 .88v.12a1 1 0 01-1 1H3.41l2.8 2.8a1 1 0 01.08 1.31l-.08.1a1 1 0 01-1.42 0L.3 15.2l-.07-.08A1 1 0 010 14.5v.09a1 1 0 010-.07v-.02a1.02 1.02 0 01.2-.6 1 1 0 01.1-.1l-.09.08a1 1 0 01.08-.09zm12.4-9l4.5 4.5a1 1 0 01.09.08l-.08-.09a1 1 0 01.29.71v.02a1 1 0 010 .07V5.5a1 1 0 01-.21.62h-.01l-.07.09-4.5 4.5a1 1 0 01-1.42-1.42l2.8-2.79H10a1 1 0 010-2h8.59l-2.8-2.8A1 1 0 0117.21.3z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
