import * as React from 'react'

import { Button, Text } from '@mondough/monzo-ui'

import AppStoreButton from '../AppStoreButton/AppStoreButton'
import styles from './Footer.module.scss'
import { FooterProps } from './Footer.props'

export default function Footer({ links }: FooterProps) {
  return (
    <footer className={styles.footer}>
      <div className={styles.inner}>
        <section className={styles.links}>
          {links.map((link) => (
            <Button
              key={link.id}
              href={link.href}
              theme="invisible"
              className={styles.link}
            >
              {link.children}
            </Button>
          ))}
        </section>
        <Text color="white" className={styles.text}>
          Monzo Bank Limited is a company registered in England and Wales
          (No.09446231). Monzo Bank Limited is authorised by the Prudential
          Regulation Authority and regulated by the Financial Conduct Authority
          and the Prudential Regulation Authority. Our financial Services
          Register number is 730427. Our address is Broadwalk House, 5 Appold
          St, London EC2A 2AG.
        </Text>
        <section className={styles.download}>
          <AppStoreButton href="https://monzo.com/download" store="app-store" />
          <AppStoreButton
            href="https://monzo.com/download"
            store="google-play"
          />
        </section>
      </div>
    </footer>
  )
}
