import '../shared-types'

import { DialogContent, DialogOverlay } from '@reach/dialog'
import cx from 'classnames'
import * as React from 'react'

import { isBlank } from '@mondough/utils/formatting'

import Button from '../button'
import type { Colors } from '../shared-types'
import Title from '../title'
import { DialogHeader } from './dialog-header'
import styles from './dialog.module.scss'

export type Props = {
  open?: boolean
  className?: string
  headerClassName?: string
  children?: React.ReactNode
  title?: string
  noHeaderBorder?: boolean
  subtitle?: string | React.ReactNode
  hideHeader?: boolean
  onDismiss?: (event: React.SyntheticEvent<Element, Event> | void) => void
  onGoBack?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isDismissable?: boolean
  showBackButton?: boolean
  showCloseButton?: boolean
  hasBlurOverlay?: boolean
  overlayClassName?: string
  backgroundColor?: 'primary' | 'secondary' | Colors
  ariaLabel?: string
  actions?: React.ReactNode
  unclampHeight?: boolean
  innerScrollWrapper?: boolean
  leftAlign?: boolean
}

/**
 * To correctly render the Dialog component, make sure your Next app's `_app.js/tsx` imports the following at the top:
 * `import '@mondough/monzo-ui/src/components/dialog/dialog-globals.scss'`
 * `import '@mondough/monzo-ui/src/components/tabs/tabs-globals.scss'`
 */
function Dialog(props: Props) {
  const {
    children,
    className,
    headerClassName,
    open,
    onDismiss,
    onGoBack,
    isDismissable = true,
    showCloseButton = true,
    showBackButton = false,
    title = '',
    noHeaderBorder,
    subtitle,
    hideHeader,
    hasBlurOverlay,
    overlayClassName,
    ariaLabel,
    backgroundColor,
    unclampHeight,
    innerScrollWrapper,
    leftAlign,
    ...restProps
  } = props

  const _classNames = cx(
    {
      [styles.dialog]: true,
      [styles[`background-${backgroundColor ?? ''}`]]:
        !isBlank(backgroundColor),
      [styles.unclampHeight]: unclampHeight,
      [styles.innerScrollWrapper]: innerScrollWrapper,
    },

    className,
  )

  const headerClassNames = cx(
    {
      [styles.leftAlignHeader]: leftAlign,
    },
    headerClassName,
  )

  return (
    <DialogOverlay
      isOpen={open}
      // ReachUI will begin to throw errors if no function is passed in to
      // onDismiss
      onDismiss={isDismissable ? onDismiss : () => undefined}
      className={cx(overlayClassName, hasBlurOverlay && 'blurOverlay')}
    >
      <DialogContent
        {...restProps}
        className={_classNames}
        aria-label={ariaLabel != null && ariaLabel !== '' ? ariaLabel : title}
        {...restProps}
      >
        {showBackButton && onGoBack != null && (
          <Button
            // Stop the analytics library in app.web-banking from attempting to track this button.
            // We don't want this component to be tied to the analytics library so lets leave
            // the responsibility for analytics with the parent component using <Dialog>
            data-monzoanalytics-no-tracking
            theme="tertiary"
            icon="chevron-left"
            loneIcon
            title="Go back"
            onClick={onGoBack}
            className={styles.back}
          />
        )}

        {showCloseButton && (
          <Button
            data-monzoanalytics-no-tracking
            data-testid="close-dialog-button"
            theme="tertiary"
            icon="close"
            loneIcon
            title="Close"
            onClick={onDismiss}
            className={styles.close}
          />
        )}

        {!hideHeader && (
          <DialogHeader noBorder={noHeaderBorder} className={headerClassNames}>
            <Title is="h1">{title}</Title>
            {subtitle ? <div>{subtitle}</div> : null}
          </DialogHeader>
        )}

        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export default Dialog
