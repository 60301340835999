import '@mondough/money'

import cx from 'classnames'
import * as React from 'react'

import * as MoneyLib from '@mondough/money'
import type { MoneyValue } from '@mondough/money'

import Text from '../text'
import { Props as TextProps } from '../text/text'
import VisuallyHidden from '../visually-hidden'
import styles from './money.module.scss'

const defaultCurrencyCode = 'GBP'

type FormatOptions = {
  separateThousands?: boolean
  showNegative?: boolean
  showPositive?: boolean
}

export type Props = {
  amount: MoneyValue | number | string
  currencyCode?: string
  localAmount?: void | MoneyValue | number | string
  localCurrencyCode?: string | null | undefined
  options?: FormatOptions
  majorClassName?: string
  minorClassName?: string
  symbolClassName?: string
  className?: string
} & TextProps

// Amounts can come from our backend in a few formats, so to be defensive we support a few:
// * Minor units - an integer or string of '100' with separate currency code of 'GBP' represents £10.
// * Money Value - an instance of the lib.money class.
function getInitialAmountAsMoney(
  amount: MoneyValue | number | string,
  currencyCode: string,
): MoneyValue {
  if (typeof amount === 'string' || typeof amount === 'number') {
    return MoneyLib.fromMinorUnits(currencyCode, amount)
  }

  if (amount == null) {
    return MoneyLib.parse('GBP 0.00')
  }

  return amount
}

export default function Money(props: Props) {
  const {
    color = 'primary',
    amount,
    majorClassName,
    minorClassName,
    className,
    options,
    size = 'large',
    symbolClassName,
    currencyCode: propCurrencyCode,
    ...otherProps
  } = props

  const currencyCode =
    amount instanceof MoneyLib.Value
      ? amount.currencyCode
      : propCurrencyCode ?? defaultCurrencyCode

  const money = getInitialAmountAsMoney(amount, currencyCode)
  if (money == null) {
    return null
  }
  const isNegative = money.lessThan(MoneyLib.fromMinorUnits(currencyCode, 0))

  const { symbolOnLeft, symbol, decimalSeparator: separator } = money.currency
  const showNegative = options?.showNegative ?? true
  const showPositive = options?.showPositive ?? false

  const [major, minor] = money
    .formatAmount({ ...options, showNegative: false, showPositive: false })
    .split(separator)

  const currencySymbol = (
    <span aria-hidden className={cx(styles.symbol, symbolClassName)}>
      {symbol}
    </span>
  )

  // We hide the `<span>` from assistive tech and use a VisuallyHidden representation of the money instead.
  // This is because screen readers often fail to interpret two separate spans as one amount of money.
  // Since we're just separating them to format them differently, it's better just to have the whole amount for screen readers.
  return (
    <Text {...otherProps} className={className} color={color} size={size}>
      <VisuallyHidden is="span">{money.format(options)}</VisuallyHidden>
      <span aria-hidden className={cx(styles.major, majorClassName)}>
        {showNegative === true && isNegative && '-'}
        {showPositive === true && !isNegative && '+'}
      </span>
      {symbolOnLeft && currencySymbol}
      <span aria-hidden className={cx(styles.major, majorClassName)}>
        {major}
      </span>
      <span aria-hidden className={cx(styles.minor, minorClassName)}>
        .{minor}
      </span>
      {symbolOnLeft === false && currencySymbol}
    </Text>
  )
}
