import * as React from 'react'

export default function SvgCheckmarkCircle(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path
        d="M12.005 22C17.488 22 22 17.488 22 12.005 22 6.512 17.488 2 11.995 2 6.502 2 2 6.512 2 12.005 2 17.488 6.512 22 12.005 22Zm-1.048-5.248c-.425 0-.774-.198-1.066-.566l-2.21-2.624c-.226-.264-.31-.51-.31-.793 0-.594.48-1.066 1.075-1.066.34 0 .604.141.86.443l1.632 1.982 3.653-5.795c.255-.406.566-.613.944-.613.585 0 1.113.434 1.113 1.028 0 .236-.094.5-.264.746l-4.389 6.673c-.245.368-.623.585-1.038.585Z"
        fill="currentColor"
      />
    </svg>
  )
}
