import cx from 'classnames'
import React from 'react'

import styles from './BottomSheet.module.scss'
import { BottomSheetProps } from './BottomSheet.types'

const BottomSheet: React.FC<BottomSheetProps> = ({
  children,
  contentClassName,
  overlayZIndex,
  containerZIndex,
}: BottomSheetProps) => (
  <>
    <div
      className={styles.overlay}
      style={overlayZIndex ? { zIndex: overlayZIndex } : undefined}
    />
    <section
      className={styles.container}
      role="dialog"
      style={containerZIndex ? { zIndex: containerZIndex } : undefined}
    >
      <div className={cx([styles.content, contentClassName])}>{children}</div>
    </section>
  </>
)

export default BottomSheet
