import * as React from 'react'
import { SVGProps } from 'react'
const SvgSubscriptionSparkles = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.31607 14.1666C8.02289 14.1666 7.80967 13.9734 7.72971 13.6136C7.58312 12.9673 7.44319 12.3144 7.2966 11.6681C7.07005 10.6953 6.46369 10.0957 5.49086 9.86916C4.86451 9.72258 4.23817 9.58933 3.61848 9.44275C3.56518 9.42943 3.51187 9.42276 3.45857 9.40278C3.17205 9.3095 2.9988 9.07631 3.00547 8.80314C3.00547 8.52997 3.19204 8.32342 3.49855 8.25013C4.18486 8.09023 4.87118 7.94365 5.55749 7.77709C6.49701 7.55056 7.07005 6.95091 7.28993 6.0248C7.44319 5.38519 7.57645 4.74557 7.72305 4.10596C7.73637 4.03933 7.75636 3.96604 7.78302 3.89941C7.88963 3.63957 8.13617 3.47966 8.39603 3.49965C8.64924 3.5263 8.84913 3.7062 8.91577 3.98603C9.07568 4.67894 9.21561 5.37853 9.38886 6.06478C9.61541 6.97757 10.1951 7.55056 11.108 7.77709C11.721 7.93033 12.3407 8.05692 12.9604 8.19683C13.027 8.21016 13.1003 8.23015 13.1669 8.24347C13.4801 8.33009 13.6733 8.56328 13.6667 8.84978C13.6667 9.12294 13.4668 9.34281 13.1469 9.4161C12.4939 9.56934 11.8343 9.70926 11.1813 9.85584C10.2284 10.0757 9.61541 10.6487 9.38886 11.6081C9.22894 12.281 9.08235 12.9673 8.92243 13.6402C8.84913 13.96 8.60926 14.1533 8.32274 14.1533L8.31607 14.1666Z"
      fill="#091723"
    />
    <path
      d="M2.99213 6.16483C2.84554 6.16483 2.73893 6.07155 2.69895 5.89166C2.62565 5.56519 2.55902 5.24538 2.47906 4.91891C2.36579 4.43254 2.06594 4.13272 1.57952 4.01945C1.26635 3.94616 0.953178 3.87953 0.640006 3.80624C0.613353 3.80624 0.5867 3.79292 0.560047 3.78626C0.413455 3.73962 0.333496 3.62635 0.333496 3.48644C0.333496 3.34652 0.426782 3.24658 0.580036 3.2066C0.919862 3.12665 1.26635 3.05336 1.60618 2.97341C2.0726 2.86015 2.35912 2.56033 2.4724 2.09394C2.55236 1.77413 2.61899 1.45432 2.68562 1.13451C2.69229 1.1012 2.69895 1.06789 2.71228 1.03457C2.77891 0.901321 2.89885 0.821369 3.03211 0.834695C3.15871 0.84802 3.25866 0.934635 3.29198 1.07455C3.37194 1.42101 3.44523 1.76747 3.52519 2.11393C3.63847 2.57365 3.92499 2.86015 4.38475 2.97341C4.69126 3.0467 5.00443 3.11333 5.31094 3.18662C5.34426 3.19328 5.37757 3.19994 5.41755 3.21327C5.57747 3.25991 5.67076 3.37317 5.67076 3.51975C5.67076 3.65967 5.57081 3.76627 5.41089 3.79958C5.08439 3.87287 4.75789 3.94616 4.42473 4.01945C3.94498 4.12605 3.64513 4.41255 3.52519 4.89226C3.44523 5.23206 3.37194 5.57185 3.29198 5.91165C3.252 6.07155 3.13872 6.16483 2.99213 6.17149V6.16483Z"
      fill="#091723"
    />
  </svg>
)
export default SvgSubscriptionSparkles
