import 'what-input'
import '@mondough/monzo-ui/src/tokens/themes.scss'
import '@mondough/monzo-ui/src/components/dialog/dialog-globals.scss'

import '../scss/globals.scss'

import { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import React from 'react'

import {
  NextPageAnalytics,
  getClient as getAnalyticsClient,
  useNextScreenTracking,
} from '@mondough/analytics-v2'
import { MonzoUiThemeProvider, Theme } from '@mondough/monzo-ui'
import { initErrorHandling } from '@mondough/next-utils'
import { displayExternalSelfXSSWarning } from '@mondough/self-xss-warning'

import { ErrorBoundary, Layout } from '../components'
import { AuthProvider, ReferralProvider, TabProvider } from '../providers'
import {
  generateHashedFingerprint,
  getCookieConsent,
  ignoredErrors,
  initializeServerSideLogging,
  setupAnalytics,
} from '../utils'

type InitialProps = {
  hashedFingerprint?: string | null
}
interface AppPropsWithAnalytics extends AppProps<InitialProps> {
  analytics: NextPageAnalytics
}

const App = (props: AppPropsWithAnalytics) => {
  const { Component, pageProps } = props
  useNextScreenTracking(props)
  initializeServerSideLogging()

  React.useEffect(() => {
    const setup = async () => {
      displayExternalSelfXSSWarning()
      initErrorHandling(undefined, ignoredErrors, 'app.tabs-monzo-me')
      if (getCookieConsent() === true && !getAnalyticsClient()) {
        await setupAnalytics()
      }
    }
    void setup()
  }, [])

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, maximum-scale=5"
        />
        <meta name="robots" content="noindex" />
      </Head>
      <MonzoUiThemeProvider initialTheme={Theme.LightRefreshed}>
        <ErrorBoundary>
          <AuthProvider hashedFingerprint={pageProps.hashedFingerprint ?? null}>
            <ReferralProvider>
              <TabProvider>
                <Layout {...pageProps}>
                  <Component {...pageProps} />
                </Layout>
              </TabProvider>
            </ReferralProvider>
          </AuthProvider>
        </ErrorBoundary>
      </MonzoUiThemeProvider>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }: AppContext) => {
  let pageProps: InitialProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }
  if (ctx.req) {
    try {
      pageProps = {
        ...pageProps,
        hashedFingerprint: await generateHashedFingerprint(ctx.req),
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching hashed fingerprint', error)
    }
  }
  return { pageProps }
}

export default App
