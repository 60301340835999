import React from 'react'

import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import styles from './loading-spinner.module.scss'

export type Props = {
  size?: 18 | 20 | 24 | 40 | 48 | 64
  theme?: 'light' | 'dark'
  className?: string
  color?: string
  align?: 'left' | 'center' | 'right'
} & SpacingProps

export default function LoadingSpinner(props: Props) {
  const { size = 20, theme, ...otherProps } = props
  return (
    <Spacer {...otherProps}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 41 41"
        className={styles['loading-spinner']}
      >
        <circle
          strokeOpacity=".1"
          stroke={theme === 'light' ? '#fff' : '#14233C'}
          className={styles.ring}
          strokeWidth="4.8"
          fill="none"
          cx="20.6"
          cy="20.4"
          r="17.6"
        />

        <circle
          fill={theme === 'light' ? '#fff' : '#00A4DB'}
          fillRule="nonzero"
          cx="20.4"
          cy="2.8"
          r="2.4"
          className={styles.blob}
        />
      </svg>
    </Spacer>
  )
}
