import * as React from 'react'
import { SVGProps } from 'react'
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 2a1 1 0 011 1v1h1a2 2 0 012 2v13a2 2 0 01-2 2H5a2 2 0 01-2-2V6a2 2 0 012-2h1V3a1 1 0 112 0v1h8V3a1 1 0 011-1zm2 6H5v11h14V8zM9 10a1 1 0 011 1v1a1 1 0 01-1 1H8a1 1 0 01-1-1v-1a1 1 0 011-1h1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgCalendar
