import * as React from 'react'
import { SVGProps } from 'react'
const SvgMoneyCash = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="#00000000"
      d="M21 5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h18ZM3.001 14 3 17h3a3 3 0 0 0-2.999-3ZM21 14a3 3 0 0 0-3 3h3l.001-3H21Zm-9-5.5c-1.38 0-2.5 1.567-2.5 3.5s1.12 3.5 2.5 3.5 2.5-1.567 2.5-3.5-1.12-3.5-2.5-3.5ZM21 7h-3a3 3 0 0 0 3 3V7ZM6 7H3v3l.176-.005A3 3 0 0 0 6 7Z"
    />
  </svg>
)
export default SvgMoneyCash
