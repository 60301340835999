import * as React from 'react'
export default function SvgPercentage(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 10.5a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm3.23-8.684a1 1 0 01.04 1.322l-.086.092-16 15a1 1 0 01-1.454-1.368l.086-.091 16-15a1 1 0 011.414.045zM15.5 12.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM4.5.5a4.5 4.5 0 110 9 4.5 4.5 0 010-9zm0 2a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
        fill="currentColor"
      />
    </svg>
  )
}
