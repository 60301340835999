import {
  HTTPError,
  captureException,
  captureExceptionWithInfo,
} from '@mondough/error-handling'

export const captureSharedTabsException = (
  error: Error | HTTPError | unknown,
  errorInfo?: React.ErrorInfo | Record<string, unknown>,
  statusToIgnore: number[] = [401, 404],
) => {
  if (error instanceof HTTPError) {
    if (statusToIgnore.includes(error.status)) {
      return
    }
    if (errorInfo) {
      captureExceptionWithInfo(error, errorInfo)
    } else {
      captureException(error)
    }
  }
  return
}
