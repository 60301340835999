import type { AnalyticsRequestBody, AnalyticsResponse } from './types'

export default function sendEventToAPI({
  apiUrl,
  accessToken,
  event,
  delay,
}: {
  apiUrl: string
  accessToken: string
  event: AnalyticsRequestBody
  delay: number
}): Promise<AnalyticsResponse | null | undefined> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      fetch(`${apiUrl}analytics/track`, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },

        body: JSON.stringify({ events: [event] }),
      })
        .then((rsp) => {
          if (!rsp.ok) {
            reject(rsp)
            return
          }
          return rsp.json()
        })
        .then((json) => resolve(json))
        .catch((error) => reject(error))
    }, delay)
  })
}
