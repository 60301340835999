import * as React from 'react'
import { SVGProps } from 'react'
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.293 12.707a3 3 0 004.242 0l3.536-3.536a3 3 0 00-4.243-4.242l-1.89 1.89a7.017 7.017 0 00-2.424-.405l2.9-2.9a5 5 0 017.071 7.072L16.95 14.12a5 5 0 01-7.708-.778l.636-.636a.997.997 0 011.415 0zM4.929 19.07a3 3 0 004.242 0l1.89-1.89a7.017 7.017 0 002.424.404l-2.9 2.9a5 5 0 01-7.07-7.071L7.05 9.878a5 5 0 017.708.778l-.637.637a.997.997 0 01-1.414 0 3 3 0 00-4.243 0L4.93 14.828a3 3 0 000 4.243z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLink
