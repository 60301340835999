import { useEffect } from 'react'

export const useScrollToTop = ({
  depsArray = [],
}: { depsArray?: unknown[] } = {}) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...depsArray])
}
