import * as React from 'react'
import { SVGProps } from 'react'
const SvgBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0a3 3 0 0 1 2.995 2.824L14 3v1h4a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h4V3A3 3 0 0 1 8.824.005L9 0h2Zm0 2H9a1 1 0 0 0-.993.883L8 3v1h4V3a1 1 0 0 0-.883-.993L11 2ZM0 9V7h20v2h-8v1a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V9H0Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgBriefcase
