import { useRouter } from 'next/router'
import React, { useCallback, useEffect } from 'react'

import {
  SessionSyncResponse,
  clearAuthValue,
  exchangeAuthCodeForAccessToken,
  getAuthValue,
  pagesWithNoAuth,
  redirectToAuthWebsite,
  syncSession,
  userLogout,
} from '../utils'

export const AuthContext = React.createContext({
  isSessionUpgraded: false,
  hashedFingerprint: null as string | null,
  sessionSyncErrorReason: undefined as
    | SessionSyncResponse['reason']
    | undefined,
  userAuthenticate: (_authCode: string, _redirectTo?: string) => {
    return
  },
})

/**
 * AuthProvider is responsible for storing React authentication state (isSessionUpgraded) and actions (userAuthenticate).
 * To avoid the user having to re-authenticate every time they visit the app, we store auth data in local storage.
 * In this provider, we trust the local storage values to be correct,
 * so we don't need to check the server for the user's authentication status on every page load.
 * The utils in auth.ts and apiFetch.ts are responsible for keeping the local storage values in sync with the server.
 */
export const AuthProvider = ({
  children,
  hashedFingerprint,
}: {
  children: JSX.Element
  hashedFingerprint: string | null
}) => {
  const router = useRouter()
  const [isSessionUpgraded, setIsSessionUpgraded] = React.useState<boolean>(
    () => getAuthValue('isSessionUpgraded') === 'true',
  )
  const [sessionSyncErrorReason, setSessionSyncErrorReason] = React.useState<
    SessionSyncResponse['reason'] | undefined
  >(undefined)

  const handleSyncSessionError = useCallback(
    async (reason: SessionSyncResponse['reason']) => {
      setIsSessionUpgraded(false)
      clearAuthValue('isSessionUpgraded')
      setSessionSyncErrorReason(reason)
      await router.push('/woops')
      await userLogout()
    },
    // We don't want to re-run this effect when the router changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  //Called by the oauth2/callback page
  const userAuthenticate = async (authCode: string, redirectTo?: string) => {
    if (isSessionUpgraded) {
      return
    }

    try {
      // TOKEN EXCHANGE
      await exchangeAuthCodeForAccessToken(authCode)
      //SESSION UPGRADE
      const { status, reason } = await syncSession()
      if (status === 'upgraded') {
        setIsSessionUpgraded(true)
        if (redirectTo) {
          await router.push(redirectTo)
        }
      } else {
        await handleSyncSessionError(reason)
        throw new Error('Session sync failed')
      }
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    //Redirect to auth.monzo if user is not authenticated but the page requires so
    if (!isSessionUpgraded && !pagesWithNoAuth.includes(router.pathname)) {
      void redirectToAuthWebsite(null, 'login', hashedFingerprint)
    }
  }, [hashedFingerprint, isSessionUpgraded, router])

  useEffect(() => {
    //Redirect to the confirmation page if the user has not confirmed their identity
    if (
      getAuthValue('fallbackMatch') === 'true' &&
      getAuthValue('hasConfirmedIdentity') !== 'true' &&
      router.pathname !== '/oauth2/callback'
    ) {
      void router.push('/oauth2/callback')
    }
  }, [router])

  return (
    <AuthContext.Provider
      value={{
        isSessionUpgraded,
        sessionSyncErrorReason,
        hashedFingerprint,
        userAuthenticate,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
