import { PropsWithChildren } from 'react'

import { SpacingProps } from '../shared-types'

// pickSpacingProps extracts any padding or margin prop
export function pickSpacingProps(props: PropsWithChildren<unknown>) {
  const spacingProps = Object.keys(props)
    .filter((key) => /^(padding|margin)(Top|Right|Bottom|Left)?$/.test(key))
    .reduce((obj, key) => {
      const obj2 = { ...obj }
      // @ts-ignore
      obj2[key] = props[key]
      return obj2
    }, {})

  return spacingProps
}

type RestProps<T> = Omit<T, keyof SpacingProps>

export function filterSpacingProps<T extends Record<string, unknown>>(
  props: T,
): {
  spacingProps: SpacingProps
  restProps: RestProps<T>
} {
  const { spacingProps, restProps } = Object.keys(props).reduce(
    (
      obj: { spacingProps: SpacingProps; restProps: Partial<RestProps<T>> },
      key,
    ) => {
      if (/^(padding|margin)(Top|Right|Bottom|Left)?$/.test(key)) {
        // it is a spacing prop
        // @ts-ignore
        obj.spacingProps[key] = props[key]
      } else {
        // @ts-ignore
        obj.restProps[key] = props[key]
      }
      return obj
    },
    { spacingProps: {}, restProps: {} },
  )

  // At this point all the properties of restProps are all populated, so we can assert that it is RestProps
  return { spacingProps, restProps: restProps as RestProps<T> }
}
