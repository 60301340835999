export const categories = [
  'default_bills',
  'default_charity',
  'default_eating-out',
  'default_entertainment',
  'default_equipment',
  'default_expenses',
  'default_family',
  'default_finances',
  'default_general',
  'default_gifts',
  'default_groceries',
  'default_holidays',
  'default_income',
  'default_insurance',
  'default_inventory',
  'default_marketing',
  'default_other-income',
  'default_personal-care',
  'default_refunds',
  'default_rent',
  'default_sales',
  'default_services',
  'default_shopping',
  'default_software',
  'default_supplies',
  'default_tax',
  'default_transfers',
  'default_transport',
  'unknown',
  'default_wages',
] as const

export type CategoryNames = (typeof categories)[number]

export interface CategoryIconProps {
  category: Category
  className?: string
}

export interface Category {
  name: CategoryNames
  color: string
}
