import React from 'react'

export default function mergeRefs<T = HTMLElement>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>,
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref != null) {
        // eslint-disable-next-line
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}
