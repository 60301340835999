import * as React from 'react'
import { SVGProps } from 'react'
const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 32 29" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 1c2-1 4 0 5 2l12 20a4 4 0 01-3 6H4a4 4 0 01-3-6L13 3l1-2zm2 19a2 2 0 100 4 2 2 0 000-4zm0-12l-2 2v5a2 2 0 004 0v-5l-2-2z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgAlert
