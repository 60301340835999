import * as React from 'react'
export default function SvgTaskTypes(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4649 3C14.7733 1.8044 13.4806 1 12 1C10.5194 1 9.22675 1.8044 8.53513 3H5C3.89543 3 3 3.89543 3 5V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V5C21 3.89543 20.1046 3 19 3H15.4649ZM12 3C10.8954 3 10 3.89543 10 5H8C7.44772 5 7 5.44772 7 6C7 6.55228 7.44772 7 8 7H16C16.5523 7 17 6.55228 17 6C17 5.44772 16.5523 5 16 5H14C14 3.89543 13.1046 3 12 3ZM12.6942 17.3638C12.4159 17.7846 12.0226 18 11.5141 18C11.0152 18 10.6603 17.8042 10.3245 17.3736L8.35496 15.0512C8.10553 14.7478 8 14.4542 8 14.1508C8 13.4657 8.49887 12.9666 9.18961 12.9666C9.61174 12.9666 9.90915 13.1231 10.197 13.5049L11.4662 15.0933L13.7839 10.6361C14.0718 10.1762 14.3595 10 14.8296 10C15.5204 10 16 10.4796 16 11.1744C16 11.4387 15.9137 11.7127 15.7123 12.0357L12.6942 17.3638Z"
        fill="currentColor"
      />
    </svg>
  )
}
