import * as React from 'react'
import { SVGProps } from 'react'
const SvgMonzoM = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 164 149" xmlns="http://www.w3.org/2000/svg">
    <g fillRule="evenodd">
      <path
        d="m164 108c0 2-0.8 3.9-2.2 5.4l-34 34c-1.9 1.9-5.2 0.6-5.2-2.1v-76.3l40.9-40.7h0.5v79.7z"
        fill="#E64B5F"
      />
      <path
        d="m136.9 1.2c-1.2-1.2-3.1-1.2-4.3 0l-50.6 50.5h-0.7v57.1l0.7 1.5 82-82-27.1-27.1z"
        fill="#EAD19F"
      />
      <path
        d="m0 108c0 2 0.8 3.9 2.2 5.4l34 34c1.9 1.9 5.2 0.6 5.2-2.1v-76.3l-40.9-40.7h-0.5v79.7z"
        fill="#1E788C"
      />
      <path
        d="m31.4 1.2c-1.2-1.2-3.1-1.2-4.3 0l-27.1 27.1 82 82v-58.6l-50.6-50.5z"
        fill="#9ABDA9"
      />
    </g>
  </svg>
)
export default SvgMonzoM
