/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import './types'

import type { AppInitialProps } from 'next/app'
import * as React from 'react'

import type { NextPageAnalytics } from './types'
import { getClient, isBlank } from '.'

interface Props extends AppInitialProps {
  analytics: NextPageAnalytics
  Component: any
}

export function getAnalyticsFromPage(props: Props): NextPageAnalytics {
  const analytics = props.Component.analytics
  const logError = () => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `Page analytics missing for component '${props.Component.name}'.\nTry adding '${props.Component.name}.analytics = { name: <screen name> }' to your component`,
      )
    }
  }
  if (analytics == null) {
    logError()
    return {
      name: '',
      customParameters: {},
    }
  }

  const {
    name,
    parameters: customParameters,
    disableTracking,
  } = typeof analytics === 'function' ? analytics(props) : analytics

  if (disableTracking) {
    return {
      name: undefined,
      customParameters: {},
      disableTracking,
    }
  }

  if (isBlank(name)) {
    logError()
    return {
      name: undefined,
      customParameters: {},
      disableTracking,
    }
  }

  return {
    name,
    customParameters,
    disableTracking,
  }
}

const screenTrackEventBacklog: Set<{
  name: string | Promise<string> | undefined
  stringifiedParams: string
  disableTracking: boolean | undefined
}> = new Set()

export async function sendNextScreenTrackingBacklog() {
  const analyticsClient = getClient()
  // Depending on implementation, the analytics client may not support backlogging
  if (analyticsClient?.options?.enableBacklog === true) {
    for (const screenTrackEvent of screenTrackEventBacklog) {
      const { name, stringifiedParams, disableTracking } = screenTrackEvent

      if (analyticsClient == null || name == null || disableTracking === true) {
        return
      }

      const resolvedName = await name
      analyticsClient.trackScreen({
        name: resolvedName,
        customParameters: JSON.parse(stringifiedParams),
      })
      screenTrackEventBacklog.delete(screenTrackEvent)
    }
  }
}

export function useNextScreenTracking(props: Props) {
  const analyticsClient = getClient()
  const { name, customParameters, disableTracking } =
    getAnalyticsFromPage(props)
  // We're stringifying the parameters so that the following 'useEffect' does a comparison
  // between object values. Otherwise the effect always re-runs, even when parameters don't
  // change, because the object reference has changed.
  const stringifiedParams = JSON.stringify(customParameters ?? {})

  React.useEffect(() => {
    if (analyticsClient == null) {
      screenTrackEventBacklog.add({ name, stringifiedParams, disableTracking })
    }
    if (analyticsClient == null || name == null || disableTracking === true) {
      return
    }

    const trackThatScreen = async () => {
      const resolvedName = await name
      analyticsClient.trackScreen({
        name: resolvedName,
        customParameters: JSON.parse(stringifiedParams),
      })
    }

    sendNextScreenTrackingBacklog()

    trackThatScreen()
  }, [name, stringifiedParams, disableTracking, analyticsClient])
}
