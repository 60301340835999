import * as React from 'react'
import { SVGProps } from 'react'
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5.01 2.5 0C1.4 0 .5.9.5 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V2c0-1.1-.9-1.99-2-1.99Zm0 17.99h-10V4h10v14Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPhone
