import { $Keys, $ReadOnly, $Values } from 'utility-types'

import { ACTION_EVENT_TYPE_FRIENDLY_NAMES } from './constants'

const EVENT_TYPE = {
  SESSION: 'session',
  SCREEN: 'screen',
  LIFECYCLE: 'lifecycle',
  ACTION: 'action',
  VIEW: 'view',
  DATA: 'data',
  ERROR: 'error',
} as const

export type EventType = $Values<typeof EVENT_TYPE>

export type DomActionEventType = $Keys<typeof ACTION_EVENT_TYPE_FRIENDLY_NAMES>

export type ActionType = $Values<typeof ACTION_EVENT_TYPE_FRIENDLY_NAMES>

export type NonNullValue =
  | string
  | number
  | boolean
  | Array<string>
  | Array<number>
  | Array<boolean>

export type Parameters = Record<string, NonNullValue>

export type AnalyticsEvent = {
  name: string
  tags?: Array<string>
  customParameters?: Parameters
}

export type EventReference = {
  id: string
  name: string
  timestamp: string
}

export type SessionReference = {
  id: string
  timestamp: string
}

export type TabReference = {
  id: string
  timestamp: string
}

export type AnalyticsRequestBody = {
  name: string
  data: {
    // Analytics version
    version: string

    // Current session id comprising of the type and a 32bit random UUID
    client_session_id: string

    // Timestamp the current session began
    client_session_timestamp: string

    client_tab_id: string

    client_tab_timestamp: string

    // Event's unique id comprising of the type and a 32bit random UUID
    client_event_id: string

    // Date and time when the event took place
    client_timestamp: string

    // Id of currently active user, or null if not set (i.e. user not logged in)
    user_id: string | null | undefined

    // Id of currently active anonymous user, or null. For logged out user experiences
    anon_id?: string | null

    // Type of event
    type: EventType

    // Free form dictionary with information specific to the event being tracked
    parameters: Parameters

    parent_lifecycle?: EventReference | null | undefined

    sibling?: EventReference | null | undefined

    parent_screen?: EventReference | null | undefined

    last_lifecycle?: EventReference | null | undefined

    last_action?: EventReference | null | undefined

    last_screen?: EventReference | null | undefined
  }
}

export type ClientConfig = $ReadOnly<{
  // The unique name of the web app using the analytics library. This gets used to namespace the analytics events.
  appName: string

  // The path of the API that we're sending the analytics events to.
  apiUrl: string

  // This should either be the client access token or the user's access token. Generally the user's access token
  // is the preference and then the client access token is used as a fallback for when the user isn't logged in.
  accessToken: string

  // If the user is authenticated then we should include the user ID otherwise we'll leave it as null
  userId: string | null | undefined

  // If we are serving a logged out experience, we should include the anonymous ID otherwise we'll leave it as null
  anonId?: string | null

  // Unique ID representing the current version of the web app. This'd usually be the git commit of the deployed web app.
  appVersion: string

  // Optional choice to specify a custom session length (in seconds). The default value is 5 minutes but you might want to control this via
  // environment variables instead, so this option allows that
  sessionLength?: number
  // Optional config settings to customise the behaviour of the analytics library
  options?: {
    enableBacklog?: boolean
  }

  // Optional function that is used to sanitise the `parameters.url` value sent with all analytics events.
  urlSanitizer?: (arg0: string) => string
}>

export type AnalyticsResponse = {
  events: number
}

export type CustomAnalyticsParams = Record<
  string,
  | (string | null | undefined)
  | (number | null | undefined)
  | (boolean | null | undefined)
  | (Array<string | number | boolean> | null | undefined)
>

export type NextPageAnalytics = {
  name?: string | Promise<string>
  customParameters?: Parameters
  disableTracking?: boolean
}

export enum ActionAnalyticsAttributes {
  ACTION_NO_TRACKING = 'data-monzoanalytics-no-tracking',
  ACTION_TRACKING_NAME = 'data-monzoanalytics-action-id',
  ACTION_TRACKING_PARAMETERS = 'data-monzoanalytics-action-parameters',
}
