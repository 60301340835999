import cx from 'classnames'
import * as React from 'react'

import styles from './CategoryIcon.module.scss'
import { CategoryIconProps, categories } from './CategoryIcon.types'

export default function CategoryIcon({
  category,
  className,
}: CategoryIconProps) {
  const categoryIconSrc = categories.includes(category.name)
    ? `/images/categories/category-${category.name.replace('default_', '')}.svg`
    : '/images/categories/category-unknown.svg'

  return (
    <span
      data-testid="category-icon"
      className={cx(
        styles.wrapper,
        className,
        styles[category?.color ?? 'default_grey_1'],
      )}
    >
      <img src={categoryIconSrc} alt={category.name} width={26} />
    </span>
  )
}
