import * as React from 'react'
import { SVGProps } from 'react'
const SvgSlack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.27032 19.5185C8.27032 21.0586 7.00867 22.3202 5.46863 22.3202C3.92858 22.3202 2.67285 21.0586 2.67285 19.5185C2.67285 17.9785 3.9345 16.7168 5.47455 16.7168H8.27625V19.5185H8.27032ZM9.68598 19.5185C9.68598 17.9785 10.9476 16.7168 12.4877 16.7168C14.0277 16.7168 15.2894 17.9785 15.2894 19.5185V26.5316C15.2894 28.0717 14.0277 29.3333 12.4877 29.3333C10.9476 29.3333 9.68598 28.0717 9.68598 26.5316C9.68598 26.5257 9.68598 19.5185 9.68598 19.5185ZM12.4818 8.27026C10.9417 8.27026 9.68006 7.00861 9.68006 5.46856C9.68006 3.92852 10.9417 2.67279 12.4818 2.67279C14.0218 2.67279 15.2835 3.93444 15.2835 5.47449V8.27619L12.4818 8.27026ZM12.4818 9.68592C14.0218 9.68592 15.2835 10.9476 15.2835 12.4876C15.2835 14.0277 14.0218 15.2893 12.4818 15.2893H5.47455C3.9345 15.2893 2.67285 14.0277 2.67285 12.4876C2.67285 10.9476 3.9345 9.68592 5.47455 9.68592H12.4818ZM23.73 12.4817C23.73 10.9416 24.9917 9.68 26.5317 9.68C28.0717 9.68 29.3334 10.9416 29.3334 12.4817C29.3334 14.0217 28.0717 15.2834 26.5317 15.2834H23.73V12.4817ZM22.3143 12.4817C22.3143 14.0217 21.0527 15.2834 19.5126 15.2834C17.9726 15.2834 16.711 14.0217 16.711 12.4817V5.47449C16.711 3.93444 17.9726 2.67279 19.5126 2.67279C21.0527 2.67279 22.3143 3.93444 22.3143 5.47449V12.4817ZM19.5186 23.7299C21.0586 23.7299 22.3203 24.9916 22.3203 26.5316C22.3203 28.0717 21.0586 29.3274 19.5186 29.3274C17.9785 29.3274 16.7169 28.0658 16.7169 26.5257V23.724H19.5186V23.7299ZM19.5186 22.3143C17.9785 22.3143 16.7169 21.0526 16.7169 19.5126C16.7169 17.9725 17.9785 16.7109 19.5186 16.7109H26.5317C28.0717 16.7109 29.3334 17.9725 29.3334 19.5126C29.3334 21.0526 28.0717 22.3143 26.5317 22.3143H19.5186Z" />
  </svg>
)
export default SvgSlack
