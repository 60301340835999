import * as React from 'react'
import { SVGProps } from 'react'
const SvgLineChart = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23 5.42857C23 6.76983 21.9056 7.85714 20.5556 7.85714C20.3327 7.85714 20.1167 7.8275 19.9116 7.77198L17.3715 12.1882C17.8277 12.6294 18.1111 13.2461 18.1111 13.9286C18.1111 15.2698 17.0167 16.3571 15.6667 16.3571C14.3166 16.3571 13.2222 15.2698 13.2222 13.9286C13.2222 13.8722 13.2242 13.8163 13.228 13.7609L10.3857 12.8197C9.95009 13.4871 9.19361 13.9286 8.33333 13.9286C7.97638 13.9286 7.6373 13.8526 7.3316 13.7159L5.40028 16.1144C5.70709 16.5203 5.88889 17.0248 5.88889 17.5714C5.88889 18.9127 4.79447 20 3.44444 20C2.09442 20 1 18.9127 1 17.5714C1 16.2302 2.09442 15.1429 3.44444 15.1429C3.80139 15.1429 4.14047 15.2189 4.44618 15.3555L6.3775 12.957C6.07069 12.5511 5.88889 12.0467 5.88889 11.5C5.88889 10.1587 6.9833 9.07143 8.33333 9.07143C9.68336 9.07143 10.7778 10.1587 10.7778 11.5C10.7778 11.5563 10.7758 11.6123 10.772 11.6676L13.6143 12.6089C14.0499 11.9415 14.8064 11.5 15.6667 11.5C15.8896 11.5 16.1055 11.5296 16.3107 11.5852L18.8507 7.16898C18.3945 6.72779 18.1111 6.11106 18.1111 5.42857C18.1111 4.08731 19.2055 3 20.5556 3C21.9056 3 23 4.08731 23 5.42857Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLineChart
