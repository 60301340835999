import cx from 'classnames'
import * as React from 'react'

import Icon from '../icon'
import type { Icons } from '../icon/icon'
import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import styles from './banner.module.scss'

export type Props = {
  status: 'info' | 'info-light' | 'warning' | 'error'
  icon?: Icons
  children: React.ReactNode
  className?: string
} & SpacingProps

export default function Banner(props: Props) {
  const { children, icon, status, className, ...otherProps } = props

  const classNames = cx(className, styles.root, styles[`status-${status}`])

  return (
    <Spacer {...otherProps} className={classNames}>
      {icon && (
        <Icon icon={icon} size={24} color="currentColor" marginRight="small" />
      )}

      <span>{children}</span>
    </Spacer>
  )
}
