import * as React from 'react'
const SvgDirectionalLines = (props: HTMLOrSVGElement) => (
  <svg
    {...props}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 10C0 10.3667 0.3 10.6667 0.666667 10.6667H4V9.33333H0.666667C0.3 9.33333 0 9.63333 0 10ZM0 2C0 2.36667 0.3 2.66667 0.666667 2.66667H6.66667V1.33333H0.666667C0.3 1.33333 0 1.63333 0 2ZM6.66667 11.3333V10.6667H11.3333C11.7 10.6667 12 10.3667 12 10C12 9.63333 11.7 9.33333 11.3333 9.33333H6.66667V8.66667C6.66667 8.3 6.36667 8 6 8C5.63333 8 5.33333 8.3 5.33333 8.66667V11.3333C5.33333 11.7 5.63333 12 6 12C6.36667 12 6.66667 11.7 6.66667 11.3333ZM2.66667 4.66667V5.33333H0.666667C0.3 5.33333 0 5.63333 0 6C0 6.36667 0.3 6.66667 0.666667 6.66667H2.66667V7.33333C2.66667 7.7 2.96667 8 3.33333 8C3.7 8 4 7.7 4 7.33333V4.66667C4 4.3 3.7 4 3.33333 4C2.96667 4 2.66667 4.3 2.66667 4.66667ZM12 6C12 5.63333 11.7 5.33333 11.3333 5.33333H5.33333V6.66667H11.3333C11.7 6.66667 12 6.36667 12 6ZM8.66667 4C9.03333 4 9.33333 3.7 9.33333 3.33333V2.66667H11.3333C11.7 2.66667 12 2.36667 12 2C12 1.63333 11.7 1.33333 11.3333 1.33333H9.33333V0.666667C9.33333 0.3 9.03333 0 8.66667 0C8.3 0 8 0.3 8 0.666667V3.33333C8 3.7 8.3 4 8.66667 4Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDirectionalLines
