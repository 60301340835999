import * as React from 'react'
export default function SvgPot(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 18 18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8628 4.0007C17.1958 5.4831 18 7.4028 18 9.5c0 4.6944-4.0294 8.5-9 8.5s-9-3.8056-9-8.5c0-2.0972.8042-4.017 2.1372-5.4993h13.7256zM15 0c.5523 0 1 .4477 1 1 0 .5128-.386.9355-.8834.9933L15 2H3c-.5523 0-1-.4477-1-1 0-.5128.386-.9355.8834-.9933L3 0h12z"
        fill="currentColor"
      />
    </svg>
  )
}
