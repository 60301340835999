import * as React from 'react'
import { SVGProps } from 'react'
const SvgAccountManagement = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 76 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.2561 13.7579C31.7246 13.7579 32.1044 13.3762 32.1044 12.9053C32.1044 12.4345 31.7246 12.0527 31.2561 12.0527C30.7875 12.0527 30.4077 12.4345 30.4077 12.9053C30.4077 13.3762 30.7875 13.7579 31.2561 13.7579Z"
      fill="#FFA9DF"
    />
    <path
      d="M30.3877 30.5472L37.4017 7.4619C37.7388 6.36038 39.4832 5.04192 41.0246 5.48953L70.513 14.0596C72.0764 14.514 72.9732 16.1591 72.5126 17.728L65.6971 41.4307C65.4774 42.195 64.6639 42.6167 63.9163 42.3541L30.3877 30.548V30.5472Z"
      fill="#016B83"
    />
    <path
      d="M29.6475 30.1869L36.6615 7.10155C36.9985 6.00003 38.7429 4.68157 40.2844 5.12918L69.7727 13.6992C71.3361 14.1537 72.2329 15.7987 71.7724 17.3676L64.5524 42.4779L29.6475 30.1869Z"
      fill="#004E60"
    />
    <path
      d="M49.8079 51.2823L63.6655 44.2599C64.2094 43.9843 64.5518 43.4248 64.5518 42.8127V41.9678L29.6468 32.0298L11.1143 36.7023V37.9667C11.1143 38.3123 11.3332 38.6198 11.6581 38.7325L48.3438 51.382C48.8255 51.548 49.3535 51.5114 49.8079 51.2815V51.2823Z"
      fill="#2EAFC0"
    />
    <path
      d="M49.0975 49.8004L64.5525 41.9688L29.9271 30.2822C29.7461 30.2213 29.5491 30.2221 29.3689 30.286L11.1143 36.7041L49.0967 49.8012L49.0975 49.8004Z"
      fill="#016B83"
    />
    <path
      d="M21.8882 34.6447L29.2673 31.6294L59.5525 41.7577L59.248 41.9678L52.1552 44.7676L22.1821 34.7946L21.8882 34.6447Z"
      fill="#004E60"
    />
    <path
      d="M22.1821 34.7946L29.3613 32.0298L59.2481 41.9678L52.2484 45.168L22.1821 34.7946Z"
      fill="#112231"
    />
    <path
      d="M28.6937 37.915L42.2021 42.6568L41.9052 42.7649L37.6566 44.3567L24.0762 40.1485L23.8081 40.0275L28.6937 37.915Z"
      fill="#004E60"
    />
    <path
      d="M28.6936 38.1992L41.9051 42.7651L37.6565 44.6408L24.0762 40.1488L28.6936 38.1992Z"
      fill="#112231"
    />
    <path
      d="M54.3164 11.2101C54.4169 10.899 54.302 10.5827 54.0598 10.5036C53.8175 10.4244 53.5395 10.6125 53.4389 10.9236C53.3384 11.2346 53.4533 11.551 53.6955 11.6301C53.9378 11.7092 54.2158 11.5212 54.3164 11.2101Z"
      fill="#112231"
    />
    <path
      d="M27.6226 32.5811L57.9495 42.5617L57.5511 42.7436L27.103 32.8117L27.6226 32.5811Z"
      fill="#004E60"
    />
    <path
      d="M25.4542 33.3203L56.1022 43.4067L55.7053 43.5879L24.9346 33.551L25.4542 33.3203Z"
      fill="#004E60"
    />
    <path
      d="M23.2862 34.0596L54.2547 44.251L53.8593 44.4322L22.7666 34.2902L23.2862 34.0596Z"
      fill="#004E60"
    />
    <path
      d="M26.2046 39.8672L37.7641 43.7632"
      stroke="#004E60"
      strokeWidth="0.287363"
      strokeMiterlimit="10"
    />
    <path
      d="M37.3672 7.48926C37.3672 7.48926 35.7568 10.0782 35.3584 11.3883L36.2401 8.48496L36.4446 7.73514L37.3672 7.48926Z"
      fill="#112231"
    />
    <path
      d="M0.416595 17.0876C0.281768 16.6568 0.318126 16.213 0.487795 15.8301C0.577174 15.6284 0.641558 15.4868 0.708214 15.3642C0.877126 15.0521 1.42325 13.7892 1.76183 13.6849L18.0637 9.64653C18.9977 9.35117 19.9929 9.87338 20.2861 10.812L32.0607 45.0906C32.3546 46.0292 31.0889 48.0145 30.1549 48.3099L13.6675 53.5175C12.7335 53.8129 11.7382 53.2907 11.4451 52.3521L0.416595 17.0876Z"
      fill="#016B83"
    />
    <path
      d="M1.08069 15.6831C0.786798 14.7445 1.30641 13.7443 2.24035 13.4497L18.7278 8.24126C19.6618 7.9459 20.657 8.46811 20.9502 9.40671L31.9787 44.6711C32.2726 45.6097 31.753 46.61 30.819 46.9046L14.3316 52.1123C13.3976 52.4076 12.4023 51.8854 12.1092 50.9468L1.08069 15.6831Z"
      fill="#8CE6D1"
    />
    <path
      d="M13.5289 20.5818C13.6917 20.53 13.8553 20.4866 14.0189 20.4493C15.1278 17.1555 14.8476 13.481 13.8197 9.79199L2.24055 13.4497C1.30662 13.7451 0.787761 14.7446 1.0809 15.6832L5.27718 29.1016C6.80345 28.4203 8.11308 27.659 9.22881 26.8301C9.07202 24.069 10.7839 21.4481 13.5289 20.581V20.5818Z"
      fill="#BBF6E2"
    />
    <path
      d="M14.0199 20.4484C13.2223 22.8174 11.7051 24.9907 9.229 26.8299C9.25779 27.33 9.34717 27.8355 9.5032 28.3356C10.522 31.5945 13.9767 33.4054 17.2194 32.3816C20.462 31.3577 22.264 27.8857 21.2452 24.6268C20.2772 21.5324 17.1126 19.7435 14.0199 20.4484Z"
      fill="#8CE6D1"
    />
    <path
      d="M14.0199 20.4487C13.8563 20.486 13.6927 20.5302 13.5298 20.5812C10.7848 21.4482 9.07219 24.0692 9.22974 26.8302C11.7059 24.9903 13.2223 22.8177 14.0206 20.4487H14.0199Z"
      fill="#BBF6E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0795 13.018L2.21005 19.2945L1.08069 15.6831C0.786798 14.7445 1.30641 13.7443 2.24035 13.4497L18.7278 8.24126C19.6618 7.9459 20.657 8.46811 20.9502 9.40671L22.0795 13.018Z"
      fill="#004E60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8205 9.79102L2.24055 13.4495C1.30662 13.7449 0.787761 14.7444 1.0809 15.683L2.21026 19.2943L14.7241 15.342C14.6703 13.5272 14.3416 11.6614 13.8205 9.79178V9.79102Z"
      fill="#2EAFC0"
    />
    <g filter="url(#filter0_d_673_275)">
      <path
        d="M4.32269 8.13326C3.90004 8.67069 3.82429 9.00792 3.89776 10.0211C3.44405 9.14799 3.21151 8.92495 2.5957 8.77118C3.01836 8.23374 3.09411 7.89651 3.02063 6.8833C3.47435 7.75644 3.70689 7.97949 4.32269 8.13326Z"
        fill="white"
      />
    </g>
    <path
      d="M11.4108 44.3996L8.96873 44.4339L8.6665 43.4648L11.4108 44.3996Z"
      fill="#112231"
    />
    <path
      d="M3.25336 14.9926C3.25336 14.9926 3.59194 13.2311 3.89417 12.9243L2.72314 13.2973L3.25336 14.9926Z"
      fill="#112231"
    />
    <path d="M7.44043 40.9718L10.5861 38.4932L8.75082 41.6325" fill="#112231" />
    <g filter="url(#filter1_d_673_275)">
      <path
        d="M4.04468 11.7234C3.28192 12.6605 3.14028 13.2443 3.25314 14.9929C2.46009 13.4955 2.0503 13.1157 0.956543 12.8652C1.7193 11.9281 1.86094 11.3443 1.74808 9.5957C2.54038 11.0931 2.95091 11.4729 4.04468 11.7234Z"
        fill="white"
      />
    </g>
    <path
      d="M3.98278 46.7432L3.95703 47.1946L4.18578 46.9045C4.18578 46.8756 4.18805 46.8475 4.19184 46.8185C4.11988 46.8033 4.04944 46.7782 3.98354 46.7432H3.98278Z"
      fill="#FF4F40"
    />
    <path
      d="M8.95849 40.1898C8.73126 40.1692 8.51463 40.0528 8.37223 39.851C8.24952 39.6775 8.20483 39.4727 8.22983 39.2771C8.1056 39.2504 7.9882 39.1949 7.88746 39.1126L7.17848 39.2755L5.12654 35.5181L4.84477 37.0109C4.84401 37.0329 4.84174 37.055 4.83947 37.0771C5.0311 37.1182 5.20911 37.2286 5.33181 37.4021C5.5901 37.7683 5.50451 38.2753 5.14018 38.5356C4.96748 38.6589 4.76372 38.7039 4.56906 38.6787C4.55315 38.7541 4.52664 38.8272 4.48953 38.8957L4.30168 39.8914L0.92041 41.694L2.10885 42.115C2.16339 42.1112 2.21793 42.1119 2.27246 42.1188C2.31336 41.9262 2.4232 41.7473 2.59514 41.624C2.95947 41.3644 3.46394 41.4504 3.72298 41.8166C3.84569 41.9901 3.89038 42.1949 3.86538 42.3905C4.05702 42.4316 4.23502 42.542 4.35773 42.7148C4.61602 43.081 4.53043 43.588 4.16609 43.8483C4.16079 43.8521 4.15473 43.8552 4.14943 43.859L4.06838 45.2673C4.19563 45.2178 4.33122 45.2018 4.46226 45.2193C4.50316 45.0267 4.61299 44.8478 4.78493 44.7245C5.11367 44.49 5.55678 44.538 5.83022 44.8197L6.69523 43.7227C6.71265 43.4898 6.8293 43.2667 7.03381 43.1213C7.20651 42.998 7.41026 42.9531 7.60493 42.9782C7.64583 42.7856 7.75566 42.6067 7.9276 42.4834C8.29194 42.2238 8.7964 42.3098 9.05545 42.676C9.17815 42.8496 9.22285 43.0543 9.19785 43.25C9.38948 43.2911 9.56749 43.4015 9.69019 43.5743C9.79851 43.728 9.84547 43.9062 9.8379 44.0805L11.4119 44.4002L8.16317 41.0211L8.95925 40.1906L8.95849 40.1898ZM7.30876 41.6057C7.13606 41.729 6.93231 41.7739 6.73764 41.7488C6.69674 41.9414 6.58691 42.1203 6.41421 42.2436C6.04988 42.5032 5.54541 42.4172 5.28637 42.051C5.16366 41.8775 5.11897 41.6727 5.14396 41.4771C4.95233 41.4359 4.77433 41.3256 4.65162 41.1528C4.39333 40.7866 4.47892 40.2796 4.84326 40.0193C5.01595 39.896 5.21971 39.851 5.41437 39.8762C5.45528 39.6836 5.56511 39.5047 5.73705 39.3814C6.10138 39.1218 6.60585 39.2078 6.8649 39.574C6.9876 39.7475 7.03229 39.9523 7.0073 40.1479C7.19893 40.189 7.37693 40.2994 7.49964 40.4722C7.75793 40.8384 7.67234 41.3454 7.30801 41.6057H7.30876Z"
      fill="#FF4F40"
    />
    <path
      d="M4.56928 38.6781C4.76394 38.7033 4.9677 38.6583 5.1404 38.535C5.50473 38.2754 5.59032 37.7677 5.33203 37.4015C5.20933 37.228 5.03208 37.1176 4.83969 37.0765C4.84272 37.0544 4.84423 37.0323 4.84499 37.0103L4.48975 38.8943C4.5261 38.8258 4.55337 38.7527 4.56928 38.6774V38.6781Z"
      fill="#FF945A"
    />
    <path
      d="M4.35776 42.7144C4.23506 42.5408 4.05781 42.4304 3.86542 42.3901C3.89041 42.1945 3.84572 41.9897 3.72302 41.8161C3.46473 41.45 2.95951 41.3639 2.59517 41.6235C2.42247 41.7468 2.31264 41.925 2.2725 42.1183C2.21872 42.1115 2.16342 42.1107 2.10889 42.1145L4.20703 42.8583L4.14946 43.8593C4.15477 43.8555 4.16083 43.8524 4.16613 43.8486C4.53046 43.5891 4.61605 43.0813 4.35776 42.7151V42.7144Z"
      fill="#FF945A"
    />
    <path
      d="M7.00873 40.1489C7.03373 39.9532 6.98904 39.7485 6.86633 39.5749C6.60804 39.2088 6.10282 39.1227 5.73849 39.3823C5.56579 39.5056 5.45596 39.6838 5.41581 39.8771C5.22115 39.852 5.01739 39.8969 4.84469 40.0202C4.48036 40.2798 4.39477 40.7876 4.65306 41.1537C4.77576 41.3273 4.95301 41.4377 5.1454 41.478C5.12041 41.6736 5.16509 41.8784 5.2878 42.052C5.54609 42.4181 6.05131 42.5042 6.41565 42.2446C6.58835 42.1213 6.69818 41.9431 6.73908 41.7498C6.93375 41.7749 7.1375 41.73 7.3102 41.6067C7.67453 41.3471 7.76013 40.8393 7.50184 40.4732C7.37913 40.2996 7.20188 40.1892 7.00949 40.1489H7.00873Z"
      fill="#FF945A"
    />
    <path
      d="M8.22985 39.277C8.20485 39.4727 8.24954 39.6774 8.37225 39.851C8.51465 40.0527 8.73204 40.1684 8.95851 40.1898L10.5863 38.4922L7.88672 39.1118C7.98746 39.1948 8.10562 39.2504 8.22909 39.2763L8.22985 39.277Z"
      fill="#FF945A"
    />
    <path
      d="M4.78505 44.7232C4.61235 44.8465 4.50252 45.0246 4.46238 45.218C4.33134 45.2012 4.19575 45.2172 4.0685 45.2659L3.98291 46.7435C4.04881 46.7785 4.11925 46.8036 4.19121 46.8189C4.18742 46.847 4.18591 46.8759 4.18515 46.9049L5.83034 44.8183C5.5569 44.5374 5.11379 44.4887 4.78505 44.7232Z"
      fill="#FF945A"
    />
    <path
      d="M9.6893 43.5738C9.5666 43.4002 9.38935 43.2898 9.19696 43.2495C9.22195 43.0538 9.17726 42.8491 9.05456 42.6755C8.79627 42.3093 8.29105 42.2233 7.92671 42.4829C7.75401 42.6062 7.64418 42.7844 7.60404 42.9777C7.40937 42.9526 7.20562 42.9975 7.03292 43.1208C6.82841 43.2662 6.71251 43.49 6.69434 43.7222L6.88521 43.4801L9.83625 44.08C9.84382 43.9057 9.79686 43.7275 9.68854 43.5738H9.6893Z"
      fill="#FF945A"
    />
    <path
      d="M22.8842 33.8343L13.4031 36.8295C12.7789 37.0267 12.432 37.695 12.6282 38.3223L12.6284 38.323C12.8246 38.9503 13.4896 39.299 14.1138 39.1018L23.595 36.1066C24.2191 35.9094 24.566 35.2411 24.3698 34.6138L24.3696 34.6131C24.1734 33.9858 23.5084 33.6371 22.8842 33.8343Z"
      fill="#8CE6D1"
    />
    <path
      d="M20.0537 10.7405C20.0537 10.7405 21.2664 10.7725 21.5489 11.3191L21.2316 10.3394L20.0537 10.7405Z"
      fill="#112231"
    />
    <path
      d="M24.0522 37.5706L14.5711 40.5658C13.9469 40.763 13.6 41.4314 13.7962 42.0586L13.7964 42.0594C13.9926 42.6866 14.6576 43.0353 15.2818 42.8381L24.7629 39.8429C25.3871 39.6457 25.734 38.9774 25.5378 38.3501L25.5376 38.3494C25.3414 37.7221 24.6764 37.3734 24.0522 37.5706Z"
      fill="#8CE6D1"
    />
    <path
      d="M21.7004 7.2998C21.8777 9.44117 21.7489 9.68705 20.0537 10.7406C20.0545 10.7406 20.056 10.7406 20.0567 10.7406C20.0567 10.7406 20.0575 10.7406 20.0583 10.7406C20.1461 10.7315 20.2302 10.7223 20.312 10.714C20.7438 10.6706 21.0891 10.6462 21.377 10.6599C22.3177 10.7041 22.6472 11.1486 23.363 12.6163C23.0843 10.398 23.2214 10.1537 25.0097 9.1755C22.9684 9.28664 22.726 9.15342 21.7004 7.2998Z"
      fill="#C3FF34"
    />
    <path
      d="M46.1909 17.5536C48.4299 17.3815 50.6652 17.1806 52.886 16.8548C55.4705 16.4764 58.2026 15.8043 60.4303 14.501L46.831 10.5532C46.9438 12.9549 46.7567 15.3034 46.1909 17.5528V17.5536Z"
      fill="#8CE6D1"
    />
    <path
      d="M41.0708 32.5815L63.1119 39.8437C63.3634 39.9267 63.6345 39.7874 63.7148 39.5339L67.496 27.6113C64.8131 28.4434 61.9856 28.942 59.2133 29.283C53.0923 30.0374 46.7752 30.2589 41.0708 32.5815Z"
      fill="#8CE6D1"
    />
    <path
      d="M70.237 17.349L60.4303 14.502C58.2026 15.8052 55.4712 16.4774 52.886 16.8557C50.6644 17.1808 48.4292 17.3825 46.1909 17.5545C46.0621 18.0653 45.9152 18.5708 45.747 19.0702C44.4957 22.7865 42.1166 26.1946 38.2331 29.0797C37.434 29.6735 36.5705 30.2452 35.6411 30.7925L41.0713 32.5814C46.7757 30.2589 53.0928 30.0366 59.2138 29.2829C61.9861 28.9411 64.8136 28.4433 67.4965 27.6113L70.5589 17.9549C70.6407 17.6976 70.4945 17.4236 70.237 17.349Z"
      fill="#8CE6D1"
    />
    <path
      d="M37.7035 8.40744L34.854 18.6179C35.1805 18.5685 35.5069 18.519 35.8334 18.4748C39.2692 18.0128 42.7345 17.8194 46.1915 17.5545C46.7574 15.305 46.9445 12.9566 46.8316 10.5549L38.2951 8.07706C38.0406 8.00322 37.7755 8.15166 37.7035 8.4082V8.40744Z"
      fill="#BBF6E2"
    />
    <path
      d="M45.7468 19.0693C45.915 18.5692 46.0627 18.0637 46.1907 17.5537C42.7337 17.8186 39.2683 18.012 35.8325 18.474C35.5061 18.5182 35.1796 18.5677 34.8531 18.6172L31.9309 29.0872C31.862 29.3347 31.9991 29.592 32.2414 29.6719L35.6401 30.7917C36.5695 30.2436 37.433 29.6726 38.2321 29.0789C42.1156 26.193 44.4947 22.7857 45.7461 19.0693H45.7468Z"
      fill="#BBF6E2"
    />
    <g filter="url(#filter2_d_673_275)">
      <path
        d="M73.4523 32.5811C73.0524 34.3677 72.885 34.5344 71.2383 34.9561C71.2466 34.9576 71.2542 34.9592 71.2618 34.9607C71.5481 35.0185 71.792 35.0703 72.001 35.1266C73.0478 35.406 73.2152 35.7706 73.4516 37.3312C73.7894 35.4563 73.9628 35.2926 75.6656 34.9561C73.9772 34.5222 73.8144 34.3517 73.4516 32.5811H73.4523Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter3_d_673_275)">
      <path
        d="M37.3673 7.48918C35.4336 8.68585 34.8594 9.65566 34.1512 12.9237C33.443 9.6549 32.8696 8.68508 30.9351 7.48918C32.8688 6.29251 33.443 5.32269 34.1512 2.05469C34.8594 5.32345 35.4328 6.29327 37.3673 7.48918Z"
        fill="white"
      />
    </g>
    <path
      d="M74.8202 41.3689L71.7495 40.8315L70.7481 38.0142L69.8142 40.8315L66.6094 40.9662L68.2569 42.5245L68.8795 43.1129L66.3428 44.589L67.8471 44.4702L69.7468 44.3203L69.4801 47.4741L71.7495 43.9853L74.7535 45.1257L72.8182 42.7102L74.8202 41.3689Z"
      fill="#2EAFC0"
    />
    <path
      d="M73.9272 41.7578C73.5659 42.0577 73.2114 42.3653 72.8638 42.6797L74.0416 41.891L73.928 41.7578H73.9272Z"
      fill="white"
    />
    <path
      d="M70.4393 44.9025L69.6372 45.6257L69.6122 45.9187L69.747 45.7969C69.7001 45.8578 69.6538 45.9195 69.6069 45.9811L69.5713 46.4014C70.2439 45.5914 70.9529 44.8126 71.6959 44.0674L71.7497 43.9844L71.7709 43.9928C71.8482 43.9159 71.9247 43.839 72.0027 43.7621L72.9654 42.8943L72.823 42.7162C72.4988 43.01 72.1792 43.3092 71.8664 43.6152L71.3149 44.1123C72.0989 43.1227 72.8957 42.1415 73.7032 41.1724L73.476 41.1328C72.4451 42.3721 71.4316 43.6304 70.4393 44.9025Z"
      fill="white"
    />
    <path
      d="M68.6357 42.8822C69.5431 41.8788 70.4763 40.8976 71.4337 39.9423L71.3595 39.7329C70.3763 40.7103 69.4189 41.7144 68.4888 42.7429L68.6357 42.8822Z"
      fill="white"
    />
    <path
      d="M67.0297 40.9486L66.9517 41.2896L67.1213 41.4495L67.2365 40.9395L67.0297 40.9486Z"
      fill="white"
    />
    <path
      d="M70.5589 42.0844C70.0392 42.8335 69.5371 43.5963 69.0606 44.3743L69.3083 44.3545C69.8643 43.4539 70.4536 42.5747 71.0648 41.7114C71.4367 41.4404 71.8109 41.1725 72.1881 40.9076L71.9086 40.8589C71.7496 40.9708 71.5913 41.0834 71.433 41.1968C71.5322 41.0591 71.6329 40.922 71.7337 40.7858L71.6526 40.5566C71.4064 40.8916 71.1618 41.2281 70.9209 41.5683C69.6991 42.4605 68.5031 43.3892 67.3367 44.3514C67.5366 44.1185 67.7396 43.8878 67.9426 43.6579L67.3965 43.9761C67.2329 44.1649 67.07 44.3545 66.9087 44.5448L67.4745 44.4999C68.4819 43.6694 69.5113 42.8632 70.5604 42.0852L70.5589 42.0844Z"
      fill="white"
    />
    <path
      d="M66.3428 44.5889L66.4109 44.5835L66.4193 44.5439L66.3428 44.5889Z"
      fill="white"
    />
    <path
      d="M65.5765 12.0248C68.8005 12.0248 71.4141 9.39807 71.4141 6.15789C71.4141 2.9177 68.8005 0.291016 65.5765 0.291016C62.3524 0.291016 59.7388 2.9177 59.7388 6.15789C59.7388 9.39807 62.3524 12.0248 65.5765 12.0248Z"
      fill="#112231"
    />
    <path
      d="M66.9217 11.7337C70.1457 11.7337 72.7593 9.10706 72.7593 5.86687C72.7593 2.62669 70.1457 0 66.9217 0C63.6976 0 61.084 2.62669 61.084 5.86687C61.084 9.10706 63.6976 11.7337 66.9217 11.7337Z"
      fill="#FF4F40"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M67.6808 8.91726C67.6808 9.33823 67.3415 9.68002 66.9218 9.68002C66.5022 9.68002 66.1939 9.36868 66.1651 8.97435L66.1629 8.91726H67.68H67.6808ZM66.9218 2.05545C67.3407 2.05545 67.6808 2.39648 67.6808 2.81821V2.94838C68.5397 3.25364 69.1616 4.06131 69.1965 5.01972L69.198 5.10574V6.24912C69.198 6.74773 69.4366 7.19077 69.8047 7.46938C69.8971 7.53866 69.9562 7.6498 69.9562 7.77388C69.9562 7.98474 69.7865 8.15526 69.5767 8.15526H64.2662C64.0564 8.15526 63.8867 7.98474 63.8867 7.77388C63.8867 7.66502 63.9322 7.56682 64.0049 7.49679L64.0382 7.46862C64.3851 7.20676 64.6169 6.79873 64.6427 6.3359L64.6449 6.24836V5.10497L64.6464 5.01895C64.6813 4.06055 65.3039 3.25212 66.1621 2.94762V2.81745C66.1621 2.39648 66.5014 2.05469 66.9203 2.05469L66.9218 2.05545Z"
      fill="#F2F8F3"
    />
    <defs>
      <filter
        id="filter0_d_673_275"
        x="2.5957"
        y="6.8833"
        width="1.72705"
        height="4.1377"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_673_275"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_673_275"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_673_275"
        x="0.956543"
        y="9.5957"
        width="3.08789"
        height="6.39697"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_673_275"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_673_275"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_673_275"
        x="71.2383"
        y="32.5811"
        width="4.42725"
        height="5.75"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_673_275"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_673_275"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_673_275"
        x="30.9351"
        y="2.05469"
        width="6.43213"
        height="11.8691"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_673_275"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_673_275"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgAccountManagement
