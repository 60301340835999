import cx from 'classnames'
import React, { ChangeEvent, ForwardedRef } from 'react'

import { ConditionalWrapper, Icon, VisuallyHidden } from '../'
import styles from './text-input.module.scss'

export type TProps = {
  type?: 'text' | 'password' | 'email' | 'tel' | 'number' | 'search' | 'date'
  placeholder?: string
  disabled?: boolean
  invalid?: boolean
  required?: boolean
  fullWidth?: boolean
  className?: string
  onClear?: () => void
  allowClearOnDisabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  inputSize?: 'small' | 'regular'
  clearButtonRef?: ForwardedRef<HTMLButtonElement>
} & React.ComponentPropsWithoutRef<'input'>

export default React.forwardRef(function TextInput(
  props: TProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const {
    type = 'text',
    disabled = false,
    invalid,
    required,
    placeholder,
    fullWidth,
    className,
    onClear,
    inputSize = 'regular',
    allowClearOnDisabled = false,
    clearButtonRef,
    ...otherProps
  } = props

  const _classNames = cx(
    [styles.input],
    {
      [styles.invalid]: invalid,
      [styles['full-width']]: fullWidth,
      [styles.small]: inputSize === 'small',
    },

    className,
  )

  const displayClearButton = !!onClear && (!disabled || allowClearOnDisabled)

  return (
    <ConditionalWrapper
      isWrapped={displayClearButton}
      wrapper={(children) => (
        <span
          className={cx({
            [styles.clear]: displayClearButton,
            [styles['full-width']]: fullWidth,
          })}
        >
          {children}
        </span>
      )}
    >
      <input
        {...otherProps}
        type={type}
        disabled={disabled}
        aria-invalid={invalid}
        required={required}
        className={_classNames}
        placeholder={placeholder}
        ref={ref}
      />
      {!!onClear && // !!onClear here is redundant but makes the type-check below happy
        displayClearButton && (
          <button type="button" onClick={() => onClear()} ref={clearButtonRef}>
            <Icon icon="close" size={18} color="grey1" aria-hidden />
            <VisuallyHidden>Clear this input</VisuallyHidden>
          </button>
        )}
    </ConditionalWrapper>
  )
})
