import cx from 'classnames'
import React from 'react'

import * as MoneyLib from '@mondough/money'
import { Money, Text } from '@mondough/monzo-ui'

import { TabContext } from '../../../providers'
import { DEFAULT_CURRENCY_CODE } from '../../../utils'
import AvatarWithBadge from '../../AvatarWithBadge/AvatarWithBadge'
import Quantity from '../../Quantity/Quantity'
import styles from './ActivityItem.module.scss'
import {
  ActivityItemProps,
  TrailingContentMoney,
  TrailingContentQuantity,
} from './ActivityItem.types'

const isTrailingContentMoney = (
  trailingContent: ActivityItemProps['trailingContent'],
): trailingContent is TrailingContentMoney => {
  return trailingContent?.type === 'money'
}

const isTrailingContentQuantity = (
  trailingContent: ActivityItemProps['trailingContent'],
): trailingContent is TrailingContentQuantity => {
  return trailingContent?.type === 'quantity'
}

type ActivityItemComponentProps = {
  clickable?: boolean
  onClick?: () => void
  className: string
  id?: string
}

const ActivityItemComponent: React.FC<ActivityItemComponentProps> = ({
  clickable,
  onClick,
  ...rest
}: ActivityItemComponentProps) =>
  clickable ? (
    <button onClick={onClick} {...rest} data-monzoanalytics-no-tracking />
  ) : (
    <article {...rest} />
  )

const ActivityItem: React.FC<ActivityItemProps> = (
  props: ActivityItemProps,
) => {
  const { setTabItem } = React.useContext(TabContext)
  const { id, title, subtitle, avatar, trailingContent, className, onClick } =
    props

  return (
    <ActivityItemComponent
      clickable={!!onClick}
      className={cx([styles.article, className])}
      onClick={async () => {
        setTabItem({
          id: id || '',
          name: title,
        })
        await onClick?.()
      }}
      id={id}
    >
      <div className={styles['left-side-content']}>
        {avatar && (
          <AvatarWithBadge
            type={avatar.type}
            src={avatar.src}
            icon={avatar.icon}
            placeholderName={avatar.placeholderName || ''}
            category={avatar.category}
            shape={avatar.shape}
            badge={avatar.badge}
            className={cx(styles.avatar, avatar.className)}
          />
        )}
        <div className={styles['text-wrapper']}>
          <Text
            size="medium"
            color="primary"
            className={cx(
              styles['primary-text'],
              trailingContent && styles['ellipsis'],
            )}
          >
            {title}
          </Text>
          {subtitle && (
            <Text
              size="small"
              color="secondary"
              className={styles['secondary-text']}
            >
              {subtitle}
            </Text>
          )}
        </div>
      </div>
      <div className={styles['trailing-content']}>
        {isTrailingContentMoney(trailingContent) && (
          <Money
            amount={MoneyLib.fromMajorUnits(
              DEFAULT_CURRENCY_CODE,
              trailingContent.content as string,
            )}
            size="extra-large"
            options={{ showNegative: false }}
            symbolClassName={styles['money-symbol']}
            className={styles['money']}
            minorClassName={styles['money-minor']}
          />
        )}
        {isTrailingContentQuantity(trailingContent) && (
          <Quantity
            id={`quantity-${id}`}
            label={trailingContent.content.label}
            step={trailingContent.content.step}
            min={trailingContent.content.min}
            max={trailingContent.content.max}
            value={trailingContent.content.value}
            onChange={trailingContent.content.onChange}
          />
        )}
        {trailingContent?.subtitle && (
          <Text
            size="small"
            color="secondary"
            className={styles['secondary-text']}
          >
            {trailingContent?.subtitle}
          </Text>
        )}
      </div>
    </ActivityItemComponent>
  )
}

export default ActivityItem
