import * as React from 'react'
import { SVGProps } from 'react'
const SvgDisputes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 64 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M54.0681 0.849981C54.2189 0.741899 54.4168 0.710111 54.6476 0.738367C55.4361 0.836559 56.642 1.68355 57.9166 3.04339C57.9847 3.11404 58.0541 3.18962 58.1236 3.26521C58.193 3.34079 58.2604 3.41991 58.3298 3.4962C58.4701 3.65303 58.6069 3.81692 58.7444 3.98575C58.9037 4.18072 59.0643 4.38063 59.225 4.58973C59.5961 5.07716 59.944 5.57377 60.2562 6.06331C60.2597 6.06543 60.2611 6.07037 60.2625 6.07603C60.3074 6.14667 60.3495 6.2159 60.3951 6.28724C60.7129 6.79798 60.9956 7.29883 61.2341 7.78272L61.2376 7.78484C61.3499 8.00736 61.4523 8.22352 61.5428 8.43756C62.0394 9.56995 62.2801 10.5427 62.1854 11.1707C62.158 11.3536 62.1012 11.5105 62.0163 11.6291C62.0051 11.6496 61.9861 11.6659 61.9735 11.6814L62.7599 11.0605C62.7816 11.0456 62.8055 11.028 62.8258 11.0075C62.8609 10.9778 62.889 10.9439 62.9184 10.9072C63.2783 10.4127 63.117 9.33188 62.567 7.99253C62.5144 7.86325 62.4561 7.73539 62.3972 7.60188C61.8739 6.44548 61.0917 5.13579 60.1215 3.86425C57.9152 0.965126 55.5694 -0.636313 54.8412 0.238933L54.0625 0.846449L54.0695 0.849981H54.0681Z"
      fill="#ED5E11"
    />
    <path
      d="M56.9943 8.41341C56.9333 8.33782 56.8715 8.25729 56.8091 8.17676C56.8715 8.25729 56.9318 8.33287 56.9943 8.41341Z"
      fill="#FF945A"
    />
    <path
      d="M55.8676 6.86214C55.7701 6.71662 55.6754 6.56686 55.5835 6.41992C55.6754 6.56756 55.7708 6.71662 55.8676 6.86214Z"
      fill="#FF945A"
    />
    <path
      d="M56.1791 7.32058C56.0753 7.17082 55.9743 7.02318 55.8774 6.87695C55.975 7.02247 56.0788 7.17223 56.1791 7.32058Z"
      fill="#FF945A"
    />
    <path
      d="M54.5889 3.3869C54.4037 2.68048 54.4163 2.15067 54.6338 1.86811C54.7523 1.71411 54.9172 1.63711 55.1353 1.63994C55.3851 1.64276 55.6909 1.74519 56.034 1.94087C56.4619 2.18529 56.9488 2.57169 57.4574 3.06972C57.6096 3.06195 57.7625 3.05417 57.9183 3.04852C56.6436 1.68797 55.4377 0.841688 54.6492 0.743497C54.4184 0.715241 54.222 0.75268 54.0697 0.85511C54.0115 0.893963 53.9603 0.946238 53.9168 1.00699C53.5169 1.55517 53.7646 2.83448 54.4696 4.38435C54.3258 4.06717 54.1995 3.76483 54.0978 3.47661C54.2641 3.44482 54.4247 3.41515 54.5889 3.3869Z"
      fill="#FF945A"
    />
    <path
      d="M54.8988 4.3093C54.7557 4.33191 54.6112 4.35734 54.4702 4.38489C54.767 5.02843 55.1395 5.72001 55.5814 6.4236C55.7063 6.40947 55.8368 6.39322 55.963 6.38404C55.5246 5.67338 55.1633 4.9698 54.9002 4.31425L54.8988 4.30859V4.3093Z"
      fill="#FF945A"
    />
    <path
      d="M57.3852 8.88951C57.3227 8.81746 57.2617 8.74187 57.2007 8.66699C57.2617 8.74258 57.3242 8.81463 57.3852 8.88951Z"
      fill="#FF945A"
    />
    <path
      d="M56.8848 7.73259C56.7754 7.58424 56.666 7.43589 56.5621 7.28613C56.4338 7.29885 56.3075 7.30803 56.1826 7.32216C56.3517 7.56305 56.5299 7.80464 56.713 8.04482C56.7473 8.08721 56.7803 8.13312 56.8147 8.1748C56.9494 8.1748 57.0862 8.17197 57.2223 8.16844C57.1093 8.02716 56.9978 7.88235 56.8848 7.73188V7.73259Z"
      fill="#FF945A"
    />
    <path
      d="M62.1905 11.1784C62.2846 10.5504 62.0439 9.57769 61.548 8.44531C61.4077 8.48699 61.266 8.52372 61.1221 8.56328C61.5157 9.62361 61.5809 10.4056 61.2989 10.7772C61.1804 10.9312 61.0155 11.0082 60.7973 11.0054C60.1379 11.0039 59.0927 10.2714 58.0011 9.08744C57.8608 9.09733 57.7205 9.10793 57.5781 9.11217C57.5156 9.04011 57.4525 8.96806 57.3887 8.89106C59.362 11.2236 61.301 12.4351 61.9717 11.6849C61.9864 11.6666 61.9997 11.651 62.0145 11.6326C62.1015 11.5104 62.1569 11.3571 62.1835 11.1742L62.1905 11.1777V11.1784Z"
      fill="#FF945A"
    />
    <path
      d="M58.3286 3.49951C58.4689 3.65634 58.6057 3.82022 58.7432 3.98906C58.605 3.82022 58.4668 3.65987 58.3286 3.49951Z"
      fill="#FF945A"
    />
    <path
      d="M57.917 3.04346C57.985 3.1141 58.0545 3.18968 58.1239 3.26527C58.0545 3.18968 57.9885 3.11551 57.917 3.04346Z"
      fill="#FF945A"
    />
    <path
      d="M60.2612 6.08008C60.3061 6.15143 60.3482 6.21995 60.3938 6.2913C60.3517 6.22207 60.3068 6.15143 60.2612 6.08008Z"
      fill="#FF945A"
    />
    <path
      d="M58.1226 3.26953C58.192 3.34512 58.2594 3.42494 58.3288 3.50053C58.2615 3.42141 58.192 3.34512 58.1226 3.26953Z"
      fill="#FF945A"
    />
    <path
      d="M59.1085 8.97083C58.7774 9.01674 58.447 9.05348 58.1187 9.0782C58.7774 9.77967 59.4158 10.3201 59.9552 10.6281C60.2814 10.8146 60.5662 10.9135 60.7985 10.9156C60.9872 10.9156 61.1324 10.8492 61.232 10.7206C61.5049 10.3568 61.413 9.5734 61.0384 8.58301C60.407 8.74619 59.7623 8.87899 59.105 8.96871L59.1085 8.97083Z"
      fill="#FF945A"
    />
    <path
      d="M59.0498 4.90987C59.3031 5.24895 59.5437 5.59368 59.7647 5.94123C59.8867 5.9158 60.0053 5.88896 60.1182 5.86353C60.1631 5.93417 60.2108 6.00199 60.2522 6.07121C59.9408 5.58167 59.5956 5.08647 59.221 4.59764C59.0604 4.38854 58.9018 4.18509 58.7405 3.99365C58.6002 3.99507 58.4606 3.99648 58.3188 4.00072C58.563 4.28469 58.8071 4.59128 59.047 4.9134L59.0491 4.90987H59.0498Z"
      fill="#FF945A"
    />
    <path
      d="M60.293 6.81131C60.4557 7.10306 60.6051 7.38704 60.7426 7.6689C60.8703 7.63357 60.9994 7.6032 61.1271 7.56788C61.165 7.64346 61.2049 7.71623 61.2393 7.7904C60.9987 7.31004 60.7174 6.80637 60.4003 6.29492C60.4908 6.43691 60.5757 6.58031 60.6578 6.7216C60.5406 6.75409 60.4199 6.78447 60.2965 6.81343L60.293 6.81131Z"
      fill="#FF945A"
    />
    <path
      d="M57.3355 3.07207C56.4614 2.23427 55.6574 1.72565 55.1355 1.7207C54.9468 1.7207 54.8016 1.78711 54.702 1.91567C54.4936 2.18906 54.4978 2.70686 54.6725 3.36665C55.5543 3.22042 56.441 3.12152 57.3341 3.06713L57.3355 3.07278V3.07207Z"
      fill="#FF945A"
    />
    <path
      d="M58.9766 8.02005C59.5477 7.93952 60.104 7.82861 60.6568 7.69298C60.5228 7.41324 60.3698 7.12715 60.2036 6.83398C59.6831 6.95196 59.1134 7.04661 58.4764 7.11655C58.0366 7.16317 57.5925 7.20273 57.1604 7.23664C56.9955 7.25077 56.8265 7.26278 56.6616 7.27691C56.7556 7.41183 56.8524 7.54393 56.9521 7.67744C57.0776 7.84416 57.2025 8.00522 57.3288 8.16345C57.8752 8.14156 58.4273 8.09564 58.9752 8.01581L58.9766 8.02146V8.02005Z"
      fill="#FF945A"
    />
    <path
      d="M57.0819 6.279C57.507 6.24156 57.944 6.20695 58.3755 6.15962C58.8595 6.10593 59.2874 6.03953 59.6782 5.9597C59.4614 5.6284 59.2327 5.29426 58.9844 4.96296C58.7276 4.62246 58.4702 4.29893 58.2099 3.99658C57.1296 4.02484 56.0542 4.12444 54.9893 4.29257C55.2495 4.92905 55.6129 5.63758 56.064 6.36448C56.407 6.32986 56.7459 6.30231 57.0847 6.27476L57.0826 6.2783L57.0819 6.279Z"
      fill="#FF945A"
    />
    <path
      d="M61.5478 8.44521C61.4559 8.2347 61.35 8.01641 61.2427 7.79248C61.3549 8.015 61.4573 8.23116 61.5478 8.44521Z"
      fill="#FF945A"
    />
    <path
      d="M58.0048 9.08957C57.7438 8.80488 57.4815 8.49759 57.2191 8.16699C57.0823 8.17052 56.9462 8.17335 56.8115 8.17335C56.874 8.25388 56.9343 8.32947 56.9967 8.41C57.0037 8.41353 57.0051 8.41918 57.0101 8.42625C57.0711 8.50183 57.1314 8.57671 57.1924 8.6523C57.1995 8.65583 57.2009 8.66148 57.2058 8.66854C57.2668 8.74413 57.3292 8.81548 57.3896 8.89107C57.3896 8.89107 57.3966 8.8946 57.4001 8.89672C57.4611 8.9723 57.5271 9.04577 57.5895 9.11782C57.7277 9.11147 57.8715 9.10299 58.0125 9.0931L58.0055 9.08957H58.0048Z"
      fill="#FE6B1B"
    />
    <path
      d="M60.6542 7.69114C60.1049 7.82889 59.5451 7.93767 58.9741 8.01821C58.4227 8.09591 57.8748 8.14395 57.3276 8.16585C57.5921 8.49292 57.8538 8.80021 58.1168 9.08206C58.4473 9.05381 58.7756 9.02061 59.1067 8.97469C59.7605 8.88286 60.4073 8.7557 61.0401 8.58899C60.9341 8.30713 60.8029 8.0069 60.6549 7.69608L60.6535 7.69043L60.6542 7.69114Z"
      fill="#FE6B1B"
    />
    <path
      d="M60.7427 7.66922C60.8739 7.93836 60.9854 8.20115 61.0843 8.45546C61.0997 8.49149 61.1124 8.52539 61.1243 8.5593C61.266 8.52257 61.4063 8.48089 61.5501 8.44133C61.4582 8.23082 61.3523 8.01254 61.245 7.7886L61.2415 7.78649C61.2071 7.71231 61.1671 7.64026 61.1292 7.56396C61.0015 7.59929 60.8739 7.63461 60.7448 7.66498L60.7427 7.66851V7.66922Z"
      fill="#FE6B1B"
    />
    <path
      d="M54.8511 4.18687C54.7402 3.89794 54.6533 3.63092 54.5887 3.38721C54.4246 3.41546 54.2604 3.44372 54.0977 3.47692C54.2029 3.76726 54.3285 4.0696 54.4695 4.38466C54.614 4.35923 54.7536 4.33451 54.8981 4.30908C54.8813 4.2674 54.8637 4.22643 54.8469 4.18475L54.8504 4.18687H54.8511Z"
      fill="#FE6B1B"
    />
    <path
      d="M58.3217 3.99727C58.462 3.99586 58.6037 3.99091 58.7433 3.99021C58.6051 3.82137 58.4669 3.66102 58.3287 3.50066C58.2613 3.42154 58.1919 3.34596 58.1224 3.26967C58.053 3.19408 57.987 3.11991 57.9155 3.04785C57.7597 3.0535 57.6068 3.06127 57.4546 3.06904C57.7394 3.34455 58.0284 3.6589 58.3217 3.99798V3.99727Z"
      fill="#FE6B1B"
    />
    <path
      d="M58.2082 4.00016C57.915 3.66108 57.6203 3.34814 57.3334 3.07617C56.4404 3.13057 55.5537 3.22946 54.6719 3.37569C54.7462 3.65755 54.8522 3.97049 54.9855 4.29968C56.0504 4.13155 57.1258 4.03195 58.2061 4.00369L58.2082 4.00016Z"
      fill="#FE6B1B"
    />
    <path
      d="M55.9612 6.37913C55.8328 6.39185 55.708 6.40598 55.5796 6.41869C55.6715 6.56633 55.7669 6.71539 55.8637 6.86091C55.8672 6.86303 55.8686 6.86797 55.8735 6.87574C55.971 7.02126 56.0749 7.17102 56.1752 7.31937C56.3 7.30524 56.4263 7.29606 56.5547 7.28334C56.3435 6.9817 56.1422 6.67795 55.9556 6.37207L55.9605 6.37913H55.9612Z"
      fill="#FE6B1B"
    />
    <path
      d="M58.4776 7.11592C59.1145 7.04599 59.6842 6.95133 60.2047 6.83336C60.0434 6.54726 59.8631 6.2541 59.6737 5.96094C59.285 6.03723 58.8543 6.10717 58.3709 6.16085C57.9451 6.20677 57.506 6.24492 57.0773 6.28024C56.7385 6.30779 56.3976 6.33887 56.0566 6.36995C56.2418 6.67018 56.446 6.97605 56.6578 7.27769C56.8227 7.26356 56.9883 7.24944 57.1566 7.23743C57.5902 7.20069 58.0328 7.16467 58.4727 7.11734L58.4783 7.11592H58.4776Z"
      fill="#FE6B1B"
    />
    <path
      d="M59.7654 5.94115C59.9513 6.23219 60.128 6.52324 60.2929 6.81145C60.4171 6.7832 60.537 6.75211 60.6542 6.71962C60.5728 6.57834 60.4851 6.43776 60.3967 6.29295C60.3546 6.22372 60.3097 6.15308 60.2641 6.08173C60.2606 6.07961 60.2592 6.07467 60.2578 6.06901C60.2129 5.99837 60.1687 5.93268 60.1238 5.86133C60.0109 5.88676 59.8916 5.91431 59.7703 5.93903L59.7646 5.94045L59.7654 5.94115Z"
      fill="#FE6B1B"
    />
    <path
      d="M60.7428 7.66884C60.6088 7.3891 60.458 7.10017 60.2931 6.81125C60.1283 6.52304 59.9564 6.23058 59.7656 5.94095C59.5425 5.59693 59.304 5.24796 59.0507 4.90959C58.8108 4.58746 58.566 4.28088 58.3226 3.9969C58.0314 3.65429 57.7396 3.34347 57.4555 3.06797C56.9469 2.56995 56.46 2.18354 56.0321 1.93912C55.6891 1.74344 55.3853 1.63748 55.1335 1.63819C54.9181 1.63748 54.7483 1.71589 54.6319 1.86636C54.4172 2.15034 54.4039 2.6752 54.587 3.38515C54.6508 3.62957 54.7364 3.89942 54.8494 4.18481C54.8662 4.22649 54.8837 4.26746 54.9006 4.30914C55.1629 4.96469 55.5249 5.66828 55.9634 6.37893C56.15 6.6841 56.3506 6.98856 56.5625 7.2902C56.6663 7.43996 56.7757 7.58831 56.8851 7.73665C56.9981 7.88712 57.1089 8.03193 57.2226 8.17322C57.485 8.50382 57.7466 8.81111 58.0083 9.09579C59.0998 10.279 60.1423 11.0102 60.8045 11.0137C61.0199 11.0144 61.1897 10.936 61.3061 10.7855C61.5881 10.414 61.5229 9.63125 61.1293 8.57163C61.1174 8.53773 61.1013 8.5017 61.0893 8.46779C60.9911 8.21278 60.8754 7.94858 60.7477 7.68155L60.7449 7.67096L60.7428 7.66884ZM61.236 10.7227C61.1363 10.8512 60.9911 10.9169 60.8024 10.9176C60.5702 10.9155 60.2812 10.8145 59.9592 10.6301C59.419 10.3221 58.7785 9.7796 58.1226 9.08025C57.8596 8.7991 57.5923 8.49322 57.3334 8.16403C57.2071 8.0058 57.083 7.84473 56.9567 7.67802C56.8571 7.54451 56.761 7.41241 56.6663 7.27748C56.4516 6.97443 56.251 6.66997 56.0651 6.36974C55.614 5.64214 55.2506 4.93361 54.9904 4.29783C54.8571 3.96865 54.7497 3.65924 54.6768 3.37385C54.5035 2.71052 54.4993 2.19343 54.7062 1.92287C54.8059 1.79431 54.9511 1.72861 55.1398 1.7279C55.6645 1.72932 56.4678 2.23793 57.3397 3.07927C57.6246 3.35477 57.9213 3.66418 58.2145 4.00326C58.4748 4.3056 58.7344 4.6249 58.989 4.96963C59.2373 5.30094 59.466 5.63507 59.6828 5.96638C59.8722 6.25954 60.0525 6.552 60.2138 6.8388C60.3801 7.13267 60.5309 7.42159 60.667 7.6978C60.8186 8.01004 60.9462 8.30885 61.0522 8.59071C61.4233 9.57898 61.5166 10.368 61.2458 10.7283L61.2353 10.7227H61.236Z"
      fill="white"
    />
    <path
      d="M56.9383 5.80262C57.033 5.69172 57.2063 5.70514 57.2835 5.82805L60.0299 9.64622L61.4421 11.9053C60.9384 11.9152 60.2334 11.6108 59.4336 11.0711L59.4301 11.0689C59.1951 10.9121 58.9545 10.7327 58.7048 10.5349C58.9419 10.7228 59.1734 10.8944 59.3979 11.047C59.1544 10.8803 58.9054 10.6931 58.6479 10.4897C58.0993 10.0446 57.522 9.51412 56.9503 8.91296V8.91155C56.8324 8.78793 56.7131 8.66006 56.5974 8.5315C56.5974 8.52938 56.5946 8.52726 56.5918 8.52443C56.5897 8.52302 56.5876 8.5209 56.5855 8.51807C56.5076 8.43119 56.4283 8.34147 56.3491 8.25105C56.3098 8.20584 56.2698 8.16063 56.2305 8.11613C56.039 7.8929 55.8468 7.65343 55.6595 7.41678C55.6511 7.40547 55.6419 7.39347 55.6328 7.38216C56.1821 6.69129 56.4164 6.41226 56.9369 5.80333L56.9383 5.80262Z"
      fill="#112231"
    />
    <path
      d="M55.5408 36.4834C57.4454 34.1868 58.2227 31.6826 57.2777 30.8907C56.3328 30.0981 54.0227 31.3181 52.1181 33.6146C50.2134 35.9112 49.4362 38.4154 50.3811 39.2073C51.326 39.9999 53.6361 38.7799 55.5408 36.4834Z"
      fill="#3A4920"
    />
    <path
      d="M53.9891 32.242C54.0488 32.4412 54.1238 32.6334 54.1989 32.8269C54.1989 32.8305 54.201 32.8347 54.2024 32.8368C54.2024 32.8404 54.2059 32.8446 54.208 32.8503C54.2178 32.8736 54.227 32.8948 54.2368 32.9181C54.2557 32.9626 54.2754 33.0071 54.2964 33.0516C54.3385 33.1484 54.3834 33.2451 54.4276 33.3419C54.6163 33.758 54.8071 34.1762 54.9516 34.612C54.9937 34.7399 55.0323 34.8678 55.0674 34.997C55.5374 34.3288 55.8896 33.6457 56.0783 33.024C56.3189 32.2399 56.2712 31.6571 55.9457 31.3802C55.8131 31.2658 55.6377 31.21 55.4216 31.21C55.3831 31.21 55.3424 31.21 55.3024 31.2156C54.892 31.2523 54.4009 31.4671 53.8867 31.8161C53.8965 31.8683 53.9057 31.9185 53.9176 31.9708C53.9386 32.0619 53.9618 32.153 53.9891 32.2434V32.2413V32.242Z"
      fill="#A3DC1A"
    />
    <path
      d="M54.18 36.3663C54.1779 36.3331 54.1744 36.3006 54.1701 36.2695C53.5156 36.947 52.8218 37.4605 52.208 37.7318C52.3195 37.9021 52.4276 38.0787 52.5391 38.251C52.4521 38.3054 52.3658 38.3556 52.2789 38.4043C52.9278 38.0419 53.6272 37.5022 54.3069 36.8184C54.2705 36.8551 54.2354 36.8898 54.1989 36.9265C54.1989 36.7407 54.1933 36.5549 54.18 36.3705V36.367V36.3663Z"
      fill="#A3DC1A"
    />
    <path
      d="M52.14 37.631C52.7657 37.3618 53.482 36.8257 54.1547 36.1129C54.1547 36.0973 54.1512 36.0818 54.1512 36.074C54.1379 35.9751 54.1204 35.8762 54.1028 35.778C54.0607 35.5513 54.0067 35.3266 53.9394 35.1041C53.8741 34.8873 53.7991 34.6725 53.7121 34.4613C53.7044 34.4436 53.6966 34.4246 53.691 34.4125C53.6854 34.3991 53.6798 34.3857 53.6735 34.3716C53.6545 34.3271 53.6349 34.2826 53.616 34.2381C53.5739 34.1413 53.529 34.0445 53.4848 33.9498C53.2863 33.514 53.0765 33.0612 52.932 32.5942C52.6605 32.8535 52.3946 33.1424 52.1386 33.4561C51.449 34.3002 50.9327 35.2066 50.6885 36.0083C50.6401 36.163 50.6037 36.3121 50.5791 36.4498C50.8141 36.4922 51.0435 36.5798 51.2476 36.7077C51.6251 36.9457 51.8965 37.2749 52.1393 37.6296L52.1414 37.6317L52.14 37.631Z"
      fill="#A3DC1A"
    />
    <path
      d="M50.4614 36.4321C50.4867 36.2866 50.5252 36.134 50.5729 35.9715C50.8234 35.1563 51.3453 34.2344 52.044 33.379C52.3155 33.0463 52.6024 32.7397 52.8915 32.4649C52.8395 32.2812 52.7968 32.0947 52.7722 31.9075C52.8143 31.8666 52.8592 31.8284 52.9013 31.7896C52.4832 32.1675 52.0672 32.5991 51.6708 33.0795C50.3997 34.611 49.6315 36.2378 49.541 37.3836C49.5621 37.1088 49.6238 36.8086 49.7199 36.4872C49.957 36.4173 50.2089 36.4003 50.4593 36.4314L50.4614 36.4335V36.4321Z"
      fill="#A3DC1A"
    />
    <path
      d="M51.5336 38.7483C51.4697 38.6473 51.4087 38.547 51.3449 38.4446C51.2986 38.3711 51.2523 38.2955 51.2046 38.2221C51.1814 38.1875 51.1583 38.1521 51.1351 38.1196C51.1162 38.0928 51.0853 38.0539 51.0888 38.0575C51.0327 37.9897 50.9752 37.924 50.9134 37.8618C50.9078 37.8561 50.9022 37.8505 50.8959 37.8441C50.8419 37.8173 50.7921 37.7841 50.7458 37.7452C50.717 37.7198 50.6896 37.6951 50.6651 37.6661C50.623 37.6407 50.5802 37.616 50.536 37.5927C50.5325 37.5905 50.5283 37.5891 50.5262 37.587C50.5227 37.587 50.5164 37.5849 50.5108 37.5835C50.4701 37.5722 50.4301 37.558 50.3894 37.5467C50.3817 37.5467 50.355 37.539 50.3354 37.5333H50.3277C50.2856 37.5312 50.2428 37.5298 50.2007 37.5298C50.1874 37.5298 50.174 37.5298 50.16 37.5319C50.127 37.5397 50.0674 37.5474 50.0562 37.551C50.0562 37.551 50.0253 37.5587 50.0022 37.5665C50.0001 37.5665 49.9965 37.5686 49.9944 37.5701C49.9867 37.5736 49.9425 37.5969 49.9117 37.6146C49.8983 37.6259 49.885 37.6379 49.871 37.6513C49.852 37.669 49.8345 37.6901 49.817 37.7092C49.8149 37.7113 49.8113 37.717 49.8092 37.7205C49.7959 37.7438 49.7763 37.7784 49.7707 37.7883C49.7672 37.7961 49.7629 37.8039 49.7594 37.8138C49.7538 37.8448 49.7286 37.8851 49.7208 37.9105C49.7075 37.955 49.6956 38.0017 49.6865 38.0483C49.6788 38.085 49.6788 38.1415 49.6654 38.1783C49.6654 38.1882 49.6654 38.1959 49.6633 38.2037C49.6612 38.2369 49.6598 38.2694 49.6577 38.3005C49.723 38.4538 49.8156 38.5809 49.9348 38.6819C50.2989 38.9857 50.865 38.9935 51.5315 38.7554L51.5336 38.7498V38.7483Z"
      fill="#A3DC1A"
    />
    <path
      d="M53.8731 31.1982C53.871 31.2159 53.8696 31.2371 53.8675 31.2427C53.8654 31.2661 53.864 31.2894 53.8619 31.3127C53.8584 31.3671 53.8584 31.4215 53.8584 31.4751C53.8584 31.5451 53.864 31.6143 53.8696 31.6821C54.3838 31.3416 54.877 31.1325 55.2909 31.0937C55.5932 31.0668 55.8395 31.1304 56.0226 31.2872C56.3867 31.5974 56.4463 32.2261 56.1923 33.0589C55.9903 33.7173 55.6108 34.4435 55.1064 35.1485C55.1562 35.3576 55.199 35.5688 55.2313 35.7814C57.0356 33.5315 57.7539 31.1304 56.8314 30.3576C56.2457 29.8659 55.1345 30.1485 53.9215 30.9814C53.9082 31.0259 53.8984 31.0725 53.8886 31.1191C53.8865 31.1304 53.8787 31.1735 53.8731 31.1982Z"
      fill="#A3DC1A"
    />
    <path
      d="M52.8954 32.4663C53.2209 32.1604 53.5499 31.8948 53.8719 31.6822C53.8663 31.6123 53.8607 31.5445 53.8607 31.4752C53.8607 31.4208 53.8607 31.3664 53.8642 31.3128C53.8642 31.2894 53.8677 31.2661 53.8698 31.2428C53.8698 31.2372 53.8733 31.216 53.8754 31.1983C53.881 31.1729 53.8867 31.1305 53.8909 31.1192C53.9007 31.0726 53.9119 31.0281 53.9238 30.9814C53.8291 31.045 53.7351 31.1128 53.6404 31.1849C53.5941 31.2195 53.5478 31.2548 53.5001 31.2916C53.4966 31.2937 53.4945 31.2951 53.4903 31.2993C53.4461 31.3339 53.3998 31.3693 53.3556 31.406C53.3556 31.406 53.3521 31.4081 53.3521 31.4095C53.3079 31.4463 53.2637 31.483 53.2195 31.5197C53.2118 31.5254 53.2062 31.531 53.1985 31.5374C53.1564 31.572 53.1136 31.6087 53.0715 31.6462C53.0694 31.6483 53.068 31.6497 53.0638 31.6518C53.0217 31.6886 52.9768 31.7274 52.9347 31.7663C52.9249 31.774 52.9158 31.7839 52.9059 31.7917C52.8638 31.8306 52.8189 31.8694 52.7769 31.9097C52.8021 32.0976 52.8442 32.2834 52.8961 32.467L52.8954 32.4663Z"
      fill="#C3FF34"
    />
    <path
      d="M53.6171 34.2398C53.636 34.2843 53.6557 34.3288 53.6746 34.3733C53.6802 34.3867 53.6858 34.4001 53.6921 34.4143C53.6957 34.4256 53.7055 34.4454 53.7132 34.463C53.7981 34.6742 53.8752 34.8869 53.9405 35.1058C54.0057 35.3262 54.0597 35.553 54.1039 35.7798C54.1229 35.8787 54.1383 35.9776 54.1523 36.0758C54.1523 36.0835 54.1544 36.0991 54.1558 36.1146C54.3158 35.9444 54.4736 35.7663 54.628 35.5763C54.7837 35.3863 54.9303 35.1927 55.0671 34.9971C55.0327 34.8671 54.9942 34.7378 54.9514 34.6121C54.8069 34.1762 54.616 33.758 54.4273 33.3419C54.3831 33.2452 54.3403 33.1484 54.2962 33.0516C54.2772 33.0071 54.2576 32.9626 54.2365 32.9181C54.2267 32.8948 54.2176 32.8736 54.2078 32.8503C54.2057 32.8446 54.2042 32.8404 54.2021 32.8368C54.2021 32.8347 54.2 32.8312 54.1986 32.827C54.1236 32.6334 54.0485 32.442 53.9889 32.2421C53.9622 32.153 53.937 32.0619 53.9173 31.9687C53.9061 31.9185 53.8963 31.8662 53.8865 31.814C53.5729 32.0273 53.2509 32.2901 52.9331 32.5946C53.0797 33.0615 53.2895 33.5122 53.4859 33.9502C53.5301 34.0469 53.5729 34.1437 53.6171 34.2384V34.2405V34.2398Z"
      fill="#C3FF34"
    />
    <path
      d="M54.721 35.6521C54.5421 35.8711 54.359 36.076 54.1724 36.2695C54.1759 36.3027 54.178 36.3331 54.1822 36.3663C54.1955 36.5521 54.2032 36.7379 54.2011 36.9222C54.2376 36.8876 54.2741 36.8509 54.3092 36.8135C54.3127 36.8099 54.3169 36.8057 54.3204 36.8022C54.3534 36.7689 54.3856 36.7365 54.4186 36.7011C54.4221 36.6976 54.4263 36.6934 54.4319 36.6877C54.5049 36.6121 54.5785 36.5344 54.6515 36.4553C54.6515 36.4553 54.6536 36.4532 54.655 36.4518C54.688 36.415 54.7203 36.3804 54.7532 36.343C54.763 36.3331 54.7708 36.3218 54.7799 36.3119C54.8051 36.2829 54.8297 36.2561 54.8549 36.2264C54.8662 36.2151 54.876 36.201 54.8879 36.1897C54.9132 36.1628 54.9363 36.1339 54.9609 36.1042C54.9707 36.0929 54.9819 36.0809 54.9917 36.0696C55.0247 36.0307 55.0591 35.9905 55.0921 35.9495C55.1384 35.8937 55.1847 35.8372 55.2288 35.7814C55.1959 35.568 55.1538 35.3575 55.104 35.1484C54.9826 35.3166 54.8535 35.4854 54.7188 35.6521H54.721Z"
      fill="#C3FF34"
    />
    <path
      d="M50.8208 37.6521C50.9786 37.7856 51.1947 37.8421 51.464 37.8167C51.6703 37.7976 51.899 37.7333 52.1403 37.6309C51.8976 37.2748 51.6261 36.9471 51.2487 36.709C51.0445 36.5811 50.8151 36.4943 50.5801 36.4512C50.4777 37.0128 50.5612 37.431 50.8208 37.6535V37.6514V37.6521Z"
      fill="#C3FF34"
    />
    <path
      d="M49.6656 38.1689C49.679 38.1322 49.679 38.0757 49.6867 38.0389C49.6965 37.9923 49.7077 37.9478 49.7211 37.9012C49.7288 37.8757 49.7519 37.8355 49.7596 37.8044C49.7631 37.7966 49.7674 37.7889 49.7709 37.779C49.7765 37.7691 49.7961 37.7366 49.8094 37.7112C49.813 37.7076 49.8151 37.7034 49.8172 37.6999C49.8347 37.6808 49.8515 37.661 49.8712 37.6419C49.8845 37.6306 49.8978 37.6186 49.9119 37.6052C49.9406 37.5875 49.9869 37.5642 49.9946 37.5607C49.9946 37.5607 49.9982 37.5586 50.0024 37.5572C50.0255 37.5515 50.0543 37.5437 50.0564 37.5416C50.0676 37.5381 50.1279 37.5303 50.1602 37.5225C50.1735 37.5225 50.1869 37.5225 50.2009 37.5204C50.243 37.5204 50.2858 37.5204 50.3279 37.524H50.3356C50.3566 37.5296 50.384 37.5353 50.3896 37.5374C50.4303 37.5473 50.4703 37.5607 50.511 37.5741C50.5166 37.5741 50.5222 37.5776 50.5264 37.5776C50.5306 37.5776 50.532 37.5812 50.5362 37.5833C50.5804 37.6066 50.6232 37.632 50.6653 37.6568C50.4359 37.3954 50.3665 36.9694 50.4633 36.4255C50.2128 36.3944 49.9589 36.4142 49.7239 36.4813C49.6278 36.8027 49.566 37.1051 49.545 37.3777C49.5394 37.4554 49.5352 37.5289 49.5352 37.6024V37.608C49.5352 37.6794 49.5387 37.7472 49.545 37.8136V37.8235C49.5506 37.8892 49.5604 37.9513 49.5737 38.0135C49.5737 38.017 49.5737 38.0191 49.5758 38.0234C49.5892 38.0834 49.6046 38.1414 49.6242 38.1958C49.6242 38.1993 49.6264 38.2014 49.6278 38.2056C49.6376 38.2325 49.6467 38.26 49.6586 38.2848C49.6586 38.2516 49.6621 38.2191 49.6642 38.188C49.6642 38.1802 49.6642 38.1703 49.6663 38.1626V38.1682L49.6656 38.1689ZM50.0241 37.555C50.0339 37.5515 50.0374 37.5515 50.0241 37.555V37.555ZM49.907 37.613C49.8971 37.6229 49.8859 37.6306 49.8859 37.6306C49.8859 37.6285 49.8936 37.6207 49.907 37.613ZM49.8087 37.7154C49.7898 37.75 49.7975 37.7331 49.8087 37.7154V37.7154Z"
      fill="#C3FF34"
    />
    <path
      d="M52.2078 37.7312C51.9462 37.8456 51.6992 37.917 51.4762 37.9367C51.432 37.9403 51.3878 37.9424 51.345 37.9424C51.1738 37.9424 51.0237 37.9092 50.896 37.8435C50.9016 37.8491 50.9072 37.8548 50.9135 37.8612C50.9774 37.9233 51.0328 37.989 51.0889 38.0568C51.0868 38.0533 51.1177 38.0914 51.1352 38.119C51.1584 38.1536 51.1815 38.1868 51.2047 38.2214C51.2531 38.2949 51.2994 38.3684 51.345 38.4439C51.4067 38.5464 51.4698 38.6474 51.5337 38.7477C51.6129 38.7209 51.6915 38.6877 51.7743 38.653H51.7764C51.8571 38.6184 51.9399 38.5817 52.0226 38.5386H52.0261C52.1089 38.4976 52.1938 38.4531 52.2787 38.4051H52.2808C52.3657 38.3564 52.452 38.3062 52.5411 38.2518C52.4316 38.0794 52.3236 37.9035 52.2099 37.7326L52.2078 37.7305V37.7312Z"
      fill="#C3FF34"
    />
    <path
      d="M51.4757 37.9365C51.6987 37.9153 51.9478 37.8454 52.2073 37.7309C52.8219 37.4618 53.515 36.9468 54.1695 36.2687C54.3561 36.0751 54.5413 35.8695 54.7181 35.6513C54.8528 35.4867 54.9818 35.3185 55.1032 35.1476C55.6097 34.4426 55.9892 33.7164 56.1891 33.058C56.4431 32.2251 56.3835 31.5957 56.0194 31.2863C55.8363 31.1316 55.5901 31.0659 55.2877 31.0928C54.8738 31.1316 54.3806 31.3407 53.8664 31.6812C53.5451 31.8945 53.2154 32.1595 52.8899 32.4653C52.6009 32.738 52.314 33.0446 52.0425 33.3794C51.3431 34.2356 50.8197 35.1568 50.5714 35.972C50.5216 36.133 50.4844 36.2877 50.4599 36.4325C50.3638 36.9786 50.4311 37.4025 50.6619 37.6638C50.6872 37.6928 50.7138 37.7182 50.7426 37.7429C50.7889 37.7818 50.8387 37.8143 50.8927 37.8418C51.0218 37.9075 51.1698 37.9407 51.3417 37.9407C51.3838 37.9407 51.4287 37.9386 51.4729 37.9351H51.475L51.4757 37.9365ZM50.5798 36.4495C50.6051 36.3103 50.6416 36.1627 50.6893 36.008C50.9341 35.2062 51.4497 34.2999 52.1393 33.4557C52.3953 33.1421 52.6633 32.8539 52.9327 32.5939C53.2505 32.2901 53.5718 32.0266 53.8861 31.8133C54.4003 31.465 54.8913 31.2496 55.3017 31.2129C55.3424 31.2093 55.3824 31.2072 55.421 31.2072C55.6349 31.2072 55.8103 31.2651 55.945 31.3775C56.2705 31.6544 56.3168 32.2372 56.0776 33.0213C55.8868 33.6408 55.5346 34.3246 55.0667 34.9943C54.9299 35.19 54.7833 35.3835 54.6276 35.5735C54.4732 35.7615 54.3154 35.9416 54.1555 36.1118C53.4813 36.8246 52.7672 37.3608 52.1407 37.6299C51.9001 37.7323 51.6728 37.798 51.4644 37.8157C51.1951 37.8411 50.979 37.7846 50.8211 37.6511C50.5609 37.4307 50.4781 37.0104 50.5805 36.4488H50.5784L50.5798 36.4495Z"
      fill="white"
    />
    <path
      d="M52.9714 33.6084C53.0296 33.6628 53.0275 33.7857 52.9679 33.8521L50.6465 36.2313L49.5466 37.4478C49.5283 37.0974 49.663 36.5747 49.9142 35.9615L49.9156 35.9587C49.9885 35.7792 50.072 35.5928 50.1653 35.3985C50.0769 35.5829 49.9969 35.7623 49.9254 35.9339C50.0033 35.7475 50.0909 35.5539 50.1863 35.354C50.3961 34.9259 50.6479 34.4688 50.9356 34.0083H50.9363C50.9952 33.9129 51.0569 33.8168 51.118 33.7229C51.1194 33.7229 51.1201 33.7208 51.1215 33.7179C51.1222 33.7165 51.1229 33.7151 51.1243 33.713C51.1657 33.6494 51.2092 33.5851 51.2526 33.5208C51.2744 33.4883 51.2961 33.4566 51.3172 33.4248C51.4245 33.2679 51.5403 33.1097 51.6546 32.955C51.6602 32.9479 51.6658 32.9402 51.6715 32.9331C52.0334 33.2453 52.6522 33.3075 52.9721 33.6084H52.9714Z"
      fill="#112231"
    />
    <path
      d="M57.8749 6.59911H57.8756L57.3684 5.92236C57.5396 6.18303 57.6154 6.5101 57.5508 6.84706L56.7763 10.8779L52.5616 32.8233C52.4487 33.4103 51.938 33.8349 51.3445 33.8349H14.0315C13.6597 33.8349 13.3321 33.6717 13.1069 33.4174L13.49 33.9253C13.5412 34.0143 13.6036 34.0969 13.6752 34.1704L13.6773 34.1732C13.9003 34.4028 14.2118 34.5483 14.5633 34.5483H51.877C52.4711 34.5483 52.9811 34.1238 53.0941 33.5368L58.0833 7.56054C58.1513 7.20733 58.0643 6.86613 57.8756 6.59981L57.8749 6.59911Z"
      fill="#004E60"
    />
    <path
      d="M56.7775 10.8777L57.552 6.84691C57.6166 6.50996 57.5408 6.18289 57.3696 5.92222C57.15 5.58738 56.7726 5.36133 56.3349 5.36133H19.1398C18.5477 5.36133 18.0384 5.78235 17.9233 6.36726L17.0366 10.8763L56.7775 10.8784V10.8777Z"
      fill="#016B83"
    />
    <path
      d="M14.0319 33.8346H51.3456C51.9397 33.8346 52.4497 33.4101 52.5627 32.823L56.7774 10.8776L17.0365 10.8755L12.8154 32.3427C12.7362 32.7467 12.8589 33.1367 13.1066 33.4164C13.3318 33.6707 13.6594 33.8339 14.0312 33.8339L14.0319 33.8346Z"
      fill="#2EAFC0"
    />
    <path
      d="M49.3646 8.19041C49.1738 8.76896 48.562 9.23872 47.998 9.23872C47.434 9.23872 47.1295 8.76967 47.319 8.19041C47.5084 7.61115 48.1201 7.14209 48.6855 7.14209C49.2509 7.14209 49.5554 7.61115 49.3646 8.19041Z"
      fill="#F2F8F3"
    />
    <path
      d="M52.3199 8.19041C52.127 8.76896 51.5132 9.23872 50.9492 9.23872C50.3852 9.23872 50.0828 8.76967 50.2743 8.19041C50.4658 7.61185 51.0797 7.14209 51.6451 7.14209C52.2105 7.14209 52.5129 7.61115 52.3199 8.19041Z"
      fill="#F2F8F3"
    />
    <path
      d="M55.2748 8.19041C55.0798 8.76896 54.4639 9.23872 53.8999 9.23872C53.3358 9.23872 53.0356 8.76967 53.2292 8.19041C53.4228 7.61185 54.0388 7.14209 54.6042 7.14209C55.1696 7.14209 55.4706 7.61115 55.2748 8.19041Z"
      fill="#F2F8F3"
    />
    <path
      d="M25.5094 15.2368H20.4199C20.1506 15.2368 19.9177 15.4283 19.8643 15.6946L18.0965 24.4428H18.107L16.9467 30.5625C16.8801 30.9142 17.1481 31.2399 17.503 31.2399H22.5925C22.8619 31.2399 23.0948 31.0485 23.1481 30.7821L24.4452 24.3171H24.4347L26.0209 15.8691C26.0875 15.5173 25.8651 15.2368 25.5094 15.2368Z"
      fill="#BBF6E2"
    />
    <path
      d="M50.6171 21.5415H45.5276C45.2582 21.5415 45.0253 21.7329 44.972 21.9993L44.4403 24.4435H44.4508L43.2905 30.5631C43.2238 30.9149 43.4918 31.2406 43.8468 31.2406H48.9363C49.2057 31.2406 49.4386 31.0491 49.4919 30.7828L50.789 24.3177H50.7785L51.1734 22.219C51.2401 21.8672 50.9721 21.5415 50.6171 21.5415Z"
      fill="#BBF6E2"
    />
    <path
      d="M34.3252 12.9644H29.2357C28.9663 12.9644 28.7334 13.1558 28.6801 13.4221L26.5145 24.4429H26.525L25.3647 30.5625C25.2981 30.9143 25.566 31.24 25.921 31.24H31.0105C31.2799 31.24 31.5128 31.0485 31.5661 30.7822L32.8632 24.3171H32.8527L34.8822 13.6418C34.9488 13.29 34.6808 12.9644 34.3259 12.9644H34.3252Z"
      fill="#BBF6E2"
    />
    <path
      d="M42.7931 14.6104H37.7036C37.4342 14.6104 37.2013 14.8018 37.148 15.0681L35.3289 24.4429H35.3395L34.1792 30.5626C34.1125 30.9144 34.3805 31.24 34.7355 31.24H39.825C40.0943 31.24 40.3273 31.0486 40.3806 30.7823L41.6777 24.3172H41.6671L43.3494 15.2878C43.416 14.936 43.148 14.6104 42.7931 14.6104Z"
      fill="#BBF6E2"
    />
    <mask
      id="mask0_686_1117"
      maskUnits="userSpaceOnUse"
      x="13"
      y="19"
      width="19"
      height="19"
    >
      <path
        d="M16.1063 22.9591C19.3572 19.3529 24.8052 18.9241 28.5646 21.86C31.8294 25.3447 31.9305 30.8462 28.6796 34.4525C25.4288 38.0587 19.9815 38.4875 16.2214 35.5517C12.9565 32.0669 12.8555 26.5647 16.1056 22.9591H16.1063Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_686_1117)">
      <path
        d="M65.2288 0.512225L65.2303 0.510812L64.5806 -0.355957C64.8002 -0.0218236 64.897 0.397079 64.8143 0.829404L63.8216 5.99611L58.4192 34.1248C58.2747 34.8778 57.6202 35.421 56.8591 35.421H9.03175C8.55472 35.421 8.13451 35.2119 7.84619 34.8863L8.33725 35.5369C8.4032 35.6513 8.48317 35.7566 8.57507 35.8512L8.57787 35.8548C8.86409 36.1487 9.26326 36.3351 9.71363 36.3351H57.5409C58.3021 36.3351 58.9566 35.7912 59.1011 35.0389L65.4961 1.7435C65.5831 1.29069 65.4716 0.853422 65.2303 0.511518L65.2288 0.512225Z"
        fill="#004E60"
      />
      <path
        d="M9.03157 35.4217H56.8589C57.62 35.4217 58.2745 34.8777 58.419 34.1254L63.8214 5.99675L12.8829 5.99463L7.4721 33.5108C7.37038 34.0293 7.52822 34.5288 7.84601 34.8869C8.13433 35.2126 8.55454 35.4217 9.03157 35.4217Z"
        fill="#2EAFC0"
      />
      <path
        d="M23.1397 14.5107H16.6163C16.2705 14.5107 15.973 14.7559 15.9043 15.0971L14.2417 23.384H14.2557L12.7685 31.228C12.6829 31.6787 13.026 32.0962 13.482 32.0962H20.0054C20.3512 32.0962 20.6487 31.8511 20.7174 31.5099L22.38 23.2229H22.366L23.8532 15.3789C23.9388 14.9282 23.5957 14.5107 23.1397 14.5107Z"
        fill="#BBF6E2"
      />
      <path
        d="M35.0431 8.67139H28.5197C28.1738 8.67139 27.8764 8.91651 27.8076 9.25771L25.0317 23.3839H25.0458L23.5586 31.2279C23.473 31.6786 23.816 32.096 24.272 32.096H30.7954C31.1413 32.096 31.4387 31.8509 31.5074 31.5097L33.17 23.2228H33.156L35.7572 9.53957C35.8428 9.08888 35.4998 8.67139 35.0438 8.67139H35.0431Z"
        fill="#BBF6E2"
      />
    </g>
    <path
      d="M16.0004 38.1523L14.5854 39.7312L15.8356 40.7795L17.5101 38.9209C16.9952 38.6935 16.4711 38.4455 16.0004 38.1523Z"
      fill="#016B83"
    />
    <path
      d="M15.6639 37.5445C11.0016 33.2827 10.2784 26.148 14.5106 21.4531C18.7429 16.7583 25.979 16.4065 30.6406 20.6683C35.3022 24.9294 35.6522 32.216 31.42 36.9109C27.1877 41.6057 20.3255 41.8063 15.6632 37.5445H15.6639ZM29.1555 22.3157C25.3953 18.8783 19.5594 19.1622 16.1459 22.9486C12.7323 26.735 13.015 32.6116 16.7751 36.049C20.5352 39.4864 26.3712 39.2018 29.784 35.4154C33.1976 31.629 32.9156 25.7524 29.1555 22.3157Z"
      fill="#004E60"
    />
    <path
      d="M14.164 36.791C9.46455 32.4953 9.11239 25.1508 13.3776 20.4192C17.6435 15.687 24.9372 15.3323 29.636 19.628C34.3347 23.923 34.6876 31.2676 30.4217 35.9999C26.1557 40.7321 18.8621 41.0867 14.1633 36.7918L14.164 36.791ZM28.0905 21.3425C24.3304 17.9051 18.4945 18.1891 15.0809 21.9754C11.6673 25.7618 11.95 31.6385 15.7102 35.0759C19.4703 38.5133 25.3062 38.2286 28.7191 34.4422C32.1327 30.6558 31.8506 24.7792 28.0905 21.3425Z"
      fill="#68CDB5"
    />
    <g opacity="0.5">
      <path
        d="M28.7199 34.4406C26.2484 37.1822 22.5128 38.0779 19.2129 37.0685C18.2266 36.6764 17.2893 36.1099 16.4566 35.3491C15.3321 34.3212 14.5198 33.0688 14.0385 31.7195C13.8182 31.1247 13.6646 30.5066 13.5804 29.8751C13.4962 29.3078 13.4717 28.7307 13.4976 28.1542C13.5594 26.8488 13.8968 25.5575 14.5184 24.3636C14.8656 23.6869 15.3083 23.0469 15.8407 22.4556C19.0965 18.8437 24.5641 18.4792 28.2744 21.5288C28.9261 22.1568 29.467 22.8639 29.8928 23.6148C30.5424 24.7571 30.9317 26.0166 31.0573 27.2988C31.1113 27.871 31.1162 28.4495 31.0664 29.0231C31.0131 29.6582 30.8988 30.2897 30.7136 30.9071C30.3277 32.1808 29.6669 33.3902 28.7199 34.4406Z"
        fill="#BBF6E2"
      />
    </g>
    <g opacity="0.4">
      <path
        d="M31.0572 27.2987L13.4976 28.1535C13.5593 26.848 13.8967 25.5567 14.5183 24.3628L29.8927 23.6147C30.5423 24.757 30.9317 26.0166 31.0572 27.2987Z"
        fill="#BBF6E2"
      />
      <path
        d="M30.7127 30.907L14.0377 31.7186C13.8174 31.1238 13.6638 30.5057 13.5796 29.8742L31.0656 29.0229C31.0123 29.658 30.8979 30.2895 30.7127 30.907Z"
        fill="#BBF6E2"
      />
    </g>
    <path
      d="M12.4677 38.1722L12.5785 38.0493L11.5122 39.3343L13.0983 40.5924L13.9752 39.5716L13.3986 40.2131L12.4677 38.1722Z"
      fill="#016B83"
    />
    <path
      d="M12.5786 38.0493L12.4678 38.1722L13.3987 40.2131L13.9753 39.5716L14.1549 39.3717L12.5786 38.0493Z"
      fill="#112231"
    />
    <path
      d="M14.5875 39.7338L16.0031 38.155C14.6254 37.296 13.4131 36.1756 12.4773 34.8843L11.1865 36.8813L12.5783 38.049L14.1546 39.3714L14.5868 39.7345L14.5875 39.7338Z"
      fill="#2EAFC0"
    />
    <path
      d="M10.0433 38.1846L1.50722 48.8846C0.519482 50.1498 0.738355 51.9822 1.99477 52.9762C3.25119 53.9708 5.07092 53.7504 6.05796 52.4852L14.5947 41.7852L12.8886 40.4352L11.2765 39.1601L10.044 38.1846H10.0433Z"
      fill="#FF945A"
    />
    <path
      d="M13.2956 40.7583L4.77989 51.4322C3.95701 52.4869 2.55609 52.8147 1.38525 52.3188C1.54941 52.5625 1.75215 52.785 1.99417 52.9771C3.25059 53.9718 5.07032 53.7521 6.05735 52.4862L14.5941 41.7861L13.2956 40.759V40.7583Z"
      fill="#FF4F40"
    />
  </svg>
)
export default SvgDisputes
