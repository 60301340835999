import * as React from 'react'
import { SVGProps } from 'react'
const SvgPersonCircles = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm8.502-5.002a4.498 4.498 0 1 1 0 8.996 4.498 4.498 0 0 1 0-8.996ZM6.998 0a7 7 0 1 1 0 13.999A7 7 0 0 1 6.999 0Zm0 7.168c1.808 0 3.278 1.076 3.338 2.42v.084c0 .461-.373.834-.834.834H4.491a.833.833 0 0 1-.833-.834c0-1.382 1.495-2.504 3.341-2.504Zm0-4.175c.922 0 1.67.75 1.67 1.67 0 .922-.748 1.671-1.67 1.671-.921 0-1.67-.749-1.67-1.67 0-.922.749-1.67 1.67-1.67Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPersonCircles
