import * as React from 'react'

export default function SvgArrowLeft(
  props: HTMLOrSVGElement,
): React.ReactElement<SVGElement> {
  return (
    <svg {...props} viewBox="0 0 18 16">
      <path
        d="M7.3 0.299977C7.49257 0.155548 7.73078 0.0854286 7.97089 0.102493C8.211 0.119557 8.4369 0.222659 8.60711 0.39287C8.77732 0.563081 8.88042 0.788978 8.89748 1.02909C8.91455 1.2692 8.84443 1.5074 8.7 1.69998L3.42 6.99998H17C17.2457 6.9982 17.4834 7.08693 17.6679 7.24924C17.8523 7.41154 17.9705 7.63607 18 7.87998V7.99998C18 8.26519 17.8946 8.51955 17.7071 8.70708C17.5196 8.89462 17.2652 8.99998 17 8.99998H3.41L8.71 14.3C8.87907 14.472 8.98027 14.6993 8.99497 14.94C9.00968 15.1807 8.93688 15.4187 8.79 15.61L8.71 15.71C8.61704 15.8037 8.50644 15.8781 8.38458 15.9289C8.26272 15.9796 8.13201 16.0058 8 16.0058C7.86799 16.0058 7.73728 15.9796 7.61542 15.9289C7.49356 15.8781 7.38296 15.8037 7.29 15.71L0.290001 8.70998L0.219999 8.62998C0.076695 8.45136 -0.000963951 8.22897 0 7.99998V8.08998C-0.000816917 8.06665 -0.000816917 8.0433 0 8.01998V7.99998V7.97998C0.00636205 7.71969 0.113987 7.47215 0.299999 7.28998L0.209999 7.38998C0.234502 7.35497 0.261225 7.32157 0.290001 7.28998L7.29 0.289977L7.3 0.299977Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
