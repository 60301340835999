import * as React from 'react'
import { SVGProps } from 'react'
const SvgAirplane = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="#00000000"
      d="M8.028 3.221c-.24-1.08 1.194-1.693 1.808-.771l5.034 7.554H19c1.591 0 2.892.826 2.994 1.869l.006.132c0 1.06-1.238 1.928-2.802 1.995l-.197.005-4.134-.001-5.031 7.55c-.614.922-2.048.31-1.808-.77l1.506-6.78h-4.66L3.838 15.56c-.626.939-2.05.283-1.811-.761l.03-.11.895-2.684-.894-2.683C1.7 8.25 3.093 7.529 3.77 8.358l.068.092 1.036 1.554h4.661Z"
    />
  </svg>
)
export default SvgAirplane
