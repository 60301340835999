import React from 'react'

import styles from './visually-hidden.module.scss'

export type Props = {
  is?: keyof JSX.IntrinsicElements
} & React.ComponentPropsWithRef<React.ElementType>

export default function VisuallyHidden(props: Props) {
  const { is: Is = 'span', ...otherProps } = props
  return <Is {...otherProps} className={styles.root} />
}
