import cx from 'classnames'
import * as React from 'react'

import {
  PolymorphicComponentPropsWithRef,
  PolymorphicRef,
  SpacingProps as SharedSpacingProps,
} from '../shared-types'
import styles from './spacer.module.scss'

export type Sizes =
  | 'extra-small'
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'none'

export type Props = SpacingProps & {
  children?: React.ReactNode
  disabled?: boolean
  width?: number
  height?: number
  className?: string
  fullWidth?: boolean
}

// Export this here so we don't have to update the imports across the entire repo
export type SpacingProps = SharedSpacingProps

type SpacerProps<C extends React.ElementType> =
  PolymorphicComponentPropsWithRef<C, Props>

type SpacerComponent = <C extends React.ElementType = 'div'>(
  props: SpacerProps<C>,
) => React.ReactElement | null

const Spacer: SpacerComponent = React.forwardRef(
  <C extends React.ElementType>(
    props: SpacerProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const {
      marginTop = '',
      marginRight = '',
      marginBottom = '',
      marginLeft = '',
      paddingTop = '',
      paddingRight = '',
      paddingBottom = '',
      paddingLeft = '',
      fullWidth,
      is,
      children,
      className,
      ...otherProps
    } = props

    const _classNames = cx(
      {
        [styles[`margin-top-${marginTop}`]]: Boolean(marginTop),
        [styles[`margin-right-${marginRight}`]]: Boolean(marginRight),
        [styles[`margin-bottom-${marginBottom}`]]: Boolean(marginBottom),
        [styles[`margin-left-${marginLeft}`]]: Boolean(marginLeft),
        [styles[`padding-top-${paddingTop}`]]: Boolean(paddingTop),
        [styles[`padding-right-${paddingRight}`]]: Boolean(paddingRight),
        [styles[`padding-bottom-${paddingBottom}`]]: Boolean(paddingBottom),
        [styles[`padding-left-${paddingLeft}`]]: Boolean(paddingLeft),
        [styles['full-width']]: fullWidth,
      },

      className,
    )
    const Component = is || 'div'

    return (
      <Component {...otherProps} className={_classNames} ref={ref}>
        {children}
      </Component>
    )
  },
)

export default Spacer
