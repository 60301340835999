import * as React from 'react'
import { SVGProps } from 'react'
const SvgLookerColor = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_766_1321)">
      <path
        d="M11.9492 1.86652e-06C11.1746 0.000341654 10.4632 0.427705 10.0991 1.11148C9.735 1.79525 9.77751 2.62404 10.2096 3.26698L11.1053 2.37254C11.0757 2.28332 11.0608 2.18988 11.0611 2.09587C11.0611 1.60747 11.4571 1.21154 11.9455 1.21154C12.4339 1.21154 12.8298 1.60747 12.8298 2.09587C12.8298 2.58427 12.4339 2.9802 11.9455 2.9802C11.8519 2.98041 11.7589 2.96548 11.6701 2.93599L10.7756 3.83043C11.5205 4.3356 12.5047 4.30884 13.221 3.76393C13.9373 3.21903 14.2257 2.27764 13.9376 1.42499C13.6495 0.572339 12.8492 -0.00119399 11.9492 1.86652e-06Z"
        fill="#34A853"
      />
      <path
        d="M11.1152 6.10824C11.1166 5.40025 10.8873 4.71108 10.4621 4.14502L9.2998 5.30602C9.66329 5.97084 9.53248 6.79719 8.98145 7.31725L9.61311 8.86104C10.549 8.25827 11.1148 7.22146 11.1152 6.10824Z"
        fill="#FBBC04"
      />
      <path
        d="M7.8671 7.77448H7.83678C7.12389 7.77454 6.48979 7.32142 6.25892 6.64694C6.02804 5.97247 6.25145 5.22582 6.81484 4.789C7.37824 4.35218 8.157 4.32181 8.7527 4.71343L9.9036 3.56253C8.78449 2.65526 7.2041 2.58527 6.00917 3.39007C4.81424 4.19487 4.28507 5.68568 4.70517 7.06374C5.12527 8.44181 6.39611 9.38389 7.83678 9.38523C8.05908 9.38533 8.28083 9.36333 8.49877 9.31953L7.8671 7.77448Z"
        fill="#EA4335"
      />
      <path
        d="M11.9934 9.10966C11.2659 9.10892 10.5423 9.21491 9.84569 9.42423L10.7667 11.6742C11.1676 11.5749 11.5791 11.5248 11.9921 11.5252C14.3853 11.5258 16.4448 13.2169 16.9111 15.5642C17.3775 17.9116 16.1209 20.2615 13.9097 21.177C11.6985 22.0925 9.14855 21.3186 7.81914 19.3286C6.48973 17.3386 6.75109 14.6866 8.44339 12.9944C8.79651 12.6424 9.19965 12.3444 9.63977 12.1101L8.7289 9.8664C5.33362 11.5292 3.72607 15.4747 4.99245 19.0369C6.25882 22.599 9.99612 24.6444 13.6791 23.7908C17.3621 22.9373 19.8187 19.4565 19.3891 15.7004C18.9594 11.9443 15.7803 9.10822 11.9997 9.1084L11.9934 9.10966Z"
        fill="#4285F4"
      />
    </g>
    <defs>
      <clipPath id="clip0_766_1321">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgLookerColor
