import * as React from 'react'
import { SVGProps } from 'react'
const SvgArrowsSwap = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="#00000000"
      d="M5.793 11.293a1 1 0 0 1 1.414 1.414L4.414 15.5H13a1 1 0 0 1 .993.883L14 16.5a1 1 0 0 1-1 1H4.414l2.793 2.793a1 1 0 0 1 .083 1.32l-.083.094a1 1 0 0 1-1.414 0l-4.5-4.5-.073-.082-.007-.008a1.34 1.34 0 0 1-.067-.096.85.85 0 0 1-.075-.15A.987.987 0 0 1 1 16.5l.004.09A.963.963 0 0 1 1 16.52v-.04a.993.993 0 0 1 .293-.687l-.08.09a.997.997 0 0 1 .08-.09Zm12.414-9 4.5 4.5a.997.997 0 0 1 .08.09l-.08-.09a1.012 1.012 0 0 1 .282.558A.975.975 0 0 1 23 7.5v.02a.963.963 0 0 1-.004.07L23 7.5a1.017 1.017 0 0 1-.146.52 1.045 1.045 0 0 1-.067.097l-.007.008-.073.082-4.5 4.5a1 1 0 0 1-1.414-1.414L19.585 8.5H11a1 1 0 1 1 0-2h8.585l-2.792-2.793a1 1 0 0 1 1.414-1.414Z"
    />
  </svg>
)
export default SvgArrowsSwap
