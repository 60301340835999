import React, { useEffect, useRef } from 'react'

import {
  buildActionAnalyticsAttributes,
  getClient,
} from '@mondough/analytics-v2'
import { Button, VisuallyHidden } from '@mondough/monzo-ui'

import styles from './Quantity.module.scss'
import { QuantityProps } from './Quantity.types'
import { QuantityMoneyInput } from '..'

const Quantity: React.FC<QuantityProps> = ({
  id,
  label,
  min = '0.00',
  max,
  value,
  onChange,
}: QuantityProps) => {
  const numberValue = Number(value)
  const numberMin = Number(min)
  const numberMax = Number(max)
  const intervalRef = useRef<NodeJS.Timeout | null>(null) // To keep track of the decrement interval
  const timeoutRef = useRef<NodeJS.Timeout | null>(null) // To keep track of the timeout before starting the decrement interval
  const timeoutTime = 400 // Time in milliseconds before starting the decrement interval
  const intervalTime = 100 // Time interval in milliseconds between decrements

  // Function to stop decreasing the counter
  const stopLongPress = () => {
    clearInterval(intervalRef.current ?? undefined)
    clearTimeout(timeoutRef.current ?? undefined)
    timeoutRef.current = null
    intervalRef.current = null
  }

  const isButtonDisabled = (type: 'increase' | 'decrease') =>
    type === 'increase' ? numberValue >= numberMax : numberValue <= numberMin

  // Function to start decreasing the counter at regular intervals
  const startLongPress = (type: 'increase' | 'decrease') => {
    if (isButtonDisabled(type)) return
    onChange(type)
    timeoutRef.current ??= setTimeout(
      () =>
        (intervalRef.current ??= setInterval(() => {
          onChange(type)
        }, intervalTime)),
      timeoutTime,
    )
  }

  useEffect(() => {
    if (numberValue <= numberMin || numberValue >= numberMax) {
      stopLongPress()
      // onChange(numberValue <= numberMin ? min : max)
    }
  }, [numberValue, numberMin, numberMax, onChange, min, max])

  useEffect(() => {
    // Cleanup interval on unmount
    return () => stopLongPress()
  }, [])

  // Keyboard events
  const handleKeyDown =
    (type: 'increase' | 'decrease') =>
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (isButtonDisabled(type)) return
      if (e.key === ' ' || e.key === 'Enter') startLongPress(type)
    }
  const handleKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === ' ' || e.key === 'Enter') stopLongPress()
  }

  useEffect(() => {
    // Cleanup interval on unmount
    return () => stopLongPress()
  }, [])

  return (
    <div className={styles.quantity}>
      <VisuallyHidden>
        <label htmlFor={id}>{label}</label>
      </VisuallyHidden>
      <Button
        theme="secondary"
        loneIcon
        disabled={isButtonDisabled('decrease')}
        className={styles.button}
        onPointerDown={() => startLongPress('decrease')}
        onPointerUp={() => stopLongPress()}
        onKeyDown={handleKeyDown('decrease')}
        onKeyUp={handleKeyUp}
        {...buildActionAnalyticsAttributes('quantity-decrease')}
      >
        -
      </Button>
      <QuantityMoneyInput
        id={id}
        amount={value}
        onChange={(value) => {
          const newValue = value?.toString() || ''
          onChange('input', newValue)
          const analyticsClient = getClient()
          if (!analyticsClient) return
          analyticsClient.trackAction({
            name: 'quantity-input-change',
            type: 'key',
          })
        }}
        required
        className={styles.input}
        disabled={numberValue <= numberMin && numberValue >= numberMax}
      />
      <Button
        theme="secondary"
        loneIcon
        disabled={isButtonDisabled('increase')}
        className={styles.button}
        onPointerDown={() => startLongPress('increase')}
        onPointerUp={() => stopLongPress()}
        onKeyDown={handleKeyDown('increase')}
        onKeyUp={handleKeyUp}
        {...buildActionAnalyticsAttributes('quantity-increase')}
      >
        +
      </Button>
    </div>
  )
}

export default Quantity
