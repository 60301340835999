import cx from 'classnames'
import * as React from 'react'

import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import { pickSpacingProps } from '../spacer/utils'
import styles from './dialog.module.scss'

type Props = {
  children: React.ReactNode
  ariaLive?: 'polite' | 'off'
  className?: string
  is?: keyof React.ElementType
} & React.ComponentPropsWithoutRef<'section'> &
  SpacingProps

export default function DialogInner(props: Props) {
  const { children, className, ariaLive = 'polite', is = 'section' } = props
  const spacingProps = pickSpacingProps(props)

  return (
    <Spacer
      is={is}
      className={cx(styles.inner, className)}
      paddingTop="large"
      paddingBottom="large"
      aria-live={ariaLive}
      paddingLeft="medium"
      paddingRight="medium"
      {...spacingProps}
    >
      {children}
    </Spacer>
  )
}
