import * as React from 'react'
export default function SvgChatForm(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15.279 2.618c.46 1.718 2.007 2.868 3.692 3.202-.556 6.2-3.642 11.98-8.53 15.83-4.889-3.85-7.975-9.63-8.532-15.83 1.685-.335 3.232-1.484 3.692-3.202l4.84-.775 4.838.775Zm1.681-1.07c0 1.138.883 2.102 2.101 2.432.292.079.602.121.925.121.311 0 .612-.04.895-.113a24.494 24.494 0 0 1-.087 1.872c-.61 7.201-4.38 13.893-10.354 18.054C4.467 19.753.696 13.06.087 5.86A24.495 24.495 0 0 1 0 3.988c.283.073.583.113.894.113.323 0 .634-.043.925-.121C3.038 3.65 3.92 2.686 3.92 1.548a2.187 2.187 0 0 0-.057-.495L10.44 0l6.577 1.053a2.192 2.192 0 0 0-.057.495ZM10.484 21.27 7.798 18.63c1.083-.607 2.34-7.105 2.686-10.268.563 4.723 1.603 9.314 2.686 10.268l-2.686 2.642Zm-5.502-6.368-1.083-2.08c2.682 1.812 4.244-1.335 5.204-3.268.233-.47.431-.87.601-1.107-1.213 5.069-3.076 6.628-4.722 6.455Zm10.744.303-2.296 3.076c-.39.216-1.846-3.102-2.643-10.137.15.438.29.897.43 1.363.75 2.484 1.554 5.15 4.509 5.698Zm.26-.346 1.083-2.08c-2.752 1.752-4.1-.952-5.626-4.016l-.18-.36c.902 4.056 2.513 6.542 4.723 6.456Zm-10.7.39c.562.88 1.949 2.946 2.252 3.076.996-.174 2.253-6.629 2.643-10.224-.087.276-.173.569-.263.872-.767 2.598-1.762 5.966-4.633 6.276Zm.086-5.112a.26.26 0 1 1-.52 0 .26.26 0 0 1 .52 0Zm8.317-5.632a.26.26 0 1 1-.52 0 .26.26 0 0 1 .52 0ZM6.845 7.712a.26.26 0 1 0 0-.52.26.26 0 0 0 0 .52Zm8.837.346a.26.26 0 1 1-.52 0 .26.26 0 0 1 .52 0Zm-5.326-.812c.025.143.23.143.256 0l.233-1.321a.13.13 0 0 1 .105-.105l1.32-.233c.144-.025.144-.231 0-.256l-1.32-.233a.13.13 0 0 1-.105-.106l-.233-1.32c-.026-.143-.231-.143-.256 0l-.233 1.32a.13.13 0 0 1-.106.106l-1.32.233c-.143.025-.143.23 0 .256l1.32.233a.13.13 0 0 1 .106.105l.233 1.32Z"
      />
    </svg>
  )
}
