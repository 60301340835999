//General helper functions

import { createHash } from 'crypto'
import { IncomingMessage } from 'http'

import getConfig from 'next/config'

export const extractHashFromUrl = (url: string): string => {
  const decodedUrl = new URL(decodeURIComponent(url))
  return decodedUrl.hash.substring(1)
}

const getClientIpFromRequest = (req?: IncomingMessage): string | null => {
  if (!req) {
    return null
  }

  const xForwardedForHeader = req?.headers['x-forwarded-for']
  const xForwardedForIps = Array.isArray(xForwardedForHeader)
    ? xForwardedForHeader
    : [xForwardedForHeader]

  return (
    xForwardedForIps?.[0] ||
    req.headers['x-real-ip']?.[0] ||
    req.socket.remoteAddress ||
    null
  )
}

// Function to hash a string with a pepper
const hashWithPepper = (inputString: string, pepper: string): string => {
  const combined = inputString + pepper
  // Create a SHA-256 hash of the combined string
  const hash = createHash('sha256').update(combined).digest('hex')
  return hash
}

export const generateHashedFingerprint = async (
  req?: IncomingMessage,
): Promise<string | null> => {
  const ip = getClientIpFromRequest(req)
  const { serverRuntimeConfig } = getConfig()
  const pepper = serverRuntimeConfig?.fingerprintPepper
  if (!ip || !pepper) {
    return null
  }
  return hashWithPepper(ip, pepper)
}

export const fromKebabToSentenceOrTitleCase = ({
  kebabCase,
  titleCase,
}: {
  kebabCase: string
  titleCase?: boolean
}): string => {
  return kebabCase
    .split('-')
    .map((word, index) =>
      index === 0 || titleCase
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word,
    )
    .join(' ')
}

export const fromSentenceToKebabCase = (sentence: string): string => {
  return sentence
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-')
}
