import cx from 'classnames'
import React, { useEffect, useState } from 'react'

import Spacer from '../spacer'
import styles from './avatar.module.scss'

export type AvatarSizes =
  | 'small'
  | 'medium'
  | 'large'
  | 'extra-large'
  | 'extra-large-2'

export type AvatarProps = {
  size?: AvatarSizes
  initials?: string
  src?: string
  name: string
  square?: boolean
  className?: string
}

const getInitials = (name: string) =>
  name
    ?.split(' ')
    .map((part) => part.charAt(0).toUpperCase())
    .slice(0, 2)
    .join('') ?? ''

export default function Avatar(props: AvatarProps) {
  const {
    size = 'medium',
    initials: propInitials,
    src,
    name,
    square,
    className,
  } = props

  const [profileImgHasLoaded, setProfileImgHasLoaded] = useState<boolean>(false)

  useEffect(() => {
    // reset this value every time the props change.
    setProfileImgHasLoaded(false)
  }, [name])

  const initials = propInitials != null ? propInitials : getInitials(name)

  useEffect(() => {
    if (src != null && src !== '') {
      const profileImage = new Image()
      profileImage.src = src
      profileImage.addEventListener('load', () => setProfileImgHasLoaded(true))
    }
  }, [src])

  /**
   * Pick a fallback color based on the name to ensure the same avatar color
   * is always returned
   */
  const getFallbackColor = () => {
    if (!props.name) {
      return 'fallback-0'
    }

    const sum = [...name]
      .map((letter) => letter.charCodeAt(0))
      .reduce((current, previous) => previous + current)
    const colorIndex = sum % 8
    return `fallback-${colorIndex}`
  }

  const fallbackClass = getFallbackColor()

  return (
    <Spacer
      is="figure"
      className={cx(
        {
          [styles.root]: true,
          [styles[`size-${size}`]]: true,
          [styles[fallbackClass]]: true,
          [styles.square]: square === true,
        },

        className,
      )}
    >
      {profileImgHasLoaded === true ? (
        <img
          alt=""
          src={src}
          className={styles.image}
          itemProp="image"
          width="80"
          data-testid="profile-picture"
        />
      ) : (
        <p
          aria-hidden="true"
          className={cx(styles.initials, styles[fallbackClass], styles[size])}
        >
          {initials}
        </p>
      )}
    </Spacer>
  )
}
