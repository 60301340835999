import * as React from 'react'

export default function SvgTask(props: HTMLOrSVGElement): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.465 3A3.998 3.998 0 0 0 12 1c-1.48 0-2.773.804-3.465 2H5a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-3.535ZM12 3a2 2 0 0 0-2 2H8a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2h-2a2 2 0 0 0-2-2Zm.694 14.364c-.278.42-.671.636-1.18.636-.499 0-.854-.196-1.19-.626L8.356 15.05c-.25-.303-.355-.597-.355-.9 0-.685.499-1.184 1.19-1.184.422 0 .72.156 1.007.538l1.27 1.588 2.317-4.457c.288-.46.575-.636 1.046-.636.69 0 1.17.48 1.17 1.174 0 .265-.086.539-.288.862l-3.018 5.328Z"
        fill="currentColor"
      />
    </svg>
  )
}
