import classnames from 'classnames/bind'
import * as React from 'react'

import { BackgroundColorVariables } from '../shared-types'
import styles from './dialog.module.scss'

const cx = classnames.bind(styles)

type Props = React.PropsWithChildren<{
  backgroundColor?: BackgroundColorVariables
  className?: string
}>

export default function DialogFooterActionsBar({
  children,
  backgroundColor,
  className,
}: Props) {
  const classNames = ['footerActionsBar']
  if (backgroundColor != null) {
    classNames.push(backgroundColor)
  }
  if (className != null) {
    classNames.push(className)
  }

  return <div className={cx(classNames)}>{children}</div>
}
