import * as React from 'react'
export default function SvgChevronDoubleRight(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <path
        d="M 10.789 4.707 C 10.254 4.153 10.519 3.228 11.266 3.042 C 11.601 2.959 11.955 3.053 12.203 3.293 L 20.203 11.293 C 20.593 11.683 20.593 12.317 20.203 12.707 L 12.203 20.707 C 11.649 21.242 10.724 20.977 10.538 20.23 C 10.455 19.895 10.549 19.541 10.789 19.293 L 18.082 12 L 10.789 4.707 Z"
        fill="currentColor"
        fillRule="evenodd"
      />

      <path
        d="M 3.787 4.693 C 3.252 4.139 3.517 3.214 4.264 3.028 C 4.599 2.945 4.953 3.039 5.201 3.279 L 13.201 11.279 C 13.591 11.669 13.591 12.303 13.201 12.693 L 5.201 20.693 C 4.647 21.228 3.722 20.963 3.536 20.216 C 3.453 19.881 3.547 19.527 3.787 19.279 L 11.08 11.986 L 3.787 4.693 Z"
        fillRule="evenodd"
        fill="currentColor"
      />
    </svg>
  )
}
