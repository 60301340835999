import { SVGProps } from 'react'

const SvgSterlingEnvelope = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18 2a2 2 0 0 1 1.994 1.85L20 4v4.399l1.25 1a2 2 0 0 1 .75 1.562V20a2 2 0 0 1-1.85 1.994L20 22H4a2 2 0 0 1-1.995-1.85L2 20v-9.039A2 2 0 0 1 2.75 9.4L4 8.399V4a2 2 0 0 1 1.85-1.995L6 2h12Zm0 2H6v9.2l6 4.8 6-4.801V4Zm-8.741 9.317-.031-.03-.067-.072-.058-.086-.053-.11-.027-.084-.019-.106-.003-.115.014-.113.028-.1.043-.1.056-.091.041-.051.029-.032.07-.063c.295-.236.647-.908.709-1.304l.009-.11v-.25h-.25a.75.75 0 0 1-.743-.648L9 9.75a.75.75 0 0 1 .648-.743L9.75 9H10v-.5l.005-.164a2.5 2.5 0 0 1 4.242-1.624.75.75 0 0 1-.963 1.144l-.085-.071a1 1 0 0 0-1.692.599L11.5 8.5V9h1.75a.75.75 0 0 1 .743.648L14 9.75a.75.75 0 0 1-.648.743l-.102.007H11.5v.25c0 .372-.121.82-.316 1.25h3.066a.75.75 0 0 1 .102 1.493l-.102.007-4.555-.002-.094-.013-.1-.028-.1-.043-.091-.056-.051-.041Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgSterlingEnvelope
