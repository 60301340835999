import '../shared-types'

import cx from 'classnames'
import * as React from 'react'

import type { Colors, TextColorVariables } from '../shared-types'
import Spacer from '../spacer'
import { SpacingProps } from '../spacer/spacer'
import styles from './text.module.scss'

export type Props = {
  color?: TextColorVariables | Colors
  bold?: boolean
  is?: keyof JSX.IntrinsicElements
  size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'
  children?: React.ReactNode | string
  align?: 'left' | 'center' | 'right'
  className?: string
  title?: string
} & SpacingProps &
  React.ComponentPropsWithoutRef<keyof JSX.IntrinsicElements>

const Text = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    children,
    color = 'primary',
    bold,
    size = 'medium',
    align,
    is = 'p',
    className,
    ...otherProps
  } = props

  const classNames = cx(
    {
      [styles.text]: true,
      [styles[`color-${String(color)}`]]: true,
      [styles[`size-${String(size)}`]]: true,
      [styles[`align-${String(align)}`]]: Boolean(align),
      [styles.bold]: bold,
    },

    className,
  )

  return (
    <Spacer {...otherProps} ref={ref} is={is} className={classNames}>
      {children}
    </Spacer>
  )
})

export default Text
