import * as React from 'react'
export default function SvgConfirmationTick(): React.ReactElement {
  return (
    <svg width="60" height="48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.247.633a2.158 2.158 0 0 0-3.056.003L20.355 30.491 9.808 19.94a2.158 2.158 0 0 0-3.056-.003L.632 26.06a2.167 2.167 0 0 0 .003 3.062L18.05 46.55c.625.625 1.436 1.45 2.266 1.45.826 0 1.725-.825 2.353-1.45L59.37 9.82c.84-.847.84-2.216-.003-3.063L53.247.633Z"
        fill="#00A4DB"
      />
    </svg>
  )
}
