import * as React from 'react'
import { SVGProps } from 'react'
const SvgInternalMonzoProjects = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1011_3951)">
      <path
        d="M25.8236 0.400391L41.1625 9.56401V21.2449V31.0692V31.762V38.3245H43.4249H49.1344V0.400391H25.8236Z"
        fill="#004E60"
      />
      <path
        d="M41.1625 31.0693V21.2449V9.56401L25.8235 0.400391L24.8889 0.731606L40.2278 9.89522V47.2121L41.1625 46.8809V38.3245V31.762V31.0693Z"
        fill="#BBF6E2"
      />
      <path
        d="M40.2278 9.89506L24.8889 0.731445L25.4387 38.6556L40.2278 47.212V9.89506ZM38.5873 12.0734V43.2683L26.6493 36.1538L26.6482 36.092L26.1897 4.44823L38.5873 12.0728V12.0734Z"
        fill="#68CDB5"
      />
      <path
        d="M38.3674 42.8802V12.1959L26.4151 4.8457L26.867 36.0268L38.3674 42.8802ZM36.1754 25.3722C36.6042 25.3429 36.9962 25.6278 37.2079 26.0678C37.4789 26.3046 37.668 26.6855 37.6977 27.126C37.7494 27.8916 37.2997 28.5464 36.6927 28.5878C36.2639 28.617 35.8719 28.3322 35.6602 27.8922C35.3892 27.6554 35.2001 27.2745 35.1704 26.834C35.1187 26.0683 35.5684 25.4136 36.1754 25.3722Z"
        fill="#68CDB5"
      />
      <path
        d="M26.6487 36.1553L38.5867 43.2698V12.0738L26.1891 4.44922L26.6476 36.093L26.6487 36.1548V36.1553ZM38.3668 12.1974V42.8818L26.8664 36.0284L26.4145 4.84723L38.3668 12.1974Z"
        fill="#016B83"
      />
      <path
        d="M36.3628 28.1458C36.105 28.1634 35.8603 28.0668 35.6602 27.8918C35.8719 28.3318 36.2639 28.6166 36.6927 28.5874C37.2997 28.546 37.7499 27.8918 37.6977 27.1256C37.668 26.6851 37.4784 26.3042 37.2079 26.0674C37.2958 26.2501 37.3525 26.4593 37.3678 26.684C37.4195 27.4497 36.9698 28.1044 36.3628 28.1458Z"
        fill="#112231"
      />
      <path
        d="M35.6603 27.8922C35.8604 28.0672 36.105 28.1638 36.3629 28.1461C36.9699 28.1047 37.4201 27.4505 37.3679 26.6843C37.3525 26.4597 37.2959 26.2504 37.2079 26.0677C36.9962 25.6278 36.6042 25.3429 36.1754 25.3722C35.5685 25.4136 35.1182 26.0677 35.1704 26.8339C35.2001 27.2745 35.3898 27.6554 35.6603 27.8922Z"
        fill="#BBF6E2"
      />
      <path
        d="M57.4376 47.2345V47.245C57.4634 47.2836 57.4904 47.3223 57.5179 47.3604C57.8928 47.3515 58.2573 47.24 58.5756 47.0479C58.6136 47.0833 58.6521 47.1203 58.6906 47.1578C58.7532 47.2185 58.8176 47.2809 58.8841 47.3394C59.3349 47.7363 59.9292 47.9345 60.523 47.9345C61.1102 47.9345 61.6973 47.7402 62.1459 47.3521L61.8892 47.0535C61.1426 47.7004 59.8847 47.6955 59.1425 47.043C59.0831 46.9905 59.0243 46.9342 58.9627 46.874C58.9418 46.8536 58.9204 46.8332 58.8989 46.8128C59.2541 46.5036 59.5152 46.0858 59.6109 45.6165C59.7296 45.0358 59.5114 44.3408 58.9413 44.1829C58.5993 44.088 58.0501 44.2172 57.6278 44.8917C57.2925 45.4277 57.116 46.0631 57.1171 46.6963C57.2105 46.8829 57.3139 47.0634 57.4299 47.2362H57.4365L57.4376 47.2345ZM57.6361 46.9585C57.6548 46.9309 57.6768 46.905 57.7015 46.8807C57.7928 46.7912 57.906 46.7416 58.0165 46.7416C58.0253 46.7416 58.0341 46.7416 58.0429 46.7427C58.1045 46.7471 58.1633 46.7636 58.2205 46.7885C58.0363 46.8785 57.8378 46.9381 57.6361 46.9585ZM57.9615 45.0998C58.154 44.7929 58.4987 44.4678 58.8379 44.5611C59.1056 44.635 59.3305 45.0286 59.2271 45.5354C59.1458 45.9334 58.9099 46.2911 58.5932 46.5495C58.442 46.4429 58.2716 46.3629 58.072 46.3485C58.0539 46.3474 58.0358 46.3463 58.0176 46.3463C57.8406 46.3463 57.6641 46.4087 57.5151 46.5224C57.5432 46.0206 57.696 45.5249 57.9621 45.0998H57.9615Z"
        fill="#016B83"
      />
      <path
        d="M65.6268 52.5191C62.68 52.1564 59.7326 51.7938 56.7858 51.4311C56.181 51.3566 55.5328 51.2627 55.0864 50.8465C54.563 50.3585 54.4668 49.5724 54.1199 48.9453C53.5904 47.9892 52.4892 47.4648 51.4056 47.3522C50.4561 47.2534 49.5044 47.4234 48.5676 47.6254L48.6929 51.1904L52.3606 51.7822L52.5244 50.3353L52.6882 48.8885L53.68 51.0347C53.9813 51.6872 54.585 52.1482 55.292 52.2652L65.7406 54.0002H65.7412C65.8632 53.5023 65.855 52.9801 65.6279 52.5186L65.6268 52.5191Z"
        fill="#99AF73"
      />
      <path
        d="M43.4309 22.1399C42.3665 21.6624 41.5429 21.3715 41.1625 21.2451V31.0695L43.4309 22.1399Z"
        fill="#112231"
      />
      <path
        d="M48.4989 45.6895L49.2603 45.4902L48.4989 45.6895Z"
        fill="#61763D"
      />
      <path
        d="M48.6924 51.19L48.5681 47.625C48.5681 47.625 48.5676 47.625 48.567 47.625L48.6924 51.19Z"
        fill="#61763D"
      />
      <path
        d="M53.5056 30.8377C50.3036 24.5269 42.5935 21.7199 41.1618 21.2451C41.5423 21.3715 42.3659 21.6624 43.4302 22.1399L41.1618 31.0695V31.7623C42.2603 32.4705 45.1945 34.5092 46.5635 36.8879C48.0583 39.4851 48.463 45.6993 48.463 45.6993L48.4987 45.6899L51.4532 44.9171C51.4532 44.9171 55.9895 43.7528 56.0412 43.739H56.0423L56.277 43.6783L57.1078 43.4636C57.1078 43.4636 56.8472 37.4239 53.5056 30.8377Z"
        fill="#FF4F40"
      />
      <path
        d="M64.3506 50.8668C63.3792 50.1464 62.1823 49.8224 61.0294 49.4608C59.8765 49.0992 58.6945 48.6581 57.8632 47.7782C57.7378 47.6457 57.624 47.5061 57.5185 47.3609C57.491 47.3228 57.464 47.2842 57.4377 47.2455C57.4355 47.2422 57.4333 47.2394 57.4311 47.2361C57.3151 47.0633 57.2111 46.8828 57.1182 46.6962C56.6597 45.7793 56.4519 44.7139 56.2782 43.6816C56.1929 43.7037 56.1028 43.7275 56.0077 43.7523L48.5284 46.4887L48.568 47.6253C49.5043 47.4233 50.4554 47.2538 51.4049 47.3521C52.4891 47.4647 53.5897 47.9891 54.1192 48.9452C54.4661 49.5717 54.5628 50.3584 55.0857 50.8464C55.5321 51.2626 56.1803 51.3564 56.7851 51.431C59.7319 51.7936 62.6793 52.1563 65.6261 52.519C65.3826 51.8704 64.9109 51.2825 64.3501 50.8668H64.3506Z"
        fill="#61763D"
      />
      <path
        d="M54.6932 44.0926C53.2187 44.4718 51.5468 44.8946 51.4577 44.9162C51.455 44.9167 51.4533 44.9173 51.4533 44.9173L48.4988 45.6901L48.4625 45.6995L48.5252 46.4563L48.5279 46.4883L56.0072 43.752C55.6246 43.8519 55.1704 43.9695 54.6927 44.0926H54.6932Z"
        fill="#112231"
      />
      <path
        d="M54.6932 44.0926C55.1709 43.9695 55.625 43.852 56.0077 43.752C56.1023 43.7272 56.1924 43.704 56.2782 43.6814C56.7818 43.55 57.1084 43.4633 57.1084 43.4633C57.1084 43.4633 57.1007 43.2872 57.0715 42.9632C57.0369 42.8925 56.9995 42.8241 56.9599 42.7567C56.989 42.7369 57.0176 42.7164 57.0457 42.6955C56.9418 41.6858 56.6751 39.7543 56.0132 37.353C55.5805 37.242 55.117 37.2266 54.6508 37.3259C53.0323 37.672 51.9992 39.2696 52.3439 40.8948C52.4143 41.226 52.5369 41.5329 52.7007 41.8084C51.726 42.4747 51.196 43.6869 51.4538 44.9163C51.4538 44.9163 51.4555 44.9163 51.4582 44.9152C51.5467 44.8936 53.2186 44.4708 54.6937 44.0915L54.6932 44.0926Z"
        fill="#FF945A"
      />
      <path
        d="M53.5056 30.8379C53.3637 30.5586 53.2136 30.2865 53.0553 30.0215C52.2405 30.7143 51.8216 31.8227 52.0597 32.9445C52.3873 34.4918 53.8454 35.5048 55.3814 35.2999C54.9009 33.8895 54.2879 32.3786 53.5061 30.8379H53.5056Z"
        fill="#FF945A"
      />
      <path
        d="M50.1817 29.9334C50.8909 29.2959 51.2263 28.3961 51.1741 27.5106C49.0129 25.1507 46.3151 23.5266 44.2308 22.5137C43.758 23.5653 43.9125 24.8432 44.7278 25.759C44.9527 26.0119 45.2111 26.2161 45.4898 26.374C44.973 27.4399 45.1143 28.7593 45.9506 29.6977C47.054 30.9359 48.948 31.0419 50.1817 29.934V29.9334Z"
        fill="#FF945A"
      />
      <path
        d="M50.8992 36.9015C50.8992 35.2399 49.5577 33.8929 47.9028 33.8929C47.5653 33.8929 47.2415 33.9498 46.9385 34.053C46.4916 32.9556 45.4184 32.1816 44.1643 32.1816C43.5475 32.1816 42.974 32.3688 42.4974 32.6901C43.8306 33.6815 45.5916 35.199 46.5636 36.8882C47.0067 37.6583 47.3542 38.7469 47.6236 39.8967C47.7154 39.905 47.8083 39.91 47.9023 39.91C49.5571 39.91 50.8986 38.5631 50.8986 36.9015H50.8992Z"
        fill="#FF945A"
      />
      <path
        d="M55.2907 52.2654C54.5837 52.1478 53.9806 51.6874 53.6788 51.0349L52.687 48.8887L52.5231 50.3355L52.3593 51.7824L55.2902 52.266L55.2907 52.2654Z"
        fill="#004E60"
      />
      <path
        d="M48.6923 51.1907L48.567 47.6257C48.567 47.6257 48.5675 47.6257 48.5681 47.6257L48.5285 46.4891L48.5257 46.4571L48.463 45.7002C48.463 45.7002 48.3333 43.7052 47.9485 41.5032L43.4248 38.3252H41.1625V45.4093L48.6923 51.1912V51.1907Z"
        fill="#004E60"
      />
    </g>
    <defs>
      <clipPath id="clip0_1011_3951">
        <rect
          width="80"
          height="53.6"
          fill="white"
          transform="translate(0 0.400391)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgInternalMonzoProjects
