import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.005 20.5c5.483 0 9.995-4.512 9.995-9.995C20 5.012 15.488.5 9.995.5 4.502.5 0 5.012 0 10.505 0 15.988 4.512 20.5 10.005 20.5zm-1.048-5.248c-.425 0-.774-.198-1.066-.566l-2.21-2.624c-.226-.264-.31-.51-.31-.793 0-.594.48-1.066 1.075-1.066.34 0 .605.141.86.443l1.632 1.982 3.653-5.795c.255-.406.566-.613.944-.613.585 0 1.113.434 1.113 1.028 0 .236-.094.5-.264.746l-4.389 6.673c-.245.368-.623.585-1.038.585z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCircleCheckmark
