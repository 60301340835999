import * as React from 'react'
import { SVGProps } from 'react'
const SvgFincrime = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55.345 19.0317L52.5356 15.9728L58.4877 14.3955L52.1979 11.6955L58.2377 6.2382L49.6668 9.5414L54.2724 2.2581L45.6413 6.69281L44.8964 1.08L45.4584 1.00444L46.0968 5.81947L55.8656 0.799805L51.0639 8.3929L60.8929 4.60548L53.2367 11.5223L60.2275 14.5233L53.5907 16.2819L55.7616 18.6457L55.345 19.0317Z"
      fill="#68CDB5"
    />
    <path
      d="M45.6413 6.69285L48.2564 5.34915C48.0246 5.24966 47.7915 5.15647 47.556 5.06958L46.0968 5.8195L45.9333 4.58788C45.7384 4.54444 45.5429 4.50603 45.3462 4.47266L45.6407 6.69347L45.6413 6.69285Z"
      fill="#2EAFC0"
    />
    <path
      d="M58.3955 14.4199L52.5355 15.9733L55.3449 19.0322L55.7609 18.6462L53.59 16.2825L58.7206 14.9224C58.6141 14.7536 58.5051 14.5862 58.3955 14.4199Z"
      fill="#2EAFC0"
    />
    <path
      d="M54.4484 9.66211L52.1973 11.6959L58.3354 14.3304C58.1368 14.0326 57.9319 13.7385 57.7226 13.4483L53.2361 11.5228L54.8525 10.0626C54.719 9.92783 54.5843 9.79434 54.4478 9.66274L54.4484 9.66211Z"
      fill="#2EAFC0"
    />
    <path
      d="M51.2581 7.02504L49.6667 9.5418L52.9209 8.28752C52.7537 8.14773 52.5832 8.00984 52.4109 7.87446L51.0639 8.3933L51.7218 7.35247C51.5689 7.24102 51.4141 7.13209 51.2575 7.02441L51.2581 7.02504Z"
      fill="#2EAFC0"
    />
    <path
      d="M24.7377 11.5635L28.4574 16.0051L28.7663 11.5635H24.7377Z"
      fill="#004E60"
    />
    <path
      d="M31.1031 11.5632H30.6752L30.4954 9.91602L29.3651 11.5632H28.7662L28.4573 16.0048L31.1031 19.1639V11.5632Z"
      fill="#112231"
    />
    <path
      d="M24.0891 24.1562L27.5996 28.3492L27.8909 24.1562H24.0891Z"
      fill="#004E60"
    />
    <path
      d="M30.4541 24.156H30.0262L29.8463 22.5088L28.7161 24.156H27.8909L27.5996 28.3489L30.4541 31.7573V24.156Z"
      fill="#112231"
    />
    <path
      d="M23.1406 36.749L26.7187 41.0219L27.0157 36.749H23.1406Z"
      fill="#004E60"
    />
    <path
      d="M29.5056 36.7488H29.0776L28.8978 35.1016L27.7676 36.7488H27.0157L26.7188 41.0216L29.5056 44.3494V36.7488Z"
      fill="#112231"
    />
    <path
      d="M44.5687 11.5632L44.7485 9.91602L45.8787 11.5632H50.5057L44.6363 18.5726L44.1407 19.1639V11.5632H44.5687Z"
      fill="#004E60"
    />
    <path
      d="M43.9195 24.156L44.0993 22.5088L45.2296 24.156H49.8565L43.4916 31.7573V24.156H43.9195Z"
      fill="#004E60"
    />
    <path
      d="M30.5789 8.32715L27.7332 49.2665H28.5664L31.4115 8.32715H30.5789Z"
      fill="#004E60"
    />
    <path
      d="M31.4116 8.32715L28.5665 49.2665H42.4103L45.256 8.32715H31.4116Z"
      fill="#004E60"
    />
    <path
      d="M42.971 36.7488L43.1508 35.1016L44.2811 36.7488H48.9086L42.5431 44.3494V36.7488H42.971Z"
      fill="#004E60"
    />
    <path
      d="M34.4269 32.5796C33.8267 32.8365 33.2572 33.2219 32.7954 33.6948C33.7809 34.57 35.0754 35.1021 36.4938 35.1021C39.0356 35.1021 41.1796 33.3963 41.8582 31.0615C41.0105 31.4022 40.0769 31.5797 39.1672 31.6918C37.5658 31.8889 35.9111 31.9437 34.4269 32.579V32.5796Z"
      fill="#016B83"
    />
    <path
      d="M37.5521 28.5191C38.6536 28.3579 39.8597 27.9883 40.396 27.0086C40.6071 26.6226 40.6842 26.1799 40.6578 25.7385C39.6347 24.59 38.1486 23.8672 36.4939 23.8672C33.4114 23.8672 30.9116 26.3751 30.9047 29.4718C31.6408 29.2029 32.4215 29.0373 33.1984 28.9328C34.6432 28.7383 36.1092 28.7307 37.5515 28.5198L37.5521 28.5191Z"
      fill="#016B83"
    />
    <path
      d="M40.6584 25.7383C40.6848 26.1797 40.6077 26.6223 40.3966 27.0083C39.8602 27.9881 38.6535 28.3577 37.5527 28.5189C36.1098 28.7298 34.6444 28.738 33.1996 28.9319C32.4227 29.0365 31.6421 29.2021 30.9059 29.4709C30.9059 29.4753 30.9059 29.4791 30.9059 29.4835C30.9059 31.1603 31.6377 32.6639 32.7967 33.6934C33.2585 33.2206 33.828 32.8352 34.4282 32.5783C35.9125 31.943 37.5671 31.8882 39.1686 31.6911C40.0783 31.579 41.0118 31.4015 41.8595 31.0608C42.0049 30.5602 42.0844 30.0313 42.0844 29.4841C42.0844 28.0447 41.545 26.7325 40.6591 25.7383H40.6584Z"
      fill="#2EAFC0"
    />
    <path
      d="M35.5979 47.7803C38.6848 47.7803 41.1872 45.2654 41.1872 42.1631C41.1872 39.0608 38.6848 36.5459 35.5979 36.5459C32.5111 36.5459 30.0087 39.0608 30.0087 42.1631C30.0087 45.2654 32.5111 47.7803 35.5979 47.7803Z"
      fill="#016B83"
    />
    <path
      d="M37.921 11.7221C39.3294 11.7221 40.615 12.2467 41.5981 13.1112C40.5737 11.9337 39.0688 11.1895 37.3903 11.1895C34.3034 11.1895 31.801 13.7043 31.801 16.8067C31.801 18.4935 32.5416 20.0054 33.7132 21.0349C32.8536 20.0469 32.3311 18.7548 32.3311 17.3394C32.3311 14.237 34.8334 11.7221 37.9203 11.7221H37.921Z"
      fill="#112231"
    />
    <path
      d="M36.9749 24.3403C38.3834 24.3403 39.669 24.8648 40.652 25.7293C39.6276 24.5519 38.1227 23.8076 36.4443 23.8076C33.3573 23.8076 30.855 26.3225 30.855 29.4248C30.855 31.1117 31.5955 32.6235 32.7671 33.653C31.9076 32.6651 31.385 31.373 31.385 29.9575C31.385 26.8552 33.8874 24.3403 36.9743 24.3403H36.9749Z"
      fill="#112231"
    />
    <path
      d="M36.1279 37.0786C37.5363 37.0786 38.822 37.6031 39.805 38.4676C38.7806 37.2902 37.2757 36.5459 35.5972 36.5459C32.5103 36.5459 30.0079 39.0608 30.0079 42.1631C30.0079 43.85 30.7485 45.3618 31.9201 46.3913C31.0605 45.4034 30.538 44.1113 30.538 42.6958C30.538 39.5935 33.0403 37.0786 36.1272 37.0786H36.1279Z"
      fill="#112231"
    />
    <path
      d="M38.0725 15.625C39.1696 15.4644 40.3713 15.096 40.9057 14.1201C41.1306 13.7089 41.2033 13.2335 41.1607 12.7644C40.2466 12.1089 39.1295 11.7217 37.9209 11.7217C35.1949 11.7217 32.9262 13.6831 32.4325 16.2785C32.8623 16.174 33.2996 16.0959 33.7357 16.0374C35.1754 15.8434 36.6353 15.8359 38.0725 15.6256V15.625Z"
      fill="#FF945A"
    />
    <path
      d="M34.9593 19.6697C34.403 19.9077 33.8742 20.2566 33.4325 20.6829C33.5221 20.8037 33.6154 20.9215 33.7138 21.0342C34.6968 21.8981 35.9825 22.4232 37.3909 22.4232C40.095 22.4232 42.3505 20.4927 42.868 17.9287C41.8988 18.4224 40.7729 18.6516 39.6815 18.7857C38.0857 18.9821 36.438 19.0363 34.9587 19.6697H34.9593Z"
      fill="#FF945A"
    />
    <path
      d="M41.598 13.11C41.4583 12.9872 41.3117 12.872 41.1607 12.7637C41.204 13.2328 41.1306 13.7088 40.9057 14.1193C40.3713 15.0953 39.1696 15.4637 38.0726 15.6242C36.6353 15.8345 35.1749 15.8421 33.7357 16.036C33.2997 16.0946 32.8623 16.1727 32.4325 16.2772C32.3674 16.621 32.3317 16.9749 32.3317 17.3382C32.3317 18.5918 32.7414 19.7479 33.4325 20.6823C33.8742 20.256 34.4036 19.9072 34.9593 19.6692C36.4379 19.0357 38.0863 18.9816 39.6821 18.7851C40.7735 18.651 41.8994 18.4218 42.8686 17.9282C42.9419 17.5655 42.9808 17.1902 42.9808 16.8055C42.9808 15.39 42.4589 14.0979 41.5987 13.11H41.598Z"
      fill="#FF4F40"
    />
    <path
      d="M39.8051 38.4684C39.6654 38.3456 39.5187 38.2304 39.3678 38.1221C39.411 38.5912 39.3377 39.0672 39.1128 39.4777C38.5783 40.4537 37.3766 40.8221 36.2796 40.9826C34.8423 41.1929 33.3819 41.2005 31.9427 41.3944C31.5067 41.453 31.0694 41.5311 30.6396 41.6356C30.5744 41.9794 30.5387 42.3333 30.5387 42.6966C30.5387 43.9502 30.9484 45.1063 31.6395 46.0407C32.0812 45.6144 32.6106 45.2656 33.1664 45.0276C34.645 44.3941 36.2934 44.34 37.8891 44.1435C38.9806 44.0094 40.1064 43.7802 41.0757 43.2866C41.149 42.9239 41.1878 42.5486 41.1878 42.1639C41.1878 40.7484 40.6659 39.4563 39.8057 38.4684H39.8051Z"
      fill="#2EAFC0"
    />
    <path
      d="M44.4978 8.32715L41.6527 49.2665H42.4854L45.3311 8.32715H44.4978Z"
      fill="#016B83"
    />
    <path
      d="M21.2623 53.8848L21.1489 54.3998L21.4659 54.1083C21.4722 54.0756 21.4791 54.0428 21.4885 54.0107C21.4083 53.9798 21.3319 53.937 21.2623 53.8848Z"
      fill="#FF4F40"
    />
    <path
      d="M28.208 47.26C27.9499 47.1939 27.7212 47.0195 27.5947 46.7607C27.4857 46.5378 27.4712 46.2929 27.5364 46.0725C27.3986 46.019 27.2733 45.9327 27.1718 45.8187L26.3247 45.8742L24.6537 41.1611L24.0535 42.8291C24.0485 42.8543 24.0423 42.8795 24.0347 42.904C24.2484 42.9865 24.4326 43.1471 24.5422 43.37C24.7728 43.8403 24.5798 44.4089 24.1124 44.64C23.8906 44.7496 23.6469 44.7641 23.4276 44.6986C23.3957 44.7823 23.3512 44.8617 23.2961 44.9334L22.8957 46.0454L18.6666 47.4924L19.9585 48.1995C20.0224 48.2052 20.0851 48.2165 20.1459 48.2348C20.2279 48.02 20.3877 47.8349 20.6095 47.7247C21.0775 47.493 21.6433 47.687 21.8732 48.1567C21.9822 48.3796 21.9966 48.6245 21.9315 48.8449C22.1451 48.9274 22.3293 49.088 22.439 49.3109C22.6695 49.7812 22.4766 50.3498 22.0092 50.5809C22.0023 50.5847 21.9948 50.5872 21.9879 50.5903L21.6339 52.1979C21.7899 52.1645 21.9484 52.1714 22.0969 52.2155C22.179 52.0008 22.3387 51.8156 22.5605 51.7055C22.9828 51.4964 23.4846 51.6349 23.7478 52.0096L24.947 50.907C25.0102 50.642 25.1857 50.4065 25.4482 50.2768C25.67 50.1672 25.9137 50.1527 26.133 50.2182C26.2151 50.0035 26.3748 49.8184 26.5966 49.7082C27.0646 49.4765 27.6304 49.6704 27.8603 50.1401C27.9693 50.363 27.9837 50.608 27.9186 50.8283C28.1322 50.9108 28.3164 51.0714 28.4261 51.2943C28.5226 51.4914 28.5439 51.7055 28.5025 51.9051L30.2574 52.5675L27.1373 48.0666L28.2087 47.2582L28.208 47.26ZM26.0453 48.5849C25.8235 48.6944 25.5798 48.7089 25.3605 48.6434C25.2784 48.8581 25.1186 49.0432 24.8968 49.1534C24.4288 49.3852 23.8631 49.1912 23.6331 48.7215C23.5241 48.4986 23.5097 48.2536 23.5749 48.0333C23.3612 47.9508 23.177 47.7902 23.0674 47.5673C22.8368 47.097 23.0298 46.5284 23.4972 46.2973C23.719 46.1877 23.9627 46.1732 24.182 46.2387C24.264 46.024 24.4238 45.8389 24.6456 45.7287C25.1136 45.497 25.6794 45.6909 25.9093 46.1607C26.0183 46.3836 26.0327 46.6285 25.9676 46.8489C26.1812 46.9314 26.3654 47.0919 26.4751 47.3148C26.7056 47.7852 26.5127 48.3538 26.0453 48.5849Z"
      fill="#FF4F40"
    />
    <path
      d="M23.4283 44.6986C23.6476 44.7641 23.8913 44.7496 24.1131 44.64C24.5811 44.4083 24.7735 43.8397 24.5429 43.37C24.4339 43.1471 24.2491 42.9865 24.0354 42.904C24.0429 42.8795 24.0486 42.8543 24.0542 42.8291L23.2968 44.9341C23.3513 44.8617 23.3957 44.7829 23.4283 44.6992V44.6986Z"
      fill="#FF945A"
    />
    <path
      d="M22.4389 49.3111C22.3299 49.0882 22.1451 48.9276 21.9314 48.8451C21.9966 48.6247 21.9822 48.3798 21.8732 48.1569C21.6426 47.6865 21.0769 47.4932 20.6095 47.7249C20.3877 47.8345 20.2279 48.0202 20.1458 48.235C20.0851 48.2167 20.0218 48.2054 19.9585 48.1997L22.2391 49.4477L21.9872 50.5905C21.9941 50.5874 22.0016 50.5849 22.0085 50.5811C22.4765 50.3494 22.6689 49.7808 22.4383 49.3111H22.4389Z"
      fill="#FF945A"
    />
    <path
      d="M25.9677 46.848C26.0328 46.6276 26.0184 46.3827 25.9094 46.1598C25.6788 45.6895 25.1131 45.4961 24.6457 45.7279C24.4239 45.8374 24.2641 46.0232 24.1821 46.2379C23.9628 46.1724 23.7191 46.1869 23.4973 46.2964C23.0292 46.5282 22.8369 47.0967 23.0675 47.5665C23.1765 47.7894 23.3613 47.9499 23.575 48.0324C23.5098 48.2528 23.5242 48.4977 23.6332 48.7206C23.8638 49.191 24.4295 49.3843 24.8969 49.1526C25.1187 49.043 25.2785 48.8573 25.3606 48.6426C25.5798 48.7081 25.8236 48.6936 26.0454 48.584C26.5134 48.3523 26.7057 47.7837 26.4752 47.314C26.3661 47.0911 26.1813 46.9305 25.9677 46.848Z"
      fill="#FF945A"
    />
    <path
      d="M27.5364 46.0718C27.4712 46.2921 27.4856 46.5371 27.5947 46.76C27.7212 47.0188 27.9505 47.1932 28.208 47.2593L30.3971 45.6064L27.1718 45.8174C27.2733 45.9314 27.3986 46.0176 27.5364 46.0711V46.0718Z"
      fill="#FF945A"
    />
    <path
      d="M22.5605 51.7064C22.3387 51.816 22.1789 52.0018 22.0969 52.2165C21.949 52.1724 21.7899 52.1655 21.6339 52.1988L21.2623 53.8857C21.3319 53.938 21.4083 53.9802 21.4885 54.0116C21.4791 54.0438 21.4716 54.0765 21.466 54.1092L23.7471 52.0112C23.484 51.6359 22.9822 51.498 22.5599 51.7071L22.5605 51.7064Z"
      fill="#FF945A"
    />
    <path
      d="M28.4254 51.2954C28.3164 51.0725 28.1316 50.912 27.9179 50.8295C27.9831 50.6091 27.9687 50.3642 27.8597 50.1413C27.6291 49.6709 27.0633 49.4776 26.5959 49.7093C26.3741 49.8189 26.2144 50.0046 26.1323 50.2193C25.913 50.1538 25.6693 50.1683 25.4475 50.2779C25.1856 50.4076 25.0096 50.6431 24.9463 50.9082L25.2113 50.6645L28.5018 51.9068C28.5432 51.7072 28.5219 51.4931 28.4254 51.2961V51.2954Z"
      fill="#FF945A"
    />
    <path
      d="M46.5711 37.6486C47.0887 37.6486 47.5084 37.2269 47.5084 36.7066C47.5084 36.1864 47.0887 35.7646 46.5711 35.7646C46.0534 35.7646 45.6338 36.1864 45.6338 36.7066C45.6338 37.2269 46.0534 37.6486 46.5711 37.6486Z"
      fill="#112231"
    />
    <path
      d="M46.8015 37.4875C47.3192 37.4875 47.7388 37.0657 47.7388 36.5455C47.7388 36.0253 47.3192 35.6035 46.8015 35.6035C46.2839 35.6035 45.8643 36.0253 45.8643 36.5455C45.8643 37.0657 46.2839 37.4875 46.8015 37.4875Z"
      fill="#FFA9DF"
    />
    <g filter="url(#filter0_d_823_1810)">
      <path
        d="M49.8565 30.1973C49.3704 32.3671 49.1674 32.5705 47.1675 33.0817C47.1775 33.0836 47.1869 33.0855 47.1963 33.0874C47.5447 33.1573 47.841 33.2209 48.0947 33.2889C49.3666 33.6277 49.5696 34.0709 49.8572 35.9669C50.2669 33.6887 50.4781 33.491 52.5462 33.0824C50.4956 32.5554 50.2976 32.3476 49.8572 30.1979L49.8565 30.1973Z"
        fill="white"
      />
    </g>
    <path
      d="M23.1407 18.8334C23.9227 18.8334 24.5566 18.1963 24.5566 17.4103C24.5566 16.6244 23.9227 15.9873 23.1407 15.9873C22.3587 15.9873 21.7247 16.6244 21.7247 17.4103C21.7247 18.1963 22.3587 18.8334 23.1407 18.8334Z"
      fill="#68CDB5"
    />
    <defs>
      <filter
        id="filter0_d_823_1810"
        x="47.1675"
        y="30.1973"
        width="5.37878"
        height="6.76953"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_823_1810"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_823_1810"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgFincrime
