import * as React from 'react'
import { SVGProps } from 'react'
const SvgDocumentCsv = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2v6a2 2 0 0 0 1.85 1.995L14 10h6v10a2 2 0 0 1-1.85 1.994L18 22H6a2 2 0 0 1-1.995-1.85L4 20V4a2 2 0 0 1 1.85-1.995L6 2h6Zm-2 15H7a1 1 0 0 0-.117 1.993L7 19h3a1 1 0 0 0 .117-1.993L10 17Zm7 0h-3a1 1 0 0 0-.117 1.993L14 19h3a1 1 0 0 0 .117-1.993L17 17Zm0-4h-3a1 1 0 1 0 0 2h3a1 1 0 1 0 0-2Zm-7 0H7a1 1 0 0 0-.117 1.993L7 15h3a1 1 0 0 0 .117-1.993L10 13Zm4-11 6 6h-6V2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDocumentCsv
