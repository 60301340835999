import cx from 'classnames'
import { format as formatDate, parse as parseDate, startOfDay } from 'date-fns'
import * as React from 'react'

import { mergeRefs } from '@mondough/utils'

import Icon from '../icon'
import styles from './date-input.module.scss'
import Datepicker from './datepicker'

export const KeyCodes = Object.freeze({
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
})

// These aren't exact, so we can pass in (potentially) any of the Formik or native input props
export type Props = {
  id: string
  name: string
  label?: string
  required?: boolean
  className?: string
  placeholder?: string
  value: string
  setValue: (arg0: string) => void
  max?: string | number
  min?: string | number
  invalid?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

// function to see whether client supports input type="date"
// source: https://gomakethings.com/how-to-check-if-a-browser-supports-native-input-date-pickers/
function isDateSupported() {
  const input = document.createElement('input')
  const value = 'a'
  input.setAttribute('type', 'date')
  input.setAttribute('value', value)
  return input.value !== value
}

export const ISO_DATE_FORMAT = 'yyyy-MM-dd'
export const YYYY_DATE_FORMAT = 'dd/MM/yyyy'
export const YY_DATE_FORMAT = 'dd/MM/yy'

function isYYYYDateFormat(dateString: string) {
  return /^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)
}

function isYYDateFormat(dateString: string) {
  return /^\d{1,2}\/\d{1,2}\/\d{2}$/.test(dateString)
}

/* Uses the native datepicker where supported.
Otherwise, uses custom datepicker for browsers like Safari that don't support input type=date. */
const DateInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      className: classNameProp,
      required = false,
      placeholder: placeholderProp,
      onChange,
      onBlur,
      value,
      setValue,
      max,
      min,
      invalid,
      ...restProps
    },
    ref,
  ) => {
    const usingCustomPicker = !isDateSupported()
    // for custom picker, we show the value in input as dd/mm/yyyy but actual `value` prop is yyyy-mm-dd
    // so this is for the visual representation of the value in the input field itself
    const [inputFieldValue, setInputFieldValue] = React.useState(
      value != null && value !== '' && usingCustomPicker
        ? formatDate(new Date(value), YYYY_DATE_FORMAT)
        : '',
    )

    // we also store the value as a date, which we pass in to the datepicker object, so we don't have to
    // keep converting it every time we want to do some kind of calculation
    const [valueAsDate, setValueAsDate] = React.useState(
      value != null && value !== '' && usingCustomPicker
        ? startOfDay(new Date(value))
        : null,
    )

    // these refs are used to manage focus and set event listeners
    const inputRef = React.useRef<HTMLInputElement>(null)
    const buttonRef = React.useRef<HTMLButtonElement>(null)

    const [showPicker, setShowPicker] = React.useState(false)

    // flow-pleasing magic
    const className: string = classNameProp != null ? classNameProp : ''

    function toggleDatepicker() {
      // if showPicker is true, that means we are now closing it
      // so we must return the focus to the calendar button
      if (buttonRef.current != null && showPicker) {
        buttonRef.current.focus()
      }
      setShowPicker(!showPicker)
    }

    // this hook updates the state values for the custom picker
    React.useEffect(() => {
      if (!usingCustomPicker) {
        return
      }

      if (value == null || value === '') {
        setInputFieldValue(value)
        return
      }

      const newValueAsDate = new Date(value)
      setInputFieldValue(formatDate(newValueAsDate, YYYY_DATE_FORMAT))
      setValueAsDate(startOfDay(newValueAsDate))
    }, [value, usingCustomPicker])

    // this hook adds an event listener to toggle the datepicker when pressing enter
    React.useEffect(() => {
      if (usingCustomPicker && inputRef.current != null) {
        inputRef.current.addEventListener('keydown', (e: KeyboardEvent) => {
          if (e.keyCode === KeyCodes.ENTER) {
            e.preventDefault()
            e.stopPropagation()
            toggleDatepicker()
          }
          if (e.keyCode === KeyCodes.SPACE) {
            setShowPicker(true)
          }
        })
      }
      // we don't need it to re-render if the toggle function changes for any reason
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputRef, usingCustomPicker])

    // this is only called if we're using the custom picker
    function onCustomInputChange(e: React.ChangeEvent<HTMLInputElement>) {
      const newValue = e.target.value
      setInputFieldValue(newValue)
    }

    // this is only called if we're using the custom picker
    // we only set the value on blur, because we don't want to try and set the value to an
    // invalid date while the user is still typing
    function onCustomInputBlur(e: React.ChangeEvent<HTMLInputElement>) {
      const newValue = e.target.value
      if (isYYDateFormat(newValue)) {
        setValue(
          formatDate(
            parseDate(newValue, YY_DATE_FORMAT, new Date()),
            ISO_DATE_FORMAT,
          ),
        )
      } else if (isYYYYDateFormat(newValue)) {
        setValue(
          formatDate(
            parseDate(newValue, YYYY_DATE_FORMAT, new Date()),
            ISO_DATE_FORMAT,
          ),
        )
      }
    }

    const placeholder =
      placeholderProp !== '' && placeholderProp != null
        ? placeholderProp
        : 'dd/mm/yyyy'

    return (
      <div className={styles['datepicker-container']}>
        <div
          className={cx(styles['input-wrapper'], {
            [styles.extended]: usingCustomPicker,
            [className]: usingCustomPicker && className !== '',
          })}
        >
          <input
            {...restProps}
            value={usingCustomPicker ? inputFieldValue : value}
            ref={mergeRefs([inputRef, ref])}
            onChange={usingCustomPicker ? onCustomInputChange : onChange}
            onBlur={usingCustomPicker ? onCustomInputBlur : onBlur}
            className={cx(styles['date-input'], {
              [styles.invalid]: invalid,
              [styles['non-native']]: usingCustomPicker,
              [className]: !usingCustomPicker && className !== '',
            })}
            type="date"
            id={id}
            aria-autocomplete="none"
            placeholder={placeholder}
            max={max}
            min={min}
            required={required}
            // @ts-expect-error
            invalid={invalid === true ? 1 : 0}
          />

          {usingCustomPicker && (
            <button
              type="button"
              ref={buttonRef}
              onClick={() => setShowPicker(!showPicker)}
              aria-label={`${value} - choose date`}
              className={styles['calendar-button']}
            >
              <Icon icon="calendar" color="grey500" size={24}></Icon>
            </button>
          )}
        </div>
        {showPicker && (
          <Datepicker
            selectedDate={valueAsDate}
            setSelectedDate={setValue}
            hidePicker={toggleDatepicker}
            max={max}
            min={min}
          />
        )}
      </div>
    )
  },
)

export default DateInput
