import * as React from 'react'

export default function SvgCard(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 20 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 5v9a2 2 0 01-2 2H2a2 2 0 01-2-2V5h20zM5 9H3a1 1 0 00-.993.883L2 10v1a1 1 0 00.883.993L3 12h2a1 1 0 00.993-.883L6 11v-1a1 1 0 00-.883-.993L5 9zm13-9a2 2 0 012 2v1H0V2a2 2 0 012-2h16z"
        fill="currentColor"
      />
    </svg>
  )
}
