import { isPast } from 'date-fns'

import { HTTPError } from '@mondough/error-handling'
import { getEnv } from '@mondough/next-utils'

import {
  clearAuth,
  fetchClientTokenFromAPI,
  getAccessToken,
  logoutAndRedirectClientErrorCodes,
  refreshUserAccessToken,
  retryableClientErrorCodes,
  syncSession,
} from './'

const normaliseContentTypeHeader = (ct: string | null | undefined): string =>
  ct == null ? 'text/plain' : ct.trim().toLowerCase().replace(/;.+$/, '')

// A default cache expiration time of 1 week, which fits most static asset use cases.
// Could be made dynamic if there are more varied use cases in the future.
const CACHE_EXPIRATION_MS = 3600 * 1000 * 24 * 7

type Options = Partial<{
  method: string
  authorizationType: 'none' | 'user' | 'client'
  useCache: boolean
  body: BodyInit | null
  retryOnExpiredToken: boolean
}>

const apiFetch = async <ResponseData>(
  input: string,
  options: Options = {},
): Promise<ResponseData> => {
  const {
    authorizationType = 'user',
    useCache = false,
    retryOnExpiredToken = true,
    ...fetchOptions
  } = options
  const prependedInput = `${getEnv('apiPath')}${input}`
  if (authorizationType === 'client' && getAccessToken('client') == null) {
    await fetchClientTokenFromAPI()
  }
  const authedOptions = {
    ...fetchOptions,
    ...(authorizationType === 'none'
      ? undefined
      : {
          headers: {
            authorization: `Bearer ${getAccessToken(authorizationType)}`,
          },
        }),
  }

  let response: Response | undefined
  const cacheName = 'api-fetch-cache'
  const timeStoreCacheName = 'api-fetch-time-store'
  const cache = await caches.open(cacheName)

  if (useCache) {
    const cachedTime = await getCachedTime(prependedInput, timeStoreCacheName)
    if (!isPast(cachedTime + CACHE_EXPIRATION_MS))
      response = await cache.match(prependedInput)
  }

  if (!response) {
    response = await fetch(prependedInput, authedOptions)
    await cache.put(prependedInput, response.clone())
    await setCachedTime(prependedInput, timeStoreCacheName)
  }

  const contentType = normaliseContentTypeHeader(
    response.headers.get('Content-Type'),
  )

  let json
  switch (contentType) {
    case 'application/json':
      json = await response.json()
      break
    case 'text/plain':
      json = { body: await response.text() }
      break
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
      json = await response.blob()
      break
    default:
      throw new HTTPError(response, `Unexpected content type: ${contentType}`)
  }

  if (response.ok === true) return json

  const error = new HTTPError(response, json)
  //@ts-ignore - code is not always present in the response
  const code = error.body?.code

  // If the error is because of an expired access token, refresh the token and retry the request
  if (
    Object.values(retryableClientErrorCodes).includes(code) &&
    retryOnExpiredToken
  ) {
    try {
      if (authorizationType === 'client') {
        await fetchClientTokenFromAPI()
      } else {
        await refreshUserAccessToken()
        await syncSession()
      }
    } catch (e) {
      //Swallow the error as this might be caused by concurrent requests trying to refresh the token
    }

    return apiFetch(input, options)
  }

  if (logoutAndRedirectClientErrorCodes.includes(code)) {
    await clearAuth(true)
  }

  throw error
}

export default apiFetch

// Function to set cache time
async function setCachedTime(url: string, cacheName: string) {
  const timeStore = await caches.open(cacheName)
  const timeResponse = new Response(Date.now().toString())
  await timeStore.put(url, timeResponse)
}

// Function to get cache time
async function getCachedTime(url: string, cacheName: string) {
  const timeStore = await caches.open(cacheName)
  const timeResponse = await timeStore.match(url)
  if (timeResponse) {
    const timeText = await timeResponse.text()
    return parseInt(timeText, 10)
  }
  return 0
}
