import '../shared-types'

import cx from 'classnames'
import * as React from 'react'

import type { IconSizes, Icons } from '../icon/icon'
import Icon from '../icon/icon'
import type { Colors, Sizes } from '../shared-types'
import type { SpacingProps } from '../spacer/spacer'
import Spacer from '../spacer/spacer'
import styles from './square-icon.module.scss'

export type Props = SpacingProps & {
  icon: Icons
  iconColor: Colors
  backgroundColor: Colors
  iconSize: IconSizes
  size: Sizes
  className?: string | null | undefined
}

export default function SquareIcon({
  icon,
  iconColor,
  backgroundColor,
  size,
  iconSize,
  className,
  ...restProps
}: Props) {
  return (
    <Spacer
      className={cx(
        styles.square,
        {
          [styles[`color-${String(backgroundColor)}`]]: true,
          [styles[`size-${String(size)}`]]: true,
        },

        className,
      )}
      {...restProps}
    >
      <Icon size={iconSize} color={iconColor} icon={icon} />
    </Spacer>
  )
}
