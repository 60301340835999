import apiFetch from './apiFetch'
import { captureSharedTabsException } from './errorHandling'

export type FeedbackFormKey =
  | 'easeOfUseSelect'
  | 'magicWandText'
  | 'npsSelect'
  | 'whyNotMonzoText'

export const feedbackFormQuestionKeys: FeedbackFormKey[] = [
  'easeOfUseSelect',
  'magicWandText',
  'npsSelect',
  'whyNotMonzoText',
]

export type FeedbackFormValues = {
  easeOfUseSelect: string
  magicWandText: string
  npsSelect: string
  whyNotMonzoText: string
}

export const feedbackQuestionTypes: Record<FeedbackFormKey, 'radio' | 'text'> =
  {
    easeOfUseSelect: 'radio',
    magicWandText: 'text',
    npsSelect: 'radio',
    whyNotMonzoText: 'text',
  }

export const feedbackQuestionOptions: Partial<
  Record<FeedbackFormKey, string[]>
> = {
  easeOfUseSelect: [
    'Extremely easy',
    'Easy',
    'Neither easy or difficult',
    'Difficult',
    'Extremely difficult',
  ],
  npsSelect: [
    '1: Extremely unlikely',
    ...Array.from({ length: 8 }, (_, i) => String(i + 2)),
    '10: Extremely likely',
  ],
}

export const feedbackQuestions: Record<FeedbackFormKey, string> = {
  easeOfUseSelect: 'How easy or difficult are Shared Tabs to use?',
  magicWandText: 'Which improvements would you make to Shared Tabs?',
  npsSelect:
    'How likely are you to recommend Shared Tabs to a friend or family member?',
  whyNotMonzoText: 'What is stopping you from becoming a full Monzo user?',
}

const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const sendTabsFeedback = async (feedback: FeedbackFormValues) => {
  const answers = Object.entries(feedback).map(([key, value]) => ({
    question_id: camelToSnakeCase(key),
    question_text: feedbackQuestions[key as FeedbackFormKey],
    answer: value,
  }))

  try {
    await apiFetch('tabs/feedback', {
      method: 'POST',
      body: JSON.stringify({
        answers,
      }),
    })
  } catch (error) {
    captureSharedTabsException(error as Error, {
      feedback,
    })
    throw error
  }
}
