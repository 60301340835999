import * as React from 'react'
import { SVGProps } from 'react'
const SvgThumbsDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 0.666504H13V11.4998L7.16667 17.3332L6.125 16.2915C6.02778 16.1943 5.94792 16.0623 5.88542 15.8957C5.82292 15.729 5.79167 15.5693 5.79167 15.4165V15.1248L6.70833 11.4998H2.16667C1.72222 11.4998 1.33333 11.3332 1 10.9998C0.666667 10.6665 0.5 10.2776 0.5 9.83317V8.1665C0.5 8.06928 0.510417 7.96511 0.53125 7.854C0.552083 7.74289 0.583333 7.63873 0.625 7.5415L3.125 1.6665C3.25 1.38873 3.45833 1.15261 3.75 0.958171C4.04167 0.763726 4.34722 0.666504 4.66667 0.666504ZM14.6667 11.4998V0.666504H18V11.4998H14.6667Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgThumbsDown
