import * as React from 'react'
export default function SvgRealtimeOveview(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 2.42857C22 3.76983 20.9056 4.85714 19.5556 4.85714C19.3327 4.85714 19.1167 4.8275 18.9116 4.77198L16.3715 9.18816C16.8277 9.62935 17.1111 10.2461 17.1111 10.9286C17.1111 12.2698 16.0167 13.3571 14.6667 13.3571C13.3166 13.3571 12.2222 12.2698 12.2222 10.9286C12.2222 10.8722 12.2242 10.8163 12.228 10.7609L9.38573 9.81968C8.95009 10.4871 8.19361 10.9286 7.33333 10.9286C6.97638 10.9286 6.6373 10.8526 6.3316 10.7159L4.40028 13.1144C4.70709 13.5203 4.88889 14.0248 4.88889 14.5714C4.88889 15.9127 3.79447 17 2.44444 17C1.09442 17 0 15.9127 0 14.5714C0 13.2302 1.09442 12.1429 2.44444 12.1429C2.80139 12.1429 3.14047 12.2189 3.44618 12.3555L5.3775 9.95701C5.07069 9.55114 4.88889 9.04666 4.88889 8.5C4.88889 7.15874 5.9833 6.07143 7.33333 6.07143C8.68336 6.07143 9.77778 7.15874 9.77778 8.5C9.77778 8.55635 9.77585 8.61225 9.77205 8.66764L12.6143 9.6089C13.0499 8.94151 13.8064 8.5 14.6667 8.5C14.8896 8.5 15.1055 8.52964 15.3107 8.58516L17.8507 4.16898C17.3945 3.72779 17.1111 3.11106 17.1111 2.42857C17.1111 1.08731 18.2055 0 19.5556 0C20.9056 0 22 1.08731 22 2.42857Z"
        fill="currentColor"
      />
    </svg>
  )
}
