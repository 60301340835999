import * as React from 'react'
import { SVGProps } from 'react'
const SvgHibob = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 7.93495C10.8899 7.93495 9.40037 8.41775 8.17764 9.23066V3.48991C8.17739 3.29401 8.13609 3.10009 8.05609 2.9192C7.97609 2.73831 7.85896 2.57399 7.71139 2.43564C7.56382 2.29729 7.3887 2.18761 7.19602 2.11286C7.00334 2.03812 6.79689 1.99977 6.58844 2C6.38004 1.99981 6.17365 2.0382 5.98104 2.11297C5.78843 2.18775 5.61337 2.29744 5.46587 2.43579C5.31837 2.57414 5.20131 2.73844 5.12138 2.91931C5.04144 3.10017 5.0002 3.29406 5 3.48991V14.9678L5.00076 14.9739C5.00381 18.8485 8.36678 22 12.5 22C16.6359 22 20 18.8449 20 14.9675C20 11.0893 16.6359 7.93495 12.5 7.93495ZM12.5 19.0205C10.1166 19.0205 8.17764 17.2023 8.17764 14.9675C8.17764 12.7326 10.1166 10.9144 12.5 10.9144C14.8838 10.9144 16.8224 12.7326 16.8224 14.9675C16.8224 17.2023 14.8838 19.0205 12.5 19.0205Z" />
  </svg>
)
export default SvgHibob
