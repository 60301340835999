import * as React from 'react'
import { SVGProps } from 'react'
const SvgShield = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.506 14.563c-.202.0955-.4039.147-.5437.147-.1398 0-.3417-.0441-.5359-.147C2.038 12.2926.5 11.2566.5 8.6188V3.1595c0-.867.3573-1.1903 1.1262-1.4842C2.473 1.3446 5.3157.3747 6.1468.1249 6.403.0514 6.6983 0 6.9623 0s.5593.0514.8156.125c.831.2644 3.6739 1.2123 4.5205 1.5503.7767.3012 1.1263.6172 1.1263 1.4842v5.4593c0 2.6378-1.4681 3.7767-5.9187 5.9442zm-2.0583-4.0339c.2253.2498.4427.3674.7302.3674.2795 0 .5204-.1249.6912-.3746l3.837-5.6504c.101-.1543.1942-.3307.1942-.4997 0-.3747-.3572-.6172-.7223-.6172-.233 0-.4505.125-.6136.3674L6.1546 9.2874l-1.771-2.1161c-.1864-.2204-.3806-.3086-.6136-.3086-.3806 0-.6913.2865-.6913.6613 0 .1764.07.3526.202.507l2.167 2.4981z"
      fill="currentColor"
    />
  </svg>
)
export default SvgShield
