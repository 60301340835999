import cx from 'classnames'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import { filterSpacingProps } from '../spacer/utils'
import styles from './radio-button.module.scss'

export type TProps = {
  label?: React.ReactNode
  invalid?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & React.InputHTMLAttributes<HTMLInputElement> &
  SpacingProps

const RadioButton = React.forwardRef<HTMLInputElement, TProps>((props, ref) => {
  const {
    label,
    checked,
    disabled,
    invalid,
    onChange,
    // If no id is passed, a unique ID will be generated to be able to link the
    // label and checkbox via the 'for' prop. Note that this will be
    // regenerated at every render.
    id = uuid(),
    ...otherProps
  } = props

  const { spacingProps, restProps } = filterSpacingProps(otherProps)

  return (
    <Spacer
      is="label"
      htmlFor={id}
      marginTop="small"
      className={styles['radio-wrapper']}
      {...spacingProps}
    >
      <input
        type="radio"
        checked={checked}
        className={styles.hiddenRadio}
        disabled={disabled}
        onChange={onChange}
        id={id}
        ref={ref}
        {...restProps}
      />

      <div className={cx(styles.radio, { [styles.invalid]: invalid })} />
      {label != null && <span className={styles.label}>{label}</span>}
    </Spacer>
  )
})

export default RadioButton
