import cx from 'classnames'
import * as React from 'react'

import Icon from '../icon'
import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import { filterSpacingProps } from '../spacer/utils'
import styles from './select.module.scss'

export type Props = {
  children: React.ReactNode
  className?: string
  id?: string
  name?: string
  disabled?: boolean
  title?: string
  required?: boolean
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string
  invalid?: boolean
} & SpacingProps &
  React.ComponentPropsWithoutRef<'select'>

const Select = React.forwardRef<HTMLSelectElement, Props>((props, ref) => {
  const {
    children,
    className,
    id,
    name,
    disabled = false,
    title,
    required = false,
    invalid = false,
    ...otherProps
  } = props

  const _classNames = cx(
    {
      [styles.root]: true,
      [styles.invalid]: invalid,
    },

    className,
  )

  const { spacingProps, restProps } = filterSpacingProps(otherProps)

  const isControlled = restProps.value != null && restProps.onChange != null

  return (
    <Spacer {...spacingProps} is="div" className={styles.container}>
      <select
        disabled={disabled}
        id={id}
        name={name}
        className={_classNames}
        required={required}
        defaultValue={isControlled ? undefined : ''} // should only be set on an uncontrolled component
        ref={ref}
        {...restProps}
      >
        {title != null && (
          <option disabled value="">
            {title}
          </option>
        )}

        {children}
      </select>
      {restProps.multiple || ( // when in multi-select mode, hide the dropdown icon since the input is always "open"
        <Icon
          icon="chevron-down"
          size={18}
          color={disabled ? 'grey400' : 'grey700'}
          className={styles.chevron}
        />
      )}
    </Spacer>
  )
})

export default Select
