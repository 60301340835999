import Link from 'next/link'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'

import appStore from '../../public/images/download-app-store.svg'
import googlePlay from '../../public/images/download-google-play.svg'
import { getCDNSrc } from '../../utils'
import { AppStoreButtonProps } from './AppStoreButton.props'

const AppStoreButton = ({ href, store }: AppStoreButtonProps) => {
  return (
    <Link href={href} target="_blank">
      <a {...buildActionAnalyticsAttributes(`footer-${store}-tap`)}>
        <img
          src={getCDNSrc(store === 'app-store' ? appStore : googlePlay)}
          alt={
            store === 'app-store'
              ? 'Download on the Apple App Store'
              : 'Get it on Google Play'
          }
          height={40}
        />
      </a>
    </Link>
  )
}

export default AppStoreButton
