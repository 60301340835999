import * as React from 'react'
import { SVGProps } from 'react'
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.59 6.735H10v-5c0-.55-.45-1-1-1H5c-.55 0-1 .45-1 1v5H2.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71zM0 16.735c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDownload
