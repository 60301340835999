import * as React from 'react'
import { SVGProps } from 'react'
const SvgHouse = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 20 19"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00431 9.9849C0.725385 9.9849 0.459035 9.8692 0.269042 9.66556C-0.0797214 9.29174 -0.0870327 8.72289 0.233299 8.34115L0.320191 8.24989L8.63183 0.536672C9.36005 -0.13914 10.4658 -0.176683 11.236 0.424044L11.3682 0.536672L19.6798 8.24989C19.884 8.43941 20 8.70506 20 8.98322C20 9.49688 19.6123 9.92029 19.1128 9.97819L18.9957 9.9849H18.0344V16.9966C18.0344 18.1031 17.1351 19 16.0258 19H12.5107V14.4924C12.5107 13.1094 11.3866 11.9883 9.99999 11.9883C8.66879 11.9883 7.57962 13.0215 7.4946 14.3278L7.48927 14.4924V19H3.97422C2.8649 19 1.96562 18.1031 1.96562 16.9966V9.9849H1.00431Z"
    />
  </svg>
)
export default SvgHouse
