import React from 'react'

export type Props = {
  isWrapped: boolean
  wrapper: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}

const ConditionalWrapper = ({ isWrapped, wrapper, children }: Props) =>
  isWrapped ? <>{wrapper(children)}</> : <>{children}</>

export default ConditionalWrapper
