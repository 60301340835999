import * as React from 'react'
import { SVGProps } from 'react'
const SvgSavings = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M20 3c.326 0 .647.02.963.057.298.053.576.253.671.547A8 8 0 0 1 14 14l-.135-.003-.033.058a4.954 4.954 0 0 0-.824 2.482l-.008.266V21a1 1 0 1 1-2 0v-2a3 3 0 0 0-1.2-2.4l.11.09a6 6 0 0 1-7.899-5.324c-.01-.37.234-.706.547-.848a6.002 6.002 0 0 1 8.347 4.41l.095.072c.068.051.135.104.2.158a6.901 6.901 0 0 1 .968-2.213 1.02 1.02 0 0 1 .053-.072A8 8 0 0 1 20 3Z"
    />
  </svg>
)
export default SvgSavings
