import * as React from 'react'

import type { SVGProps } from './types'

export default function SvgArrowRight(
  props: SVGProps,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 18 16">
      <path
        d="M10.7.3a1 1 0 00-1.4 1.4L14.58 7H1a1 1 0 00-1 .88V8a1 1 0 001 1h13.59l-5.3 5.3a1 1 0 00-.08 1.31l.08.1a1 1 0 001.42 0l7-7 .07-.08A1 1 0 0018 8v.09a1 1 0 000-.07V8v-.02a1 1 0 00-.3-.69l.09.1a1 1 0 00-.08-.1l-7-7z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
