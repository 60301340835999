//Heavily inspired by app.treasury/app/components/trade/TradeView/TradeDetails/fields/MoneyField.js

import cx from 'classnames'
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react'

import { TextInput } from '@mondough/monzo-ui'

import { truncateToTwoDecimalsStr } from '../../../utils'
import styles from './QuantityMoneyInput.module.scss'
import { QuantityMoneyInputProps } from './QuantityMoneyInput.types'

function QuantityMoneyInput({
  amount,
  onChange,
  required,
  currencySymbol,
  symbolClassName,
  placeholder = '0.00',
  large,
  ...props
}: QuantityMoneyInputProps) {
  const textInput = useRef<HTMLInputElement>(null)
  const [selectionStart, setSelectionStart] = useState(0)
  const [isInvalid, setIsInvalid] = useState(false)

  useEffect(() => {
    if (textInput == null || textInput.current == null) return
    textInput.current.setSelectionRange(selectionStart, selectionStart, 'none')
  }, [selectionStart])

  const setCursorPosition = (i: number) => {
    setSelectionStart(i)
    if (textInput == null || textInput.current == null) return
    textInput.current.setSelectionRange(i, i, 'none')
  }

  const validateInput = (amount: string) => {
    if (amount === '') return true
    if (amount.includes('-')) return false // Disallow negative numbers like "-1.23"
    return !isNaN(Number(amount))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Only perform these "helpers" if the current value is a valid amount.
    // E.g. if the current value is "12.34.56" (i.e. invalid because of the
    // two "." chars), we don't want the backspace helper to run because it
    // would become impossible to delete the second ".".
    if (isInvalid) return

    const start = e.currentTarget.selectionStart || 0

    // If the user currently has some text selected, return
    // so that the input can perform its default behaviour.
    if (start !== e.currentTarget.selectionEnd) return

    let char

    switch (e.key) {
      case 'Backspace':
        // Look at the character that is about to be deleted
        char = e.currentTarget.value.charAt(start - 1)
        if (char === '.' || char === ',') {
          // Don't actually delete these chars, just move the cursor.
          e.preventDefault()
          setCursorPosition(start - 1)
        }
        break
      case 'Delete':
        // Look at the character that is about to be deleted
        char = e.currentTarget.value.charAt(start)
        if (char === '.' || char === ',') {
          // Don't actually delete these chars, just move the cursor.
          e.preventDefault()
          setCursorPosition(start + 1)
        }
        break
      case ',':
        // Disallow commas, but if the next char is already
        // a comma, move the cursor one place forward.
        e.preventDefault()
        if (e.currentTarget.value.charAt(start) === ',') {
          setCursorPosition(start + 1)
        }
        break
      case '.': // Decimal point
        // Disallow decimal points, but if the next char is already
        // a decimal point, move the cursor one place forward.
        e.preventDefault()
        if (e.currentTarget.value.charAt(start) === '.') {
          setCursorPosition(start + 1)
        }
        break
      default: // nothing
    }

    // If the cursor is currently at the beginning of |0.00
    if (e.key >= '0' && e.key <= '9') {
      if (truncateToTwoDecimalsStr(amount) === '0.00' && start === 0) {
        // Treat this the same as typing in an empty input
        e.currentTarget.value = ''
      }
    }
  }

  const handleChange = (
    { currentTarget }: React.SyntheticEvent<HTMLInputElement>,
    blur = false,
  ) => {
    const newValue = currentTarget.value.replace(/,/g, '')
    let pos = currentTarget.selectionStart || 0

    // Some Android browsers don't recognise onKeyDown event, or send the correct key for backspace and period keys.
    // This is a workaround for that.

    if (amount && Number(currentTarget.value) === Number(amount) * 100) {
      // Deleting a period
      onChange?.(truncateToTwoDecimalsStr(amount))
      !blur && setCursorPosition(pos)
      return
    }
    if (currentTarget.value.split('.').length > 2) {
      // Adding a period
      onChange?.(truncateToTwoDecimalsStr(amount))
      !blur && setCursorPosition(pos)
      return
    }
    // The empty string can't be parsed as Money but
    // is a valid case, so handle and return early.
    if (newValue === '') {
      !blur && setCursorPosition(0)
      setIsInvalid(false)
      onChange?.('')
      return
    }

    if (!validateInput(newValue)) {
      setIsInvalid(true)
      // Set cursor position and input value so that it still behaves like a text field
      !blur && setCursorPosition(0)
      onChange?.(truncateToTwoDecimalsStr(amount))

      return
    }

    setIsInvalid(false)

    const oldPreCursor = currentTarget.value.substring(0, pos)
    const newPreCursor = newValue.substring(0, pos)

    // Realign the cursor if a leading zero has been removed
    // e.g. 0.00 --> 01.00 --(formatted)-> 1.00
    const oldLeadingZeros: string[] = oldPreCursor.match(/^0*/) ?? []
    const newLeadingZeros: string[] = newPreCursor.match(/^0*/) ?? []
    const oldLeadingZerosLen = (oldLeadingZeros[0] ?? '').length
    const newLeadingZerosLen = (newLeadingZeros[0] ?? '').length
    pos += newLeadingZerosLen - oldLeadingZerosLen

    !blur && setCursorPosition(pos)
    const truncatedValue = truncateToTwoDecimalsStr(newValue)
    onChange?.(truncatedValue === '0.00' && pos === 0 ? '' : truncatedValue)
  }

  const handleBlur = (e: React.SyntheticEvent<HTMLInputElement>) => {
    //We want to change the value, but not set the cursor position
    handleChange(e, true)
  }

  return (
    <div className={styles.wrapper}>
      {currencySymbol && (
        <span
          aria-hidden
          className={cx(styles.symbol, large && styles.large, symbolClassName)}
        >
          {currencySymbol}
        </span>
      )}
      <TextInput
        {...props}
        className={cx(
          styles.input,
          currencySymbol && styles['has-symbol'],
          props.className,
          large && styles.large,
        )}
        ref={textInput}
        inputMode="decimal"
        type="text"
        placeholder={placeholder}
        value={amount === '' ? amount : truncateToTwoDecimalsStr(amount)}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
      />
    </div>
  )
}

export default QuantityMoneyInput
