import * as React from 'react'

import { AnalyticsClientType, getClient } from './client'

export const AnalyticsContext: React.Context<{
  client: AnalyticsClientType | null | undefined
}> = React.createContext({
  client: getClient(),
})

export function AnalyticsProvider({
  client,
  children,
}: {
  client: AnalyticsClientType | null | undefined
  children: React.ReactNode
}) {
  return (
    <AnalyticsContext.Provider value={{ client }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
