import * as React from 'react'
export default function SvgWorkforceAttributes(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 13C20.3281 13 23.8535 15.5778 23.9955 18.7979L24 19C24 20.1046 23.1046 21 22 21L17.4641 21.0013C17.7623 20.4862 17.949 19.8985 17.991 19.2712L18 19L17.9951 18.7539L17.978 18.4851C17.7952 16.2929 16.573 14.405 14.7398 13.0736C15.1499 13.0253 15.571 13 16 13ZM8 13C12.3281 13 15.8535 15.5778 15.9955 18.7979L16 19C16 20.1046 15.1046 21 14 21H2C0.89543 21 0 20.1046 0 19C0 15.6863 3.58172 13 8 13ZM16 3C18.2091 3 20 4.79086 20 7C20 9.20914 18.2091 11 16 11C14.9348 11 13.9669 10.5836 13.25 9.90478C13.7283 9.04419 14 8.05382 14 7C14 5.94644 13.7285 4.95631 13.2515 4.09577C13.9669 3.41636 14.9348 3 16 3ZM8 3C10.2091 3 12 4.79086 12 7C12 9.20914 10.2091 11 8 11C5.79086 11 4 9.20914 4 7C4 4.79086 5.79086 3 8 3Z"
        fill="currentColor"
      />
    </svg>
  )
}
