import * as React from 'react'
import { SVGProps } from 'react'
const SvgMoneyCheque = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      fillRule="evenodd"
      stroke="#00000000"
      d="M7.049 6 5.155 7.895a2.1 2.1 0 0 0-.494.781l-.054.18-.521 2.02a2.5 2.5 0 0 0 2.894 3.079l.16-.037 2.013-.527a2.1 2.1 0 0 0 .815-.421l.137-.125L16.949 6H21a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4.049ZM20 16H4a1 1 0 0 0 0 2h16a1 1 0 1 0 0-2Zm0-4h-4a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2Zm-6.768-9.354 2.122 2.122L8.69 11.43a.1.1 0 0 1-.046.026l-2.011.528a.5.5 0 0 1-.612-.609l.521-2.02a.1.1 0 0 1 .027-.046l6.662-6.663ZM15.39.49a1.5 1.5 0 0 1 2.121 2.122l-.742.742-2.122-2.121Z"
    />
  </svg>
)
export default SvgMoneyCheque
