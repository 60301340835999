import React, { useContext } from 'react'

import { ReferralContext } from '../../providers'
import lifestyleReferral from '../../public/images/bill-split.png'
import lifestyleGeneric from '../../public/images/monzo-campaign.webp'
import { NUMBER_OF_CUSTOMERS_MILLIONS } from '../../utils'
import styles from './Layout.module.scss'
import {
  Footer,
  Header,
  MultipleBlockBanner,
  PageWrapper,
  SingleBlockBanner,
} from '..'

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { referralData, maxSavingsRate } = useContext(ReferralContext)

  const displayReferral = referralData !== null

  return (
    <PageWrapper className={styles.app}>
      <Header />
      <main className={styles.main} id="main">
        <div className={styles['desktop-row-container']}>
          <section className={styles['left-side-content']}>
            <div className={styles.content}>{children}</div>
          </section>
          <aside className={styles['right-side-content']}>
            <SingleBlockBanner
              title={
                displayReferral
                  ? referralData?.title
                  : 'Make your money more Monzo'
              }
              text={
                displayReferral
                  ? referralData?.description
                  : `More than ${NUMBER_OF_CUSTOMERS_MILLIONS} million customers use our hot coral cards to spend, save and manage their money. ${
                      maxSavingsRate
                        ? `Earn up to ${maxSavingsRate}% AER on a 6-month fixed term savings account.`
                        : ''
                    }\nUK residents only. Ts&Cs apply.`
              }
              imageSrc={displayReferral ? lifestyleReferral : lifestyleGeneric}
              buttonText={
                displayReferral ? 'Claim referral' : 'Get a free bank account'
              }
              buttonHref={
                displayReferral
                  ? referralData?.link
                  : 'https://monzo.com/sign-up/?utm_source=tabsmonzome&utm_medium=website'
              }
            />
          </aside>
        </div>
        <MultipleBlockBanner />
      </main>
      <Footer
        links={[
          {
            id: 'privacy-notice',
            href: 'https://monzo.com/legal/privacy-notice/',
            children: 'Privacy notice',
            analyticsName: 'footer-privacy-notice',
          },
          {
            id: 'cookies',
            href: '/cookies',
            children: 'Cookie notice',
            analyticsName: 'footer-cookie-notice',
          },
          {
            id: 't-and-c',
            href: 'https://monzo.com/legal/terms-and-conditions/',
            children: 'Terms & Conditions',
            analyticsName: 'terms-and-conditions',
          },
        ]}
      />
    </PageWrapper>
  )
}

export default Layout
