import * as React from 'react'
import { SVGProps } from 'react'
const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.707 19.293a1 1 0 01-1.414 1.414l-8-8a1 1 0 010-1.414l8-8a1 1 0 011.414 1.414L9.414 12l7.293 7.293z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgChevronLeft
