import * as React from 'react'
import { SVGProps } from 'react'
const SvgPhoto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 0C0.89543 0 0 0.89543 0 2V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.89543 17.1046 0 16 0H2ZM16 15.9999L11.7997 10.4052C11.3997 9.8723 10.6003 9.8723 10.2003 10.4052L7.0005 14.6673L5.8 13.0666C5.4 12.5333 4.6 12.5333 4.2 13.0666L2 15.9999H16Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPhoto
