import { addSeconds, isPast } from 'date-fns'
import router from 'next/router'

import { getClient as getAnalyticsClient } from '@mondough/analytics-v2'
import { captureException } from '@mondough/error-handling'
import { getEnv } from '@mondough/next-utils'

import apiFetch from './apiFetch'
import {
  AUTH_STATE_SEPARATOR,
  LOGIN_STATE_PREFIX,
  NULL_STATE_CODE,
  SIGNUP_STATE_PREFIX,
  monzoTokenNameSpace,
  pagesWithNoAuth,
} from './constants'
import { captureSharedTabsException } from './errorHandling'

export type AuthValue =
  | 'userCredentials'
  | 'refreshToken'
  | 'clientCredentials'
  | 'isSessionUpgraded'
  | 'fallbackMatch'
  | 'hasConfirmedIdentity'
  | 'authState'
  | 'userId'

const authValues: AuthValue[] = [
  'userCredentials',
  'refreshToken',
  'clientCredentials',
  'isSessionUpgraded',
  'fallbackMatch',
  'hasConfirmedIdentity',
  'authState',
  'userId',
]

export type Credentials = {
  accessToken: string
  expiry: string
}

export type SessionSyncResponse = {
  status: 'upgraded' | 'not_upgraded'
  reason?: 'full_monzo_user' | 'unknown_error'
}

export const setAuthValue = (token: string, type: AuthValue): void => {
  if (typeof window === 'undefined') return
  localStorage.setItem(`${monzoTokenNameSpace}::${type}`, token)
}

export const getAuthValue = (type: AuthValue): string | null => {
  if (typeof window === 'undefined') return null
  return localStorage.getItem(`${monzoTokenNameSpace}::${type}`) || null
}

export const clearAuthValue = (type: AuthValue): void => {
  if (typeof window === 'undefined') return
  localStorage.removeItem(`${monzoTokenNameSpace}::${type}`)
}

export const getAccessToken = (type: 'client' | 'user'): string | null => {
  const credentials = getAuthValue(
    type === 'user' ? 'userCredentials' : 'clientCredentials',
  )

  return credentials ? JSON.parse(credentials).accessToken : null
}

export const getTokenExpiry = (type: 'client' | 'user'): string | null => {
  const credentials = getAuthValue(
    type === 'user' ? 'userCredentials' : 'clientCredentials',
  )
  return credentials ? JSON.parse(credentials).expiry : null
}

export const isAccessTokenValid = (type: 'client' | 'user'): boolean => {
  const expiry = getTokenExpiry(type)
  return expiry ? !isPast(new Date(expiry)) : false
}

export async function codeVerifierToChallengeSHA256(
  codeVerifier: string,
): Promise<string> {
  const encoder = new TextEncoder()
  const data = encoder.encode(codeVerifier)
  const hash = await crypto.subtle.digest('SHA-256', data)
  return base64EncodeURL(hash)
}

// base64EncodeURL encodes the given byte array as a base64 encoded URL,
// as described in chapter 5 of https://www.ietf.org/rfc/rfc4648.txt
// We use btoa(...) to convert into base64 and then replace URL un-safe
// characters with their base64url mappings.
function base64EncodeURL(byteArray: ArrayBuffer) {
  return btoa(
    Array.from(new Uint8Array(byteArray))
      .map((val) => {
        return String.fromCharCode(val)
      })
      .join(''),
  )
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '')
}

export const fetchClientTokenFromAPI = async (): Promise<void> => {
  const bodyData = new URLSearchParams({
    grant_type: 'client_credentials',
    client_id: getEnv('clientId'),
    client_secret: getEnv('clientSecret'),
  })

  try {
    const { access_token, expires_in } = await apiFetch<{
      access_token: string
      expires_in: number
    }>('oauth2/token', {
      body: bodyData,
      method: 'POST',
      authorizationType: 'none',
      retryOnExpiredToken: false,
    })

    setAuthValue(
      JSON.stringify({
        accessToken: access_token,
        expiry: addSeconds(new Date(), expires_in - 60).toISOString(), // Lets remove a minute for safety so we don't ever think it's valid when it's actually expired
      }),
      'clientCredentials',
    )
  } catch (error) {
    captureSharedTabsException(error)
    clearAuthValue('clientCredentials')
    throw error
  }
}

export const generateAuthState = (
  inviteId: string | null,
  fingerprint: string | null,
): string => {
  const randomValues = new Uint32Array(2)
  crypto.getRandomValues(randomValues)

  // Combine two 32-bit integers to form a 64-bit unsigned integer
  const combined =
    (BigInt(randomValues[0] || 0) << 32n) | BigInt(randomValues[1] || 0)

  // Convert to a string if needed (base-36 or other encoding)
  const randomStateParam = combined.toString(36)
  const optionalInviteId = inviteId ?? NULL_STATE_CODE
  const optionalFingerprint = fingerprint ?? NULL_STATE_CODE
  const intent = inviteId ? SIGNUP_STATE_PREFIX : LOGIN_STATE_PREFIX
  return [intent, optionalInviteId, optionalFingerprint, randomStateParam].join(
    AUTH_STATE_SEPARATOR,
  )
}

export const parseAuthState = (
  state?: string,
): {
  intent: 'login' | 'signup'
  inviteId: string | null
  fingerprint: string | null
} => {
  if (!state) {
    return {
      intent: 'login',
      inviteId: null,
      fingerprint: null,
    }
  }
  const [intent, optionalInviteId, fingerprint, _] =
    state?.split(AUTH_STATE_SEPARATOR)
  return {
    intent: intent as 'login' | 'signup',
    inviteId:
      optionalInviteId === NULL_STATE_CODE
        ? null
        : (optionalInviteId as string),
    fingerprint:
      fingerprint === NULL_STATE_CODE ? null : (fingerprint as string),
  }
}

export const clearAllAuthValues = () => {
  authValues.forEach((value) => clearAuthValue(value))
}

export const clearAllUserAuthValues = () => {
  authValues.forEach(
    (value) => value !== 'clientCredentials' && clearAuthValue(value),
  )
}

export const redirectToAuthWebsite = async (
  inviteId: string | null,
  intent: 'login' | 'signup',
  fingerprint: string | null,
) => {
  await clearAllUserAuthValues()
  const authBaseUrl = getEnv('authPath')
  const clientId = getEnv('clientId')
  const redirectUri = `${window.location.origin}/oauth2/callback`
  const state = generateAuthState(inviteId, fingerprint)

  const urlParams = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    state,
    intent,
    new_design: 'true',
  })
  const authUrl = `${authBaseUrl}?${urlParams.toString()}`

  setAuthValue(state, 'authState')
  window.location.href = authUrl
}

export const clearAuth = async (redirect?: boolean): Promise<void> => {
  clearAllUserAuthValues()

  // Downgrade the analytics library so that we use the client token instead of the user's token.
  // Otherwise the analytics requests will return a 401 because the user's access token is no longer valid.
  const analyticsClient = getAnalyticsClient()
  if (analyticsClient != null) {
    const clientToken = getAccessToken('client') as string

    analyticsClient.updateAccessToken(clientToken)
    analyticsClient.clearUserId()
  }
  if (redirect) {
    // Redirect to the home page if the user is not on a page that doesn't require authentication
    if (pagesWithNoAuth.includes(router.pathname)) await router.push('/')
    //Reloading the page re-runs AuthProvider, so users are redirected to the login page
    // This is a bit hacky, but works for now
    window.location.reload()
  }
}

export const userLogout = async (redirect?: boolean) => {
  try {
    const rsp = await apiFetch<{ ok: boolean }>('oauth2/logout', {
      method: 'POST',
      retryOnExpiredToken: false,
    })

    if (rsp.ok !== true) {
      captureException(
        new Error(`Unexpected response from attempted log out: 'ok !== true'`),
      )
    }
  } catch (err) {
    captureSharedTabsException(err)
  }
  await clearAuth(redirect)
}

export async function exchangeAuthCodeForAccessToken(
  authCode: string,
): Promise<{
  access_token: string | null | undefined
  user_id: string | null | undefined
}> {
  const payload = new URLSearchParams({
    grant_type: 'authorization_code',
    client_id: getEnv('clientId'),
    client_secret: getEnv('clientSecret'),
    redirect_uri: `${window.location.origin}/oauth2/callback`,
    code: authCode,
  })

  try {
    const res = await apiFetch<{
      access_token: string
      user_id: string
      refresh_token: string
      expires_in: number
    }>('oauth2/token', {
      method: 'POST',
      body: payload,
      authorizationType: 'client',
    })
    const { access_token, user_id, refresh_token, expires_in } = res

    const accessTokenExpiry = addSeconds(
      new Date(),
      expires_in - 60,
    ).toISOString()

    setAuthValue(
      JSON.stringify({
        accessToken: access_token,
        expiry: accessTokenExpiry,
      }),
      'userCredentials',
    )
    setAuthValue(refresh_token, 'refreshToken')
    setAuthValue(user_id, 'userId')
    clearAuthValue('authState')

    const analyticsClient = getAnalyticsClient()
    if (analyticsClient != null) {
      analyticsClient.updateAccessToken(access_token)
      analyticsClient.updateUserId(user_id)
    }

    return { access_token, user_id }
  } catch (error) {
    captureSharedTabsException(error)
    throw error
  }
}

export const refreshUserAccessToken = async (): Promise<void> => {
  try {
    const refreshToken = getAuthValue('refreshToken')
    if (!refreshToken) {
      await clearAuth()
      throw new Error('No refresh token found')
    }

    if (isAccessTokenValid('user')) {
      return // No need to refresh the token
    }

    const payload = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: getEnv('clientId'),
      client_secret: getEnv('clientSecret'),
      refresh_token: refreshToken,
    })

    const res = await apiFetch<{
      access_token: string
      refresh_token: string
      expires_in: number
    }>('oauth2/token', {
      method: 'POST',
      body: payload,
      authorizationType: 'none',
      retryOnExpiredToken: false, // Avoids an infinite loop if this calls ever fails with an EXPIRED_ACCESS_TOKEN code
    })

    const { access_token, refresh_token, expires_in } = res
    const newExpiry = addSeconds(new Date(), expires_in - 60).toISOString()

    const analyticsClient = getAnalyticsClient()
    if (analyticsClient != null) {
      analyticsClient.updateAccessToken(access_token)
    }

    setAuthValue(
      JSON.stringify({ accessToken: access_token, expiry: newExpiry }),
      'userCredentials',
    )
    setAuthValue(refresh_token, 'refreshToken')
  } catch (error) {
    captureSharedTabsException(error)
    throw error
  }
}

export const syncSession = async (): Promise<SessionSyncResponse> => {
  try {
    const { status, reason } = await apiFetch<SessionSyncResponse>(
      'tabs/web-session-sync',
      {
        method: 'PUT',
        retryOnExpiredToken: false,
      },
    )
    setAuthValue(status === 'upgraded' ? 'true' : 'false', 'isSessionUpgraded')
    return { status, reason }
  } catch (error) {
    captureSharedTabsException(error)
    throw error
  }
}
