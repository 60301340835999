import * as React from 'react'
import { SVGProps } from 'react'
const SvgThumbsUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 17.3332H5V6.49984L10.8333 0.666504L11.875 1.70817C11.9722 1.80539 12.0521 1.93734 12.1146 2.104C12.1771 2.27067 12.2083 2.43039 12.2083 2.58317V2.87484L11.2917 6.49984H15.8333C16.2778 6.49984 16.6667 6.6665 17 6.99984C17.3333 7.33317 17.5 7.72206 17.5 8.1665V9.83317C17.5 9.93039 17.4896 10.0346 17.4688 10.1457C17.4479 10.2568 17.4167 10.3609 17.375 10.4582L14.875 16.3332C14.75 16.6109 14.5417 16.8471 14.25 17.0415C13.9583 17.2359 13.6528 17.3332 13.3333 17.3332ZM3.33333 6.49984V17.3332H0V6.49984H3.33333Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgThumbsUp
