import { FeedItemsResponse } from '../components/Activity/Activity.types'
import apiFetch from './apiFetch'
import { getAccessToken, getAuthValue, isAccessTokenValid } from './auth'
import { captureSharedTabsException } from './errorHandling'

export type TabInvite = {
  inviteId: string
  expired: boolean
  tabName: string
  tabCreatorName: string
  tabCreatorSrc: Promise<string>
  tabId: string
  referral?: TabCreatorReferral
}

export type GetTabResponse = {
  tab: Tab
}

export type ReadTabInvitePreviewResponse = {
  tab_id: string
  tab_name: string
  expired: boolean
  creator_name: string
  creator_user_id: string
  referral?: TabCreatorReferral
}

export type AcceptInviteResponse = {
  tab_id: string
  signup_required: boolean
}

export type Tab = {
  created_by: string
  currency: string
  id: string
  item_count: number
  left_participants: TabParticipant[]
  modified_at: string
  name: string
  opened_at: string
  participants: TabParticipant[]
  reminder_sent_recently: boolean
  status: string
  total: number
  closed_at?: string
  closed_by?: string
}

export type TabParticipant = {
  first_name: string
  id: string
  name: string
  payment_status: string
  settle_amount: number
  settle_currency: string
  status: string
  tab_id: string
  total_amount: number
  total_currency: string
  user_id: string
  invited_at: string
  invited_by: string
  type: 'monzo' | 'guest'
}

export type TabCreatorReferral = {
  title: string
  description: string
  link: string
  tabId?: string | undefined
}

export type TabItem = {
  // Simplified for now
  name: string
  id: string
}

export type UserProfile = {
  name: string
  email: string
}

type TabCreatorReferralResponse = {
  referral: TabCreatorReferral
}

type UserIdentification =
  | { userId: string; participantId?: never }
  | { userId?: never; participantId: string }

export type UserNotificationSettings = {
  notifications_enabled: boolean
  using_default: boolean // Whether or not we are returning the default. If not, a user has set this
  feature_enabled: boolean // Whether or not the feature is enabled for the user
}

export const getTab = async (tabId: string) => {
  try {
    const { tab } = await apiFetch<GetTabResponse>(`tabs/${tabId}`, {
      method: 'GET',
    })
    return tab
  } catch (error) {
    captureSharedTabsException(error as Error, {
      tab_id: tabId,
    })
    return null
  }
}

export const getTabFromArray = (tabs: Tab[] | null, tabId: string | null) => {
  if (!tabs) {
    return null
  }
  return tabs.find((tab) => tab.id === tabId) ?? null
}

export const getTabParticipantsByTabId = async (tabId: string) => {
  const tab = await getTab(tabId)
  return tab?.participants ?? null
}

export const getTabParticipant = ({
  userId,
  participantId,
  tab,
}: UserIdentification & { tab: Tab }) => {
  const tabParticipants = tab.participants
  return (
    tabParticipants?.find((person) =>
      userId ? person.user_id === userId : person.id === participantId,
    ) ?? null
  )
}

export const fetchTabParticipant = async ({
  userId,
  participantId,
  tabId,
}: UserIdentification & { tabId: string }) => {
  const tabParticipants = await getTabParticipantsByTabId(tabId)
  return (
    tabParticipants?.find((person) =>
      userId ? person.user_id === userId : person.id === participantId,
    ) ?? null
  )
}

export const getOwedAmount = (tab: Tab): number => {
  const userId = getAuthValue('userId')
  const user =
    tab.participants?.find((person) => person.user_id === userId) ?? null
  return user?.settle_amount || 0
}

export const getFeedItems = async (tabId: string, from?: string) => {
  try {
    const { feed_items, next_page } = await apiFetch<FeedItemsResponse>(
      'tabs/web-feed?' +
        new URLSearchParams({
          id: tabId,
          ascending: 'false',
          sync_reason: 'visible_items_refresh',
          ...(from ? { from } : {}),
        }),
      {
        method: 'GET',
      },
    )
    return {
      feed_items,
      next_page,
    }
  } catch (error) {
    captureSharedTabsException(error as Error, {
      tab_id: tabId,
    })
    return null
  }
}

export const isMonzoUser = (participant: TabParticipant): boolean => {
  return participant.type === 'monzo'
}

export const getUserProfileSrc = async (
  userId: string,
  dimension = 120,
): Promise<string> => {
  // We're making authenticated requests here to avoid reaching the unauthenticated rate limit
  // Which is 100 requests per day
  if (!userId) {
    return ''
  }

  try {
    const blob = await apiFetch<Blob>(
      `user-images/profile_picture/${userId}?w=${dimension}&h=${dimension}`,
      {
        method: 'GET',
        useCache: true,
        authorizationType: isAccessTokenValid('user') ? 'user' : 'none',
      },
    )

    const objectUrl = URL.createObjectURL(blob)
    return objectUrl
  } catch (error) {
    captureSharedTabsException(error as Error, {
      user_id: userId,
    })
    return ''
  }
}

export const getDataFromInvite = async (
  inviteId: string | null,
): Promise<TabInvite | null> => {
  if (!inviteId) {
    return null
  }
  try {
    const res = await apiFetch<ReadTabInvitePreviewResponse>(
      `tabs/invite/view`,
      {
        method: 'PUT',
        body: JSON.stringify({
          code: inviteId,
        }),
        authorizationType: 'client',
      },
    )

    return {
      inviteId,
      expired: res.expired,
      tabName: res.tab_name,
      tabCreatorName: res.creator_name,
      tabCreatorSrc: getUserProfileSrc(res.creator_user_id),
      tabId: res.tab_id,
      referral: res.referral,
    }
  } catch (error) {
    captureSharedTabsException(error as Error, {
      invite_id: inviteId,
    })
    return null
  }
}

export const acceptInvite = async (
  inviteId: string,
): Promise<{
  tabId: string
  signupRequired: boolean
}> => {
  if (!inviteId) {
    throw new Error('No invite id provided')
  }
  try {
    const res = await apiFetch<AcceptInviteResponse>(`tabs/invite/accept`, {
      method: 'PUT',
      body: JSON.stringify({
        code: inviteId,
      }),
    })

    return {
      tabId: res.tab_id,
      signupRequired: res.signup_required,
    }
  } catch (error) {
    captureSharedTabsException(error as Error, {
      invite_id: inviteId,
    })
    throw error
  }
}

export const isUserInTab = async (
  tabId: string,
  inviteId: string,
): Promise<boolean> => {
  if (!getAccessToken('user')) return false
  try {
    const { is_tab_member } = await apiFetch<{
      is_tab_member: boolean
      tab_id: string
    }>(`tabs/${tabId}/ismember`, {
      method: 'PUT',
      body: JSON.stringify({
        code: inviteId,
      }),
    })
    return is_tab_member
  } catch (error) {
    captureSharedTabsException(error as Error, {
      tab_id: tabId,
    })
    return false
  }
}

export const leaveTab = async (tabId: string) => {
  try {
    await apiFetch(`tabs/${tabId}/leave`, {
      method: 'PUT',
      body: new URLSearchParams({
        require_settled: 'true',
      }),
    })
  } catch (error) {
    captureSharedTabsException(error as Error, {
      tab_id: tabId,
    })
    throw error
  }
}

export const signUserUp = async (username: string) => {
  try {
    return await apiFetch(`tabs/profile`, {
      method: 'PUT',
      body: JSON.stringify({
        name: username,
      }),
    })
  } catch (error) {
    captureSharedTabsException(error as Error, {
      username,
    })
    throw error
  }
}

export const readUserProfile = async (): Promise<UserProfile> => {
  try {
    return await apiFetch<UserProfile>(`tabs/profile`, {
      method: 'GET',
    })
  } catch (error) {
    captureSharedTabsException(error as Error)
    throw error
  }
}

export const getUserAmount = (tab: Tab): string => {
  const user = getTabParticipant({
    userId: getAuthValue('userId') || '',
    tab: tab,
  })
  return user?.settle_amount?.toFixed(2) || '0.00'
}

export const getAllUserTabs = async () => {
  const userId = getAuthValue('userId')
  try {
    const { tabs } = await apiFetch<{ tabs: Tab[] }>('tabs', {
      method: 'GET',
    })
    return tabs
  } catch (error) {
    captureSharedTabsException(error as Error, {
      user_id: userId,
    })
    return null
  }
}

// The two methods below are somewhat lifted from service.business-profile.
// They dictate what's accepted for a profile preferred name.
// The main difference is the addition of accents to the list of valid characters

const isBasicAlpha = (r: string): boolean => {
  if (r === ' ') {
    return true
  }

  if (r >= 'a' && r <= 'z') {
    return true
  }

  if (r >= 'A' && r <= 'Z') {
    return true
  }

  if (r >= 'À' && r <= 'ÿ') {
    return true
  }

  return false
}

const isPunctuation = (r: string): boolean => {
  const validPunctuation = new Set(['.', ',', "'", '-'])

  return validPunctuation.has(r)
}

export const isUsernameValid = (username: string): boolean => {
  if (!username) return false
  if (username.trim() === '') return false

  for (let i = 0; i < username.length; i++) {
    const r = username.charAt(i)
    if (!isBasicAlpha(r) && !isPunctuation(r)) {
      return false
    }
  }
  return true
}

export const getReferral = async (tabId?: string) => {
  if (!tabId) {
    return null
  }
  try {
    const { referral } = await apiFetch<TabCreatorReferralResponse>(
      `tabs/${tabId}/referral`,
      {
        method: 'GET',
      },
    )
    return referral
  } catch (error) {
    captureSharedTabsException(error as Error, { tab_id: tabId })
    return null
  }
}

export const reorderParticipantsInTabs = (tabs: Tab[] | null): Tab[] | null => {
  if (!tabs) {
    return null
  }
  return tabs.map((tab) => {
    const userId = getAuthValue('userId')
    const user = tab.participants?.find((person) => person.user_id === userId)
    const otherPeople =
      tab.participants
        ?.filter((person) => person.user_id !== userId)
        ?.sort((a, b) => a.name.localeCompare(b.name)) || []
    return {
      ...tab,
      participants: [user, ...otherPeople].filter(Boolean) as TabParticipant[],
    }
  })
}

export const deleteTabItem = async (tabId: string, itemId: string) => {
  try {
    await apiFetch(`tabs/${tabId}/items/${itemId}`, {
      method: 'DELETE',
    })
  } catch (error) {
    captureSharedTabsException(error as Error, {
      tab_id: tabId,
      item_id: itemId,
    })
    throw error
  }
}

export const getNotificationPreferences = async () => {
  try {
    const preferences = await apiFetch<UserNotificationSettings>(
      `tabs/notifications/settings`,
      {
        method: 'GET',
      },
    )
    return preferences
  } catch (error) {
    captureSharedTabsException(error as Error)
    throw error
  }
}

export const setNotificationPreferences = async (enabled: boolean) => {
  try {
    await apiFetch(`tabs/notifications/settings`, {
      method: 'PUT',
      body: JSON.stringify({
        enabled,
      }),
    })
  } catch (error) {
    captureSharedTabsException(error as Error)
    throw error
  }
}
