import * as React from 'react'
import { SVGProps } from 'react'
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={20}
    height={17}
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5 0C17.0716 0 18.5129 0.55774 19.6368 1.48607C19.9199 1.71655 20 1.99236 20 2.24969V14.56C20 15.1123 19.5523 15.56 19 15.56C18.8623 15.56 18.731 15.5322 18.6116 15.4818C17.6875 14.9769 16.6273 14.69 15.5 14.69C13.7535 14.69 12.1679 15.3788 11 16.4996V1.80957C12.1679 0.6888 13.7535 0 15.5 0ZM4.5 0C6.24649 0 7.83211 0.6888 9 1.80957V16.4996C7.83211 15.3788 6.24649 14.69 4.5 14.69C3.37275 14.69 2.31252 14.9769 1.38836 15.4818C1.26896 15.5322 1.13773 15.56 1 15.56C0.44772 15.56 0 15.1123 0 14.56V2.24969C0 1.99236 0.08009 1.71655 0.36323 1.48607C1.48713 0.55774 2.92843 0 4.5 0Z"
      fill="#091723"
    />
  </svg>
)
export default SvgBook
