import * as React from 'react'

import styles from './skip-link.module.scss'

export type Props = Exclude<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> & {
  target: string
}

export default function SkipLink({ target, ...props }: Props) {
  return (
    <a {...props} href={`#${target}`} className={styles['skip-link']}>
      Skip to main content
    </a>
  )
}
