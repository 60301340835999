import getConfig from 'next/config'

export default function getEnv(key: string) {
  const {
    publicRuntimeConfig: env = {},
    serverRuntimeConfig: serverEnv = {},
  }: {
    publicRuntimeConfig: Record<string, any>
    serverRuntimeConfig: Record<string, any>
  } = getConfig()

  // try server env vars first, otherwise client env vars
  return serverEnv[key] || env[key]
}
