import { Theme } from '../types'

export function stringIsTheme(str: string): str is Theme {
  return Object.values(Theme).includes(str as Theme)
}

export function getThemeFromDOM(): Theme | undefined {
  if (typeof window === 'undefined') {
    return
  }

  const attributeValue = document.documentElement.getAttribute('data-theme')
  if (attributeValue == null) {
    return
  }

  if (stringIsTheme(attributeValue)) {
    return attributeValue
  }
}

export function buildThemeDOMAttributes(theme: Theme | undefined) {
  if (theme == null || !stringIsTheme(theme)) return {}

  return {
    'data-theme': theme,
  }
}
