import type { AnalyticsClientType } from '@mondough/analytics-v2'
import {
  createClient as createAnalyticsClient,
  destroyClient,
  getClient,
} from '@mondough/analytics-v2'
import { getEnv } from '@mondough/next-utils'

import apiFetch from './apiFetch'
import { fetchClientTokenFromAPI, getAccessToken, getAuthValue } from './auth'
import {
  SHARED_TABS_ANALYTICS_NAME,
  SHARED_TABS_ANALYTICS_SESSION_LENGTH,
  WEB_APP_PREFIX,
} from './constants'
import { getCookieConsent } from './cookies'

type AnalyticsEvent = {
  name: string
  data: Record<string, unknown>
}

export default async function sendAnalytics(
  event: AnalyticsEvent | AnalyticsEvent[],
): Promise<number | null | undefined> {
  const events = Array.isArray(event) ? event : [event]
  const currentTime = new Date().toISOString()
  const eventsWithTimestamp = events.map((e) => ({
    ...e,
    name: `${WEB_APP_PREFIX}.${e.name}`,
    time: currentTime,
  }))

  try {
    const rsp = await apiFetch<{ events: number }>('analytics/track', {
      method: 'POST',
      body: JSON.stringify({
        events: eventsWithTimestamp,
      }),
    })

    return rsp?.events
  } catch (error) {
    // We don't need to capture this error. Analytics are nice to have but not essential. We'd also probably end upwith lots of
    // unhelpful noise in Sentry if we capture them.
    return null
  }
}

export const setupAnalytics = async (): Promise<
  AnalyticsClientType | undefined
> => {
  const userAccessToken = getAccessToken('user')
  const clientAccessToken = getAccessToken('client')
  const userId = getAuthValue('userId')

  let accessToken = userAccessToken ?? clientAccessToken
  if (!accessToken) {
    await fetchClientTokenFromAPI()
    accessToken = getAccessToken('client')
  }
  try {
    return await createAnalyticsClient({
      appName: SHARED_TABS_ANALYTICS_NAME,
      apiUrl: getEnv('apiPath'),
      accessToken: accessToken as string,
      userId,
      appVersion: getEnv('gitCommit'),
      sessionLength: SHARED_TABS_ANALYTICS_SESSION_LENGTH,
    })
  } catch (error) {
    await sendAnalytics({
      name: `${SHARED_TABS_ANALYTICS_NAME}.analytics-v2-client-failed`,
      data: { error: (error as Error)?.message },
    })
  }
}

export const sendMagicLinkEvent = async (
  analyticsId: string,
  outcome: string,
) => {
  let analyticsClient = getClient()
  const cookiesAccepted = getCookieConsent()
  if (!analyticsClient) {
    analyticsClient = await setupAnalytics()
    analyticsClient?.trackScreen({
      name: 'oauth-callback',
    }) // This is needed to set the parent screen for the error event
  }
  analyticsClient?.trackAction({
    name: 'magic-link',
    type: 'auth',
    customParameters: {
      outcome,
      analytics_id: analyticsId,
    },
  })
  if (!cookiesAccepted) {
    destroyClient()
  }
}
