import * as React from 'react'
import { SVGProps } from 'react'
const SvgChartBarLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 18"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M13.649 2.049A1.2 1.2 0 1 0 11.951.352L8.336 3.566l-1.378-1.2a2 2 0 0 0-2.652.02L.393 5.914a1.2 1.2 0 0 0 1.614 1.775l3.616-3.287 1.438 1.243a2 2 0 0 0 2.65-.03l3.938-3.565ZM12.25 7c.69 0 1.25.56 1.25 1.25v8.5a1.25 1.25 0 1 1-2.5 0v-8.5c0-.69.56-1.25 1.25-1.25ZM8.25 10.2C8.25 9.538 7.69 9 7 9s-1.25.538-1.25 1.2v6.6c0 .663.56 1.2 1.25 1.2s1.25-.537 1.25-1.2v-6.6ZM3 12.21C3 11.543 2.44 11 1.75 11S.5 11.543.5 12.21v4.58c0 .669.56 1.21 1.25 1.21S3 17.46 3 16.79v-4.58Z"
    />
  </svg>
)
export default SvgChartBarLine
