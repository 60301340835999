import cx from 'classnames'
import React from 'react'

import { LoadingSpinner } from '@mondough/monzo-ui'

import styles from './Loading.module.scss'
import { LoadingProps } from './Loading.types'

const Loading: React.FC<LoadingProps> = ({
  fullScreen = true,
  height,
  spinnerSize = 48,
}: LoadingProps) => (
  <div
    className={cx([styles.loading, { [styles.fullscreen]: fullScreen }])}
    style={{ height: height ?? 'auto' }}
  >
    <LoadingSpinner size={spinnerSize} />
  </div>
)

export default Loading
