import { Text, VisuallyHidden } from '../../'
import styles from './suggestion-renderer.module.scss'

export interface DefaultSuggestionProps {
  id: string
  name: string
}

export const DefaultSuggestionRenderer = ({
  suggestion,
  selected,
}: {
  suggestion: DefaultSuggestionProps
  selected?: boolean
}) => (
  <div id={suggestion.id} className={styles.suggestion}>
    <Text marginTop="none" marginBottom="none">
      {suggestion.name}
    </Text>
    {selected && (
      <>
        <Text marginTop="none" marginBottom="none" aria-hidden>
          ✓
        </Text>
        <VisuallyHidden aria-live="polite">Selected</VisuallyHidden>
      </>
    )}
  </div>
)
