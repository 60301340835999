import cx from 'classnames'
import * as React from 'react'

import { SpacingProps } from '../spacer/spacer'
import Text from '../text'
import styles from './text-button.module.scss'

export type Props = {
  className?: string
  children?: React.ReactNode
  onClick?: (arg0: React.MouseEvent<HTMLButtonElement>) => unknown
  bold?: boolean
} & Pick<
  React.ComponentPropsWithoutRef<'button'>,
  'title' | 'type' | 'disabled'
> &
  SpacingProps

const TextButton = React.forwardRef<HTMLElement, Props>(
  ({ className, ...otherProps }, ref) => {
    return (
      <Text
        {...otherProps}
        ref={ref}
        is="button"
        className={cx(styles['link-button'], className)}
      />
    )
  },
)

export default TextButton
