import { useContext, useEffect } from 'react'

import { TabContext } from '../providers'

export const usePageTitle = (title?: string) => {
  const { setPageTitle } = useContext(TabContext)

  useEffect(() => {
    setPageTitle(title || '')
  }, [setPageTitle, title])
}
