import cx from 'classnames'
import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { PlainCard, Title } from '@mondough/monzo-ui'

import ActionButton from '../ActionButton/ActionButton'
import Loading from '../Loading/Loading'
import styles from './Activity.module.scss'
import { ActivityProps } from './Activity.types'
import { mapFeedActivityItem } from './Activity.utils'
import ActivityItem from './ActivityItem/ActivityItem'

const Activity: React.FC<ActivityProps> = ({
  items,
  displayShowMoreButton,
  onShowMore,
  isShowMoreLoading,
  displayRetryButton,
  onRetry,
  loading,
}: ActivityProps) => (
  <PlainCard className={styles.wrapper} as="section">
    <Title
      is="h2"
      size="extra-small"
      marginTop="small"
      marginLeft="small"
      marginBottom="small"
      className={styles.title}
    >
      Activity
    </Title>
    {loading ? (
      <Loading fullScreen={false} height={300} />
    ) : (
      <ul className={cx(styles.list, 'custom-scrollbar')}>
        {items.map((item, index) => (
          <li key={index} className={styles['list-item']}>
            <ActivityItem {...mapFeedActivityItem(item)} />
          </li>
        ))}
        {displayShowMoreButton && (
          <ActionButton
            title="Show More"
            onClick={onShowMore}
            connectedUp
            className={styles['show-more']}
            loading={isShowMoreLoading}
            {...buildActionAnalyticsAttributes('show-more')}
          />
        )}
        {displayRetryButton && (
          <ActionButton
            title="Retry"
            onClick={onRetry}
            className={styles['show-more']}
            loading={loading}
            {...buildActionAnalyticsAttributes('retry')}
          />
        )}
      </ul>
    )}
  </PlainCard>
)

export default Activity
