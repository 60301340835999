import * as React from 'react'
import { SVGProps } from 'react'
const SvgDocument = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44 0v6c0 1.054.78 1.918 1.768 1.995L10.35 8h5.732v10c0 1.054-.78 1.918-1.768 1.994l-.143.006H2.707C1.7 20 .875 19.184.802 18.15L.796 18V2c0-1.054.78-1.918 1.769-1.995L2.707 0H8.44zm-.956 15H3.663c-.528 0-.956.448-.956 1 0 .513.37.936.844.993l.112.007h3.821c.528 0 .956-.448.956-1 0-.513-.37-.935-.844-.993L7.484 15zm5.733-4H3.663c-.528 0-.956.448-.956 1 0 .513.369.935.844.993l.112.007h9.554c.527 0 .955-.448.955-1 0-.513-.369-.935-.844-.993L13.217 11zM10.35 0l5.733 6h-5.732V0z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDocument
