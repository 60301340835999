import * as React from 'react'
import { SVGProps } from 'react'
const SvgLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={24}
    height={16}
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 14C21.1 14 21.99 13.1 21.99 12L22 2C22 0.9 21.1 0 20 0H4C2.9 0 2 0.9 2 2V12C2 13.1 2.9 14 4 14H1C0.45 14 0 14.45 0 15C0 15.55 0.45 16 1 16H23C23.55 16 24 15.55 24 15C24 14.45 23.55 14 23 14H20ZM5 2H19C19.55 2 20 2.45 20 3V11C20 11.55 19.55 12 19 12H5C4.45 12 4 11.55 4 11V3C4 2.45 4.45 2 5 2Z"
      fill="#091723"
    />
  </svg>
)
export default SvgLaptop
