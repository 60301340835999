import * as React from 'react'
export default function SvgReceipt(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 0a2 2 0 012 2v18l-2-2-2 2-2-2-2 2-2-2-2 2-2-2-2 2V2a2 2 0 012-2h12zM7 11H3a1 1 0 000 2h4a1 1 0 000-2zm6-4H3a1 1 0 100 2h10a1 1 0 000-2zm0-4H3a1 1 0 100 2h10a1 1 0 000-2z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
