import * as React from 'react'
import { SVGProps } from 'react'
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="7 7 20 20">
    <path
      d="M18.695 10.646l2.651 2.65-9.344 9.346c-.2.2-.445.353-.713.446l-2.188.757a.75.75 0 01-.954-.954l.757-2.188c.093-.268.246-.512.446-.713l9.345-9.344zm4.801-2.15c.732.732.732 1.92 0 2.652l-.824.823-2.65-2.651.822-.824a1.875 1.875 0 012.652 0z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEdit
