import * as React from 'react'
export default function SvgAvatar(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 38 38">
      <path
        d="M19 .667C29.125.667 37.333 8.875 37.333 19c0 10.125-8.208 18.333-18.333 18.333C8.875 37.333.667 29.125.667 19 .667 8.875 8.875.667 19 .667zm0 3.666C10.9 4.333 4.333 10.9 4.333 19c0 3.59 1.29 6.88 3.432 9.429 2.621-2.34 6.516-3.849 10.884-3.926L19 24.5c4.51 0 8.545 1.527 11.235 3.929A14.608 14.608 0 0033.667 19C33.667 10.9 27.1 4.333 19 4.333zm0 4.584a6.417 6.417 0 110 12.833 6.417 6.417 0 010-12.833z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
