import * as React from 'react'
export default function SvgMessage(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 22 20">
      <g fillRule="nonzero" fill="none">
        <path
          d="M17.6208283 1.25000325c1.1309555.00332934 1.8688659.2740167 2.3535633.75696017.4852979.4835418.7575405 1.21954246.7607412 2.35098546h0l-.000003 8.07817172c-.0031977 1.1293193-.2754403 1.86532-.7607382 2.3488618-.4846974.4829435-1.2226078.7536308-2.3557711.7569634h0-8.03981243c-.97626225 3.2584039-4.29117803 2.9954614-4.29117803 2.9954614s-.54823335-.0434864-1.2287738-.321927c.21834618-.2475177.44319895-.5315743.66181199-.8504149.39424944-.5750009.67566091-1.1281559.86157832-1.5860196L5.58 15.785l-1.28649468-.2436264c-1.00693417-.0131979-1.69542167-.2436922-2.16860243-.6508789l-.11386932-.1054542c-.48536093-.4835226-.7576874-1.2195026-.76103357-2.3510436h0l.00000329-8.07826808c.00334289-1.12932093.27566935-1.86530096.76103028-2.34882347C2.49579527 1.52397979 3.2337482 1.25333175 4.3669141 1.25h0z"
          stroke="currentColor"
          strokeWidth="1.5"
        />

        <path
          d="M12.1246592 8.37523013c.0070849.3039044-.1125536.59637276-.32863466.80338051-.20992935.21021216-.49237977.32579076-.78509629.32126107-.29712477.00128707-.58367743-.11351248-.80190397-.32126107-.10363609-.09882379-.18709207-.21795766-.24548081-.35042519-.06015017-.1388599-.090332-.28951496-.08846155-.44156309-.00072634-.15016889.02942218-.29879402.08846155-.43609481.05819624-.13577925.14158582-.25853347.24548081-.36136173.10503469-.10429265.22764086-.18798827.36180775-.24698369.41617043-.18059648.89657173-.09125795 1.22519251.22784473.21654926.20743261.33623046.50066919.32863466.80520327zM15.8746592 8.37522671c.0070849.30390348-.1125536.59637094-.3286347.80337807-.2100381.21032414-.4926696.32591133-.7855386.32126009-.150594.00103044-.299936-.02822459-.4396539-.08612505-.1336881-.05490174-.2564692-.13459846-.36225-.23513504-.1035396-.09880881-.1868528-.21795328-.2450385-.35042413-.0601502-.13885947-.090332-.28951407-.0884616-.44156174-.0007263-.15016843.0294222-.29879311.0884616-.43609348.0579951-.13578108.1412415-.25854533.2450385-.36136064.1052715-.10420345.2280057-.18788389.36225-.24698294.4161705-.18059594.8965718-.09125767 1.2251925.22784404.2165493.20743198.3362305.50066767.3286347.80520082zM8.37465921 8.37522671c.00708486.30390348-.11255364.59637094-.32863467.80337807-.21003819.21032414-.49266962.32591133-.78553859.32126009-.15059401.00103044-.29993607-.02822459-.43965392-.08612505-.13368817-.05490174-.25646928-.13459846-.36225006-.23513504-.10353953-.09880881-.1868528-.21795328-.2450385-.35042413-.06015017-.13885947-.090332-.28951407-.08846155-.44156174-.00072634-.15016843.02942218-.29879311.08846155-.43609348.05799511-.13578108.1412415-.25854533.2450385-.36136064.10527152-.10420345.22800569-.18788389.36225006-.24698294.41617043-.18059594.89657173-.09125767 1.22519251.22784404.21654923.20743198.33623045.50066767.32863467.80520082z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
