import {
  BILL_SPLIT_MINIMAL_AMOUNT,
  DEFAULT_CURRENCY_CODE,
  TabParticipant,
  captureSharedTabsException,
  getAuthValue,
  normaliseValueToInterval,
  roundToTwoDecimalPlaces,
} from './'
import apiFetch from './apiFetch'

export type ParticipantAmounts = Record<
  string,
  {
    modified: boolean
    amount: number
  }
>

export const getEqualSplitAmounts = (
  totalBillAmount: number,
  length: number,
) => {
  const roundedSplitAmount = roundToTwoDecimalPlaces(
    totalBillAmount / length,
    'number',
  )
  let equalSplitAmount = 0

  // Example: a bill of 0.02 split among 3 people
  if (totalBillAmount < BILL_SPLIT_MINIMAL_AMOUNT * length) equalSplitAmount = 0
  // Example: a bill of 1.00 split among 18 people - the roundedSplitAmount amount is 0.06
  // In this case, we want to round down to 0.05
  else if (roundedSplitAmount * length > totalBillAmount) {
    equalSplitAmount = roundToTwoDecimalPlaces(
      roundedSplitAmount - 0.01,
      'number',
    )
  }
  // Example: a bill of 1.00 split among 3 people - the roundedSplitAmount amount is 0.33
  else equalSplitAmount = roundedSplitAmount

  // Calculate the remainder, which may or may not be equal to the equalSplitAmount
  const remainder = roundToTwoDecimalPlaces(
    totalBillAmount - equalSplitAmount * (length - 1),
    'number',
  )
  return {
    equalSplitAmount,
    remainder,
  }
}
/**
 * Method to split the bill equally among the participants.
 * The total should always be equal to the total bill amount.
 * @param {number} totalBillAmount - The total amount of the bill
 * @param {number} participants - The number of participants
 * @returns {number[]} - The amounts for each participant
 */
export const splitBillEqually = (
  totalBillAmount: number,
  participants: TabParticipant[],
  setParticipantAmounts: React.Dispatch<
    React.SetStateAction<ParticipantAmounts>
  >,
): void => {
  const participantLength = participants.length
  setParticipantAmounts((_) => {
    const { equalSplitAmount, remainder } = getEqualSplitAmounts(
      totalBillAmount,
      participantLength,
    )
    const participantAmounts: ParticipantAmounts = {}
    participants.forEach(
      (p, i) =>
        (participantAmounts[p.id] = {
          amount: i === 0 ? remainder : equalSplitAmount,
          modified: false,
        }),
    )
    return participantAmounts
  })
}

/**
 * Given a bill amount and the current participant splits,
 * this methods calculates the max amount that can be assigned to a specific participant.
 * @param {string} participantId - The id of the participant we are updating
 * @param {number} totalBillAmount - The total amount of the bill
 * @param {ParticipantAmounts} participantAmounts - The current amounts for each participant
 */
export const remainingToSplit = ({
  participantId,
  totalBillAmount,
  participantAmounts,
}: {
  participantId: string
  totalBillAmount: number
  participantAmounts: ParticipantAmounts
}) => {
  //Sum all the participant amounts that have been modified and are not from the participant we are updating
  const modifiedAmount = Object.entries(participantAmounts)
    .filter(([key, obj]) => obj.modified && key !== participantId)
    .map(([_, obj]) => obj.amount)
    .reduce((a, b) => a + b, 0)

  return roundToTwoDecimalPlaces(totalBillAmount - modifiedAmount, 'number')
}

export const sumAllParticipantAmounts = (
  participantAmounts: ParticipantAmounts,
) =>
  roundToTwoDecimalPlaces(
    Object.values(participantAmounts).reduce(
      (acc, { amount }) => acc + amount,
      0,
    ),
    'number',
  )

/**
 * Method to split the bill among the participants.
 * The initial state for each participant should be the total bill amount divided by the number of participants.
 * The total should always be equal to the total bill amount.
 * A participant can only be updated to a value between 0 and the remaining amount to split.
 * Once a participant has been modified by the user, the remaining amount to split is equally distributed among the unmodified participants.
 * @param {string} type - The type of action to perform
 * @param {number} totalBillAmount - The total amount of the bill
 * @param {string} participantId - The id of the participant we are updating
 * @param {number} inputAmount - The amount to update the participant with
 * @param {React.Dispatch<React.SetStateAction<ParticipantAmounts>>} setParticipantAmounts - The function to update the participant amounts
 */
export const splitBill = ({
  type,
  totalBillAmount,
  participantId,
  inputAmount,
  setParticipantAmounts,
}: {
  type: 'increase' | 'decrease' | 'input'
  totalBillAmount: number
  participantId: string
  inputAmount?: number
  setParticipantAmounts: React.Dispatch<
    React.SetStateAction<ParticipantAmounts>
  >
}) => {
  setParticipantAmounts((oldSplit) => {
    // Calculate the upper limit for the participant we are updating
    const upperLimit = remainingToSplit({
      participantId,
      totalBillAmount,
      participantAmounts: oldSplit,
    })

    // Calculate the new amount for the participant we are updating
    let rawAmount: number
    const oldParticipantAmount = oldSplit[participantId]?.amount || 0
    switch (type) {
      case 'increase':
        rawAmount = oldParticipantAmount + 1
        break
      case 'decrease':
        rawAmount = oldParticipantAmount - 1
        break
      case 'input':
        rawAmount = inputAmount || 0
        break
    }
    const newParticipantAmount = normaliseValueToInterval({
      value: rawAmount,
      min: 0,
      max: upperLimit,
    })

    // Create a new split object, not only updating the participant
    // but also equally splitting the remainder of the bill among the unmodified participants
    const newSplit = structuredClone(oldSplit)
    newSplit[participantId] = { amount: newParticipantAmount, modified: true }

    const remainingBillAmount = upperLimit - newParticipantAmount

    const numberOfParticipantsStillToSplit = Object.values(newSplit).filter(
      (obj) => obj.modified === false,
    ).length

    const { remainder, equalSplitAmount } = getEqualSplitAmounts(
      remainingBillAmount,
      numberOfParticipantsStillToSplit,
    )

    //iterate over newSplit and update the remaining participants
    Object.entries(newSplit).forEach(([key, value], index) => {
      if (value.modified === false) {
        newSplit[key] = {
          amount: index === 0 ? remainder : equalSplitAmount,
          modified: false,
        }
      }
    })
    return newSplit
  })
}

export const getParticipantsToNotifyText = (
  participants: TabParticipant[],
  participantAmounts: ParticipantAmounts,
): string => {
  const peopleToNotify = participants
    .filter((p) => {
      const amount = participantAmounts[p.id]?.amount || 0
      return amount > 0 && p.user_id !== getAuthValue('userId')
    })
    .map((p) => p.first_name)

  if (peopleToNotify.length === 0) {
    return ''
  }

  let dynamicText = ''

  if (peopleToNotify.length === 1) {
    dynamicText = peopleToNotify[0] || ''
  } else {
    const lastPerson = peopleToNotify.pop()
    dynamicText = `${peopleToNotify.join(', ')} and ${lastPerson}`
  }

  return `We'll let ${dynamicText} know you've added this bill.`
}

const transformRecord = (record: ParticipantAmounts) => {
  return Object.entries(record)
    .map(([key, value]) => ({
      participant_id: key,
      amount: Math.floor(value.amount * 100),
    }))
    .filter(({ amount }) => amount > 0)
}

export const addBill = async (
  tabId: string | string[] | undefined,
  bill: {
    amount: number
    title: string
    participantAmounts: ParticipantAmounts
  },
) => {
  const transformedArray = transformRecord(bill.participantAmounts)
  try {
    await apiFetch(`tabs/${tabId}/items/add`, {
      method: 'PUT',
      body: JSON.stringify({
        request_items: [
          {
            type: 'non-monzo-transaction',
            currency: DEFAULT_CURRENCY_CODE,
            idempotency_key: crypto.randomUUID(),
            participant_amounts: transformedArray,
            amount: bill.amount,
            title: bill.title,
          },
        ],
      }),
    })
    return {
      success: true,
    }
  } catch (e) {
    const error = e as Error
    captureSharedTabsException(error, {
      tab_id: tabId,
    })
    throw new Error('Error adding bill', error)
  }
}
