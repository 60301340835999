import * as React from 'react'
import { SVGProps } from 'react'
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.293 5.293a1 1 0 011.414 0L12 10.585l5.293-5.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414L13.415 12l5.292 5.293a1 1 0 01.083 1.32l-.083.094a1 1 0 01-1.414 0L12 13.415l-5.293 5.292a1 1 0 01-1.32.083l-.094-.083a1 1 0 010-1.414L10.585 12 5.293 6.707a1 1 0 01-.083-1.32z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgClose
