import * as React from 'react'
import { SVGProps } from 'react'
const SvgContentful = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    fillRule="evenodd"
  >
    <path d="M9.063 3.02a3.293 3.293 0 0 1 .843 3.223 6.674 6.674 0 0 1 3.44-.952h.024a6.692 6.692 0 0 1 4.737 1.986 2.634 2.634 0 0 0 3.726.092 2.634 2.634 0 0 0 .092-3.725c-.024-.027-.052-.052-.079-.08A11.934 11.934 0 0 0 13.383.023h-.046a11.904 11.904 0 0 0-6.682 2.035h.074c.875 0 1.713.346 2.332.965l.002-.003ZM19.932 16.001c-.696 0-1.364.275-1.858.764a6.697 6.697 0 0 1-4.726 1.95h-.025a6.607 6.607 0 0 1-3.441-.969c.079.287.117.584.117.881a3.295 3.295 0 0 1-3.295 3.295h-.087a11.914 11.914 0 0 0 6.687 2.067h.047c3.163.006 6.199-1.246 8.438-3.48a2.636 2.636 0 0 0-1.855-4.508h-.002ZM4.373 16.295a3.278 3.278 0 0 1 2.331-.965c.303 0 .603.04.892.123a6.687 6.687 0 0 1 .014-6.92 3.297 3.297 0 0 1-4.17-3.182v-.09a11.983 11.983 0 0 0-.033 13.434v-.068c0-.875.344-1.713.966-2.332Z" />
    <path d="M6.734 2.64c-.75 0-1.273.311-1.909.946-.605.606-.796 1.135-.799 1.765a2.708 2.708 0 1 0 5.42-.003A2.71 2.71 0 0 0 6.736 2.64h-.003ZM3.988 18.63c0 .747.31 1.274.946 1.909.606.605 1.135.799 1.765.799a2.7 2.7 0 1 0-.016-5.4 2.7 2.7 0 0 0-2.692 2.7v-.008h-.003Z" />
  </svg>
)
export default SvgContentful
