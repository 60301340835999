import { SVGProps } from 'react'

const SvgReceiptSplit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M19 9.113V22l-2-2-2 2-2-2-2 2-2-2-2 2v-5.959l12-6.928ZM16 16h-4a1 1 0 0 0-.117 1.993L12 18h4a1 1 0 0 0 .117-1.993L16 16ZM15 2a2 2 0 0 1 2 2v3.959L5 14.887V4a2 2 0 0 1 2-2h8ZM8 8a1 1 0 1 0 0 2 1 1 0 0 0 0-2Zm6-4H8a1 1 0 0 0-.117 1.993L8 6h6a1 1 0 0 0 .117-1.993L14 4Z"
      clipRule="evenodd"
    />
  </svg>
)

export default SvgReceiptSplit
