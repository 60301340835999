import * as React from 'react'
export default function SvgTaskViews(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7C20.4189 7 22.4366 8.71767 22.8999 10.9998L23 11C23.5523 11 24 11.4477 24 12C24 12.5523 23.5523 13 23 13L22.8997 13.0012C22.436 15.2828 20.4185 17 18 17C15.2386 17 13 14.7614 13 12C13 11.6575 13.0344 11.323 13.1001 10.9998H10.8999C10.9656 11.323 11 11.6575 11 12C11 14.7614 8.76142 17 6 17C3.58146 17 1.56399 15.2828 1.10026 13.0012L1 13C0.447715 13 0 12.5523 0 12C0 11.4872 0.38604 11.0645 0.883379 11.0067L1 11L1.10006 10.9998C1.5634 8.71767 3.58111 7 6 7C7.63573 7 9.08801 7.78547 10.0002 8.99981H13.9998C14.912 7.78547 16.3643 7 18 7ZM18 9C16.3431 9 15 10.3431 15 12C15 13.6569 16.3431 15 18 15C19.6569 15 21 13.6569 21 12C21 10.3431 19.6569 9 18 9ZM6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9Z"
        fill="currentColor"
      />
    </svg>
  )
}
