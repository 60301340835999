export class CurrencyNotFoundError extends Error {
  givenCurrencyCode: string

  constructor(givenCurrencyCode: string) {
    super(`Currency not found for code "${givenCurrencyCode}"`)
    this.givenCurrencyCode = givenCurrencyCode
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, CurrencyNotFoundError)
    }
  }
}

export class MoneyParseError extends Error {
  constructor(message: string) {
    super(message)
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, MoneyParseError)
    }
  }
}

export class IntegerOverflowError extends Error {
  constructor(overflowValue: string) {
    super(`${overflowValue} would overflow a JavaScript number`)
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, IntegerOverflowError)
    }
  }
}

export class IntegerUnderflowError extends Error {
  constructor(overflowValue: string) {
    super(`${overflowValue} would underflow a JavaScript number`)
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, IntegerUnderflowError)
    }
  }
}

export class CurrencyMismatchError extends Error {
  thisCurrencyCode: string
  operandCurrencyCode: string

  constructor(thisCurrencyCode: string, operandCurrencyCode: string) {
    super(
      `Cannot operate on Money values with different currencies: this ${thisCurrencyCode} vs ${operandCurrencyCode}`,
    )

    this.thisCurrencyCode = thisCurrencyCode
    this.operandCurrencyCode = operandCurrencyCode

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, CurrencyMismatchError)
    }
  }
}
