import * as React from 'react'
export default function SvgChatCategorization(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z"
        fill="currentColor"
      />
    </svg>
  )
}
