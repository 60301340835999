import * as React from 'react'
import { SVGProps } from 'react'
const SvgQa = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.6185 34.2956L27.8269 34.1631L25.8732 35.4075L27.5106 38.0156L29.2258 36.9232L28.1421 37.6134L27.6185 34.2956Z"
      fill="#016B83"
    />
    <path
      d="M27.8268 34.1631L27.6184 34.2956L28.1421 37.6134L29.2257 36.9232L29.5636 36.7075L27.8268 34.1631Z"
      fill="#112231"
    />
    <path
      d="M32.4006 35.8961C30.7637 34.0998 29.5358 31.9965 28.8418 29.6982L26.2928 31.9162L27.8269 34.1629L29.5637 36.7073L31.4174 39.4227L30.0402 37.4055L32.4006 35.8961Z"
      fill="#2EAFC0"
    />
    <path
      d="M32.4006 35.8965L30.0402 37.4059L31.4175 39.4231L34.2135 37.6234C33.5649 37.0864 32.9589 36.5092 32.4001 35.8965H32.4006Z"
      fill="#016B83"
    />
    <path
      d="M24.6208 33.4131L7.33813 44.2236C5.32532 45.5133 4.7284 48.2069 6.00515 50.2402C7.2819 52.2734 9.94842 52.8764 11.9612 51.5867L29.2439 40.7762L27.5105 38.0154L25.8731 35.4073L24.6208 33.4131Z"
      fill="#FF945A"
    />
    <path
      d="M65.7546 23.3069L65.5887 20.4883L65.5864 20.4957C65.6041 20.7935 65.6137 21.0936 65.6137 21.3953C65.6137 30.6648 57.1751 38.1799 46.7658 38.1799C36.3564 38.1799 28.7396 31.3751 27.9769 22.7286L27.9746 22.7246L28.2359 26.4721L28.2381 26.4675C28.3222 27.5777 28.5272 28.658 28.8419 29.6993C29.5359 31.9976 30.7638 34.1008 32.4006 35.8971C32.9589 36.5099 33.5655 37.087 34.2141 37.624C37.5786 40.4094 42.087 42.1139 47.0435 42.1139C57.4529 42.1139 65.8914 34.5994 65.8914 25.3293C65.8914 24.6449 65.8443 23.9702 65.7551 23.3075L65.7546 23.3069Z"
      fill="#004E60"
    />
    <path
      d="M27.9245 38.6758L10.6844 49.4593C9.00668 50.5339 6.87573 50.2935 5.4729 49.0067C5.58308 49.4324 5.75858 49.8489 6.0045 50.2407C7.28125 52.274 9.94777 52.877 11.9606 51.5873L29.2433 40.7767L27.9239 38.6758H27.9245Z"
      fill="#FF4F40"
    />
    <path
      d="M14.8879 40.4151C18.2558 38.2786 21.6237 36.1421 24.9917 34.0056L24.6202 33.4141L7.33756 44.2246C7.03144 44.4208 6.75882 44.6497 6.51971 44.9033C9.44749 43.8218 12.231 42.1001 14.8873 40.4151H14.8879Z"
      fill="#FFA9DF"
    />
    <path
      d="M65.5863 20.4963C65.5698 20.2198 65.546 19.945 65.5147 19.6719C64.7457 14.9164 61.9298 10.9222 57.994 8.28136C52.4655 4.58663 45.2765 3.90162 39.0375 6.08288C32.4823 8.7105 27.9177 14.5785 27.9177 21.3959C27.9177 21.8446 27.9381 22.2892 27.9767 22.7287C28.7395 31.3751 36.86 38.18 46.7656 38.18C57.175 38.18 65.6136 30.6655 65.6136 21.3954C65.6136 21.0936 65.6039 20.7935 65.5863 20.4958V20.4963Z"
      fill="#68CDB5"
    />
    <path
      d="M57.994 8.28098C61.9299 10.9218 64.7458 14.916 65.5148 19.6715C65.5148 19.6738 65.5159 19.6767 65.5159 19.679C65.3824 17.7989 64.8639 15.9212 64.0211 14.2287C61.9622 10.0475 58.0797 6.96144 53.7395 5.4715C48.9994 3.84157 43.5953 4.0091 39.0313 6.08423C39.033 6.08365 39.0353 6.08308 39.037 6.08193C45.2759 3.90067 52.4656 4.58568 57.9934 8.28041L57.994 8.28098Z"
      fill="#FFD7F0"
    />
    <path
      d="M61.7992 15.4905C61.8617 15.6339 61.9191 15.7813 61.9696 15.9351C60.9064 14.0625 58.78 12.9925 56.8388 12.0637C53.3152 10.3775 49.8962 10.031 46.3346 10.4997C43.3966 10.8864 40.4943 11.8175 37.9851 13.3895C34.6848 16.0596 32.6129 19.9127 32.6129 24.196C32.6129 26.7055 33.3251 29.0669 34.5774 31.1294C37.5745 34.1041 41.9244 35.9756 46.7656 35.9756C55.8074 35.9756 63.1373 29.4484 63.1373 21.3963C63.1373 19.273 62.6625 17.2782 61.7987 15.4905H61.7992Z"
      fill="#BBF6E2"
    />
    <path
      d="M49.0046 9.59921C44.762 9.59921 40.896 11.0346 37.9852 13.3897C40.4944 11.8178 43.3966 10.8866 46.3346 10.4999C49.8957 10.0312 53.3153 10.3777 56.8389 12.0639C58.7801 12.9927 60.9065 14.0627 61.9697 15.9353C61.9192 15.7821 61.8618 15.6341 61.7993 15.4907C61.7056 15.2755 61.5988 15.0702 61.4813 14.8734C61.4529 14.8258 61.4239 14.7787 61.3938 14.7322C58.3922 11.8252 53.7788 9.59863 49.0051 9.59863L49.0046 9.59921Z"
      fill="#D9FF6F"
    />
    <path
      d="M60.393 13.1975C60.0511 12.7523 59.6825 12.3226 59.2895 11.9113C56.2181 8.69732 51.6546 6.57859 46.7663 6.7989C40.5591 7.0783 35.1517 10.0524 32.3693 14.497C31.8241 15.4086 31.3771 16.3995 31.0551 17.4126C30.2446 19.9519 30.2463 22.7425 31.0449 25.284C31.7162 27.4429 32.9265 29.3706 34.4821 30.9684C33.2894 28.9443 32.613 26.6403 32.613 24.1957C32.613 19.9123 34.6848 16.0592 37.9852 13.3892C40.8959 11.0341 44.762 9.59863 49.0045 9.59863C53.7781 9.59863 58.3916 11.8252 61.3932 14.7322C61.3995 14.738 61.4051 14.7443 61.4114 14.75C61.4324 14.7707 61.4534 14.7908 61.4744 14.8108C61.153 14.2549 60.7912 13.7162 60.3925 13.1981L60.393 13.1975Z"
      fill="#004E60"
    />
    <path
      d="M31.0545 17.4119C31.3766 16.3988 31.8235 15.4079 32.3688 14.4963C32.3705 14.4934 32.3716 14.4911 32.3733 14.4883C31.7207 15.3437 31.1431 16.292 30.7405 17.3098C30.0947 18.9799 29.8244 20.8066 29.9419 22.596C30.0674 24.5432 30.6519 26.4566 31.6128 28.1479C32.095 28.9878 32.655 29.7887 33.2985 30.5087C33.7568 31.0136 34.2453 31.5167 34.7712 31.9493C35.4431 32.5202 36.1808 33.0388 36.943 33.4788C37.3434 33.7032 37.787 33.9464 38.2079 34.1277C38.6815 34.3457 39.2165 34.5523 39.7118 34.7146L40.0963 34.8328L40.2894 34.8885C40.4246 34.9206 40.746 34.9739 40.8897 35.0078C38.4935 34.192 36.2621 32.7955 34.4827 30.9683C32.9271 29.3705 31.7168 27.4428 31.0455 25.2839C30.2469 22.7423 30.2452 19.9518 31.0557 17.4125L31.0545 17.4119Z"
      fill="#D9FF6F"
    />
    <path
      d="M34.3185 22.9893C37.256 27.4752 42.5356 30.2933 47.8596 30.217C53.1835 30.1407 58.382 27.1729 61.1927 22.6055V22.4816C58.2644 17.9906 52.9899 15.1621 47.6659 15.2287C42.342 15.2947 37.1378 18.2527 34.3191 22.8155V22.9899L34.3185 22.9893Z"
      fill="#F2F8F3"
    />
    <path
      d="M44.5155 22.0256L41.5974 21.9912C41.5696 22.2316 41.5548 22.4754 41.5548 22.7233C41.5548 24.4037 42.2097 25.9292 43.2763 27.0543L45.3976 25.0726C44.8075 24.4685 44.4428 23.6389 44.4428 22.7233C44.4428 22.484 44.4684 22.2511 44.5155 22.0262V22.0256Z"
      fill="#FFD7F0"
    />
    <path
      d="M47.7557 16.459C44.5763 16.459 41.9564 18.8766 41.5974 21.9908L44.5155 22.0252C44.833 20.5117 46.1632 19.3763 47.7557 19.3763C48.0431 19.3763 48.3225 19.4136 48.5883 19.483C48.7979 19.0734 49.2204 18.7934 49.7083 18.7934C50.404 18.7934 50.968 19.3631 50.968 20.0659C50.968 20.4251 50.8209 20.7487 50.5841 20.9799C50.8913 21.4876 51.0685 22.0843 51.0685 22.7222C51.0685 24.5702 49.5856 26.0687 47.7557 26.0687C46.8328 26.0687 45.9984 25.6872 45.3976 25.0722L43.2763 27.0538C44.4048 28.2448 45.9945 28.9866 47.7557 28.9866C51.1804 28.9866 53.9565 26.1823 53.9565 22.7228C53.9565 19.2633 51.1804 16.459 47.7557 16.459Z"
      fill="#FFA9DF"
    />
    <path
      d="M51.0686 22.7229C51.0686 22.0843 50.8914 21.4877 50.5841 20.9805C50.3575 21.2019 50.0485 21.3391 49.7083 21.3391C49.0126 21.3391 48.4486 20.7694 48.4486 20.0666C48.4486 19.8566 48.4992 19.6586 48.5883 19.4837C48.3225 19.4142 48.0431 19.377 47.7557 19.377C46.1632 19.377 44.833 20.5123 44.5156 22.0258C44.4684 22.2507 44.4429 22.4836 44.4429 22.7229C44.4429 23.6385 44.8075 24.4681 45.3976 25.0722C45.9985 25.6873 46.8328 26.0688 47.7557 26.0688C49.5851 26.0688 51.0686 24.5708 51.0686 22.7223V22.7229Z"
      fill="#112231"
    />
    <path
      d="M49.7083 21.338C50.0485 21.338 50.3574 21.2014 50.584 20.9794C50.8209 20.7482 50.968 20.424 50.968 20.0655C50.968 19.3627 50.404 18.793 49.7083 18.793C49.2204 18.793 48.7978 19.0729 48.5883 19.4826C48.4991 19.657 48.4485 19.8555 48.4485 20.0655C48.4485 20.7683 49.0125 21.338 49.7083 21.338Z"
      fill="#D9FF6F"
    />
    <g filter="url(#filter0_d_841_3379)">
      <path
        d="M66.2832 31.1777C65.4597 34.5816 64.8452 35.6097 62.8403 36.9384C64.7685 38.0767 65.3211 39.048 65.9368 42.3824C66.7609 38.9785 67.3754 37.9504 69.3797 36.6217C67.4509 35.484 66.8983 34.5122 66.2832 31.1777Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter1_d_841_3379)">
      <path
        d="M23.0299 15.6777C22.2973 18.7052 21.7509 19.6197 19.9681 20.801C21.6828 21.8131 22.1746 22.6771 22.7221 25.6426C23.4548 22.6151 24.0017 21.7006 25.7839 20.5193C24.0687 19.5073 23.5775 18.6433 23.0299 15.6777Z"
        fill="white"
      />
    </g>
    <path
      d="M63.9962 23.8786C64.8776 24.0519 65.4813 24.8975 65.7733 25.7552C65.8119 25.8683 65.846 25.9824 65.8783 26.0977C65.8806 25.9956 65.8829 25.8935 65.8829 25.7908C65.8829 25.2963 65.8948 24.7793 65.8687 24.2785C65.3354 24.1442 64.7265 24.0152 64.0285 23.874L63.9973 23.8792L63.9962 23.8786Z"
      fill="#112231"
    />
    <g filter="url(#filter2_d_841_3379)">
      <path
        d="M70.2158 17.149C69.0902 22.2006 68.6193 22.6733 63.9866 23.8649C64.0002 23.8678 64.0133 23.8707 64.0269 23.873C64.7255 24.0141 65.3343 24.1438 65.867 24.2774C69.0515 25.0778 69.5303 26.0382 70.2153 30.5803C71.1649 25.2768 71.6533 24.8161 76.4445 23.8644C71.6942 22.6377 71.2359 22.1541 70.2153 17.1484L70.2158 17.149Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_841_3379"
        x="62.8403"
        y="31.1777"
        width="6.53937"
        height="12.2051"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_841_3379"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_841_3379"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_841_3379"
        x="19.9681"
        y="15.6777"
        width="5.8158"
        height="10.9648"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_841_3379"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_841_3379"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_841_3379"
        x="63.9866"
        y="17.1484"
        width="12.4579"
        height="14.4316"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_841_3379"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_841_3379"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgQa
