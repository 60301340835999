import cx from 'classnames'
import React from 'react'

import { SpacingProps } from '../shared-types'
import RadioActionIcon, {
  Props as RadioActionIconProps,
} from './radio-action-icon'
import styles from './radio-action.module.scss'

export type Props = {
  checked?: boolean
  defaultChecked?: boolean
  disabled?: boolean
  invalid?: boolean
  title?: string
  name: string
  subtitle?: string | string[]
  onChange?: (event: React.SyntheticEvent<HTMLInputElement>) => void
  id?: string
  icon?: RadioActionIconProps
  value: string
  className?: string
} & SpacingProps

const RadioAction = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      checked,
      defaultChecked,
      disabled,
      invalid,
      title,
      name,
      subtitle,
      onChange,
      id,
      icon,
      value,
      className,
    } = props

    return (
      <label
        htmlFor={id || value}
        className={cx(
          styles.root,
          {
            [styles.disabled]: disabled,
            [styles.checked]: checked,
            [styles.invalid]: invalid,
          },

          className,
        )}
      >
        {icon != null && <RadioActionIcon {...icon} />}
        <div className={cx(styles['title-group'])}>
          <span className={styles.title}>{title}</span>
          <span className={styles.subtitle}>
            {Array.isArray(subtitle)
              ? subtitle.map((line) => (
                  <>
                    {line}
                    <br />
                  </>
                ))
              : subtitle}
          </span>
        </div>
        <input
          ref={ref}
          defaultChecked={defaultChecked}
          checked={checked}
          id={id || value}
          disabled={disabled}
          onChange={onChange}
          value={value}
          name={name}
          className={styles.input}
          type="radio"
        />
        <span className={cx(styles.check)}>
          <span className={styles['check-inner']} />
        </span>
      </label>
    )
  },
)

export default RadioAction
