import cx from 'classnames'
import React from 'react'

import { Icon, Money, SquareIcon, Text } from '@mondough/monzo-ui'

import AvatarWithBadge from '../AvatarWithBadge/AvatarWithBadge'
import { AvatarWithBadgeProps } from '../AvatarWithBadge/AvatarWithBadge.types'
import Loading from '../Loading/Loading'
import styles from './ActionButton.module.scss'
import {
  ActionButtonAsset,
  ActionButtonIcon,
  ActionButtonProps,
} from './ActionButton.types'

const isAssetIcon = (asset?: ActionButtonAsset): asset is ActionButtonIcon => {
  if (!asset) {
    return false
  }
  return (
    (asset as ActionButtonIcon).iconName !== undefined ||
    (asset as ActionButtonIcon).iconSrc !== undefined
  )
}

const isAssetAvatar = (
  asset?: ActionButtonAsset,
): asset is AvatarWithBadgeProps => {
  return !!asset && (asset as AvatarWithBadgeProps).src !== undefined
}

const CustomSquareIcon = ({ iconSrc }: { iconSrc: string }) => (
  <div className={styles['square-icon']}>
    <img src={iconSrc} alt="" />
  </div>
)

const ActionButton: React.FC<ActionButtonProps> = ({
  assetLeft,
  isLocked,
  isDisabled,
  title,
  subtitle,
  amount,
  participants,
  connectedDown,
  connectedUp,
  onClick,
  marginBottom,
  marginTop,
  marginRight,
  marginLeft,
  mobileOnly,
  desktopOnly,
  className,
  loading,
  destructive,
  ...rest
}: ActionButtonProps) => {
  return (
    <button
      {...rest}
      className={cx(styles.wrapper, className, {
        [styles['connected-up']]: connectedUp,
        [styles['connected-down']]: connectedDown,
        [styles.locked]: isLocked || isDisabled,
        'mobile-only': mobileOnly,
        'desktop-only': desktopOnly,
        [styles.destructive]: destructive,
      })}
      style={
        {
          '--_margin-bottom': marginBottom,
          '--_margin-top': marginTop,
          '--_margin-right': marginRight,
          '--_margin-left': marginLeft,
        } as React.CSSProperties
      }
      onClick={onClick}
      disabled={isLocked || isDisabled || loading}
    >
      {loading ? (
        <Loading spinnerSize={24} height={40} />
      ) : (
        <>
          <div className={styles['left-side-content']}>
            {isAssetIcon(assetLeft) && assetLeft.iconSrc && (
              <CustomSquareIcon iconSrc={assetLeft.iconSrc} />
            )}
            {isAssetIcon(assetLeft) && assetLeft.iconName && (
              <SquareIcon
                icon={assetLeft.iconName}
                iconColor="white"
                backgroundColor={
                  isLocked || isDisabled ? 'grey2' : 'action-blue'
                }
                iconSize={(assetLeft.iconSize = 18)}
                size="large"
              />
            )}
            {isAssetAvatar(assetLeft) && <AvatarWithBadge {...assetLeft} />}
            <div className={styles['text-wrapper']}>
              <Text
                size="large"
                color="primary"
                className={styles['primary-text']}
              >
                {title}
              </Text>
              {subtitle && (
                <Text
                  size="small"
                  color="secondary"
                  className={styles['secondary-text']}
                  align="left"
                >
                  {subtitle}
                </Text>
              )}
            </div>
          </div>
          <div className={styles['trailing-content']}>
            {amount && (
              <Money
                amount={amount}
                size="extra-large"
                options={{ showNegative: false }}
                symbolClassName={styles['money-symbol']}
                className={styles['money']}
                minorClassName={styles['money-minor']}
              />
            )}
            {participants && (
              <div className={styles['participants-wrapper']}>
                {participants.map((participant, i) => (
                  <AvatarWithBadge
                    key={i}
                    src={participant.src}
                    placeholderName={participant.name}
                    style={{
                      zIndex: 10 + participants.length - i,
                      marginLeft: '-16px',
                    }}
                    className={styles['participant-avatar']}
                  />
                ))}
              </div>
            )}
            {!isLocked && !isDisabled && (
              <Icon icon="chevron-right" size={16} />
            )}
            {isLocked && <Icon icon="lock" size={16} color="grey2" />}
          </div>
        </>
      )}
    </button>
  )
}

export default ActionButton
