import React, { useEffect, useState } from 'react'

import { TabCreatorReferral, fetchMaxInterestRate } from '../utils'

export const ReferralContext = React.createContext({
  referralData: null as TabCreatorReferral | null,
  setReferral: (_referral: TabCreatorReferral | null) => {
    return
  },
  maxSavingsRate: null as string | null,
})

export const ReferralProvider = ({ children }: { children: JSX.Element }) => {
  const [referralData, setReferralData] = useState<TabCreatorReferral | null>(
    null,
  )
  const [maxSavingsRate, setMaxSavingsRate] = useState<string | null>(null)

  useEffect(() => {
    const fetchMaxSavingsRate = async () => {
      const data = await fetchMaxInterestRate()
      setMaxSavingsRate(data)
    }
    void fetchMaxSavingsRate()
  }, [])

  return (
    <ReferralContext.Provider
      value={{
        referralData,
        setReferral: setReferralData,
        maxSavingsRate,
      }}
    >
      {children}
    </ReferralContext.Provider>
  )
}
