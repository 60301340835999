import cx from 'classnames'
import React, { CSSProperties, useEffect } from 'react'

import { Avatar, SquareIcon } from '@mondough/monzo-ui'

import CategoryIcon from '../CategoryIcon/CategoryIcon'
import styles from './AvatarWithBadge.module.scss'
import { AvatarWithBadgeProps, Image } from './AvatarWithBadge.types'

const renderImage = (props: Image) => {
  const { type, src, size, placeholderName, shape, category, icon, className } =
    props
  switch (type) {
    case 'profile':
      return (
        <Avatar
          src={src as string}
          square={shape === 'square'}
          name={placeholderName || ''}
          className={className}
          size={size}
        />
      )
    case 'category':
      return category ? (
        <CategoryIcon category={category} className={className} />
      ) : null
    case 'icon':
      return icon ? (
        <SquareIcon
          icon={icon.icon}
          iconColor={icon.iconColor}
          iconSize={icon.iconSize}
          backgroundColor={icon.backgroundColor}
          size={icon.size}
          className={cx(styles.icon, icon.className)}
        />
      ) : null
    default:
      return null
  }
}

const AvatarWithBadge: React.FC<AvatarWithBadgeProps> = (
  props: AvatarWithBadgeProps,
) => {
  const { src, badge } = props
  const [imageSrc, setImageSrc] = React.useState<string | undefined>(undefined)
  const [badgeSrc, setBadgeSrc] = React.useState<string | undefined>(undefined)
  useEffect(() => {
    let isMounted = true
    const imageLoad = async () => {
      const imageSrc = await src
      const badgeSrc = await badge?.src
      if (isMounted) {
        setImageSrc(imageSrc)
        setBadgeSrc(badgeSrc)
      }
    }
    void imageLoad()
    return () => {
      isMounted = false
    }
  }, [badge?.src, src])
  return (
    <div
      className={cx(styles.avatar, styles[props.shape || 'circle'])}
      style={
        {
          ...props.style,
          '--avatar-1-bg': '#bbf6e2', //Changes fallback background color
          '--_badge-border-color': props.badge?.badgeBorderColor,
        } as CSSProperties
      }
      aria-hidden="true"
    >
      {renderImage({ ...props, src: imageSrc, type: props.type || 'profile' })}
      {renderImage({
        ...props.badge,
        src: badgeSrc,
        className: cx(
          styles.badge,
          styles[props.badge?.shape || 'square'],
          props.badge?.className,
        ),
      })}
    </div>
  )
}

export default AvatarWithBadge
