import * as React from 'react'
import { SVGProps } from 'react'
const SvgAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3a1 1 0 011 1v7h7a1 1 0 01.993.883L21 12a1 1 0 01-1 1h-7v7a1 1 0 01-.883.993L12 21a1 1 0 01-1-1v-7H4a1 1 0 01-.993-.883L3 12a1 1 0 011-1h7V4a1 1 0 01.883-.993z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgAdd
