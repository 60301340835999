import * as React from 'react'
import { SVGProps } from 'react'
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.707 7.293a1 1 0 00-1.414 1.414l8 8a1 1 0 001.414 0l8-8a1 1 0 10-1.414-1.414L12 14.586 4.707 7.293z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgChevronDown
