import * as React from 'react'
import { SVGProps } from 'react'
const SvgStarPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00627 3.14387C9.43257 2.28538 10.5704 2.28538 10.9937 3.14387L12.793 6.78067C12.9165 7.03058 13.1164 7.22462 13.3575 7.3334L16.8003 8.86809C17.7323 9.28263 17.7323 10.7144 16.8003 11.129L13.3575 12.6637C13.1164 12.7724 12.9165 12.9665 12.793 13.2164L10.9937 16.8532C10.5674 17.7117 9.42963 17.7117 9.00627 16.8532L7.20697 13.2164C7.08349 12.9665 6.88357 12.7724 6.64249 12.6637L3.19972 11.1319C2.26774 10.7174 2.26774 9.28557 3.19972 8.87103L6.64249 7.33634C6.88357 7.22756 7.08349 7.03352 7.20697 6.78361L9.00627 3.14387Z"
      fill="#091723"
    />
  </svg>
)
export default SvgStarPlus
