import * as React from 'react'
import { SVGProps } from 'react'
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 9.2101 14.4276 10.7866 13.464 12.0483L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3466 18.0676 16.7794 18.0953 16.3871 17.7903L16.2929 17.7071L12.0483 13.464C10.7866 14.4276 9.2101 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13C10.5376 13 13 10.5376 13 7.5C13 4.46243 10.5376 2 7.5 2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSearch
