import * as React from 'react'
import { SVGProps } from 'react'
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#a)" fill="currentColor" fillRule="evenodd">
      <path
        d="M8 16A8 8 0 108 0a8 8 0 000 16zm0-7.2a.8.8 0 01-.8-.8V4.8a.8.8 0 111.6 0V8a.8.8 0 01-.8.8zm0 1.6A.8.8 0 118 12a.8.8 0 010-1.6z"
        fill="currentColor"
      />
    </g>
  </svg>
)
export default SvgError
