import * as React from 'react'
export default function SvgPaperPlane(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        d="M2.83341 17.0001L17.3751 10.7667C18.0501 10.4751 18.0501 9.52506 17.3751 9.23339L2.83341 3.00005C2.28341 2.75839 1.67508 3.16672 1.67508 3.75839L1.66675 7.60006C1.66675 8.01672 1.97508 8.37505 2.39175 8.42506L14.1667 10.0001L2.39175 11.5667C1.97508 11.6251 1.66675 11.9834 1.66675 12.4001L1.67508 16.2417C1.67508 16.8334 2.28341 17.2417 2.83341 17.0001Z"
        fill="currentColor"
      />
    </svg>
  )
}
