import * as React from 'react'
import { SVGProps } from 'react'
const SvgEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 15 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0h-12C.675 0 .007.675.007 1.5L0 10.5c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zm-.3 3.188L7.898 6.502a.759.759 0 01-.795 0L1.8 3.187a.637.637 0 11.675-1.08L7.5 5.25l5.025-3.143a.637.637 0 11.675 1.08z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEmail
