import * as React from 'react'
import { SVGProps } from 'react'
const SvgCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.76 11.35a1 1 0 10-1.52 1.3l6 7a1 1 0 001.52 0l12-14a1 1 0 10-1.52-1.3L9 17.462 3.76 11.35z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgCheckmark
