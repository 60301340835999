import cx from 'classnames'
import React from 'react'

import { mergeRefs } from '@mondough/utils'

import { Sizes } from '../shared-types'
import styles from './textarea.module.scss'

export type TProps = {
  placeholder?: string
  disabled?: boolean
  invalid?: boolean
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  className?: string
  autoSize?: boolean
  maxSizeInPx?: number
  padding?: 'none' | 'regular'
  fontSize?: Sizes
  transparentBorder?: boolean
} & React.ComponentPropsWithoutRef<'textarea'>

const TextArea = React.forwardRef<HTMLTextAreaElement, TProps>((props, ref) => {
  const {
    disabled,
    invalid,
    required,
    placeholder,
    onChange,
    className,
    autoSize = false,
    maxSizeInPx,
    padding = 'regular',
    fontSize,
    transparentBorder,
    ...elementProps
  } = props

  const innerRef = React.useRef<HTMLTextAreaElement>(null)

  const initialValue =
    typeof elementProps.value === 'string' ? elementProps.value : ''

  const [dataValue, setDataValue] = React.useState<string>(initialValue)

  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    if (onChange != null) {
      onChange(event)
    }

    // We're not growing the input size as the user types. So we can just return here and don't do anything
    if (!autoSize) {
      return
    }

    const inputElement = innerRef.current
    // If we don't have a max input size set, or if we do and the input size is getting bigger than it then return and don't do anything else
    if (
      maxSizeInPx != null &&
      inputElement?.scrollHeight != null &&
      inputElement?.scrollHeight >= maxSizeInPx
    ) {
      return
    }

    // Adjust the height of the input according to our input's current value
    setDataValue(event.target.value)
  }

  const sharedClassNames = cx({
    [styles[`padding-${String(padding)}`]]: true,
    [styles[`size-${String(fontSize)}`]]: fontSize != null,
    [styles['border-transparent']]: transparentBorder,
  })

  const inputClassNames = cx(
    [styles.textarea],
    {
      [styles.invalid]: invalid,
    },
    sharedClassNames,
    className,
  )

  const containerClassnames = cx(styles.container, sharedClassNames)

  return (
    <div
      data-testid="textarea-container"
      className={containerClassnames}
      data-value={autoSize ? dataValue : undefined}
    >
      <textarea
        {...elementProps}
        onChange={handleChange}
        data-enable-grammarly="false"
        disabled={disabled}
        aria-invalid={invalid}
        required={required}
        className={inputClassNames}
        placeholder={placeholder}
        ref={mergeRefs<HTMLTextAreaElement>([ref, innerRef])}
      />
    </div>
  )
})

export default TextArea
