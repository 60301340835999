import * as React from 'react'
import { SVGProps } from 'react'
const SvgCashAtm = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 3C21.3284 3 22 3.67157 22 4.5C22 5.32843 21.3284 6 20.5 6H20V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V6H3.5C2.67157 6 2 5.32843 2 4.5C2 3.67157 2.67157 3 3.5 3H20.5ZM18 15.5C16.067 15.5 14.5 17.067 14.5 19H18V15.5ZM6 15.5V19H9.5C9.5 17.067 7.933 15.5 6 15.5ZM12 7C9.79086 7 8 8.11929 8 9.5C8 10.8807 9.79086 12 12 12C14.2091 12 16 10.8807 16 9.5C16 8.11929 14.2091 7 12 7Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCashAtm
