import { useEffect } from 'react'

import {
  Parameters,
  getClient as getAnalyticsClient,
} from '@mondough/analytics-v2'

export const useAnalyticsScreenTracking = (
  screenName: string,
  customParameters?: Parameters,
) => {
  useEffect(() => {
    const analyticsClient = getAnalyticsClient()
    if (!analyticsClient) return
    analyticsClient.trackScreen({
      name: screenName,
      customParameters,
    })
  }, [screenName, customParameters])
}

export const useAnalyticsViewTracking = (
  viewName: string,
  customParameters?: Parameters,
) => {
  useEffect(() => {
    const analyticsClient = getAnalyticsClient()
    if (!analyticsClient) return
    analyticsClient.trackView({
      name: viewName,
      customParameters,
    })
  }, [customParameters, viewName])
}
