import cx from 'classnames'
import * as React from 'react'

import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import styles from './button-group.module.scss'

export type Props = {
  children: React.ReactNode | string
  className?: string
  direction?: 'vertical' | 'horizontal'
} & SpacingProps

export default function ButtonGroup(props: Props) {
  const { children, className, direction = 'horizontal', ...otherProps } = props

  const classNames = cx(
    {
      [styles.root]: true,
      [styles[`direction-${direction}`]]: true,
    },

    className,
  )

  return (
    <Spacer {...otherProps} className={classNames}>
      {children}
    </Spacer>
  )
}
