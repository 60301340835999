import * as React from 'react'
import { SVGProps } from 'react'
const SvgRetail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_841_2160)">
      <path
        d="M28.8885 3.31321C28.8885 2.72243 29.1077 2.18299 29.468 1.77141C29.6575 1.55488 29.794 1.40369 29.9249 1.27676C30.2574 0.953842 30.7301 0.839047 31.1925 0.841847L53.8163 0.982774C55.0969 0.982774 56.1352 2.0262 56.1352 3.31321V51.67C56.1352 52.957 55.0969 54.0004 53.8163 54.0004H31.2074C29.9268 54.0004 28.8885 52.957 28.8885 51.67V3.31321Z"
        fill="#BBF6E2"
      />
      <path
        d="M29.468 2.73083C29.468 1.44381 30.5062 0.400391 31.7869 0.400391H54.3958C55.6764 0.400391 56.7146 1.44381 56.7146 2.73083V51.0876C56.7146 52.3746 55.6764 53.418 54.3958 53.418H31.7869C30.5062 53.418 29.468 52.3746 29.468 51.0876V2.73083Z"
        fill="#8CE6D1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.7146 7.68288H29.468V2.73083C29.468 1.44381 30.5062 0.400391 31.7869 0.400391H54.3958C55.6764 0.400391 56.7146 1.44381 56.7146 2.73083V7.68288Z"
        fill="#2EAFC0"
      />
      <path
        d="M35.2201 18.7872H37.8138C39.6265 17.8689 41.1384 16.7321 42.3883 15.4199H35.2201C34.3639 15.4199 33.6702 14.7227 33.6702 13.8622C33.6702 13.0017 34.3639 12.3046 35.2201 12.3046H44.7118C46.6899 8.8859 47.5294 4.76167 47.6668 0.400391H31.7869C30.5062 0.400391 29.468 1.44381 29.468 2.73083V21.1316C30.9827 20.9581 32.3803 20.6949 33.6692 20.3486C33.6692 20.3477 33.6692 20.3458 33.6692 20.3449C33.6692 19.4844 34.3629 18.7872 35.2192 18.7872H35.2201Z"
        fill="#BBF6E2"
      />
      <path
        d="M42.3883 15.42H50.9634C51.8196 15.42 52.5133 14.7229 52.5133 13.8624C52.5133 13.0019 51.8196 12.3047 50.9634 12.3047H44.7117C44.4183 12.8124 44.1007 13.3042 43.7552 13.7784C43.3392 14.3514 42.8842 14.8992 42.3873 15.42H42.3883Z"
        fill="#2EAFC0"
      />
      <path
        d="M33.6702 13.8624C33.6702 14.7229 34.3639 15.42 35.2201 15.42H42.3883C42.8842 14.8992 43.3393 14.3514 43.7562 13.7784C44.1008 13.3033 44.4193 12.8115 44.7127 12.3047H35.221C34.3648 12.3047 33.6711 13.0019 33.6711 13.8624H33.6702Z"
        fill="#8CE6D1"
      />
      <path
        d="M50.9635 18.7871H37.8129C37.0282 19.1847 36.1868 19.5421 35.286 19.8548C34.7669 20.0349 34.2283 20.1992 33.6702 20.3494C33.672 21.2081 34.3657 21.9034 35.2201 21.9034H50.9635C51.8197 21.9034 52.5134 21.2062 52.5134 20.3457C52.5134 19.4852 51.8197 18.788 50.9635 18.788V18.7871Z"
        fill="#8CE6D1"
      />
      <path
        d="M37.8128 18.7871H35.2191C34.3629 18.7871 33.6692 19.4842 33.6692 20.3447C33.6692 20.3457 33.6692 20.3475 33.6692 20.3485C34.2282 20.1991 34.7669 20.0339 35.285 19.8538C36.1858 19.5412 37.0272 19.1837 37.8119 18.7861L37.8128 18.7871Z"
        fill="#BBF6E2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6668 0.400391H31.7869C30.5062 0.400391 29.468 1.44381 29.468 2.73083V7.68288H46.6276C47.2693 5.39725 47.5869 2.93895 47.6668 0.400391Z"
        fill="#8CE6D1"
      />
      <path
        d="M42.612 36.7561C46.9572 36.7561 50.4796 33.2161 50.4796 28.8492C50.4796 24.4824 46.9572 20.9424 42.612 20.9424C38.2669 20.9424 34.7445 24.4824 34.7445 28.8492C34.7445 33.2161 38.2669 36.7561 42.612 36.7561Z"
        fill="#2EAFC0"
      />
      <path
        d="M39.8986 28.9721H39.1585C38.9848 28.9721 38.8195 28.8321 38.7861 28.6566L38.748 28.4578C38.7053 28.2945 38.7452 28.1424 38.8548 28.0491L41.2758 26.427L43.1777 25.2259C43.4841 25.0159 43.8407 25.0159 44.0645 25.2259L44.8632 26.2478L44.9876 25.6057C45.0015 25.5357 45.0702 25.4769 45.139 25.4769H45.7955C45.8642 25.4769 45.9107 25.5357 45.8977 25.6057L45.6339 26.972L46.8291 28.0808C46.9387 28.1741 46.9786 28.3263 46.9359 28.4896L46.8978 28.6884C46.8644 28.8638 46.6991 29.0038 46.5254 29.0038H45.7853L45.0888 32.6231C45.0619 32.7631 44.9207 32.8919 44.7703 32.8919H39.2095C39.2095 32.8919 38.8557 32.9507 38.9439 32.5737C39.0322 32.1957 39.8939 28.9973 39.8939 28.9973"
        fill="#8CE6D1"
      />
      <path
        d="M62.8808 4.6539C62.1434 5.15788 61.9169 5.54893 61.6132 6.84247C61.3875 5.57413 61.1841 5.20361 60.4756 4.76963C61.2129 4.26565 61.4395 3.8746 61.7432 2.58105C61.9689 3.8494 62.1713 4.21992 62.8808 4.6539Z"
        fill="#FF945A"
      />
      <path
        d="M38.5483 9.56654L38.5251 9.54601C38.5232 9.54414 38.5213 9.54228 38.5186 9.54041L38.1657 9.23242C38.2465 9.33695 38.279 9.48161 38.2455 9.64027L35.7419 21.6144C35.675 21.9346 35.3621 22.194 35.0445 22.194H23.1299C22.9897 22.194 22.8717 22.1436 22.79 22.0596L22.7844 22.0624L23.2469 22.4759V22.4712C23.3268 22.5375 23.4317 22.5757 23.5552 22.5757H35.4698C35.7883 22.5757 36.1004 22.3163 36.1672 21.9962L38.6709 10.022C38.7099 9.8372 38.6588 9.67294 38.5473 9.56654H38.5483Z"
        fill="#016B83"
      />
      <path
        d="M23.1299 22.1939H35.0445C35.363 22.1939 35.6751 21.9344 35.7419 21.6143L38.2456 9.64012C38.279 9.48053 38.2456 9.3368 38.1657 9.23227C38.0849 9.12681 37.9549 9.06055 37.7961 9.06055H26.0088C25.6939 9.06055 25.381 9.32 25.3113 9.64012L22.6805 21.6143C22.6415 21.7925 22.687 21.9531 22.7901 22.0585C22.8718 22.1425 22.9897 22.1929 23.1299 22.1929V22.1939Z"
        fill="#004E60"
      />
      <path
        d="M35.5655 22.5752L28.8439 26.5184V22.5752H35.5655Z"
        fill="#112231"
      />
      <path
        d="M37.2492 48.1865L28.8885 50.8679V48.1865H37.2492Z"
        fill="#112231"
      />
      <path
        d="M55.0811 10.8505C55.0811 10.8505 56.7155 11.3853 56.7155 13.3527V10.8496H55.0811V10.8505Z"
        fill="#112231"
      />
      <path
        d="M50.9635 42.4648H35.2201C34.3641 42.4648 33.6702 43.1622 33.6702 44.0225C33.6702 44.8828 34.3641 45.5802 35.2201 45.5802H50.9635C51.8195 45.5802 52.5134 44.8828 52.5134 44.0225C52.5134 43.1622 51.8195 42.4648 50.9635 42.4648Z"
        fill="#2EAFC0"
      />
      <path
        d="M58.4734 5.89839C57.8595 9.62317 57.6023 9.97129 55.0754 10.8495C55.0764 10.8495 55.0782 10.8495 55.0792 10.8505C55.0792 10.8505 55.0801 10.8505 55.081 10.8505C55.1943 10.8813 55.303 10.9111 55.4079 10.941C55.9632 11.0978 56.4043 11.2424 56.7582 11.4198C57.9153 11.9984 58.153 12.928 58.4734 15.7997C58.9916 11.8901 59.2581 11.5504 61.8713 10.8486C59.2794 9.94422 59.0296 9.58771 58.4734 5.89746V5.89839Z"
        fill="#ED5E11"
      />
      <path
        d="M39.4984 38.3743L39.4807 38.3593C39.4789 38.3584 39.478 38.3565 39.4761 38.3556L39.1901 38.1055C39.2653 38.1857 39.2987 38.3043 39.2709 38.4368L37.3866 47.4487C37.3365 47.6895 37.1015 47.8845 36.861 47.8845H27.8939C27.8057 47.8845 27.7305 47.8584 27.672 47.8127L27.9822 48.0898V48.087C28.0425 48.1365 28.1215 48.1664 28.2143 48.1664H37.1814C37.421 48.1664 37.6559 47.9713 37.707 47.7305L39.5912 38.7186C39.62 38.5796 39.582 38.4555 39.4984 38.3761V38.3743Z"
        fill="#D9FF6F"
      />
      <path
        d="M27.6339 47.7783L27.6376 47.7811C27.6376 47.7811 27.6358 47.7792 27.6348 47.7773L27.6339 47.7783Z"
        fill="#D9FF6F"
      />
      <path
        d="M36.861 47.8835C37.1006 47.8835 37.3355 47.6885 37.3866 47.4477L39.2708 38.4358C39.2987 38.3033 39.2652 38.1847 39.19 38.1045C39.1287 38.0392 39.0396 37.999 38.9328 37.999H30.0605C29.8236 37.999 29.5878 38.1941 29.5348 38.4349L27.555 47.4468C27.5262 47.5793 27.5587 47.6969 27.6339 47.7771C27.6348 47.7781 27.6357 47.7799 27.6367 47.7809C27.6478 47.7921 27.659 47.8023 27.671 47.8117C27.7295 47.8565 27.8057 47.8835 27.893 47.8835H36.86H36.861ZM35.6045 43.7126H34.1456L33.8911 45.1863C33.7026 46.2783 32.0589 46.2783 32.2521 45.1863L32.5121 43.7126H31.0532C29.9722 43.7126 30.274 42.0617 31.3504 42.0617H32.8037L33.0637 40.588C33.2569 39.496 34.8746 39.496 34.6851 40.588L34.4307 42.0617H35.884C36.9603 42.0617 36.6845 43.7126 35.6045 43.7126Z"
        fill="#A3DC1A"
      />
      <path
        d="M35.8849 42.0622H34.4316L34.686 40.5885C34.8746 39.4965 33.2568 39.4965 33.0646 40.5885L32.8046 42.0622H31.3513C30.2749 42.0622 29.9731 43.7132 31.0541 43.7132H32.513L32.253 45.1868C32.0598 46.2788 33.7035 46.2788 33.892 45.1868L34.1465 43.7132H35.6054C36.6864 43.7132 36.9622 42.0622 35.8849 42.0622Z"
        fill="#C3FF34"
      />
      <path
        d="M64.024 30.306L64.0045 30.2883C64.0026 30.2864 64.0008 30.2855 63.9989 30.2836L63.672 29.998C63.7575 30.0895 63.7965 30.2239 63.764 30.3751L61.6151 40.6497C61.5575 40.9241 61.2891 41.1472 61.0161 41.1472H50.7926C50.6923 41.1472 50.6059 41.1173 50.5391 41.066L50.8929 41.3814V41.3777C50.9616 41.4346 51.0517 41.4673 51.1575 41.4673H61.3811C61.6541 41.4673 61.9225 41.2442 61.98 40.9698L64.1289 30.6952C64.1624 30.5366 64.1178 30.3956 64.0231 30.3042L64.024 30.306Z"
        fill="#FFD7F0"
      />
      <path
        d="M50.4982 41.0264L50.5019 41.0301C50.5019 41.0301 50.5 41.0273 50.4982 41.0264Z"
        fill="#FFD7F0"
      />
      <path
        d="M61.0179 41.1475C61.291 41.1475 61.5593 40.9244 61.6169 40.6501L63.7658 30.3754C63.7974 30.2242 63.7593 30.0889 63.6739 29.9984C63.6042 29.9237 63.5021 29.8789 63.3804 29.8789H53.2656C52.9953 29.8789 52.7269 30.102 52.6666 30.3764L50.409 40.651C50.3756 40.8012 50.4137 40.9366 50.4982 41.028C50.4991 41.029 50.5 41.0308 50.5019 41.0318C50.514 41.0448 50.527 41.056 50.5409 41.0672C50.6078 41.1186 50.6941 41.1484 50.7944 41.1484H61.0179V41.1475ZM55.0226 35.9789L54.5833 37.7643C54.5369 37.9528 54.3465 38.1068 54.1589 38.1068H53.026C52.8384 38.1068 52.7232 37.9528 52.7706 37.7643L53.2154 35.9798C53.2628 35.7913 53.4532 35.6373 53.6398 35.6373H54.7672C54.9539 35.6373 55.069 35.7904 55.0226 35.9798V35.9789ZM57.1111 32.4959C57.0851 32.4464 57.0944 32.3792 57.1362 32.3232C57.178 32.2672 57.2439 32.2336 57.3052 32.2355L58.6805 32.2877C58.7316 32.2896 58.7725 32.3167 58.7901 32.3605C58.8078 32.4044 58.8003 32.4595 58.7697 32.508L57.9831 33.7623C57.9497 33.8155 57.8949 33.8529 57.8373 33.8622C57.7797 33.8715 57.7287 33.851 57.7027 33.8081L57.581 33.6065C57.5039 33.6494 57.4166 33.6979 57.3238 33.7502C56.7666 34.0619 56.0032 34.4884 55.6355 34.607C55.4628 34.663 55.1442 34.6816 54.8359 34.6816C54.4617 34.6816 54.1023 34.6536 54.0336 34.6294C53.9788 34.6098 53.9491 34.5566 53.9583 34.4931C53.9676 34.4296 54.0141 34.369 54.0763 34.3363L57.2318 32.7217L57.112 32.4959H57.1111ZM58.0119 34.957L57.3358 37.7634C57.2903 37.9519 57.1009 38.1059 56.9124 38.1059H55.7794C55.5918 38.1059 55.4767 37.9519 55.5222 37.7634L56.2075 34.957C56.254 34.7684 56.4425 34.6144 56.6291 34.6144H57.7547C57.9413 34.6144 58.0565 34.7684 58.011 34.957H58.0119ZM60.9975 33.9098L60.0884 37.7643C60.0438 37.9528 59.8553 38.1068 59.6668 38.1068H58.5338C58.3462 38.1068 58.2301 37.9528 58.2747 37.7643L59.1959 33.9098C59.2414 33.7213 59.429 33.5673 59.6157 33.5673H60.7384C60.9251 33.5673 61.0402 33.7213 60.9957 33.9098H60.9975Z"
        fill="#FFA9DF"
      />
      <path
        d="M54.7673 35.6357H53.6399C53.4532 35.6357 53.2628 35.7897 53.2155 35.9782L52.7707 37.7627C52.7233 37.9512 52.8385 38.1052 53.026 38.1052H54.159C54.3466 38.1052 54.537 37.9512 54.5834 37.7627L55.0226 35.9773C55.0691 35.7888 54.9548 35.6348 54.7673 35.6348V35.6357Z"
        fill="#FFD7F0"
      />
      <path
        d="M53.9574 34.4921C53.9481 34.5556 53.9778 34.6087 54.0326 34.6283C54.1013 34.6526 54.4607 34.6806 54.8349 34.6806C55.1433 34.6806 55.4618 34.6619 55.6345 34.606C56.0023 34.4874 56.7656 34.06 57.3228 33.7492C57.4157 33.6969 57.503 33.6484 57.58 33.6055L57.7017 33.8071C57.7277 33.85 57.7788 33.8705 57.8363 33.8612C57.8939 33.8518 57.9487 33.8136 57.9821 33.7613L58.7687 32.507C58.7994 32.4584 58.8068 32.4034 58.7891 32.3595C58.7715 32.3156 58.7306 32.2886 58.6796 32.2867L57.3042 32.2344C57.2429 32.2326 57.177 32.2662 57.1352 32.3222C57.0934 32.3782 57.0841 32.4454 57.1101 32.4948L57.2299 32.7207L54.0744 34.3353C54.0122 34.367 53.9657 34.4286 53.9564 34.4921H53.9574Z"
        fill="#FFD7F0"
      />
      <path
        d="M57.7556 34.6143H56.6301C56.4434 34.6143 56.2539 34.7683 56.2084 34.9568L55.5231 37.7632C55.4767 37.9517 55.5918 38.1057 55.7803 38.1057H56.9133C57.1009 38.1057 57.2913 37.9517 57.3368 37.7632L58.0128 34.9568C58.0583 34.7683 57.9432 34.6143 57.7565 34.6143H57.7556Z"
        fill="#FFD7F0"
      />
      <path
        d="M60.7403 33.5664H59.6176C59.4309 33.5664 59.2433 33.7204 59.1978 33.9089L58.2766 37.7634C58.2311 37.952 58.3472 38.1059 58.5357 38.1059H59.6686C59.8562 38.1059 60.0457 37.952 60.0903 37.7634L60.9994 33.9089C61.044 33.7204 60.9288 33.5664 60.7422 33.5664H60.7403Z"
        fill="#FFD7F0"
      />
      <path d="M51.3562 41.4668L56.7786 42.6894V41.4668" fill="#112231" />
      <path
        d="M32.0932 13.2775C32.5186 12.655 32.981 11.9074 33.0386 11.6171C33.1101 11.2569 32.6969 11.0637 32.3468 11.451C31.8889 11.9634 31.8741 11.2158 31.4636 11.2158C31.0531 11.2158 30.6993 11.8943 30.4755 11.437C30.2712 11.0217 29.7298 11.2709 29.6629 11.6031C29.5821 12.0045 29.7549 12.71 29.9257 13.2915C28.764 14.0941 27.2308 15.5752 26.9596 16.9173C26.564 18.8688 27.8781 19.7956 29.7233 19.7956C31.5685 19.7956 33.2522 18.8679 33.6366 16.9173C33.9041 15.5612 32.9504 14.0661 32.0932 13.2775ZM29.6091 15.284C29.9109 15.284 30.0938 15.5332 30.0363 15.8235C29.975 16.1277 29.6815 16.3629 29.3927 16.3629C29.1039 16.3629 28.907 16.1137 28.9655 15.8235C29.0268 15.5192 29.3212 15.284 29.6091 15.284ZM31.0206 18.3985C30.7179 18.3985 30.534 18.1493 30.5916 17.859C30.6492 17.5688 30.9463 17.3196 31.2352 17.3196C31.524 17.3196 31.7218 17.5688 31.6642 17.859C31.6038 18.1633 31.3094 18.3985 31.0206 18.3985ZM31.9632 15.8655L29.0593 18.3014C28.9107 18.4255 28.6767 18.4255 28.5783 18.3014C28.5235 18.2323 28.5123 18.1493 28.5318 18.0522C28.5513 17.9551 28.6089 17.8721 28.6786 17.803L31.5825 15.3671C31.7468 15.229 31.9669 15.229 32.0626 15.3671C32.1174 15.4362 32.1285 15.5192 32.109 15.6163C32.0895 15.7134 32.0319 15.7964 31.9632 15.8655Z"
        fill="#016B83"
      />
    </g>
    <defs>
      <clipPath id="clip0_841_2160">
        <rect
          width="80"
          height="53.6"
          fill="white"
          transform="translate(0 0.400391)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgRetail
