import * as React from 'react'
import { SVGProps } from 'react'

const SvgGift = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11 14v8H6a2 2 0 0 1-2-2v-6h7Zm9 0v6a2 2 0 0 1-2 2h-5v-8h7ZM15.5 2a3.5 3.5 0 0 1 2.448 6.001L20 8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-7V8h-2v4H4a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1l2.052.001a3.5 3.5 0 1 1 5.943-2.693L12 5.5l.005-.192A3.5 3.5 0 0 1 15.5 2Zm-7 1.5a2 2 0 0 0-.15 3.995l.15.005h2v-2l-.005-.15A2 2 0 0 0 8.5 3.5Zm7 0a2 2 0 0 0-1.977 1.697l-.018.154-.005.149v2h2l.15-.005A2 2 0 0 0 15.5 3.5Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgGift
