import cx from 'classnames'
import * as React from 'react'

import { BackgroundColorVariables } from '../shared-types'
import styles from './plain-card.module.scss'

export type Props<T extends React.ElementType> = {
  as?: T
  backgroundColor?: BackgroundColorVariables
} & React.ComponentPropsWithoutRef<T>

export default React.forwardRef(function PlainCard<
  T extends React.ElementType = 'div',
>(
  {
    children,
    className,
    as: Element = 'div',
    backgroundColor = 'primary',
    ...otherProps
  }: Props<T>,
  ref: React.ForwardedRef<T>,
) {
  return (
    <Element
      {...otherProps}
      className={cx(styles.card, styles[backgroundColor], className)}
      ref={ref}
    >
      {children}
    </Element>
  )
})
