import * as React from 'react'
import { SVGProps } from 'react'
const SvgLooker = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.95 0a2.097 2.097 0 0 0-1.74 3.267l.895-.894a.884.884 0 1 1 .565.563l-.894.894A2.096 2.096 0 1 0 11.949 0ZM11.116 6.108a3.259 3.259 0 0 0-.654-1.963L9.3 5.306a1.666 1.666 0 0 1-.318 2.011l.631 1.544a3.276 3.276 0 0 0 1.503-2.753Z"
      fill="currentColor"
      fillRule="evenodd"
    />
    <path
      d="M7.868 7.775h-.03a1.668 1.668 0 1 1 .915-3.062l1.151-1.15A3.277 3.277 0 1 0 8.499 9.32l-.631-1.545ZM11.994 9.11c-.728 0-1.451.105-2.148.314l.92 2.25c.402-.099.813-.149 1.226-.149a5.017 5.017 0 1 1-2.352.585l-.91-2.243A7.437 7.437 0 1 0 12 9.109h-.006Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgLooker
