import '../shared-types'

import cx from 'classnames'
import * as React from 'react'

import type { Colors, TextColorVariables } from '../shared-types'
import Spacer from '../spacer'
import { SpacingProps } from '../spacer/spacer'
import { pickSpacingProps } from '../spacer/utils'
import styles from './title.module.scss'

export interface Props<C extends React.ElementType> {
  is?: C
  children: React.ReactNode
  size?: 'extra-small' | 'small' | 'medium' | 'large'
  align?: 'left' | 'center' | 'right'
  color?: TextColorVariables | Colors
  className?: string
}

type TitleFooterProps<C extends React.ElementType> = SpacingProps &
  Props<C> &
  React.ComponentPropsWithoutRef<C>

export default function Title<C extends React.ElementType>(
  props: TitleFooterProps<C>,
) {
  const {
    is = 'span',
    children,
    size = 'small',
    color = 'primary',
    align,
    className,
    ...otherProps
  } = props

  const spacingProps = pickSpacingProps(props)

  const _classNames = cx(
    {
      [styles[`size-${size}`]]: true,
      [styles[`color-${color}`]]: true,
      [styles[`align-${String(align)}`]]: Boolean(align),
    },
    styles.title,
    className,
  )

  return (
    <Spacer
      {...otherProps}
      is={is}
      className={_classNames}
      marginBottom="none"
      marginTop="none"
      {...spacingProps}
    >
      {children}
    </Spacer>
  )
}
