type LogLevel = 'info' | 'warn' | 'error' | 'debug'

interface LogEntry {
  level: LogLevel
  severity: number
  message: string
  service: string
  meta: { stack?: string }
}

const getSeverity = (level: LogLevel): number => {
  switch (level) {
    case 'error':
      return 5
    case 'warn':
      return 4
    case 'info':
      return 3
    case 'debug':
      return 2
    default:
      return 3
  }
}

const stripAnsi = (str: string): string => {
  return str.replace(/\u001b\[\d{1,2}m/g, '')
}

export const customLog = (level: LogLevel, ...args: any[]): void => {
  const logEntry: LogEntry = {
    level: level,
    severity: getSeverity(level),
    message: '',
    service: 'web.tabs-monzo-me',
    meta: { stack: '' },
  }

  const msgArr: string[] = []
  args.forEach((arg) => {
    if (arg instanceof Error) {
      // If the argument is an Error, log the message and stack trace
      msgArr.push(stripAnsi(arg.message))
      logEntry.meta.stack = stripAnsi(arg.stack || '')
    } else if (typeof arg === 'string') {
      // For strings, strip ANSI codes and add to message
      msgArr.push(stripAnsi(arg))
    } else if (typeof arg === 'object' && arg !== null) {
      // For objects, stringify and add to message
      msgArr.push(JSON.stringify(arg))
    } else {
      // For other types, convert to string and add to message
      msgArr.push(String(arg))
    }
  })
  logEntry.message = msgArr.join(' ')
  // Replace the default console.log output with a JSON stringified object
  process.stdout.write(JSON.stringify(logEntry) + '\n')
}

export const initializeServerSideLogging = (): void => {
  if (typeof window === 'undefined') {
    // Override console.log with the custom logger
    console.log = (...args: any[]) => customLog('info', ...args)
    console.info = (...args: any[]) => customLog('info', ...args)
    console.warn = (...args: any[]) => customLog('warn', ...args)
    console.error = (...args: any[]) => customLog('error', ...args)
    console.debug = (...args: any[]) => customLog('debug', ...args)
  }
}
