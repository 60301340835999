import * as React from 'react'
export default function SvgOpening(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 20 18">
      <path
        d="M18 2H4v4H2V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-4h2v4h14V2Z"
        fill="currentColor"
      />
      <path
        d="m14.707 8.293-3.5-3.5a1 1 0 0 0-1.414 1.414L11.585 8H1a1 1 0 0 0 0 2h10.585l-1.792 1.793a1 1 0 0 0 1.414 1.414l3.5-3.5.073-.082.074-.104.052-.098.044-.11.03-.112.017-.126L15 9l-.004-.09-.007-.058-.025-.118-.035-.105-.054-.113-.071-.111a1.01 1.01 0 0 0-.097-.112Z"
        fill="currentColor"
      />
    </svg>
  )
}
