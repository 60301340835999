import * as React from 'react'
import { SVGProps } from 'react'
const SvgNavigation = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.495 13.14c.554 0 .86-.503 1.127-1.082l4.824-10.442c.134-.286.204-.54.204-.763A.837.837 0 0012.797 0c-.223 0-.477.07-.77.204L1.588 5.027C1.035 5.275.5 5.593.5 6.153c0 .611.586.878 1.337.878l4.627.02c.095 0 .134.038.134.14l.012 4.613c0 .732.261 1.336.885 1.336z"
      fill="currentColor"
    />
  </svg>
)
export default SvgNavigation
