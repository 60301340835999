import React from 'react'

import { Theme } from '../types'
import { getThemeFromDOM } from '../utils/theme'

type ProviderProps = React.PropsWithChildren<{
  initialTheme?: Theme
}>

type ThemeContextType = {
  theme: Theme
  changeTheme: (theme: Theme) => void
}

export const MonzoUiThemeContext = React.createContext<ThemeContextType>({
  theme: Theme.Light,
  changeTheme: () => null,
})

export const MonzoUiThemeProvider = ({
  children,
  initialTheme,
}: ProviderProps) => {
  const [theme, setTheme] = React.useState<Theme>(
    initialTheme ?? getThemeFromDOM() ?? Theme.Light,
  )

  // Change what theme styling we're applying by updating the `data-theme` attribute on the
  // document's top element.
  function changeTheme(theme: Theme) {
    document.documentElement.setAttribute('data-theme', theme)
  }

  // Detect when the `data-theme` attribute changes and update the theme we have in state.
  // This is so that components can have an up-to-date value of the current theme, without
  // having to create a mutation observer themselves. The value by default is only in the DOM.
  // So this doesn't impact what styling we show, but it allows components to react to the
  // theme changing.
  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type !== 'attributes' ||
          mutation.attributeName !== 'data-theme'
        ) {
          continue
        }

        const newTheme = getThemeFromDOM()
        if (newTheme == null) {
          continue
        }

        setTheme(newTheme)
      }
    })

    observer.observe(document.documentElement, {
      attributeFilter: ['data-theme'],
    })

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <MonzoUiThemeContext.Provider
      value={{
        theme,
        changeTheme,
      }}
    >
      {children}
    </MonzoUiThemeContext.Provider>
  )
}
