import React from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'

import { usePageTitle } from '../../../hooks'
import jigsaw from '../../../public/images/illustrations/jigsaw.misaligned.svg'
import { getCDNSrc } from '../../../utils'
import ActionCard from '../ActionCard'
import { ErrorCardProps } from './ErrorCard.types'

const ErrorCard: React.FC<ErrorCardProps> = ({
  assetNode,
  title,
  text,
  primaryButtonText,
  primaryButtonAction,
  primaryButtonAnalyticsName,
  children,
}: ErrorCardProps) => {
  usePageTitle('')
  return (
    <ActionCard
      assetNode={
        assetNode ?? <img src={getCDNSrc(jigsaw)} alt="" width={160} />
      }
      title={title}
      text={text}
      primaryButton={
        primaryButtonText
          ? {
              text: primaryButtonText,
              onClick: primaryButtonAction,
              ...buildActionAnalyticsAttributes(
                primaryButtonAnalyticsName || 'try-again',
              ),
            }
          : undefined
      }
    >
      {children}
    </ActionCard>
  )
}

export default ErrorCard
