import * as React from 'react'
import { SVGProps } from 'react'
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0a5 5 0 015 5v3h1a2 2 0 012 2v7a2 2 0 01-2 2H2a2 2 0 01-2-2v-7a2 2 0 012-2h1V5a5 5 0 015-5zm0 2a3 3 0 00-3 3v3h6V5a3 3 0 00-3-3z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLock
