import classnames from 'classnames/bind'
import * as React from 'react'

import styles from './dialog.module.scss'

const cx = classnames.bind(styles)

type Props = {
  children: React.ReactNode
  className?: string
  noBorder?: boolean
}

export function DialogHeader({ children, className = '', noBorder }: Props) {
  return (
    <header
      className={`${cx('header', { 'no-border': noBorder })} ${className}`}
    >
      {children}
    </header>
  )
}
