import * as React from 'react'
import { SVGProps } from 'react'
const SvgSterlingsignBubble = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 2c5.523 0 10 4.477 10 10a9.954 9.954 0 0 1-1.474 5.228l1.49 3.474a1 1 0 0 1-1.314 1.313l-3.474-1.489A9.954 9.954 0 0 1 12 22C6.477 22 2 17.523 2 12S6.477 2 12 2Zm.5 5-.187.005a3.4 3.4 0 0 0-3.208 3.208L9.1 10.4v.8h-.2l-.113.007a.9.9 0 0 0-.78.78L8 12.1l.007.113a.9.9 0 0 0 .78.78L8.9 13h.199l.001 1.1-.008.145a1.555 1.555 0 0 1-.548 1.009l-.113.08h-.004a.9.9 0 0 0 .36 1.659L8.9 17h6.2l.113-.007a.9.9 0 0 0 0-1.786L15.1 15.2h-4.399c.103-.28.169-.576.19-.875l.009-.225-.001-1.1H13.1l.113-.007a.9.9 0 0 0 .78-.78L14 12.1l-.007-.113a.9.9 0 0 0-.78-.78L13.1 11.2h-2.2l-.001-.801.008-.145a1.6 1.6 0 0 1 1.447-1.447L12.5 8.8c.513 0 .984.243 1.283.643l.084.125a.9.9 0 1 0 1.536-.939 3.398 3.398 0 0 0-2.693-1.623L12.5 7Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgSterlingsignBubble
