import * as React from 'react'
export default function SvgTick(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 20 16">
      <path
        d="M1.76 7.35a1 1 0 10-1.52 1.3l6 7a1 1 0 001.52 0l12-14a1 1 0 10-1.52-1.3L7 13.462 1.76 7.35z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
