var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"daa9c97b2a6c490bb2edb856c4f70c7d6d6854df"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/tabs-monzo-me/daa9c97b2a6c490bb2edb856c4f70c7d6d6854df";

import { browserTracingIntegration } from '@sentry/browser'
import { init as initSentry } from '@sentry/nextjs'
import { format } from 'date-fns'
import getConfig from 'next/config'

import {
  captureException,
  sanitizeBreadcrumb,
  sanitizeEvent,
} from '@mondough/error-handling'

const { publicRuntimeConfig } = getConfig()
const { environment, sentryDSN, sentryOrg, sentryProject } = publicRuntimeConfig
const { NEXT_PUBLIC_GIT_COMMIT } = process.env

const BLOCKLIST: string[] = []
const IGNORE_ERRORS: string[] = []

const bootstrap = () => {
  if (!Boolean(environment)) {
    console.warn('Environment not set')
    return
  }

  if (environment === 'local') {
    console.warn('Sentry not enabled in local environment')
    return
  }

  if (!Boolean(sentryDSN)) {
    console.warn('Sentry DSN environment variable not set')
    return
  }

  if (!Boolean(sentryOrg)) {
    console.warn('Sentry Org environment variable not set')
    return
  }

  if (!Boolean(sentryProject)) {
    console.warn('Sentry Project environment variable not set')
    return
  }

  if (!Boolean(NEXT_PUBLIC_GIT_COMMIT)) {
    console.warn('Git commit not set')
    return
  }

  const releaseTag =
    NEXT_PUBLIC_GIT_COMMIT ?? `local-${format(Date.now(), 'dd-MM-yyyy')}`

  console.info(`Git Commit: ${releaseTag}`)

  initSentry({
    dsn: sentryDSN,
    tracesSampleRate:
      environment !== 'production' ? 1.0 : 0.2 /* set to 1.0 when debugging */,
    integrations: [browserTracingIntegration({ enableInp: true })],
    tracePropagationTargets: [
      // Sentry Defaults
      'localhost',
      /^\//,
      // Shared Tabs Specific
      /^https:\/\/tabs.monzo.me/,
      /^https:\/\/s101.tabs.monzo.me/,
    ],
    release: releaseTag,
    beforeBreadcrumb: sanitizeBreadcrumb(BLOCKLIST),
    // @ts-ignore
    beforeSend: (event, _) => {
      // Group errors by value (error message), regardless of the stack trace
      if (event.exception) {
        const exceptionValue =
          event.exception?.values?.[0]?.value ?? 'undefined'
        event.fingerprint = ['{{ default }}', exceptionValue]
      }
      // @ts-ignore sanitizeEvent expects a SentryEvent
      return sanitizeEvent(BLOCKLIST)(event)
    },
    ignoreErrors: IGNORE_ERRORS,
    environment: environment,
    initialScope: { tags: { component_name: 'app.tabs-monzo-me' } },
  })

  window.onunhandledrejection = (e) => captureException(e.reason)
}

bootstrap()
