import * as React from 'react'
import { SVGProps } from 'react'
const SvgGithub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    fillRule="evenodd"
  >
    <path d="M11.964 0C5.348 0 0 5.27 0 11.792c0 5.212 3.427 9.624 8.18 11.186.595.117.813-.254.813-.566 0-.273-.02-1.21-.02-2.187-3.328.703-4.02-1.405-4.02-1.405-.536-1.367-1.328-1.718-1.328-1.718-1.09-.723.08-.723.08-.723 1.207.078 1.841 1.21 1.841 1.21 1.07 1.797 2.793 1.29 3.487.977.099-.762.416-1.289.752-1.581-2.654-.274-5.447-1.289-5.447-5.818a4.56 4.56 0 0 1 1.228-3.163c-.119-.293-.535-1.503.12-3.123 0 0 1.01-.313 3.287 1.21.975-.258 1.981-.39 2.991-.39s2.04.136 2.991.39c2.278-1.523 3.288-1.21 3.288-1.21.654 1.62.238 2.83.12 3.123.772.82 1.227 1.874 1.227 3.163 0 4.53-2.792 5.525-5.467 5.818.436.37.812 1.073.812 2.186 0 1.581-.02 2.85-.02 3.24 0 .313.219.684.813.567 4.754-1.562 8.18-5.974 8.18-11.186C23.929 5.27 18.56 0 11.965 0Z" />
  </svg>
)
export default SvgGithub
