import * as React from 'react'
import { SVGProps } from 'react'
const SvgAddPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={22}
    height={18}
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 10C18.3281 10 21.8535 12.5778 21.9955 15.7979L22 16C22 17.1046 21.1046 18 20 18H8C6.89543 18 6 17.1046 6 16C6 12.6863 9.5817 10 14 10ZM4 4C4.51284 4 4.93551 4.38604 4.99327 4.88338L5 5V6.999L7 7C7.51284 7 7.93551 7.386 7.99327 7.8834L8 8C8 8.5128 7.61396 8.9355 7.11662 8.9933L7 9H5V11C5 11.5128 4.61396 11.9355 4.11662 11.9933L4 12C3.48716 12 3.06449 11.614 3.00673 11.1166L3 11V9H1C0.48716 9 0.06449 8.614 0.00672996 8.1166L0 8C0 7.4872 0.38604 7.0645 0.88338 7.0067L1 7H3V5C3 4.48716 3.38604 4.06449 3.88338 4.00673L4 4ZM14 0C16.2091 0 18 1.79086 18 4C18 6.20914 16.2091 8 14 8C11.7909 8 10 6.20914 10 4C10 1.79086 11.7909 0 14 0Z"
      fill="#091723"
    />
  </svg>
)
export default SvgAddPerson
