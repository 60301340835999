import * as React from 'react'
export default function SvgBarChart(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 14 18">
      <path
        d="M12.5 9a1.5 1.5 0 011.5 1.5v6a1.5 1.5 0 01-3 0v-6A1.5 1.5 0 0112.5 9zM7 0a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-3 0v-15A1.5 1.5 0 017 0zM1.5 5A1.5 1.5 0 013 6.5v10a1.5 1.5 0 01-3 0v-10A1.5 1.5 0 011.5 5z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
