import * as React from 'react'
import { SVGProps } from 'react'
const SvgDynamicFormBuilder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 317 393"
    fillRule="evenodd"
    fill="currentColor"
  >
    <path d="M0 70C0 31.3401 31.3401 0 70 0H247C285.66 0 317 31.3401 317 70V323C317 361.66 285.66 393 247 393H70C31.3401 393 0 361.66 0 323V70ZM126 353V287H193V353H126ZM56 73L89 40L122 73L89 106L56 73ZM197 143L230 110L263 143L230 176L197 143ZM148.333 110H170.667V132H193V154H170.667V176H148.333V154H126V132H148.333V110ZM240.667 40H218.333V62H196V84H218.333V106H240.667V84H263V62H240.667V40ZM56 143C56 124.775 70.7746 110 89 110C107.225 110 122 124.775 122 143C122 161.225 107.225 176 89 176C70.7746 176 56 161.225 56 143ZM159 40C140.775 40 126 54.7746 126 73C126 91.2254 140.775 106 159 106C177.225 106 192 91.2254 192 73C192 54.7746 177.225 40 159 40ZM159.5 283.5L263 180L159.5 180L56 180L159.5 283.5Z" />
  </svg>
)
export default SvgDynamicFormBuilder
