import * as React from 'react'

import type { SVGProps } from './types'

export default function SvgArrowDown(props: SVGProps): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 16 18">
      <path
        d="m10.2928932 4.29289322c.3905243-.39052429 1.0236893-.39052429 1.4142136 0s.3905243 1.02368927 0 1.41421356l-5.2931068 5.29289322h13.586c.5128358 0 .9355072.3860402.9932723.8833789l.0067277.1166211c0 .5522847-.4477153 1-1 1h-13.586l5.2931068 5.2928932c.3604839.360484.3882135.927715.0831886 1.3200062l-.0831886.0942074c-.3905243.3905243-1.0236893.3905243-1.4142136 0l-6.99999998-7-.07320445-.0816578c-.00231667-.0028866-.00461764-.0057862-.00690281-.0086989-.00550148-.0069077-.01103252-.0141471-.01645138-.0214435-.01784449-.0241759-.03461588-.0490316-.05026715-.0746464-.00746051-.0120471-.0145686-.0242658-.02139626-.0365981-.01087725-.0197682-.02129453-.0401707-.03101739-.060963-.00797473-.0170006-.01545736-.0343035-.02242829-.0517631-.00790975-.0197568-.015103-.0395383-.02167881-.0595996-.00481796-.0148851-.00944895-.0302731-.01370154-.0457434-.00601151-.0215565-.01120534-.0432469-.01567999-.0651989-.00346298-.0174188-.00653707-.0348451-.00914735-.0523272-.00725661-.0481048-.0110178-.0978466-.0110178-.1484669l.00396641.0892501c-.00207143-.0232552-.00333152-.0465691-.00378026-.0698956l-.00018615-.0193545c0-.0064062.00006024-.0127984.00018018-.019176.00041914-.0228438.00168542-.0464891.00378623-.0700741.00179971-.0205969.00414582-.0402457.00705293-.0597111.00260874-.0169878.00568283-.0344141.00922073-.0517646.00439973-.0220203.00959356-.0437107.0154809-.0651138.00437676-.0156237.00900775-.0310117.01401712-.0463029.0063844-.0196552.01357765-.0394367.02136587-.058916.00709246-.0177371.01457509-.03504.0225694-.052163.00970328-.0206699.02012056-.0410724.03120241-.0610555.00662306-.0121174.01373115-.0243361.02111968-.0364318.01572325-.0255662.03249464-.0504219.05031806-.0744505.02899944-.0391663.06118566-.0765534.09657971-.1119475l-.08010726.0903567c.02488288-.0317158.05164095-.0618904.08010726-.0903567z"
        fill="currentColor"
        fillRule="evenodd"
        transform="matrix(0 -1 1 0 -4 21)"
      />
    </svg>
  )
}
