import * as React from 'react'
import { SVGProps } from 'react'
const SvgWorkflows = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1011_2094)">
      <g filter="url(#filter0_d_1011_2094)">
        <path
          d="M68.9729 12.5363C67.3674 13.5707 66.8919 14.4027 66.299 17.2249C65.7116 14.4027 65.2362 13.5707 63.6251 12.5363C65.2306 11.5019 65.706 10.6698 66.299 7.84766C66.8863 10.6698 67.3618 11.5019 68.9729 12.5363Z"
          fill="white"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.351 6.75523C91.8375 8.42205 79.5687 14.9075 65.2161 28.7045C55.2533 38.2816 43.138 43.28 32.0146 44.7397C21.0117 46.1837 10.4978 44.21 4 39.1765L7.86372 34.1888C12.5647 37.8305 21.1648 39.8003 31.1937 38.4842C41.1022 37.1839 51.9365 32.7185 60.8437 24.1561C75.671 9.90287 89.0333 2.5081 100.262 0.540724C111.653 -1.45522 120.834 2.17861 126.445 9.72092C132.463 17.811 132.222 28.3341 126.046 37.9561C119.92 47.5017 107.974 56.2636 90.1695 61.9287C74.6817 66.8566 63.4693 74.3043 56.9839 82.1383C50.4935 89.9782 48.963 97.8307 51.7277 104.196C54.4087 110.368 61.6237 114.352 71.9101 113.604C82.1299 112.861 94.901 107.372 107.179 95.1709C120.214 82.2161 133.418 75.5281 145.6 74.6059C157.892 73.6753 168.712 78.6563 176.647 88.127L171.811 92.1789C165.077 84.1417 156.182 80.132 146.076 80.897C135.86 81.6704 123.985 87.3642 111.626 99.6461C98.5093 112.681 84.3875 119.023 72.3675 119.896C60.414 120.765 50.0422 116.152 45.9408 106.709C41.9231 97.4595 44.6802 87.1065 52.124 78.115C59.5726 69.1176 71.9408 61.1079 88.2565 55.9165C105.152 50.5406 115.645 42.4821 120.737 34.5482C125.78 26.6906 125.539 19.0749 121.382 13.4866C117.372 8.09498 110.701 5.11697 101.351 6.75523Z"
        fill="#FF4F40"
      />
      <path
        d="M7.82397 3.20996H27.8731L35.2338 24.4866H14.6238L7.82397 3.20996Z"
        fill="#A3DC1A"
      />
      <path
        d="M29.3354 7.43711H9.17488L7.82391 3.20996H27.8731L29.3354 7.43711Z"
        fill="#A3DC1A"
      />
      <path
        d="M11.6097 3.20996H31.6588L39.0195 24.4866H18.4095L11.6097 3.20996Z"
        fill="#C3FF34"
      />
      <path
        d="M33.121 7.43711H12.9605L11.6095 3.20996H31.6586L33.121 7.43711Z"
        fill="#A3DC1A"
      />
      <path
        d="M17.8153 5.18234C18.0098 5.70369 17.605 6.16868 17.1144 6.16868C16.6238 6.16868 16.133 5.74596 15.9928 5.2528C15.8526 4.75963 16.1203 4.33691 16.6236 4.33691C17.1269 4.33691 17.605 4.61872 17.8153 5.18234Z"
        fill="#C3FF34"
      />
      <path
        d="M29.0117 5.18234C29.2062 5.70369 28.8013 6.16868 28.3107 6.16868C27.8201 6.16868 27.3293 5.74596 27.1891 5.2528C27.0489 4.75963 27.3166 4.33691 27.8199 4.33691C28.3232 4.33691 28.8014 4.61872 29.0117 5.18234Z"
        fill="#C3FF34"
      />
      <path
        d="M21.5474 5.18234C21.742 5.70369 21.3371 6.16868 20.8465 6.16868C20.3559 6.16868 19.8651 5.74596 19.7249 5.2528C19.5847 4.75963 19.8524 4.33691 20.3557 4.33691C20.859 4.33691 21.3371 4.61872 21.5474 5.18234Z"
        fill="#C3FF34"
      />
      <path
        d="M25.2795 5.18234C25.4741 5.70369 25.0692 6.16868 24.5786 6.16868C24.088 6.16868 23.5972 5.74596 23.457 5.2528C23.3168 4.75963 23.5845 4.33691 24.0878 4.33691C24.5911 4.33691 25.0692 4.61872 25.2795 5.18234Z"
        fill="#C3FF34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.9493 9.83203L39.0189 24.4861H20.3583L15.675 9.83203H33.9493Z"
        fill="#112231"
      />
      <path
        d="M17.9885 8.28223H38.0376L45.3982 29.5589H24.7883L17.9885 8.28223Z"
        fill="#FFD7F0"
      />
      <path
        d="M39.5001 12.5094H19.3395L17.9886 8.28223H38.0377L39.5001 12.5094Z"
        fill="#8CE6D1"
      />
      <path
        d="M24.1945 10.2546C24.3891 10.776 23.9842 11.2409 23.4936 11.2409C23.003 11.2409 22.5122 10.8182 22.372 10.3251C22.2318 9.83189 22.4995 9.40918 23.0028 9.40918C23.5061 9.40918 23.9842 9.69099 24.1945 10.2546Z"
        fill="#2EAFC0"
      />
      <path
        d="M35.3908 10.2546C35.5853 10.776 35.1805 11.2409 34.6899 11.2409C34.1993 11.2409 33.7085 10.8182 33.5683 10.3251C33.4281 9.83189 33.6958 9.40918 34.1991 9.40918C34.7024 9.40918 35.1805 9.69099 35.3908 10.2546Z"
        fill="#2EAFC0"
      />
      <path
        d="M27.9266 10.2546C28.1212 10.776 27.7163 11.2409 27.2257 11.2409C26.7351 11.2409 26.2443 10.8182 26.1041 10.3251C25.9639 9.83189 26.2316 9.40918 26.7349 9.40918C27.2382 9.40918 27.7163 9.69099 27.9266 10.2546Z"
        fill="#2EAFC0"
      />
      <path
        d="M31.6587 10.2546C31.8532 10.776 31.4484 11.2409 30.9578 11.2409C30.4672 11.2409 29.9763 10.8182 29.8361 10.3251C29.6959 9.83189 29.9636 9.40918 30.467 9.40918C30.9703 9.40918 31.4484 9.69099 31.6587 10.2546Z"
        fill="#2EAFC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.378 15.0459L45.3988 29.5591H26.9036L22.2653 15.0459H40.378Z"
        fill="#112231"
      />
      <path
        d="M24.3682 13.0732H44.4174L51.778 34.3499H31.1681L24.3682 13.0732Z"
        fill="#C3FF34"
      />
      <path
        d="M45.8796 17.3004H25.719L24.368 13.0732H44.4172L45.8796 17.3004Z"
        fill="#A3DC1A"
      />
      <path
        d="M30.5741 15.0456C30.7686 15.567 30.3638 16.032 29.8732 16.032C29.3826 16.032 28.8917 15.6092 28.7515 15.1161C28.6113 14.6229 28.879 14.2002 29.3824 14.2002C29.8857 14.2002 30.3638 14.482 30.5741 15.0456Z"
        fill="#C3FF34"
      />
      <path
        d="M41.7704 15.0456C41.9649 15.567 41.5601 16.032 41.0695 16.032C40.5789 16.032 40.088 15.6092 39.9478 15.1161C39.8076 14.6229 40.0753 14.2002 40.5786 14.2002C41.082 14.2002 41.5601 14.482 41.7704 15.0456Z"
        fill="#C3FF34"
      />
      <path
        d="M34.3062 15.0456C34.5007 15.567 34.0959 16.032 33.6053 16.032C33.1147 16.032 32.6239 15.6092 32.4837 15.1161C32.3435 14.6229 32.6112 14.2002 33.1145 14.2002C33.6178 14.2002 34.0959 14.482 34.3062 15.0456Z"
        fill="#C3FF34"
      />
      <path
        d="M38.0383 15.0456C38.2329 15.567 37.828 16.032 37.3374 16.032C36.8468 16.032 36.356 15.6092 36.2158 15.1161C36.0756 14.6229 36.3433 14.2002 36.8466 14.2002C37.3499 14.2002 37.828 14.482 38.0383 15.0456Z"
        fill="#C3FF34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.8543 20.1191L51.7776 34.3505H33.0521L28.5038 20.1191H46.8543Z"
        fill="#112231"
      />
      <path
        d="M30.7472 17.8643H50.7963L58.157 39.1409H37.547L30.7472 17.8643Z"
        fill="#FFD7F0"
      />
      <path
        d="M52.2587 22.0914H32.0982L30.7472 17.8643H50.7963L52.2587 22.0914Z"
        fill="#8CE6D1"
      />
      <path
        d="M36.953 19.8366C37.1475 20.358 36.7427 20.823 36.2521 20.823C35.7615 20.823 35.2707 20.4003 35.1305 19.9071C34.9902 19.4139 35.258 18.9912 35.7613 18.9912C36.2646 18.9912 36.7427 19.273 36.953 19.8366Z"
        fill="#2EAFC0"
      />
      <path
        d="M48.1494 19.8366C48.3439 20.358 47.939 20.823 47.4484 20.823C46.9578 20.823 46.467 20.4003 46.3268 19.9071C46.1866 19.4139 46.4543 18.9912 46.9576 18.9912C47.4609 18.9912 47.939 19.273 48.1494 19.8366Z"
        fill="#2EAFC0"
      />
      <path
        d="M40.6851 19.8366C40.8797 20.358 40.4748 20.823 39.9842 20.823C39.4936 20.823 39.0028 20.4003 38.8626 19.9071C38.7224 19.4139 38.9901 18.9912 39.4934 18.9912C39.9967 18.9912 40.4748 19.273 40.6851 19.8366Z"
        fill="#2EAFC0"
      />
      <path
        d="M44.4172 19.8366C44.6118 20.358 44.2069 20.823 43.7163 20.823C43.2257 20.823 42.7349 20.4003 42.5947 19.9071C42.4545 19.4139 42.7222 18.9912 43.2255 18.9912C43.7288 18.9912 44.2069 19.273 44.4172 19.8366Z"
        fill="#2EAFC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.0876 24.4873L58.1572 39.1415H39.4266L34.7432 24.4873H53.0876Z"
        fill="#112231"
      />
      <path
        d="M37.1266 22.6553H57.1758L64.5364 43.9319H43.9265L37.1266 22.6553Z"
        fill="#C3FF34"
      />
      <path
        d="M58.6381 26.8824H38.4775L37.1265 22.6553H57.1757L58.6381 26.8824Z"
        fill="#A3DC1A"
      />
      <path
        d="M43.3325 24.6277C43.527 25.149 43.1222 25.614 42.6316 25.614C42.141 25.614 41.6502 25.1913 41.51 24.6981C41.3698 24.2049 41.6375 23.7822 42.1408 23.7822C42.6441 23.7822 43.1222 24.064 43.3325 24.6277Z"
        fill="#C3FF34"
      />
      <path
        d="M54.5288 24.6277C54.7233 25.149 54.3185 25.614 53.8279 25.614C53.3373 25.614 52.8465 25.1913 52.7063 24.6981C52.5661 24.2049 52.8338 23.7822 53.3371 23.7822C53.8404 23.7822 54.3185 24.064 54.5288 24.6277Z"
        fill="#C3FF34"
      />
      <path
        d="M47.0646 24.6277C47.2592 25.149 46.8543 25.614 46.3637 25.614C45.8731 25.614 45.3823 25.1913 45.2421 24.6981C45.1019 24.2049 45.3696 23.7822 45.8729 23.7822C46.3762 23.7822 46.8543 24.064 47.0646 24.6277Z"
        fill="#C3FF34"
      />
      <path
        d="M50.7968 24.6277C50.9913 25.149 50.5864 25.614 50.0958 25.614C49.6052 25.614 49.1144 25.1913 48.9742 24.6981C48.834 24.2049 49.1017 23.7822 49.605 23.7822C50.1083 23.7822 50.5864 24.064 50.7968 24.6277Z"
        fill="#C3FF34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9053 30.5449L64.5362 43.931H46.0312L41.7532 30.5449H59.9053Z"
        fill="#112231"
      />
      <path
        d="M43.4356 27.8682H63.4848L70.8454 49.1448H50.2355L43.4356 27.8682Z"
        fill="#FFD7F0"
      />
      <path
        d="M48.8309 36.5973C48.5921 35.9117 49.1011 35.1953 49.8272 35.1953H51.1894C52.0873 35.1953 52.8868 35.7635 53.1821 36.6114C53.4209 37.2971 52.9118 38.0134 52.1857 38.0134H50.8235C49.9257 38.0134 49.1261 37.4452 48.8309 36.5973Z"
        fill="white"
      />
      <path
        d="M50.2329 40.402C49.9941 39.7163 50.5032 39 51.2292 39H52.5914C53.4893 39 54.2888 39.5682 54.5841 40.4161C54.8229 41.1018 54.3138 41.8181 53.5878 41.8181H52.2256C51.3277 41.8181 50.5282 41.2499 50.2329 40.402Z"
        fill="white"
      />
      <path
        d="M51.4947 44.2057C51.2559 43.52 51.765 42.8037 52.4911 42.8037H53.8533C54.7511 42.8037 55.5507 43.3719 55.846 44.2198C56.0847 44.9055 55.5757 45.6218 54.8496 45.6218H53.4874C52.5895 45.6218 51.79 45.0536 51.4947 44.2057Z"
        fill="white"
      />
      <path
        d="M54.5792 36.5973C54.3404 35.9117 54.8495 35.1953 55.5755 35.1953H56.9378C57.8356 35.1953 58.6351 35.7635 58.9304 36.6114C59.1692 37.2971 58.6602 38.0134 57.9341 38.0134H56.5719C55.674 38.0134 54.8745 37.4452 54.5792 36.5973Z"
        fill="white"
      />
      <path
        d="M55.9812 40.402C55.7424 39.7163 56.2515 39 56.9775 39H58.3397C59.2376 39 60.0371 39.5682 60.3324 40.4161C60.5712 41.1018 60.0621 41.8181 59.3361 41.8181H57.9739C57.076 41.8181 56.2765 41.2499 55.9812 40.402Z"
        fill="white"
      />
      <path
        d="M57.243 44.2057C57.0042 43.52 57.5133 42.8037 58.2394 42.8037H59.6016C60.4994 42.8037 61.299 43.3719 61.5942 44.2198C61.833 44.9055 61.324 45.6218 60.5979 45.6218H59.2357C58.3378 45.6218 57.5383 45.0536 57.243 44.2057Z"
        fill="white"
      />
      <path
        d="M60.3275 36.5973C60.0887 35.9117 60.5978 35.1953 61.3238 35.1953H62.686C63.5839 35.1953 64.3834 35.7635 64.6787 36.6114C64.9175 37.2971 64.4084 38.0134 63.6824 38.0134H62.3202C61.4223 38.0134 60.6228 37.4452 60.3275 36.5973Z"
        fill="white"
      />
      <path
        d="M61.7295 40.402C61.4907 39.7163 61.9997 39 62.7258 39H64.088C64.9859 39 65.7854 39.5682 66.0807 40.4161C66.3195 41.1018 65.8104 41.8181 65.0844 41.8181H63.7221C62.8243 41.8181 62.0248 41.2499 61.7295 40.402Z"
        fill="white"
      />
      <path
        d="M64.9471 32.0953H44.7866L43.4356 27.8682H63.4848L64.9471 32.0953Z"
        fill="#8CE6D1"
      />
      <path
        d="M49.6415 29.8405C49.8361 30.3619 49.4312 30.8269 48.9406 30.8269C48.45 30.8269 47.9592 30.4042 47.819 29.911C47.6788 29.4178 47.9465 28.9951 48.4498 28.9951C48.9531 28.9951 49.4312 29.2769 49.6415 29.8405Z"
        fill="#2EAFC0"
      />
      <path
        d="M60.8378 29.8405C61.0324 30.3619 60.6275 30.8269 60.1369 30.8269C59.6463 30.8269 59.1555 30.4042 59.0153 29.911C58.8751 29.4178 59.1428 28.9951 59.6461 28.9951C60.1494 28.9951 60.6275 29.2769 60.8378 29.8405Z"
        fill="#2EAFC0"
      />
      <path
        d="M53.3737 29.8405C53.5682 30.3619 53.1633 30.8269 52.6727 30.8269C52.1821 30.8269 51.6913 30.4042 51.5511 29.911C51.4109 29.4178 51.6786 28.9951 52.1819 28.9951C52.6852 28.9951 53.1634 29.2769 53.3737 29.8405Z"
        fill="#2EAFC0"
      />
      <path
        d="M57.1057 29.8405C57.3002 30.3619 56.8954 30.8269 56.4048 30.8269C55.9142 30.8269 55.4234 30.4042 55.2832 29.911C55.143 29.4178 55.4107 28.9951 55.914 28.9951C56.4173 28.9951 56.8954 29.2769 57.1057 29.8405Z"
        fill="#2EAFC0"
      />
      <path
        d="M27.7614 38.8128C28.8836 38.7461 30.029 38.6375 31.1937 38.4847C41.1022 37.1844 51.9365 32.719 60.8437 24.1566C65.1583 20.009 69.3488 16.4422 73.3988 13.4209L75.1802 18.5444H77.0819C73.3102 21.3645 69.3495 24.7316 65.216 28.705C55.2532 38.2821 43.1379 43.2805 32.0146 44.7402C31.2555 44.8398 30.4988 44.9232 29.7455 44.9904L27.7614 38.8128Z"
        fill="#FF4F40"
      />
      <path
        d="M110.155 101.077L109.281 98.3554H103.796C104.926 97.3494 106.055 96.2885 107.179 95.1714C120.214 82.2166 133.418 75.5285 145.6 74.6064C146.088 74.5694 146.574 74.5417 147.058 74.5233L149.245 80.8143C148.203 80.789 147.146 80.8165 146.076 80.8975C135.86 81.6709 123.985 87.3647 111.626 99.6466C111.137 100.132 110.647 100.609 110.155 101.077Z"
        fill="#FF4F40"
      />
      <g filter="url(#filter1_d_1011_2094)">
        <path
          d="M40.4611 46.1101C35.8216 44.9146 35.374 44.4427 34.3775 39.5635C33.2778 44.4879 32.8181 44.9484 28.2938 46.1101C29.5905 46.371 30.5731 46.5907 31.3326 46.8744C32.2139 47.2038 32.7946 47.6204 33.2156 48.2883C33.7737 49.1737 34.0517 50.5012 34.3775 52.6562C35.1439 48.3848 35.6033 47.3358 38.3789 46.5771C38.917 46.4302 39.542 46.2938 40.2728 46.1473C40.335 46.1351 40.3976 46.1223 40.4611 46.1096V46.1101Z"
          fill="white"
        />
        <path
          d="M34.9444 49.9804C34.5629 49.9356 34.1858 49.8571 33.8174 49.7471C33.8604 49.9023 33.9018 50.0655 33.9418 50.2374C34.235 50.3132 34.5334 50.3694 34.8335 50.4071C34.8696 50.2589 34.9066 50.1168 34.9444 49.9804Z"
          fill="#2EAFC0"
        />
        <path
          d="M32.1584 44.6592C32.0171 44.7649 31.8648 44.8635 31.6995 44.9561C31.6834 45.2275 31.6725 45.499 31.6673 45.7687C31.6591 46.1858 31.666 46.6028 31.6873 47.0194C31.8478 47.0909 31.9966 47.1672 32.1358 47.2492C32.0801 46.387 32.088 45.5205 32.1589 44.6596L32.1584 44.6592Z"
          fill="#2EAFC0"
        />
        <path
          d="M29.3813 45.821C29.3408 45.9846 29.3017 46.1482 29.2643 46.3126C29.41 46.3442 29.5514 46.3758 29.688 46.4069C29.7441 46.1639 29.8037 45.9219 29.8659 45.6807C29.7114 45.7271 29.5501 45.7736 29.3817 45.821H29.3813Z"
          fill="#2EAFC0"
        />
        <path
          d="M34.243 45.0273C34.6649 44.5457 35.2026 44.1103 35.8163 43.934C35.7271 43.8142 35.6436 43.6866 35.5649 43.5498C34.7119 43.8335 34.022 44.5374 33.5122 45.2575C33.0455 45.9166 32.6775 46.6516 32.386 47.4107C32.5135 47.5011 32.6318 47.598 32.741 47.7033C32.7584 47.6568 32.7758 47.6103 32.7936 47.5638C33.1416 46.6617 33.604 45.7561 34.243 45.0273Z"
          fill="#2EAFC0"
        />
        <path
          d="M35.8243 46.6354C36.1627 46.0447 36.465 45.4119 36.6968 44.7593C36.5676 44.6738 36.4476 44.5839 36.3349 44.4883C36.2201 44.8102 36.0874 45.1237 35.953 45.4228C35.5955 46.2188 35.15 46.9836 34.6059 47.6651C34.2718 48.083 33.9021 48.4746 33.4954 48.8215C33.5545 48.9557 33.6098 49.0983 33.6624 49.2495C33.792 49.1434 33.9186 49.0333 34.0417 48.9189C34.7498 48.2606 35.3432 47.4752 35.8243 46.6358V46.6354Z"
          fill="#2EAFC0"
        />
        <path
          d="M38.6918 46.4956C38.6748 46.1925 38.64 45.8851 38.5787 45.5847C38.406 45.5299 38.242 45.4746 38.0859 45.4189C38.1877 45.8053 38.2446 46.2061 38.2651 46.6092C38.4012 46.5706 38.5435 46.5328 38.6914 46.4956H38.6918Z"
          fill="#2EAFC0"
        />
        <path
          d="M34.5954 40.5951C34.5633 40.4504 34.5311 40.3009 34.4984 40.1465C34.4128 40.1465 34.3275 40.1491 34.2431 40.1561C34.207 40.3127 34.1713 40.4644 34.1361 40.6109C34.2884 40.592 34.4432 40.5877 34.5959 40.5951H34.5954Z"
          fill="#2EAFC0"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1011_2094"
        x="63.6251"
        y="7.84766"
        width="5.34772"
        height="10.377"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1011_2094"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1011_2094"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_1011_2094"
        x="28.2938"
        y="39.5635"
        width="53.9802"
        height="79.1924"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1011_2094"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1011_2094"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_1011_2094">
        <rect width="80" height="53.6" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgWorkflows
