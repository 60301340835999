import classnames from 'classnames/bind'
import React from 'react'

import Icon, { IconSizes, Icons } from '../icon/icon'
import {
  Colors,
  FillColorVariables,
  SpacingProps,
  TextColorVariables,
} from '../shared-types'
import styles from './filled-icon.module.scss'

const cx = classnames.bind(styles)

type FilledIconSize = 'small' | 'medium' | 'large'

export type FilledIconProps = {
  icon: Icons
  size?: FilledIconSize
  background?: FillColorVariables
  color?: TextColorVariables | Colors
} & SpacingProps

const sizes: Record<FilledIconSize, IconSizes> = {
  small: 24,
  medium: 32,
  large: 48,
}

/**
 * Extends the `<Icon/>` component so that it has a background colour
 */
export default function FilledIcon({
  icon,
  size = 'medium',
  background = 'primary',
  color,
  ...spacingProps
}: FilledIconProps) {
  const className = cx([
    'filled-icon',
    `size-${size}`,
    `background-${background}`,
  ])
  return (
    <Icon
      icon={icon}
      size={sizes[size]}
      className={className}
      color={color ?? 'white'}
      {...spacingProps}
    />
  )
}
