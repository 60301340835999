import * as React from 'react'
export default function SvgCallSettings(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9567 21C18.203 21 19.297 20.5628 20.1723 19.5935C20.686 19.0233 21 18.3675 21 17.7117C21 17.208 20.8002 16.7138 20.315 16.3718L17.3943 14.2999C16.9281 13.9768 16.5476 13.8247 16.2051 13.8247C15.7579 13.8247 15.3584 14.0718 14.9207 14.509L14.2357 15.1932C14.1215 15.2978 13.9883 15.3453 13.8647 15.3453C13.7125 15.3453 13.5697 15.2883 13.4746 15.2313C12.8752 14.9082 11.8478 14.0338 10.8964 13.0835C9.94502 12.1331 9.06024 11.1162 8.7463 10.508C8.68921 10.4034 8.64164 10.2703 8.64164 10.1183C8.64164 10.0042 8.6797 9.8712 8.78435 9.75714L9.46933 9.06337C9.89746 8.6167 10.1544 8.22705 10.1544 7.78037C10.1544 7.42874 10.0021 7.04858 9.67864 6.59241L7.61416 3.70328C7.26215 3.20909 6.76744 3 6.22516 3C5.57822 3 4.93129 3.28511 4.36998 3.82682C3.41861 4.72017 3 5.83211 3 7.05809C3 9.84269 5.14058 13.2165 7.94714 16.02C10.7537 18.8332 14.1692 21 16.9567 21Z"
        fill="currentColor"
      />
    </svg>
  )
}
