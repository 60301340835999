import cx from 'classnames'
import React from 'react'
import { v4 as uuid } from 'uuid'

import { mergeRefs } from '@mondough/utils'

import { SpacingProps } from '../shared-types'
import Spacer from '../spacer'
import { filterSpacingProps } from '../spacer/utils'
import styles from './checkbox.module.scss'

export type TProps = {
  label?: React.ReactNode
  invalid?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & React.InputHTMLAttributes<HTMLInputElement> &
  SpacingProps

const Checkmark = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 13h1l7-11-1-1h-1L5 11 2 7 1 6 0 7v1l4 5h1z"
      fill="currentColor"
    />
  </svg>
)

const Checkbox = React.forwardRef<HTMLInputElement, TProps>((props, ref) => {
  const {
    label,
    checked,
    disabled,
    invalid,
    onChange,
    className,
    // If no id is passed, a unique ID will be generated to be able to link the
    // label and checkbox via the 'for' prop. Note that this will be
    // regenerated at every render.
    id = uuid(),
    ...otherProps
  } = props

  // Track the checked state internally for situations when the checkbox is uncontrolled
  const innerRef = React.useRef<HTMLInputElement>(null)
  const [isChecked, setIsChecked] = React.useState(checked ?? false)
  const { spacingProps, restProps } = filterSpacingProps(otherProps)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
    if (onChange != null) {
      onChange(event)
    }
  }

  // There is an edge case where the parent can use the input's ref to set the checked state on mount without the onChange handler being called in time – this leads to the checkbox background showing as checked but the check symbol not appearing. On mount, this effect looks to see if the input is checked and sets the internal checked state to match this.
  React.useLayoutEffect(() => {
    if (innerRef.current != null && innerRef.current.checked) {
      setIsChecked(true)
    }
  }, [])

  // Likewise, there is an edge case where changing the `checked` prop at runtime will fail to display the check symbol.
  React.useLayoutEffect(() => {
    if (innerRef.current != null && checked != null) {
      setIsChecked(checked)
    }
  }, [checked])

  return (
    <Spacer
      is="label"
      htmlFor={id}
      marginTop="small"
      className={styles['checkbox-wrapper']}
      {...spacingProps}
    >
      <input
        type="checkbox"
        checked={checked}
        className={styles.hiddenCheckbox}
        disabled={disabled}
        onChange={handleChange}
        id={id}
        ref={mergeRefs([ref, innerRef])}
        {...restProps}
      />

      <div
        className={cx(styles.checkbox, className, {
          [styles.invalid]: invalid,
        })}
      >
        {isChecked && <Checkmark />}
      </div>
      {label != null && <span className={styles.label}>{label}</span>}
    </Spacer>
  )
})

export default Checkbox
