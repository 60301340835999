import * as React from 'react'
import { SVGProps } from 'react'
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12 2c5.5228475 0 10 4.4771525 10 10s-4.4771525 10-10 10-10-4.4771525-10-10 4.4771525-10 10-10zm0 9c-.5522847 0-1 .4477153-1 1v4c0 .5522847.4477153 1 1 1s1-.4477153 1-1v-4c0-.5522847-.4477153-1-1-1zm0-4c-.5522847 0-1 .44771525-1 1s.4477153 1 1 1 1-.44771525 1-1-.4477153-1-1-1z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgInfo
