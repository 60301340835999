import * as React from 'react'
import { SVGProps } from 'react'
const SvgUs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_686_351)">
      <path
        d="M56.8941 12.2062C55.8266 8.17121 46.0267 5.24991 29.5825 1.04094L26.0903 28.0316C42.5345 32.2406 52.3344 35.1619 53.4019 39.1968C53.4741 39.4696 53.4957 39.6981 53.4755 39.8901L53.4681 39.9475C53.471 39.9288 53.4735 39.9096 53.4755 39.8901L56.9603 12.9569C56.9917 12.7535 56.9736 12.5066 56.8941 12.2062Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.5199 16.4076C54.0123 11.7898 52.1564 10.8343 48.5305 9.30962C45.4671 8.02147 35.44 5.36771 29.2171 3.79781L29.5729 1.04315C46.0244 5.25649 55.8288 8.1803 56.898 12.2146C56.9777 12.5151 56.9959 12.7618 56.9645 12.9652L56.5199 16.4076ZM55.7304 22.5205L56.3137 18.0046C54.6642 14.815 51.847 12.6196 48.8341 11.417C45.296 10.0048 34.4139 6.85325 28.9884 5.56886L28.7107 7.71881C34.6685 9.24766 45.0086 12.3423 48.2733 13.7151C51.6905 15.1521 53.4613 17.8256 55.7304 22.5205ZM55.2366 26.3443L55.6657 23.0217C54.3581 19.8096 51.8972 17.3406 48.6902 16.0606C45.2088 14.6711 34.0221 11.1608 28.4437 9.78683L28.1652 11.9427C34.2397 13.5523 44.9121 17.0058 48.1294 18.3587C51.6823 19.8527 53.1897 21.5712 55.2366 26.3443ZM53.8975 36.7125L54.2969 33.6204C52.8898 31.3525 50.7656 29.6335 48.15 28.5895C44.4474 27.1117 32.0293 23.2351 26.9115 22.0809L26.8036 22.4856L26.5819 24.2022C32.3892 25.6583 44.1785 29.4533 47.5892 30.8875C50.6572 32.1776 52.2098 33.401 53.8975 36.7125ZM26.3409 26.0687C31.5393 27.2596 43.7731 31.0815 47.4433 32.5464C50.1607 33.631 52.3479 35.4443 53.7513 37.8448L53.4871 39.8909C53.485 39.9104 53.4825 39.9295 53.4796 39.9483L53.4871 39.8909C53.5071 39.6989 53.4854 39.4704 53.4132 39.1977C52.344 35.1634 42.5396 32.2396 26.088 28.0262L26.3409 26.0687ZM27.11 20.1131C33.028 21.6288 44.4252 25.3041 47.7691 26.7102C51.0089 28.0725 52.5589 29.3605 54.3626 33.1117L54.7715 29.9451C53.3753 27.428 51.1352 25.5318 48.3299 24.4122C44.6967 22.962 32.6714 19.2022 27.3866 17.9712L27.11 20.1131ZM27.8852 14.1112L27.6139 16.2113C33.5642 17.7346 44.6363 21.1419 47.9495 22.5351C51.3401 23.9608 52.8677 25.591 54.7793 29.8854L55.2082 26.5641C53.8458 23.6613 51.5028 21.4315 48.5102 20.237C44.9659 18.8224 33.36 15.4126 27.8852 14.1112Z"
        fill="#D80027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.7588 6.99468L47.4145 22.3067C40.9933 19.9713 34.0541 18.109 27.8089 16.4794C28.0653 13.2216 28.4348 11.1014 28.8812 8.53944C29.2232 6.57645 29.6104 4.35418 30.0266 1.1623C38.4931 3.33594 45.1674 5.16868 49.7588 6.99468Z"
        fill="#2E52B2"
      />
      <path
        d="M47.073 8.71233L46.8528 7.77497L46.5364 8.53454L45.6805 8.25094L46.3385 9.01096L46.0152 9.77512L46.7305 9.47014L47.3886 10.2302L47.1945 9.29456L47.9161 8.9917L47.073 8.71233Z"
        fill="white"
      />
      <path
        d="M43.4753 7.60344L43.2552 6.66608L42.9388 7.42566L42.0828 7.14205L42.7409 7.90207L42.4176 8.66623L43.1329 8.36125L43.7909 9.12127L43.5968 8.18567L44.3185 7.88281L43.4753 7.60344Z"
        fill="white"
      />
      <path
        d="M39.8611 6.47112L39.6409 5.53376L39.3245 6.29333L38.4686 6.00973L39.1266 6.76975L38.8033 7.53391L39.5186 7.22893L40.1767 7.98894L39.9826 7.05335L40.7042 6.75049L39.8611 6.47112Z"
        fill="white"
      />
      <path
        d="M36.2532 5.3051L36.033 4.36774L35.7166 5.12732L34.8607 4.84371L35.5187 5.60373L35.1954 6.36789L35.9107 6.06291L36.5688 6.82293L36.3747 5.88733L37.0963 5.58447L36.2532 5.3051Z"
        fill="white"
      />
      <path
        d="M32.6477 4.20647L32.4275 3.26911L32.1111 4.02869L31.2552 3.74508L31.9132 4.5051L31.59 5.26926L32.3052 4.96428L32.9633 5.7243L32.7692 4.7887L33.4908 4.48584L32.6477 4.20647Z"
        fill="white"
      />
      <path
        d="M46.6677 11.957L46.4476 11.0196L46.1311 11.7792L45.2752 11.4956L45.9333 12.2556L45.61 13.0197L46.3253 12.7148L46.9833 13.4748L46.7892 12.5392L47.5109 12.2363L46.6677 11.957Z"
        fill="white"
      />
      <path
        d="M43.0696 10.8486L42.8494 9.9112L42.533 10.6708L41.6771 10.3872L42.3351 11.1472L42.0118 11.9113L42.7271 11.6064L43.3852 12.3664L43.1911 11.4308L43.9127 11.1279L43.0696 10.8486Z"
        fill="white"
      />
      <path
        d="M39.4558 9.71574L39.2356 8.77838L38.9192 9.53796L38.0633 9.25436L38.7214 10.0144L38.3981 10.7785L39.1133 10.4736L39.7714 11.2336L39.5773 10.298L40.2989 9.99512L39.4558 9.71574Z"
        fill="white"
      />
      <path
        d="M35.8474 8.54973L35.6273 7.61237L35.3108 8.37195L34.4549 8.08834L35.113 8.84836L34.7897 9.61252L35.5049 9.30754L36.163 10.0676L35.9689 9.13196L36.6906 8.8291L35.8474 8.54973Z"
        fill="white"
      />
      <path
        d="M32.2419 7.4511L32.0218 6.51374L31.7054 7.27331L30.8494 6.98971L31.5075 7.74973L31.1842 8.51389L31.8995 8.20891L32.5575 8.96892L32.3634 8.03333L33.0851 7.73047L32.2419 7.4511Z"
        fill="white"
      />
      <path
        d="M46.2624 15.2016L46.0423 14.2642L45.7259 15.0238L44.8699 14.7402L45.528 15.5002L45.2047 16.2644L45.92 15.9594L46.578 16.7194L46.3839 15.7838L47.1056 15.481L46.2624 15.2016Z"
        fill="white"
      />
      <path
        d="M42.6643 14.0932L42.4441 13.1558L42.1277 13.9154L41.2718 13.6318L41.9298 14.3918L41.6066 15.156L42.3218 14.851L42.9799 15.611L42.7858 14.6754L43.5074 14.3726L42.6643 14.0932Z"
        fill="white"
      />
      <path
        d="M39.0505 12.9604L38.8304 12.023L38.514 12.7826L37.658 12.499L38.3161 13.259L37.9928 14.0232L38.7081 13.7182L39.3661 14.4782L39.172 13.5426L39.8937 13.2397L39.0505 12.9604Z"
        fill="white"
      />
      <path
        d="M35.4421 11.7944L35.222 10.857L34.9056 11.6166L34.0496 11.333L34.7077 12.093L34.3844 12.8571L35.0997 12.5522L35.7577 13.3122L35.5636 12.3766L36.2853 12.0737L35.4421 11.7944Z"
        fill="white"
      />
      <path
        d="M31.8366 10.6957L31.6165 9.75836L31.3001 10.5179L30.4442 10.2343L31.1022 10.9944L30.7789 11.7585L31.4942 11.4535L32.1523 12.2136L31.9581 11.278L32.6798 10.9751L31.8366 10.6957Z"
        fill="white"
      />
      <path
        d="M45.8567 18.4452L45.6365 17.5079L45.3201 18.2675L44.4642 17.9839L45.1222 18.7439L44.7989 19.508L45.5142 19.203L46.1723 19.9631L45.9782 19.0275L46.6998 18.7246L45.8567 18.4452Z"
        fill="white"
      />
      <path
        d="M42.2575 17.3363L42.0374 16.399L41.721 17.1586L40.8651 16.875L41.5231 17.635L41.1998 18.3991L41.9151 18.0942L42.5732 18.8542L42.379 17.9186L43.1007 17.6157L42.2575 17.3363Z"
        fill="white"
      />
      <path
        d="M38.6433 16.2035L38.4231 15.2662L38.1067 16.0258L37.2508 15.7422L37.9089 16.5022L37.5856 17.2663L38.3008 16.9614L38.9589 17.7214L38.7648 16.7858L39.4864 16.4829L38.6433 16.2035Z"
        fill="white"
      />
      <path
        d="M35.0364 15.038L34.8162 14.1006L34.4998 14.8602L33.6439 14.5766L34.3019 15.3366L33.9786 16.1008L34.6939 15.7958L35.352 16.5558L35.1579 15.6202L35.8795 15.3174L35.0364 15.038Z"
        fill="white"
      />
      <path
        d="M31.4309 13.9394L31.2107 13.002L30.8943 13.7616L30.0384 13.478L30.6964 14.238L30.3732 15.0022L31.0884 14.6972L31.7465 15.4572L31.5524 14.5216L32.274 14.2187L31.4309 13.9394Z"
        fill="white"
      />
      <path
        d="M49.6196 38.3112L52.606 15.008C52.8596 13.2843 56.6913 14.6787 56.958 12.9546L53.9716 36.2578C53.7049 37.9819 49.8732 36.5876 49.6196 38.3112Z"
        fill="#112231"
      />
      <path
        d="M52.6025 15.0062C52.6006 15.0191 52.5989 15.0323 52.5974 15.0456L49.0686 42.2664C49.0307 42.5242 49.0728 42.8518 49.2189 43.2701C50.377 46.5867 55.2355 48.7552 68.2566 53.8527C69.8721 42.7352 70.3336 39.2523 72.1803 26.7451C58.8661 21.5379 53.9223 19.3594 52.7527 16.0098C52.614 15.6127 52.569 15.2972 52.5974 15.0456L52.6025 15.0062Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71.238 33.1635C71.3415 32.4542 71.4497 31.7136 71.5639 30.9349C64.9734 28.4737 56.5476 24.6729 53.7373 22.5459C52.9155 21.9239 52.3132 21.2871 51.871 20.6553L51.4982 23.5306C51.9813 24.0711 52.6618 24.6696 53.6177 25.3294C56.4308 27.271 64.8882 30.7293 71.238 33.1635ZM70.6549 37.182C70.7587 36.4631 70.8663 35.7196 70.9795 34.9403C64.2729 32.504 55.1411 28.4292 52.1922 26.1973C51.842 25.9322 51.5316 25.6644 51.2564 25.3954L50.9062 28.0966C51.2403 28.3782 51.6265 28.6728 52.0727 28.9808C55.0304 31.0222 64.228 34.7404 70.6549 37.182ZM70.0979 41.055C70.2041 40.3144 70.3111 39.5688 70.4212 38.8031C63.6752 36.3937 54.135 32.1614 51.1083 29.8706C50.9717 29.7673 50.8413 29.6636 50.7166 29.5595L50.373 32.2094C50.5644 32.3544 50.7693 32.5027 50.9887 32.6541C54.0283 34.7521 63.6579 38.6208 70.0979 41.055ZM69.4468 45.6012C69.5614 44.8019 69.672 44.0294 69.7811 43.2675C62.5232 40.5495 53.7577 36.6668 50.9088 34.5106C50.6341 34.3027 50.3839 34.0932 50.1561 33.8827L49.8085 36.5635C50.098 36.7981 50.4235 37.0416 50.7892 37.2941C53.6811 39.2901 62.1934 42.8854 69.4468 45.6012ZM68.8341 49.8623C68.9508 49.054 69.0614 48.2859 69.1675 47.5476C61.9339 44.7854 53.4891 41.0559 50.6614 38.9261C50.2734 38.6339 49.9254 38.349 49.613 38.0714L49.2811 40.6316C49.6797 40.9285 50.1372 41.2553 50.662 41.6175C53.5277 43.5955 61.6762 47.1111 68.8341 49.8623ZM68.554 51.8004C68.459 52.4562 68.3599 53.1392 68.2561 53.8537C55.2356 48.7564 50.3772 46.5879 49.2192 43.2713C49.0731 42.8531 49.0311 42.5255 49.069 42.2676L49.115 41.9127C49.5114 42.3921 50.0078 42.8716 50.63 43.3425C53.4588 45.4835 61.5622 49.1569 68.554 51.8004ZM52.0917 18.9526C52.56 19.6324 53.1913 20.3074 54.0393 20.9303C57.2179 23.2653 65.5511 26.8233 71.808 29.2718C71.9258 28.471 72.0496 27.6309 72.1803 26.7456C58.8666 21.5387 53.923 19.3602 52.7535 16.0107C52.6148 15.6135 52.5698 15.2981 52.5982 15.0465L52.0917 18.9526ZM52.6033 15.007C52.6014 15.02 52.5997 15.0332 52.5982 15.0465L52.6033 15.007Z"
        fill="#D80027"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7302 22.9691L26.0811 28.0129C35.7999 30.5047 43.2009 32.5459 47.8549 34.6298C46.8737 31.2955 43.3564 26.6345 31.9046 24.062C29.9979 23.6337 28.2784 23.2723 26.7302 22.9691Z"
        fill="#112231"
      />
      <path
        d="M13.533 30.5366C12.9714 26.727 15.3694 25.8063 18.9419 25.4511C28.782 24.1622 40.7648 28.1568 40.7373 33.8767C40.6995 41.7341 35.0687 47.7015 28.0842 45.2049C24.2704 43.8417 19.4161 43.0181 15.7286 39.9361C14.3638 38.7953 13.4338 36.0689 15.2692 34.9113C14.773 34.1716 14.8767 33.2837 15.0127 32.9333C14.4987 32.5501 13.6657 31.4364 13.533 30.5366Z"
        fill="#ED5E11"
      />
      <path
        d="M9.33725 43.3644C11.3766 41.6812 15.7474 39.58 19.1516 39.0376C25.437 37.7477 30.1702 39.0588 30.7456 41.7672C31.0972 43.4224 29.4946 45.4551 26.1618 44.5721C22.829 43.6892 13.6268 46.4392 11.2953 46.8572C8.96377 47.2753 7.2979 45.0475 9.33725 43.3644Z"
        fill="#ED5E11"
      />
      <path
        d="M47.2412 42.422C46.0112 35.0362 41.182 28.5402 36.529 25.6256C31.5214 22.693 23.5116 28.169 22.4141 34.2005C21.5345 39.0339 24.0778 43.5732 28.0841 45.2047C29.8742 45.9337 32.7401 48.0599 33.9335 49.0729C36.4215 49.0796 42.5662 47.7588 47.2412 42.422Z"
        fill="#ED5E11"
      />
      <path
        d="M47.2412 42.4215C46.0112 35.0357 40.136 28.1295 35.483 25.2149C30.0854 22.6613 23.5116 28.1685 22.414 34.2C21.5345 39.0334 24.0777 43.5727 28.084 45.2042C29.8742 45.9332 32.74 48.0594 33.9335 49.0724C36.4215 49.0791 42.5662 47.7583 47.2412 42.4215Z"
        fill="#ED5E11"
      />
      <path
        d="M27.6201 26.0683C25.6463 28.2192 23.0341 36.0181 31.6143 38.5954C35.1315 39.6518 37.9964 38.918 39.921 35.9787C42.1381 31.9824 40.0319 28.2714 37.9027 26.5881C32.8337 22.5807 29.5 24.0198 27.6201 26.0683Z"
        fill="#ED5E11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1457 44.4048L24.5464 41.55L24.7593 44.403C24.5626 44.3983 24.3577 44.399 24.1457 44.4048Z"
        fill="black"
      />
      <path
        d="M30.5664 1.29085C30.2434 1.20787 29.9178 1.12439 29.5896 1.0404L28.9176 0.816895L21.8036 50.6313L23.3075 50.9011L30.5664 1.29085Z"
        fill="#2EAFC0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6677 41.6017L27.5592 21.84L25.9603 21.5217L22.9826 42.3726L24.6677 41.6017Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3393 36.1345C24.7671 35.9891 23.7979 36.2389 22.984 36.4666L23.0316 36.6385C23.8401 36.4123 24.5368 36.3203 25.0615 36.4537C25.3215 36.5197 25.5376 36.6407 25.7056 36.8274C25.874 37.0146 25.9989 37.2732 26.0674 37.622C26.1496 38.0412 25.9484 38.6428 25.4776 39.257C25.0101 39.8668 24.2882 40.4731 23.3624 40.8985C21.5149 41.7474 18.8511 41.8774 15.7775 39.861L15.7725 39.9609C18.7954 42.5915 21.7563 42.1057 23.6624 41.2299C25.2996 40.4776 28.1135 37.5252 25.3393 36.1345Z"
        fill="black"
      />
      <path
        d="M26.1542 37.6044C25.871 36.1611 24.63 36.0984 23.0076 36.5522C20.3364 36.6553 15.1409 37.4764 15.7288 39.9354C21.9239 43.9997 26.5128 39.4318 26.1542 37.6044Z"
        fill="#ED5E11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7312 32.7489L23.7223 32.9271C24.1091 32.9467 24.548 33.0557 24.9485 33.2396C25.3496 33.4237 25.7057 33.6798 25.9333 33.9887C26.1592 34.2952 26.2586 34.6528 26.1534 35.0522C26.0471 35.4557 25.7287 35.9148 25.0897 36.4116C23.82 37.3987 21.8742 37.7448 19.9927 37.4725C18.1116 37.2003 16.317 36.3132 15.343 34.8614L15.2694 34.9113C15.9071 36.3725 18.0438 37.5576 20.0996 37.8348C22.1553 38.1121 25.8794 37.5025 26.5197 35.0708C26.7836 33.3498 24.8972 32.8079 23.7312 32.7489Z"
        fill="black"
      />
      <path
        d="M25.1444 36.4835C27.7335 34.4708 25.3228 32.92 23.7272 32.8393L15.4739 34.387L15.2698 34.9125C17.2546 37.8709 22.5554 38.4961 25.1444 36.4835Z"
        fill="#ED5E11"
      />
      <path
        d="M33.2317 24.029C41.9446 27.5061 38.5304 31.7991 35.9414 33.8118C33.3523 35.8244 25.9711 34.9898 25.6943 32.5792C25.4468 30.4232 25.6776 29.3631 24.4633 28.7659C21.2113 27.1663 24.2558 20.4469 33.2317 24.029Z"
        fill="#ED5E11"
      />
      <path
        d="M23.7388 21.943C26.3982 22.0775 29.5626 22.7733 31.6633 23.4154C37.4911 25.2684 34.2929 28.8719 29.0569 29.3248C27.6911 29.4429 26.1776 29.0271 24.5125 28.4994C20.7269 27.2998 20.5935 27.5492 17.6386 26.6362C17.0932 26.4676 16.6417 26.2206 16.2493 25.9853C13.3209 24.2292 14.1907 21.5968 15.7698 21.3616C17.5605 21.0948 18.5944 21.683 23.7388 21.943Z"
        fill="#ED5E11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6994 26.4687C17.1724 26.2761 16.4605 26.0719 16.0788 25.843L16.2472 26.0302C18.6668 27.4029 20.6856 27.3816 24.65 28.5985C25.2509 28.7829 26.4409 29.3007 26.8923 29.9622C27.6318 31.0457 27.431 36.9558 33.112 40.6369C29.3544 37.5889 28.5948 34.6079 27.9986 32.898C27.9821 32.8506 27.9657 32.8035 27.9494 32.7565C27.67 31.9531 27.4377 30.6441 26.9751 29.9664C26.4204 29.1535 25.6787 28.8201 24.6589 28.4203C23.1097 27.8128 19.9692 27.2982 17.6994 26.4687Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5615 21.796C21.9508 22.3829 21.4838 23.1542 19.9433 23.1795C17.6796 23.2166 15.8564 22.1188 16.4656 21.2958C17.1181 21.2734 17.7555 21.3565 18.6655 21.4752C19.4017 21.5712 20.3162 21.6905 21.5615 21.796Z"
        fill="#FFA9DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6112 27.1699C19.0098 27.9633 19.8645 28.1426 21.6337 28.3554C24.1937 28.6634 25.4814 29.1296 26.1231 29.6379C26.4408 29.8895 26.5993 30.1509 26.6771 30.4102C26.7557 30.6722 26.7538 30.9379 26.7405 31.2039C26.6901 32.2108 25.7145 33.7063 22.4029 34.1119C21.3298 34.2433 17.7868 34.5862 15.0595 32.8577L15.0121 32.9331C16.882 34.6353 21.3229 34.6881 22.3958 34.5568C25.7496 34.1461 27.127 32.3575 27.1837 31.2263C27.4947 28.5626 23.4483 28.3046 21.6532 28.0887C19.8889 27.8765 18.8344 27.589 17.6112 27.1699Z"
        fill="black"
      />
      <path
        d="M22.4139 34.201C25.7466 33.7929 26.7758 32.278 26.8293 31.209C26.8829 30.1399 26.7679 28.8837 21.6444 28.2675C19.8777 28.055 18.2938 27.7408 16.9111 26.9564L15.0123 32.9337C17.7687 34.6807 21.3409 34.3324 22.4139 34.201Z"
        fill="#ED5E11"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6532 31.9282C26.6687 31.1406 26.201 30.4553 24.9061 30.5749C22.7728 30.7719 22.0343 33.2507 23.3334 34.0478C25.3305 33.6257 26.2913 32.7487 26.6532 31.9282Z"
        fill="#FFA9DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8357 35.8198C25.6627 36.0332 25.4346 36.2539 25.1432 36.4805C24.5371 36.9516 23.7825 37.2782 22.9565 37.4641C22.0234 36.9109 22.4695 35.3353 23.9827 34.8139C25.1766 34.4026 25.8949 34.9723 25.8357 35.8198Z"
        fill="#FFA9DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4421 40.3711C25.3081 39.7445 25.8825 38.974 26.0889 38.3197C25.9843 37.7017 25.4076 37.3487 24.5238 37.6883C22.9756 38.283 22.7234 40.2893 24.2379 40.3659C24.3092 40.3695 24.3772 40.3712 24.4421 40.3711Z"
        fill="#FFA9DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5908 21.8761C22.9488 21.9002 23.3302 21.9228 23.7372 21.9434C26.3966 22.0779 29.561 22.7737 31.6617 23.4158C32.158 23.5736 32.5888 23.7441 32.9585 23.9242C33.0488 23.9586 33.1398 23.9941 33.2313 24.0306C33.5946 24.1756 33.9368 24.322 34.2588 24.4696C35.3399 24.8522 36.5323 25.5146 37.8409 26.5407C42.0566 29.7696 46.1259 35.7312 47.2404 42.4232C47.0923 42.5923 46.9428 42.7573 46.792 42.9183C42.4663 27.1975 32.2162 23.4378 22.5908 21.8761Z"
        fill="#FF945A"
      />
      <path
        d="M7.45149 18.6455C9.61222 19.7699 10.2669 20.6234 11.117 23.4267C11.8307 20.706 12.4443 19.9204 14.5554 19.0226C12.3941 17.8982 11.74 17.0447 10.8898 14.2414C10.1767 16.9622 9.56317 17.7477 7.45149 18.6455Z"
        fill="#8CE6D1"
      />
      <path
        d="M7.23049 26.0064C6.27175 25.9906 5.50719 25.1968 5.52279 24.2334C5.53839 23.27 6.32825 22.5019 7.28699 22.5177C8.24573 22.5335 9.0103 23.3274 8.9947 24.2907C8.97909 25.2541 8.18923 26.0223 7.23049 26.0064Z"
        fill="#FF4F40"
      />
      <path
        d="M69.464 13.0798L69.0389 16.6155L71.6902 19.0076L68.3206 18.8762L67.7686 22.4621L66.128 18.9382L61.4225 18.6032L64.7649 16.7321L62.6499 13.6154L66.3769 15.1428L69.464 13.0798Z"
        fill="#A3DC1A"
      />
      <path
        d="M64.4755 16.0653C64.6176 15.5711 64.9229 15.0719 65.3663 14.7293L66.096 15.0286C65.5955 15.2659 65.2468 15.7782 65.1087 16.2602C64.8608 17.124 65.0682 18.1284 65.6489 18.9066L64.8741 18.8511C64.534 18.2661 64.3534 17.6071 64.3524 16.9636L64.7647 16.733L64.4303 16.241C64.4438 16.1819 64.4582 16.1231 64.475 16.0651L64.4755 16.0653Z"
        fill="#68CDB5"
      />
      <path
        d="M65.714 18.9051C65.992 18.266 66.3666 17.6123 67.0544 17.2597C67.7328 16.9119 68.5324 16.9555 69.2642 17.204C69.0853 16.8429 68.8844 16.5075 68.6594 16.1924C68.3845 15.8072 67.659 14.9286 66.7463 14.8917L67.4304 14.4348C68.0402 14.6576 68.6322 15.1282 69.1337 15.8019L69.0362 16.6134L70.1282 17.5984C70.13 17.6024 70.1314 17.6059 70.1332 17.6099C70.1421 17.6152 70.1508 17.6213 70.1597 17.6265L71.3778 18.7253C71.4476 18.8149 71.5136 18.9073 71.5775 19.0014L70.7895 18.9705C70.6934 18.8533 70.591 18.74 70.4815 18.6329C70.5122 18.7404 70.5415 18.8503 70.5697 18.9618L69.9027 18.9354C69.8136 18.6003 69.7126 18.2826 69.6002 17.9815C68.7407 17.5228 67.9022 17.5353 67.4047 17.7903C66.8418 18.0789 66.5329 18.6628 66.2805 19.2643L66.1262 18.9336L65.7138 18.904L65.714 18.9051Z"
        fill="#68CDB5"
      />
      <path
        d="M68.0892 20.3481L67.9992 20.9354C67.6235 20.8743 67.2531 20.7631 66.901 20.6093L66.4779 19.7017C66.9566 20.0316 67.5175 20.2594 68.0897 20.3483L68.0892 20.3481Z"
        fill="#68CDB5"
      />
    </g>
    <defs>
      <clipPath id="clip0_686_351">
        <rect
          width="80"
          height="53.6"
          fill="white"
          transform="translate(0 0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default SvgUs
