import Router from 'next/router'

import type { Colors, Icons } from '@mondough/monzo-ui'

import { getUserProfileSrc } from '../../utils'
import { AvatarWithBadgeProps } from '../AvatarWithBadge/AvatarWithBadge.types'
import {
  FeedAvatarProto,
  FeedBadge,
  FeedImage,
  FeedItemProto,
  FeedTextProto,
  MDSContentColor,
  MDSFillColor,
} from './Activity.types'
import { ActivityItemProps } from './ActivityItem/ActivityItem.types'

export const extractText = (
  text: FeedTextProto | undefined,
): string | undefined => {
  switch (text?.type) {
    case 'plain':
      return text.plain.text
    case 'money':
      return (text.money.amount / 100).toString() // to minor units
    default:
      return undefined
  }
}

export const convertMdsIconToIconName = (iconId: string): Icons => {
  if (iconId === 'icn_navigation_arrow_forward') {
    return 'arrow-right'
  }
  if (iconId === 'icn_general_arrows_swap') {
    return 'arrows-swap'
  }
  return 'person-2'
}

export function convertMdsToColor(
  color: MDSContentColor | MDSFillColor | '' | undefined,
  fallback: Colors,
): Colors {
  if (!color) {
    return fallback
  }
  switch (color) {
    case 'action':
    case 'primary':
      return 'action-blue'
    case 'secondary':
      return 'navy1'
    case 'disabled':
      return 'grey3'
    case 'negative':
    case 'action_destructive':
      return 'red1'
    case 'positive':
      return 'green1'
    case 'warning':
      return 'yellow1'
    case 'on_fill':
      return 'white'
    default:
      return fallback
  }
}

export const extractImage = (img: FeedImage) => {
  let image: AvatarWithBadgeProps = {}
  switch (img.type) {
    case 'local':
      switch (img.local.type) {
        case 'category':
          image = {
            type: 'category',
            category: {
              name: img.local.category.category_icon_id,
              color: img.local.category.category_color_id,
            },
          }
          break
        case 'icon':
          image = {
            type: 'icon',
            icon: {
              icon: convertMdsIconToIconName(img.local.icon.mds_icon_id),
              iconColor: convertMdsToColor(
                img.local.icon.tint_mds_content_color,
                'white',
              ),
              backgroundColor: convertMdsToColor(
                img.local.icon.background_mds_fill_color,
                'action-blue',
              ),
              size: 'large',
              iconSize: 18,
            },
          }
          break
      }
      break
    case 'remote':
      image = {
        type: 'profile',
        src: img.remote.url,
        placeholderName: img.remote.placeholder?.initials?.name_for_initials,
      }
      break
    case 'profile':
      image = {
        type: 'profile',
        src: getUserProfileSrc(img.profile.id),
        placeholderName: img.profile.placeholder?.initials?.name_for_initials,
      }
      break
  }
  return image
}

export const extractBadge = (
  avatar: FeedAvatarProto,
): AvatarWithBadgeProps['badge'] => {
  const badgePositions = [
    'top_right_badge',
    'top_left_badge',
    'top_badge',
    'left_badge',
    'right_badge',
    'bottom_left_badge',
    'bottom_badge',
    'bottom_right_badge',
  ] as const

  for (const position of badgePositions) {
    const badge = avatar[position as keyof typeof avatar] as
      | FeedBadge
      | undefined
    if (badge) {
      return {
        ...extractImage(badge.image),
        shape: badge.shape,
        position,
      }
    }
  }
  return undefined
}

export const extractAvatar = (
  avatar: FeedAvatarProto,
): ActivityItemProps['avatar'] => {
  return {
    badge: extractBadge(avatar), //TODO: add Monzo badge to profile case if user is a Monzo user
    ...extractImage(avatar.image),
    shape: avatar.shape,
  }
}

export const mapFeedActivityItem = (item: FeedItemProto): ActivityItemProps => {
  return {
    id: item.id,
    title: extractText(item.list_item.title) || '',
    subtitle: extractText(item.list_item.subtitle),
    avatar: extractAvatar(item.list_item.avatar),
    trailingContent: item.list_item.trailing_text
      ? {
          type: 'money',
          content: extractText(item.list_item.trailing_text) || '',
          subtitle: extractText(item.list_item.trailing_subtitle),
        }
      : undefined,
    onClick: item.list_item.uri
      ? async () => {
          await Router.push(
            '/tab/[tabId]/[itemId]',
            `${Router.query.tabId}/${item.id}`,
          )
        }
      : undefined,
  }
}
