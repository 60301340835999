import * as React from 'react'

export default function SvgBank(
  props: HTMLOrSVGElement,
): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 19 21">
      <path
        d="M2 10.5V14.5C2 15.33 2.67 16 3.5 16C4.33 16 5 15.33 5 14.5V10.5C5 9.67001 4.33 9.00001 3.5 9.00001C2.67 9.00001 2 9.67001 2 10.5ZM8 10.5V14.5C8 15.33 8.67 16 9.5 16C10.33 16 11 15.33 11 14.5V10.5C11 9.67001 10.33 9.00001 9.5 9.00001C8.67 9.00001 8 9.67001 8 10.5ZM1.5 21H17.5C18.33 21 19 20.33 19 19.5C19 18.67 18.33 18 17.5 18H1.5C0.67 18 0 18.67 0 19.5C0 20.33 0.67 21 1.5 21ZM14 10.5V14.5C14 15.33 14.67 16 15.5 16C16.33 16 17 15.33 17 14.5V10.5C17 9.67001 16.33 9.00001 15.5 9.00001C14.67 9.00001 14 9.67001 14 10.5ZM8.57 0.490007L0.67 4.65001C0.26 4.86001 0 5.29001 0 5.75001C0 6.44001 0.56 7.00001 1.25 7.00001H17.76C18.44 7.00001 19 6.44001 19 5.75001C19 5.29001 18.74 4.86001 18.33 4.65001L10.43 0.490007C9.85 0.180007 9.15 0.180007 8.57 0.490007Z"
        fill="currentColor"
      />
    </svg>
  )
}
