import * as React from 'react'
import { SVGProps } from 'react'
const SvgAngledArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.859 16.5711L14.846 8.58406L14.846 15.5628C14.846 16.1206 15.3036 16.5782 15.8614 16.5782C16.4191 16.5782 16.8696 16.1277 16.8696 15.57L16.8696 6.14577C16.8696 5.58804 16.4191 5.13756 15.8614 5.13756L6.43713 5.12326C5.87939 5.12326 5.42892 5.57374 5.42892 6.13147C5.42892 6.6892 5.87939 7.13968 6.43713 7.13968L13.4159 7.15398L5.42892 15.141C5.03565 15.5342 5.03565 16.1778 5.42892 16.5711C5.82219 16.9643 6.46573 16.9643 6.859 16.5711Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgAngledArrow
