import * as React from 'react'
const SvgExport = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6665 1.33333H3.33321V4H1.99988V1.33333C1.99988 0.596954 2.59683 0 3.33321 0H12.6665C13.4029 0 13.9999 0.596954 13.9999 1.33333V10.6667C13.9999 11.403 13.4029 12 12.6665 12H3.33321C2.59683 12 1.99988 11.403 1.99988 10.6667V8H3.33321V10.6667H12.6665V1.33333Z"
      fill="currentColor"
    />
    <path
      d="M10.4712 5.52859L8.13788 3.19526C7.87754 2.93491 7.45548 2.93491 7.19508 3.19526C6.93474 3.45561 6.93474 3.87772 7.19508 4.13807L8.38981 5.33333H1.33314C0.965011 5.33333 0.666504 5.63181 0.666504 6C0.666504 6.36819 0.965011 6.66667 1.33314 6.66667H8.38981L7.19508 7.86193C6.93474 8.12227 6.93474 8.5444 7.19508 8.80473C7.45548 9.06507 7.87754 9.06507 8.13788 8.80473L10.4712 6.47141L10.5201 6.41697L10.5691 6.34714L10.6041 6.28215L10.6333 6.20809L10.653 6.13382L10.6647 6.05016L10.6665 6L10.6638 5.93998L10.6591 5.90102L10.6426 5.82267L10.619 5.75244L10.5834 5.67729L10.5356 5.60313C10.5163 5.57711 10.4948 5.55219 10.4712 5.52859Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgExport
