import * as React from 'react'
export default function SvgCustomerProblems(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 5H5V9H3V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V15H5V19H19V5Z"
        fill="currentColor"
      />
      <path
        d="M15.707 11.2929L12.207 7.79289C11.8165 7.40237 11.1834 7.40237 10.7928 7.79289C10.4023 8.18342 10.4023 8.81658 10.7928 9.20711L12.5849 11H1.9999C1.4477 11 0.999939 11.4477 0.999939 12C0.999939 12.5523 1.4477 13 1.9999 13H12.5849L10.7928 14.7929C10.4023 15.1834 10.4023 15.8166 10.7928 16.2071C11.1834 16.5976 11.8165 16.5976 12.207 16.2071L15.707 12.7071L15.7803 12.6255L15.8539 12.5207L15.9063 12.4232L15.9502 12.3121L15.9797 12.2007L15.9972 12.0752L15.9999 12L15.9959 11.91L15.9889 11.8515L15.9641 11.734L15.9287 11.6287L15.8753 11.5159L15.8036 11.4047C15.7746 11.3657 15.7424 11.3283 15.707 11.2929Z"
        fill="currentColor"
      />
    </svg>
  )
}
