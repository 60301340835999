import * as React from 'react'
export default function SvgFileQif(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      {...props}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <rect id="a" x="0" y="0" width="40" height="40" rx="8" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <use fillOpacity="0" fill="#D8D8D8" fillRule="nonzero" xlinkHref="#a" />
        <g mask="url(#b)">
          <path fill="currentColor" d="M0 0h40v40H0z" />
          <path
            d="M20 7.5V15a2.5 2.5 0 002.5 2.5H30V30a2.5 2.5 0 01-2.5 2.5h-15A2.5 2.5 0 0110 30V10a2.5 2.5 0 012.5-2.5H20zm6.25 18.75h-12.5a1.25 1.25 0 000 2.5h12.5a1.25 1.25 0 000-2.5zm0-5h-12.5a1.25 1.25 0 000 2.5h12.5a1.25 1.25 0 000-2.5zM22.5 7.5L30 15h-7.5V7.5z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}
