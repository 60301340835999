import cx from 'classnames'
import React from 'react'

import TextButton from '../text-button'
import styles from './pagination.module.scss'

export type PaginationProps = {
  count: number
  limit: number
  offset: number
  onOffsetChange: (offset: number) => void
  showPosition?: boolean
}

type PageItemT = {
  id: string
  label: string
  pageNumber: number
}

function pagesToDisplay(pages: number[], currentPage: number): PageItemT[] {
  const allPageItems = pages.map((page) => ({
    id: page.toString(),
    label: page.toString(),
    pageNumber: page,
  }))

  if (pages.length <= 5) {
    return allPageItems
  }

  // We've checked the length, so know these elements aren't null
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const firstPageItem = allPageItems[0]!
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const lastPageItem = allPageItems[allPageItems.length - 1]!

  const startSpacerItem = {
    id: 'startSpacer',
    label: '...',
    pageNumber: -1,
  }
  const endSpacerItem = {
    id: 'endSpacer',
    label: '...',
    pageNumber: -1,
  }
  if (currentPage <= 3) {
    return [...allPageItems.slice(0, 3), endSpacerItem, lastPageItem]
  }
  if (currentPage >= pages.length - 2) {
    return [
      firstPageItem,
      startSpacerItem,
      ...allPageItems.slice(pages.length - 3),
    ]
  }

  return [
    firstPageItem,
    startSpacerItem,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    allPageItems[currentPage - 1]!,
    endSpacerItem,
    lastPageItem,
  ]
}

const PositionDisplay = ({
  count,
  limit,
  offset,
}: {
  count: number
  limit: number
  offset: number
}) => (
  <div className={styles['position-display']}>
    <span>{`${offset + 1}-${Math.min(offset + limit, count)} of `}</span>
    <span className={styles.total}>{count}</span>
  </div>
)

function PageItem({
  pageItem,
  currentPage,
  onClick,
}: {
  pageItem: PageItemT
  currentPage: number
  onClick: () => void
}) {
  const isSpacer = pageItem.pageNumber === -1
  if (isSpacer) {
    return (
      <div className={cx(styles.control, styles['page-item'])}>
        {pageItem.label}
      </div>
    )
  }

  if (pageItem.pageNumber === currentPage) {
    return (
      <div
        className={cx(
          styles.control,
          styles['page-item'],
          styles['current-page-item'],
        )}
        aria-label={`Page ${pageItem.label}`}
        aria-current
      >
        {pageItem.label}
      </div>
    )
  }

  return (
    <TextButton
      className={cx(styles.control, styles['page-item'])}
      aria-label={`Page ${pageItem.label}`}
      onClick={onClick}
    >
      {pageItem.label}
    </TextButton>
  )
}

function Pagination({
  count,
  limit,
  offset,
  onOffsetChange,
  showPosition = false,
}: PaginationProps) {
  const pages = Array.from(
    { length: Math.ceil(count / limit) },
    (_, i) => i + 1,
  )
  const currentPage = Math.ceil(offset / limit) + 1

  const pageItems = pagesToDisplay(pages, currentPage)

  return (
    <div className={styles.container}>
      {showPosition && (
        <PositionDisplay count={count} limit={limit} offset={offset} />
      )}
      <div className={styles.controls}>
        <TextButton
          className={styles.control}
          aria-label="Previous page"
          disabled={currentPage === 1}
          onClick={() => onOffsetChange(offset - limit)}
        >
          Prev
        </TextButton>
        {pageItems.map((pageItem) => (
          <PageItem
            key={pageItem.id}
            pageItem={pageItem}
            currentPage={currentPage}
            onClick={() => onOffsetChange((pageItem.pageNumber - 1) * limit)}
          />
        ))}
        <TextButton
          className={styles.control}
          aria-label="Next page"
          disabled={currentPage === pages.length}
          onClick={() => onOffsetChange(offset + limit)}
        >
          Next
        </TextButton>
      </div>
    </div>
  )
}

export default Pagination
