import * as React from 'react'
export default function SvgQuestionMark(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg {...props} viewBox="0 0 38 38">
      <path
        d="M19 .667C29.125.667 37.333 8.875 37.333 19c0 10.125-8.208 18.333-18.333 18.333C8.875 37.333.667 29.125.667 19 .667 8.875 8.875.667 19 .667zm-.112 25.666a1.833 1.833 0 100 3.667 1.833 1.833 0 000-3.667zm0-18.333l-.252.005a6.235 6.235 0 00-5.653 4.228 1.65 1.65 0 103.125 1.059 2.935 2.935 0 012.59-1.986l.19-.006a2.933 2.933 0 011.214 5.605c-.065.03-.127.063-.187.1l.086-.048a5.315 5.315 0 00-2.763 4.665v.495l.006.142a1.65 1.65 0 003.294-.142v-.495l.007-.171c.052-.62.386-1.174.9-1.514l.135-.083.116-.055A6.233 6.233 0 0018.887 8v.001z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
