import Head from 'next/head'
import Link from 'next/link'
import React, { useContext } from 'react'

import { buildActionAnalyticsAttributes } from '@mondough/analytics-v2'
import { Button, Title } from '@mondough/monzo-ui'

import { AuthContext } from '../../providers'
import styles from './PlainLayout.module.scss'
import { PlainLayoutProps } from './PlainLayout.types'

const PlainLayout: React.FC<PlainLayoutProps> = ({
  pageTitle,
  displayTitle = true,
  displayBreadcrumb = true,
  displayCardLayout = true,
  children,
}: PlainLayoutProps) => {
  const { isSessionUpgraded } = useContext(AuthContext)
  return (
    <>
      <Head>
        <title>Monzo | Shared Tabs{pageTitle ? ` | ${pageTitle}` : ''}</title>
      </Head>
      <section className={styles.container}>
        {displayBreadcrumb && isSessionUpgraded && (
          <Link href={'/'}>
            <Button
              theme="invisible"
              icon="chevron-left"
              className={styles['breadcrumb']}
              {...buildActionAnalyticsAttributes('details-breadcrumb', {
                breadcrumb: 'Your Shared Tabs',
              })}
            >
              Your Shared Tabs
            </Button>
          </Link>
        )}
        {pageTitle && displayTitle && (
          <Title is={'h1'} size="medium" className={styles.heading}>
            {pageTitle}
          </Title>
        )}
        {displayCardLayout ? (
          <article className={styles.card}>{children}</article>
        ) : (
          children
        )}
      </section>
    </>
  )
}

export default PlainLayout
