import * as React from 'react'
import { SVGProps } from 'react'
const SvgTrash = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 9l-.847 10.166A2 2 0 0115.16 21H8.84a2 2 0 01-1.993-1.834L6 9h12zM6 7a1 1 0 01-.117-1.993L6 5h2l1.414-1.414A2 2 0 0110.828 3h2.344a2 2 0 011.414.586L16 5h2a1 1 0 01.117 1.993L18 7H6z"
      fill="currentColor"
    />
  </svg>
)
export default SvgTrash
