import * as React from 'react'
import { SVGProps } from 'react'
const SvgInvestments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 80 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.6497 41.1331C37.0492 41.1331 35.7523 39.8333 35.7523 38.2292C35.7523 36.625 37.0492 35.3252 38.6497 35.3252C38.7549 35.3252 38.8588 35.3309 38.9608 35.3422L32.6711 26.376C31.269 27.1765 30.1145 28.4523 29.4537 30.1486C27.4382 35.3252 31.9102 41.8275 37.201 43.9108C42.4918 45.9941 46.0386 45.453 46.0386 45.453V45.431L41.4658 38.9122C41.1591 40.1862 40.0152 41.1325 38.6497 41.1325V41.1331Z"
      fill="#99AF73"
    />
    <path
      d="M63.5701 20.1394C63.4265 19.9658 63.2766 19.7966 63.1197 19.6343C59.9874 16.3983 51.6871 15.9798 48.318 24.2428C47.4167 26.4536 46.8744 28.5362 46.5563 30.3632L46.7131 36.8529L63.5701 20.1387V20.1394Z"
      fill="#61763D"
    />
    <path
      d="M46.7212 37.203L46.7281 37.4927C48.185 37.7913 53.011 38.4144 59.5918 35.4827C66.4938 32.4083 67.1577 24.7355 63.7281 20.3398L46.7206 37.203H46.7212Z"
      fill="#61763D"
    />
    <path
      d="M50.6404 4.25993C50.8753 3.89694 50.8747 3.42978 50.6391 3.06742L50.4539 2.78334C50.2228 2.42792 49.8234 2.21959 49.4008 2.23411C48.6752 2.2581 48.0422 2.73473 47.8173 3.42663L46.038 8.89111L46.0959 11.2799L50.641 4.25993H50.6404Z"
      fill="#61763D"
    />
    <path
      d="M53.9307 10.8964C54.5083 10.0018 54.3577 8.81942 53.5736 8.09911C52.7604 7.35229 51.4553 7.63574 51.0232 8.65276L46.2999 19.7825L46.3679 22.6075L53.9301 10.8957L53.9307 10.8964Z"
      fill="#99AF73"
    />
    <path
      d="M43.72 14.017C42.1454 9.15603 39.185 5.55766 35.7208 5.05262C34.2444 4.83735 32.084 5.28367 30.3853 6.60624L44.695 23.2806C44.7996 22.2207 45.0717 18.1892 43.72 14.0176V14.017Z"
      fill="#61763D"
    />
    <path
      d="M30.1881 6.76562C28.6506 8.05283 27.5464 10.0976 27.7839 13.0716C28.2878 19.3846 34.2085 22.6042 39.1844 23.2355C42.9207 23.7096 44.1357 23.6496 44.5092 23.5902C44.5533 23.5833 30.1881 6.76562 30.1881 6.76562Z"
      fill="#61763D"
    />
    <path
      d="M63.1009 11.9607C64.5793 11.9607 65.7778 10.7595 65.7778 9.27773C65.7778 7.79595 64.5793 6.59473 63.1009 6.59473C61.6224 6.59473 60.424 7.79595 60.424 9.27773C60.424 10.7595 61.6224 11.9607 63.1009 11.9607Z"
      fill="#FFA9DF"
    />
    <path
      d="M41.5262 38.5663C41.5394 38.4552 41.5463 38.3415 41.5463 38.2266C41.5463 36.8492 40.589 35.6964 39.3053 35.3984L41.5262 38.5663Z"
      fill="#61763D"
    />
    <path
      d="M35.7523 38.2262C35.7523 39.8303 37.0492 41.1302 38.6497 41.1302C40.0146 41.1302 41.159 40.1839 41.4658 38.9099L38.9608 35.3393C38.8588 35.3286 38.7549 35.3223 38.6497 35.3223C37.0492 35.3223 35.7523 36.6221 35.7523 38.2262Z"
      fill="#61763D"
    />
    <path
      d="M41.5469 38.2289C41.5469 38.3438 41.5394 38.4568 41.5268 38.5685L46.0277 44.9894L45.8218 36.4657L45.8193 36.3741C45.595 34.3073 44.8424 30.488 42.3116 27.9514C39.5471 25.1807 35.7554 24.7672 32.9021 26.2665L39.3059 35.4013C40.5895 35.6993 41.5469 36.852 41.5469 38.2295V38.2289Z"
      fill="#99AF73"
    />
    <path
      d="M42.3028 27.9379C39.8716 25.5011 36.6461 24.8875 33.9635 25.7947C34.3786 27.7346 35.7794 29.4543 37.6186 30.2036C39.5409 30.9864 41.8569 30.6752 43.5248 29.4467C43.1721 28.9132 42.7677 28.4038 42.3028 27.9379Z"
      fill="#61763D"
    />
    <path
      d="M33.1623 18.0726C33.3702 16.2368 32.6723 14.3196 31.3332 13.0494C30.3746 12.1397 29.1029 11.5779 27.7909 11.4453C27.743 11.9529 27.738 12.4939 27.7839 13.0703C28.2878 19.3832 34.2085 22.6028 39.1844 23.2341C39.311 23.2499 39.4338 23.2657 39.5554 23.2802C38.3939 20.6774 35.9583 18.6484 33.1623 18.072V18.0726Z"
      fill="#3A4920"
    />
    <path
      d="M61.5872 34.3511C66.8995 30.5879 66.8006 23.4391 63.1197 19.6362C60.5247 16.9558 54.3841 16.2083 50.4374 20.7277C50.3978 21.7062 50.6566 22.7132 51.1782 23.5439C52.1878 25.1537 54.0125 26.1329 55.8845 26.4416C56.2863 26.5079 56.6913 26.5451 57.0963 26.5596L63.57 20.1406C63.6242 20.2057 63.6771 20.2719 63.7287 20.3382L57.4478 26.5659C58.8146 26.5697 60.1877 26.3178 61.5129 25.9599C60.24 28.5653 60.3338 31.7489 61.5879 34.3504L61.5872 34.3511Z"
      fill="#A3DC1A"
    />
    <path
      d="M63.5701 20.1409L57.0964 26.5599L46.7132 36.855L46.5563 30.3653L46.3686 22.6073L46.3006 19.7823L46.0953 11.2806L46.0373 8.89183L45.9819 6.58887L45.1001 6.61033L45.5265 24.2563C45.5265 24.2563 44.6932 23.2942 44.6945 23.2809L30.3847 6.60654C30.3185 6.65831 30.253 6.71071 30.1882 6.765C30.1882 6.765 44.5534 23.5827 44.5093 23.5896L45.5385 24.7436L45.8194 36.3727L45.8219 36.4642L46.0279 44.988L41.5269 38.5677L39.306 35.3999L32.8934 26.253C32.8184 26.2922 32.7441 26.3326 32.6711 26.3749L38.9608 35.3411L41.4658 38.9118L46.0386 45.4305L46.1683 50.7908H23.1243V51.6746H62.3646V50.8281C62.3646 50.8281 62.3683 50.8268 62.3683 50.8255C62.3683 50.3066 62.4332 49.9032 61.9866 49.4967C61.6037 49.1476 61.0419 48.8654 59.7821 48.9916C58.5426 49.116 57.7647 49.3275 56.5774 49.7056C56.374 49.7706 56.1699 49.8458 55.957 49.8597C55.7435 49.8735 55.5136 49.8148 55.3744 49.652C55.1993 49.4468 55.2112 49.1387 55.0746 48.9064C54.8497 48.5232 54.3187 48.4733 53.8784 48.5289C52.3731 48.7195 50.8261 49.7889 49.6376 50.7914H47.0508L46.7295 37.4926L46.7226 37.2028L57.4485 26.5674L63.7295 20.3397C63.6772 20.2728 63.6243 20.2071 63.5707 20.1421L63.5701 20.1409Z"
      fill="#004E60"
    />
    <path
      d="M33.4118 48.309C33.3173 48.4005 33.2209 48.4883 33.1233 48.5747L33.3532 48.8052C33.5995 49.052 33.9982 49.052 34.2438 48.8052C34.4901 48.5583 34.4901 48.1587 34.2438 47.9125L34.0076 47.6758C33.8218 47.8942 33.6234 48.1063 33.4124 48.3096L33.4118 48.309Z"
      fill="#ED5E11"
    />
    <path
      d="M19.0639 32.0273C19.0639 32.0273 19.0627 32.0303 19.0621 32.0321C19.0627 32.0309 19.0639 32.0291 19.0645 32.0279H19.0633L19.0639 32.0273Z"
      fill="#ED5E11"
    />
    <path
      d="M20.1582 33.8325C20.024 33.448 20.0133 33.0168 20.1254 32.6242C20.0209 32.8091 19.8231 32.9341 19.5964 32.9341C19.2606 32.9341 18.9879 32.6614 18.9879 32.3243C18.9879 32.2896 18.9917 32.2561 18.9967 32.2227C18.7958 32.8704 18.8053 33.59 19.0282 34.2283C19.204 34.7314 19.4981 35.1645 19.8225 35.5439L21.0236 35.0824C20.6583 34.7068 20.3188 34.2946 20.1576 33.8318L20.1582 33.8325Z"
      fill="#ED5E11"
    />
    <path
      d="M19.8231 35.5459C19.9793 35.7283 20.1418 35.8988 20.3024 36.0585C20.1418 35.8988 19.9793 35.729 19.8231 35.5459Z"
      fill="#ED5E11"
    />
    <path
      d="M20.3031 36.0561L21.1553 35.214C21.1118 35.1704 21.0677 35.1269 21.0249 35.082L19.8237 35.5435C19.9799 35.726 20.1431 35.8958 20.3031 36.0555V36.0561Z"
      fill="#112231"
    />
    <path
      d="M19.5965 32.9335C19.8238 32.9335 20.021 32.8085 20.1255 32.6236C20.1759 32.5352 20.2049 32.4329 20.2049 32.3237C20.2049 31.9872 19.9328 31.7139 19.5965 31.7139C19.3678 31.7139 19.1694 31.8401 19.0655 32.027C19.0649 32.0283 19.0636 32.0301 19.063 32.0314C19.0308 32.0895 19.0088 32.1539 18.9975 32.2221C18.9918 32.2549 18.9886 32.289 18.9886 32.3237C18.9886 32.6602 19.2607 32.9335 19.5971 32.9335H19.5965Z"
      fill="#FF945A"
    />
    <path
      d="M25.9593 48.7089C24.3153 47.635 23.0304 46.0871 21.9874 44.418L19.621 46.6086L21.081 48.13C23.6722 50.8307 27.5843 51.4897 30.8193 50.0693C30.7865 50.0718 30.7537 50.0731 30.7204 50.075C29.0582 50.1691 27.3645 49.6274 25.9593 48.7089Z"
      fill="#ED5E11"
    />
    <path
      d="M19.4509 46.4316H19.4516L19.4535 46.4297C19.4529 46.4297 19.4516 46.431 19.4509 46.4316Z"
      fill="#ED5E11"
    />
    <path
      d="M33.6517 36.0118L32.4373 34.7461L21.9873 44.4188C23.0297 46.0879 24.3153 47.6359 25.9592 48.7097C27.3644 49.6276 29.0581 50.1699 30.7203 50.0758C30.7694 50.0733 30.8192 50.0701 30.8192 50.0701C31.6267 49.7185 32.4304 49.185 33.1232 48.574C33.2209 48.4881 33.3172 48.3997 33.4117 48.3082C33.6227 48.1049 33.8211 47.8928 34.0069 47.6744C35.0708 46.4244 35.7246 44.9402 35.9671 43.4018C36.3758 40.8046 35.6099 38.0534 33.6517 36.0125V36.0118Z"
      fill="#FF945A"
    />
    <path
      d="M19.1284 41.1076C19.6039 42.4308 20.6281 43.5456 21.9042 44.1308L29.6118 36.5124L31.4434 34.7018C31.6109 34.5364 31.7577 34.3578 31.8837 34.1696C32.7749 32.8408 32.642 31.0239 31.4768 29.8396C31.0353 29.3914 30.5011 29.0909 29.9355 28.9369C28.7955 28.6269 27.5269 28.9148 26.6256 29.8055L21.154 35.2138L20.3018 36.056L19.3438 37.0035C18.7322 38.2699 18.6528 39.7837 19.1284 41.1076Z"
      fill="#ED5E11"
    />
    <path
      d="M19.9333 46.0743C19.9756 46.0364 20.0184 45.9967 20.0612 45.9544L21.748 44.2871L20.0612 45.9544C20.0196 45.9954 19.9768 46.0358 19.9333 46.0743Z"
      fill="#ED5E11"
    />
    <path
      d="M19.9334 46.0737C19.9768 46.0352 20.019 45.9948 20.0612 45.9538L21.748 44.2865L21.9048 44.1312C20.6287 43.5466 19.6046 42.4311 19.129 41.1079C18.6535 39.7841 18.7328 38.2703 19.3444 37.0039L15.244 41.0568C13.8949 42.3901 13.8798 44.5668 15.21 45.919C16.3545 47.0819 18.1212 47.2549 19.4515 46.4323C19.4521 46.4323 19.4534 46.431 19.454 46.4304C19.6216 46.3269 19.7822 46.2082 19.9334 46.0731V46.0737Z"
      fill="#ED5E11"
    />
    <path
      d="M39.2675 41.0649C38.8972 39.2563 38.0198 37.5354 36.6454 36.1314C36.5346 36.0184 36.4218 35.906 36.304 35.7974L34.172 33.8171C35.0997 32.2464 34.8295 30.1871 33.4256 28.9157C32.676 28.2371 31.7363 27.905 30.7997 27.9063C30.5175 28.2301 30.2593 28.5836 30.0306 28.9656C30.5616 29.1265 31.0611 29.4163 31.478 29.8399C32.6433 31.0242 32.7762 32.8411 31.8849 34.17C31.7583 34.3581 31.6122 34.5367 31.4447 34.7021L29.7031 36.4237L29.613 36.5127L21.9054 44.1311L21.7486 44.2864L20.0618 45.9537C20.019 45.996 19.9762 46.0358 19.934 46.0736C19.7828 46.2087 19.6216 46.3274 19.4546 46.4309L19.4528 46.4335L19.6216 46.6096L21.9879 44.419L29.9689 37.0316L32.438 34.7463L33.6523 36.0121C35.5954 38.0373 36.3639 40.7619 35.9765 43.3408C36.3777 43.5554 36.7871 43.7454 37.2016 43.9089C37.9536 44.205 38.6698 44.448 39.3456 44.6469C39.5359 43.4582 39.5088 42.2429 39.2675 41.0649Z"
      fill="#112231"
    />
  </svg>
)
export default SvgInvestments
