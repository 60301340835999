import {
  sanitizeBreadcrumb,
  sanitizeEvent,
  setup as setupErrorHandling,
} from '@mondough/error-handling'

import getEnv from './getEnv'

export default function initErrorHandling(
  blocklist?: string[],
  ignoreErrors?: string[],
  componentName?: string,
) {
  const gitCommit = process.env.NEXT_PUBLIC_GIT_COMMIT
  if (gitCommit == null || gitCommit === '') {
    console.warn('Git commit not set')
    return
  }

  console.info(`Git commit: ${gitCommit}`)

  const sentryDSN = getEnv('sentryDSN')
  if (sentryDSN == null) {
    console.warn('Sentry DSN environment variable not set')
    return
  }

  const environment = getEnv('environment')
  if (environment == null || environment === '') {
    console.warn('Environment not set')
    return
  }

  // Prevent the web app from attempting to track any errors if it is being hosted from the local file system
  // (e.g. file:///Users/Bingo/downloads/business-monzo.html).
  // This is a niche case but I've added this is to prevent PII from being track in Sentry if a user ever
  // does this. It's particularly common on the statements page and has caused an incident:
  // https://monzo-incident.com/incident/5520/
  if (typeof window !== 'undefined' && window?.location.protocol === 'file:') {
    console.warn(
      'Error capturing isn’t enabled when running from the local file system',
    )

    return
  }

  setupErrorHandling({
    sentryDSN,
    gitCommit,
    environment,
    beforeBreadcrumb: sanitizeBreadcrumb(blocklist),
    beforeSend: sanitizeEvent(blocklist),
    ignoreErrors,
    componentName,
  })
}
