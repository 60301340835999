import * as React from 'react'
import { SVGProps } from 'react'
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.707 16.707a1 1 0 01-1.414-1.414l8-8a1 1 0 011.414 0l8 8a1 1 0 01-1.414 1.414L12 9.414l-7.293 7.293z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)
export default SvgChevronUp
