import * as React from 'react'
import { $ReadOnly } from 'utility-types'

type Props = $ReadOnly<
  HTMLOrSVGElement & {
    theme?: 'light' | 'dark'
  }
>

export default function SvgLoadingSpinner({
  theme = 'dark',
  ...props
}: Props): React.ReactElement<any> {
  return (
    <svg {...props} viewBox="0 0 24 24">
      <circle
        fill="none"
        stroke={theme === 'dark' ? 'grey' : 'white'}
        strokeWidth={3}
        opacity={0.3}
        cx={12}
        cy={12}
        r={10.5}
      />

      <circle fill="currentColor" cx={12} cy={1.5} r={1.5} />
    </svg>
  )
}
