export const ANALYTICS_VERSION = '2.0.0'
export const SESSION_ID_NAMESPACE = 'session'
export const SESSION_INACTIVITY_ALERT_NAMESPACE = 'restartAnalyticsSession'
export const DEFAULT_SESSION_LENGTH_IN_SECONDS = 300 // 5 minutes
export const TAB_ID_NAMESPACE = 'tab'
export const SESSION_ID_KEY = 'analytics_session_id'
export const CURRENT_LIFECYCLE_KEY = 'analytics_current_lifecycle'
export const TAB_ID_KEY = 'analytics_tab_id'
export const ACTION_EVENT_TYPE_FRIENDLY_NAMES = {
  mousedown: 'click',
  keydown: 'key',
}

export const MAX_REQUEST_ATTEMPTS = 5
export const REQUEST_RETRY_DELAY_IN_MS = 1000
