import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10ZM6.464 7.879A1 1 0 1 1 7.88 6.464L10 8.586l2.121-2.122a1 1 0 1 1 1.415 1.415L11.414 10l2.122 2.121a1 1 0 1 1-1.415 1.415L10 11.414l-2.121 2.122a1 1 0 1 1-1.415-1.415L8.586 10 6.464 7.879Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCircleClose
