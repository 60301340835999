import * as React from 'react'

export default function SvgSparkles(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={20}
      width={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.0907 5.81818L13.9998 3.81818L15.9998 2.90909L13.9998 2L13.0907 0L12.1816 2L10.1816 2.90909L12.1816 3.81818L13.0907 5.81818Z"
        fill="currentColor"
      />
      <path
        d="M13.0907 10.1818L12.1816 12.1818L10.1816 13.0909L12.1816 13.9999L13.0907 15.9999L13.9998 13.9999L15.9998 13.0909L13.9998 12.1818L13.0907 10.1818Z"
        fill="currentColor"
      />
      <path
        d="M7.63636 6.18176L5.81818 2.18176L4 6.18176L0 7.99994L4 9.81813L5.81818 13.8181L7.63636 9.81813L11.6364 7.99994L7.63636 6.18176ZM6.53818 8.71994L5.81818 10.3054L5.09818 8.71994L3.51273 7.99994L5.09818 7.27994L5.81818 5.69449L6.53818 7.27994L8.12364 7.99994L6.53818 8.71994Z"
        fill="currentColor"
      />
    </svg>
  )
}
