import * as React from 'react'
import { SVGProps } from 'react'

const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Icon">
      <path
        id="Vector"
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM8.75 13.68C8.62 14.11 8.13 14.31 7.73 14.13C7.39 13.97 7.22 13.59 7.33 13.23C7.45 12.82 7.51 12.4 7.5 11.99C7.49 11.58 7.44 11.19 7.33 10.81C7.23 10.45 7.39 10.06 7.73 9.91C8.15 9.72 8.64 9.95 8.77 10.4C8.92 10.91 8.99 11.43 9 11.97C9 12.53 8.92 13.11 8.75 13.68ZM11.89 15.27C11.72 15.68 11.22 15.84 10.83 15.62C10.5 15.43 10.37 15.03 10.51 14.68C10.84 13.91 11 13.05 11 12.12C11 11.16 10.82 10.23 10.47 9.34C10.33 8.98 10.49 8.58 10.83 8.4C11.22 8.2 11.7 8.38 11.86 8.79C12.28 9.85 12.49 10.97 12.49 12.12C12.51 13.25 12.3 14.31 11.89 15.27ZM15 16.6C14.83 17 14.36 17.18 13.98 16.99C13.63 16.82 13.46 16.4 13.61 16.04C14.2 14.65 14.5 13.29 14.5 11.98C14.5 10.67 14.2 9.33 13.62 7.97C13.46 7.61 13.63 7.19 13.98 7.02C14.37 6.82 14.83 7 15 7.4C15.66 8.94 16 10.48 16 11.98C16 13.48 15.66 15.04 15 16.6Z"
        fill="currentColor"
      />
    </g>
  </svg>
)

export default SvgDownload
