import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'

import {
  Tab,
  TabItem,
  getAllUserTabs,
  getReferral,
  getTabFromArray,
  reorderParticipantsInTabs,
} from '../utils'
import { AuthContext } from './AuthProvider'
import { ReferralContext } from './ReferralProvider'

export const TabContext = React.createContext({
  tabs: [] as Tab[] | null,
  tab: null as unknown as Tab,
  pageTitle: '',
  breadcrumb: {
    title: '',
    href: '',
  },
  tabItem: null as TabItem | null,
  setPageTitle: (_title: string) => {
    return
  },
  refreshTab: async () => {
    return
  },
  setTabItem: (_item: TabItem) => {
    return
  },
})

export const TabProvider = ({ children }: { children: JSX.Element }) => {
  const [pageTitle, setPageTitle] = React.useState('')
  const [breadcrumb, setBreadcrumb] = React.useState({
    title: '',
    href: '',
  })
  const { setReferral, referralData } = useContext(ReferralContext)

  const router = useRouter()
  const [currentTab, setCurrentTab] = React.useState<Tab | null>(null)
  const [tabs, setTabs] = React.useState<Tab[] | null>(null)
  const [tabItem, setTabItem] = React.useState<TabItem | null>(null)
  const { isSessionUpgraded } = useContext(AuthContext)

  const refreshTab = async () => {
    const refreshedTabs = await getAllUserTabs()
    setTabs(reorderParticipantsInTabs(refreshedTabs))
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!isSessionUpgraded) {
        return
      }
      const allTabs = tabs ?? (await getAllUserTabs())
      if (!tabs) {
        setTabs(reorderParticipantsInTabs(allTabs))
      }

      if (router.pathname.includes('/tab/[tabId]') && router.isReady) {
        const id =
          typeof router.query.tabId === 'string' ? router.query.tabId : null
        const tab = getTabFromArray(allTabs, id)
        setCurrentTab(tab)
        if (!tab || tab.status !== 'open') {
          await router.replace('/')
          return
        }
        if (router.pathname === '/tab/[tabId]') {
          setPageTitle(tab.name)
          setBreadcrumb({ title: 'Your Shared Tabs', href: '/' })
        } else {
          setBreadcrumb({
            title: `Your Shared Tabs / ${tab.name}`,
            href: `/tab/${id}`,
          })
        }
      }
    }
    void fetchData()
    return () => {
      setPageTitle('')
      setBreadcrumb({ title: '', href: '' })
      setCurrentTab(null)
    }
  }, [tabs, router, isSessionUpgraded])

  useEffect(() => {
    const fetchReferral = async () => {
      if (!isSessionUpgraded) {
        return
      }
      if (referralData?.tabId !== currentTab?.id) {
        const referral = await getReferral(currentTab?.id)
        setReferral(referral ? { ...referral, tabId: currentTab?.id } : null)
      }
    }
    void fetchReferral()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab?.id, isSessionUpgraded, referralData?.tabId])

  return (
    <TabContext.Provider
      value={{
        tab: currentTab as Tab,
        tabs: tabs,
        pageTitle,
        breadcrumb,
        tabItem,
        setPageTitle,
        refreshTab,
        setTabItem,
      }}
    >
      {children}
    </TabContext.Provider>
  )
}
