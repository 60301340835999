import * as React from 'react'

export default function SvgPersonCheck(
  props: HTMLOrSVGElement,
): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={20}
      width={20}
      viewBox="0 0 20 20"
      {...props}
    >
      <g clipPath="url(#clip0_414_724)">
        <path
          d="M7.33333 7.99996C8.80667 7.99996 10 6.80663 10 5.33329C10 3.85996 8.80667 2.66663 7.33333 2.66663C5.86 2.66663 4.66667 3.85996 4.66667 5.33329C4.66667 6.80663 5.86 7.99996 7.33333 7.99996ZM7.33333 3.99996C8.06667 3.99996 8.66667 4.59996 8.66667 5.33329C8.66667 6.06663 8.06667 6.66663 7.33333 6.66663C6.6 6.66663 6 6.06663 6 5.33329C6 4.59996 6.6 3.99996 7.33333 3.99996ZM3.33333 12C3.46667 11.58 5.04667 10.88 6.64 10.7066L8 9.37329C7.74 9.34663 7.54667 9.33329 7.33333 9.33329C5.55333 9.33329 2 10.2266 2 12V13.3333H8L6.66667 12H3.33333ZM13.7333 8.33329L10.3133 11.78L8.93333 10.3933L8 11.3333L10.3133 13.6666L14.6667 9.27329L13.7333 8.33329Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_414_724">
          <rect width="16" height="16" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  )
}
