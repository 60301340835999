import * as React from 'react'
import { SVGProps } from 'react'
const SvgPhoneAndLaptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 65 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.8521 11.2938C27.2445 11.2938 27.5626 10.9805 27.5626 10.5939C27.5626 10.2074 27.2445 9.89404 26.8521 9.89404C26.4597 9.89404 26.1416 10.2074 26.1416 10.5939C26.1416 10.9805 26.4597 11.2938 26.8521 11.2938Z"
      fill="#FFA9DF"
    />
    <path
      d="M26.1245 25.076L31.9987 6.1253C32.281 5.22107 33.742 4.13875 35.0329 4.50619L59.7294 11.5413C61.0388 11.9144 61.7899 13.2648 61.4042 14.5527L55.6961 34.0101C55.5122 34.6375 54.8309 34.9837 54.2047 34.7681L26.1245 25.0766V25.076Z"
      fill="#016B83"
    />
    <path
      d="M25.5046 24.7801L31.3789 5.8294C31.6612 4.92517 33.1221 3.84285 34.413 4.21029L59.1096 11.2454C60.4189 11.6185 61.17 12.9689 60.7843 14.2568L54.7375 34.8697L25.5046 24.7801Z"
      fill="#004E60"
    />
    <path
      d="M42.389 42.0973L53.9947 36.3326C54.4502 36.1064 54.7369 35.6471 54.7369 35.1447V34.451L25.5041 26.293L9.98303 30.1286V31.1666C9.98303 31.4503 10.1664 31.7027 10.4385 31.7952L41.1628 42.1791C41.5662 42.3154 42.0084 42.2854 42.389 42.0966V42.0973Z"
      fill="#2EAFC0"
    />
    <path
      d="M41.794 40.8806L54.7376 34.4516L25.7388 24.8582C25.5872 24.8082 25.4222 24.8088 25.2713 24.8613L9.98303 30.1298L41.7933 40.8812L41.794 40.8806Z"
      fill="#016B83"
    />
    <path
      d="M19.0062 28.4396L25.1862 25.9644L50.5501 34.2786L50.2951 34.4511L44.3549 36.7495L19.2524 28.5627L19.0062 28.4396Z"
      fill="#004E60"
    />
    <path
      d="M19.2524 28.5626L25.265 26.293L50.2952 34.451L44.433 37.0781L19.2524 28.5626Z"
      fill="#112231"
    />
    <path
      d="M24.706 31.124L36.0193 35.0165L35.7707 35.1052L32.2125 36.4119L20.8389 32.9575L20.6144 32.8581L24.706 31.124Z"
      fill="#004E60"
    />
    <path
      d="M24.706 31.3574L35.7706 35.1056L32.2124 36.6453L20.8389 32.9578L24.706 31.3574Z"
      fill="#112231"
    />
    <path
      d="M46.1649 9.20203C46.2492 8.94666 46.153 8.687 45.95 8.62205C45.7471 8.5571 45.5143 8.71146 45.4301 8.96683C45.3459 9.22219 45.4421 9.48185 45.645 9.5468C45.8479 9.61175 46.0807 9.45739 46.1649 9.20203Z"
      fill="#112231"
    />
    <path
      d="M23.8091 26.7456L49.2078 34.9386L48.8742 35.088L23.3739 26.9349L23.8091 26.7456Z"
      fill="#004E60"
    />
    <path
      d="M21.9929 27.3525L47.6606 35.6324L47.3282 35.7812L21.5577 27.5419L21.9929 27.3525Z"
      fill="#004E60"
    />
    <path
      d="M20.1772 27.959L46.1133 36.3251L45.7822 36.4738L19.7421 28.1483L20.1772 27.959Z"
      fill="#004E60"
    />
    <path
      d="M22.6215 32.7266L32.3025 35.9248"
      stroke="#004E60"
      strokeWidth="0.287363"
      strokeMiterlimit="10"
    />
    <path
      d="M31.9702 6.14746C31.9702 6.14746 30.6215 8.27274 30.2878 9.34819L31.0262 6.96483L31.1975 6.3493L31.9702 6.14746Z"
      fill="#112231"
    />
    <path
      d="M1.02386 14.0267C0.910938 13.673 0.941388 13.3087 1.08349 12.9944C1.15834 12.8288 1.21226 12.7126 1.26809 12.612C1.40955 12.3558 1.86693 11.3191 2.15049 11.2334L15.8033 7.91836C16.5855 7.6759 17.419 8.10458 17.6645 8.87508L27.5258 37.0142C27.7719 37.7847 26.7119 39.4145 25.9297 39.6569L12.1215 43.9319C11.3393 44.1743 10.5057 43.7456 10.2602 42.9751L1.02386 14.0267Z"
      fill="#016B83"
    />
    <path
      d="M1.58016 12.874C1.33402 12.1035 1.7692 11.2824 2.55137 11.0406L16.3596 6.76504C17.1418 6.52258 17.9754 6.95126 18.2209 7.72176L27.4572 36.6702C27.7034 37.4407 27.2682 38.2618 26.486 38.5036L12.6778 42.7785C11.8956 43.021 11.062 42.5923 10.8165 41.8218L1.58016 12.874Z"
      fill="#8CE6D1"
    />
    <path
      d="M12.0053 16.8954C12.1417 16.8529 12.2788 16.8173 12.4158 16.7867C13.3445 14.0827 13.1098 11.0663 12.2489 8.03809L2.55138 11.0407C1.76921 11.2832 1.33466 12.1037 1.58016 12.8742L5.09455 23.8893C6.3728 23.33 7.46962 22.7051 8.40404 22.0246C8.27273 19.758 9.7064 17.6065 12.0053 16.8948V16.8954Z"
      fill="#BBF6E2"
    />
    <path
      d="M12.4164 16.7862C11.7484 18.7309 10.4778 20.515 8.40405 22.0247C8.42816 22.4353 8.50301 22.8502 8.63369 23.2608C9.48692 25.936 12.3803 27.4226 15.096 26.5821C17.8117 25.7416 19.3209 22.8915 18.4676 20.2163C17.6569 17.6761 15.0065 16.2076 12.4164 16.7862Z"
      fill="#8CE6D1"
    />
    <path
      d="M12.4164 16.7861C12.2793 16.8168 12.1423 16.853 12.0059 16.8949C9.70698 17.6066 8.27268 19.7581 8.40463 22.0247C10.4784 20.5143 11.7484 18.7308 12.417 16.7861H12.4164Z"
      fill="#BBF6E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1667 10.6863L2.526 15.8386L1.58016 12.874C1.33402 12.1035 1.7692 11.2824 2.55137 11.0406L16.3596 6.76504C17.1418 6.52258 17.9754 6.95126 18.2209 7.72176L19.1667 10.6863Z"
      fill="#004E60"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2496 8.0376L2.55138 11.0409C1.76921 11.2833 1.33466 12.1038 1.58016 12.8743L2.526 15.8388L13.0064 12.5944C12.9613 11.1046 12.686 9.57297 12.2496 8.03822V8.0376Z"
      fill="#2EAFC0"
    />
    <g filter="url(#filter0_d_60_1403)">
      <path
        d="M4.29523 6.67647C3.94126 7.11765 3.87782 7.39448 3.93935 8.22622C3.55937 7.50946 3.36462 7.32637 2.84888 7.20014C3.20285 6.75896 3.26629 6.48213 3.20476 5.65039C3.58474 6.36715 3.77949 6.55024 4.29523 6.67647Z"
        fill="white"
      />
    </g>
    <path
      d="M10.2317 36.4471L8.18646 36.4752L7.93335 35.6797L10.2317 36.4471Z"
      fill="#112231"
    />
    <path
      d="M3.3995 12.3072C3.3995 12.3072 3.68306 10.8612 3.93617 10.6094L2.95544 10.9156L3.3995 12.3072Z"
      fill="#112231"
    />
    <path d="M6.90637 33.6333L9.54089 31.5986L8.00383 34.1757" fill="#112231" />
    <g filter="url(#filter1_d_60_1403)">
      <path
        d="M4.06251 9.62354C3.4237 10.3928 3.30507 10.8721 3.39959 12.3075C2.73541 11.0783 2.39222 10.7665 1.4762 10.5609C2.115 9.79164 2.23363 9.31234 2.13911 7.87695C2.80266 9.10613 3.14648 9.41795 4.06251 9.62354Z"
        fill="white"
      />
    </g>
    <path
      d="M4.01046 38.3716L3.98889 38.7421L4.18047 38.5041C4.18047 38.4803 4.18237 38.4572 4.18554 38.4334C4.12528 38.4209 4.06628 38.4003 4.01109 38.3716H4.01046Z"
      fill="#FF4F40"
    />
    <path
      d="M8.17757 32.9917C7.98726 32.9749 7.80584 32.8793 7.68657 32.7137C7.58381 32.5712 7.54638 32.4031 7.56731 32.2425C7.46328 32.2206 7.36495 32.175 7.28058 32.1075L6.68681 32.2412L4.96831 29.1567L4.73233 30.3822C4.7317 30.4003 4.72979 30.4184 4.72789 30.4365C4.88839 30.4703 5.03746 30.5609 5.14023 30.7034C5.35655 31.0039 5.28486 31.4201 4.97973 31.6338C4.8351 31.7351 4.66445 31.7719 4.50142 31.7513C4.4881 31.8132 4.4659 31.8732 4.43481 31.9294L4.27749 32.7468L1.44568 34.2265L2.441 34.5721C2.48667 34.569 2.53235 34.5696 2.57802 34.5752C2.61228 34.4171 2.70426 34.2703 2.84826 34.169C3.15339 33.956 3.57588 34.0266 3.79283 34.3271C3.8956 34.4696 3.93303 34.6377 3.9121 34.7983C4.07259 34.8321 4.22167 34.9227 4.32443 35.0645C4.54075 35.3651 4.46907 35.7813 4.16394 35.995C4.1595 35.9981 4.15442 36.0006 4.14998 36.0037L4.08211 37.1598C4.18868 37.1192 4.30223 37.1061 4.41198 37.1204C4.44623 36.9623 4.53821 36.8155 4.68222 36.7143C4.95753 36.5218 5.32864 36.5612 5.55764 36.7924L6.28209 35.8919C6.29668 35.7007 6.39437 35.5176 6.56565 35.3982C6.71029 35.297 6.88093 35.2601 7.04396 35.2807C7.07822 35.1226 7.1702 34.9758 7.3142 34.8746C7.61933 34.6615 8.04182 34.7321 8.25877 35.0327C8.36154 35.1751 8.39897 35.3432 8.37803 35.5038C8.53853 35.5376 8.6876 35.6282 8.79037 35.77C8.88109 35.8963 8.92042 36.0425 8.91407 36.1856L10.2323 36.4481L7.51149 33.6741L8.17821 32.9924L8.17757 32.9917ZM6.79592 34.154C6.65129 34.2553 6.48064 34.2922 6.31761 34.2715C6.28336 34.4296 6.19137 34.5765 6.04674 34.6777C5.74161 34.8908 5.31912 34.8202 5.10217 34.5196C4.9994 34.3771 4.96197 34.209 4.98291 34.0484C4.82241 34.0147 4.67333 33.9241 4.57057 33.7822C4.35425 33.4817 4.42593 33.0655 4.73106 32.8518C4.8757 32.7505 5.04634 32.7137 5.20937 32.7343C5.24363 32.5762 5.33561 32.4293 5.47961 32.3281C5.78474 32.115 6.20723 32.1856 6.42419 32.4862C6.52695 32.6287 6.56438 32.7968 6.54345 32.9574C6.70394 32.9911 6.85302 33.0817 6.95578 33.2236C7.1721 33.5242 7.10042 33.9403 6.79529 34.154H6.79592Z"
      fill="#FF4F40"
    />
    <path
      d="M4.50142 31.751C4.66446 31.7716 4.8351 31.7347 4.97974 31.6335C5.28487 31.4204 5.35655 31.0036 5.14023 30.703C5.03746 30.5606 4.88902 30.4699 4.72789 30.4362C4.73043 30.4181 4.7317 30.4 4.73233 30.3818L4.43481 31.9285C4.46526 31.8722 4.4881 31.8122 4.50142 31.7504V31.751Z"
      fill="#FF945A"
    />
    <path
      d="M4.32447 35.0639C4.22171 34.9215 4.07326 34.8309 3.91214 34.7977C3.93307 34.6371 3.89564 34.469 3.79287 34.3266C3.57656 34.026 3.15343 33.9554 2.8483 34.1685C2.70367 34.2697 2.61168 34.4159 2.57806 34.5746C2.53302 34.569 2.48671 34.5684 2.44104 34.5715L4.19823 35.182L4.15002 36.0038C4.15446 36.0007 4.15954 35.9982 4.16398 35.995C4.46911 35.7819 4.54079 35.3651 4.32447 35.0646V35.0639Z"
      fill="#FF945A"
    />
    <path
      d="M6.54469 32.9579C6.56563 32.7973 6.5282 32.6292 6.42543 32.4867C6.20911 32.1861 5.78599 32.1155 5.48086 32.3286C5.33622 32.4298 5.24424 32.5761 5.21062 32.7348C5.04759 32.7142 4.87694 32.751 4.73231 32.8523C4.42718 33.0654 4.35549 33.4822 4.57181 33.7828C4.67458 33.9252 4.82302 34.0158 4.98415 34.049C4.96322 34.2096 5.00064 34.3777 5.10341 34.5201C5.31973 34.8207 5.74285 34.8913 6.04798 34.6782C6.19262 34.577 6.2846 34.4308 6.31886 34.272C6.48189 34.2927 6.65253 34.2558 6.79717 34.1546C7.1023 33.9415 7.17398 33.5247 6.95766 33.2241C6.8549 33.0816 6.70646 32.991 6.54533 32.9579H6.54469Z"
      fill="#FF945A"
    />
    <path
      d="M7.5674 32.2424C7.54646 32.403 7.58389 32.5711 7.68666 32.7136C7.80592 32.8792 7.98798 32.9742 8.17766 32.9917L9.54091 31.5981L7.28003 32.1068C7.3644 32.1749 7.46336 32.2205 7.56676 32.2418L7.5674 32.2424Z"
      fill="#FF945A"
    />
    <path
      d="M4.68229 36.713C4.53766 36.8142 4.44567 36.9605 4.41205 37.1192C4.30231 37.1054 4.18876 37.1186 4.08218 37.1586L4.0105 38.3715C4.06569 38.4002 4.12468 38.4208 4.18495 38.4333C4.18178 38.4565 4.18051 38.4802 4.17987 38.504L5.55772 36.7911C5.32871 36.5605 4.95761 36.5205 4.68229 36.713Z"
      fill="#FF945A"
    />
    <path
      d="M8.78966 35.7695C8.68689 35.627 8.53845 35.5364 8.37732 35.5033C8.39825 35.3427 8.36083 35.1746 8.25806 35.0321C8.04174 34.7315 7.61862 34.6609 7.31349 34.874C7.16885 34.9753 7.07687 35.1215 7.04325 35.2802C6.88021 35.2596 6.70957 35.2965 6.56493 35.3977C6.39366 35.517 6.2966 35.7008 6.28137 35.8914L6.44123 35.6926L8.91272 36.1851C8.91907 36.042 8.87974 35.8957 8.78902 35.7695H8.78966Z"
      fill="#FF945A"
    />
    <path
      d="M19.8406 27.7745L11.9001 30.2333C11.3774 30.3952 11.0868 30.9438 11.2511 31.4587L11.2513 31.4593C11.4156 31.9743 11.9726 32.2605 12.4953 32.0986L20.4358 29.6399C20.9585 29.478 21.2491 28.9294 21.0848 28.4144L21.0846 28.4138C20.9203 27.8989 20.3633 27.6127 19.8406 27.7745Z"
      fill="#8CE6D1"
    />
    <path
      d="M17.4697 8.81663C17.4697 8.81663 18.4853 8.84287 18.722 9.29155L18.4562 8.4873L17.4697 8.81663Z"
      fill="#112231"
    />
    <path
      d="M20.8186 30.8414L12.8781 33.3002C12.3554 33.462 12.0648 34.0107 12.2292 34.5256L12.2294 34.5262C12.3937 35.0412 12.9506 35.3274 13.4734 35.1655L21.4138 32.7068C21.9366 32.5449 22.2271 31.9962 22.0628 31.4813L22.0626 31.4807C21.8983 30.9658 21.3413 30.6796 20.8186 30.8414Z"
      fill="#8CE6D1"
    />
    <path
      d="M18.8488 5.99219C18.9973 7.75003 18.8894 7.95187 17.4697 8.81673C17.4704 8.81673 17.4716 8.81673 17.4723 8.81673C17.4723 8.81673 17.4729 8.81673 17.4735 8.81673C17.5471 8.80923 17.6175 8.80173 17.686 8.79486C18.0476 8.75924 18.3369 8.73924 18.578 8.75049C19.3658 8.78673 19.6418 9.15167 20.2413 10.3565C20.0078 8.53552 20.1226 8.33493 21.6204 7.53194C19.9108 7.62317 19.7078 7.51381 18.8488 5.99219Z"
      fill="#C3FF34"
    />
    <path
      d="M39.3599 14.4096C41.2351 14.2684 43.1071 14.1034 44.967 13.836C47.1315 13.5254 49.4196 12.9736 51.2853 11.9038L39.8959 8.66309C39.9904 10.6346 39.8337 12.5624 39.3599 14.409V14.4096Z"
      fill="#8CE6D1"
    />
    <path
      d="M35.0721 26.746L53.5316 32.7075C53.7422 32.7756 53.9693 32.6613 54.0365 32.4532L57.2033 22.666C54.9564 23.349 52.5883 23.7583 50.2665 24.0383C45.1402 24.6576 39.8496 24.8394 35.0721 26.746Z"
      fill="#8CE6D1"
    />
    <path
      d="M59.4985 14.2414L51.2853 11.9043C49.4196 12.9741 47.1321 13.5259 44.967 13.8365C43.1064 14.1033 41.2344 14.2689 39.3599 14.4101C39.252 14.8294 39.129 15.2444 38.9881 15.6543C37.9402 18.7051 35.9476 21.5027 32.6952 23.8711C32.026 24.3585 31.3028 24.8278 30.5244 25.2771L35.0722 26.7456C39.8496 24.8391 45.1402 24.6566 50.2665 24.0379C52.5883 23.7574 54.9564 23.3487 57.2033 22.6657L59.7681 14.7388C59.8366 14.5276 59.7141 14.3027 59.4985 14.2414Z"
      fill="#8CE6D1"
    />
    <path
      d="M32.2517 6.90151L29.8652 15.2833C30.1386 15.2427 30.4121 15.202 30.6855 15.1658C33.563 14.7865 36.4652 14.6278 39.3604 14.4103C39.8343 12.5637 39.991 10.6359 39.8965 8.66435L32.7472 6.63031C32.534 6.56969 32.312 6.69155 32.2517 6.90214V6.90151Z"
      fill="#BBF6E2"
    />
    <path
      d="M38.9881 15.6538C39.1289 15.2433 39.2526 14.8283 39.3598 14.4097C36.4646 14.6271 33.5624 14.7859 30.6849 15.1652C30.4115 15.2014 30.138 15.242 29.8646 15.2827L27.4173 23.8775C27.3595 24.0806 27.4743 24.2918 27.6773 24.3574L30.5237 25.2766C31.3021 24.8267 32.0253 24.358 32.6945 23.8706C35.9469 21.5016 37.9395 18.7046 38.9875 15.6538H38.9881Z"
      fill="#BBF6E2"
    />
    <g filter="url(#filter2_d_60_1403)">
      <path
        d="M62.1914 26.7456C61.8565 28.2122 61.7163 28.3491 60.3372 28.6953C60.3441 28.6965 60.3505 28.6978 60.3568 28.699C60.5966 28.7465 60.8009 28.789 60.976 28.8353C61.8527 29.0646 61.9929 29.3639 62.1908 30.645C62.4737 29.1058 62.619 28.9715 64.045 28.6953C62.631 28.3391 62.4946 28.1991 62.1908 26.7456H62.1914Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter3_d_60_1403)">
      <path
        d="M31.9702 6.14767C30.3506 7.13001 29.8698 7.92613 29.2767 10.6088C28.6835 7.92551 28.2033 7.12939 26.5831 6.14767C28.2027 5.16533 28.6835 4.36921 29.2767 1.68652C29.8698 4.36984 30.35 5.16596 31.9702 6.14767Z"
        fill="white"
      />
    </g>
    <path
      d="M63.337 33.9595L60.7653 33.5183L59.9267 31.2056L59.1445 33.5183L56.4605 33.6289L57.8402 34.9081L58.3617 35.3911L56.2372 36.6028L57.497 36.5053L59.088 36.3822L58.8647 38.9712L60.7653 36.1073L63.2812 37.0434L61.6604 35.0606L63.337 33.9595Z"
      fill="#2EAFC0"
    />
    <path
      d="M62.5891 34.2788C62.2865 34.525 61.9897 34.7775 61.6985 35.0356L62.6849 34.3882L62.5898 34.2788H62.5891Z"
      fill="white"
    />
    <path
      d="M59.6679 36.8601L58.9961 37.4538L58.9752 37.6944L59.0881 37.5944C59.0488 37.6444 59.0101 37.695 58.9707 37.7456L58.9409 38.0905C59.5042 37.4257 60.098 36.7864 60.7203 36.1746L60.7654 36.1065L60.7831 36.1134C60.8478 36.0503 60.9119 35.9871 60.9772 35.924L61.7835 35.2116L61.6643 35.0654C61.3927 35.3066 61.125 35.5522 60.863 35.8034L60.4012 36.2115C61.0578 35.3991 61.7252 34.5936 62.4014 33.7981L62.2111 33.7656C61.3477 34.783 60.4989 35.8159 59.6679 36.8601Z"
      fill="white"
    />
    <path
      d="M58.1574 35.2019C58.9173 34.3783 59.6989 33.5728 60.5007 32.7885L60.4385 32.6167C59.6151 33.4191 58.8133 34.2433 58.0343 35.0875L58.1574 35.2019Z"
      fill="white"
    />
    <path
      d="M56.8125 33.6144L56.7472 33.8944L56.8893 34.0256L56.9857 33.6069L56.8125 33.6144Z"
      fill="white"
    />
    <path
      d="M59.7681 34.5471C59.3329 35.162 58.9123 35.7882 58.5133 36.4268L58.7207 36.4106C59.1864 35.6713 59.6799 34.9496 60.1918 34.2409C60.5033 34.0185 60.8167 33.7985 61.1326 33.581L60.8985 33.5411C60.7653 33.6329 60.6327 33.7254 60.5001 33.8185C60.5832 33.7054 60.6676 33.5929 60.752 33.4811L60.6841 33.293C60.4779 33.5679 60.273 33.8441 60.0713 34.1235C59.0481 34.8558 58.0464 35.6182 57.0695 36.4081C57.237 36.2169 57.407 36.0275 57.577 35.8388L57.1196 36.1C56.9826 36.255 56.8462 36.4106 56.7111 36.5668L57.1849 36.5299C58.0286 35.8482 58.8907 35.1864 59.7693 34.5478L59.7681 34.5471Z"
      fill="white"
    />
    <path
      d="M56.2372 36.6028L56.2943 36.5984L56.3013 36.5659L56.2372 36.6028Z"
      fill="white"
    />
    <path
      d="M55.5952 9.87095C58.2954 9.87095 60.4843 7.71471 60.4843 5.05486C60.4843 2.39501 58.2954 0.23877 55.5952 0.23877C52.8951 0.23877 50.7062 2.39501 50.7062 5.05486C50.7062 7.71471 52.8951 9.87095 55.5952 9.87095Z"
      fill="#112231"
    />
    <path
      d="M56.7218 9.63218C59.422 9.63218 61.6109 7.47594 61.6109 4.81609C61.6109 2.15624 59.422 0 56.7218 0C54.0217 0 51.8328 2.15624 51.8328 4.81609C51.8328 7.47594 54.0217 9.63218 56.7218 9.63218Z"
      fill="#FF4F40"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.3575 7.31998C57.3575 7.66555 57.0733 7.94613 56.7218 7.94613C56.3704 7.94613 56.1122 7.69054 56.0881 7.36685L56.0862 7.31998H57.3568H57.3575ZM56.7218 1.68715C57.0726 1.68715 57.3575 1.9671 57.3575 2.3133V2.42015C58.0768 2.67074 58.5977 3.33375 58.6268 4.1205L58.6281 4.19112V5.12971C58.6281 5.53902 58.8279 5.90271 59.1362 6.13142C59.2136 6.18829 59.2631 6.27952 59.2631 6.38138C59.2631 6.55448 59.121 6.69446 58.9453 6.69446H54.4977C54.322 6.69446 54.1799 6.55448 54.1799 6.38138C54.1799 6.29202 54.218 6.21141 54.2789 6.15392L54.3068 6.1308C54.5973 5.91583 54.7915 5.58089 54.813 5.20095L54.8149 5.12909V4.19049L54.8162 4.11988C54.8454 3.33313 55.3668 2.66949 56.0856 2.41953V2.31267C56.0856 1.9671 56.3698 1.68652 56.7206 1.68652L56.7218 1.68715Z"
      fill="#F2F8F3"
    />
    <defs>
      <filter
        id="filter0_d_60_1403"
        x="2.84888"
        y="5.65039"
        width="1.44641"
        height="3.57568"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_60_1403"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_60_1403"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_60_1403"
        x="1.4762"
        y="7.87695"
        width="2.5863"
        height="5.43066"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_60_1403"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_60_1403"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_60_1403"
        x="60.3372"
        y="26.7456"
        width="3.70789"
        height="4.89941"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_60_1403"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_60_1403"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_60_1403"
        x="26.5831"
        y="1.68652"
        width="5.38708"
        height="9.92236"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_60_1403"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_60_1403"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
export default SvgPhoneAndLaptop
